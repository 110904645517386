import { produce } from 'immer'

import { AppActions } from '../actions'
import { Tag } from '../components/types/tag'

export type AnalyticsState = {
  list: Tag[]
}

export const initialState: AnalyticsState = {
  list: [],
}

const analyticsReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'ANALYSIS_TAGS_FETCH_SUCCESS': {
      draftState.list = action.payload
      break
    }

    default: {
      break
    }
  }
}, initialState)

export default analyticsReducer
