import { Trans } from 'react-i18next'
import { Step } from 'react-joyride'

import { ADD_CONTENTS_TOUR_KEY } from '../../../constants/localStorage'
import { getAddArticleModalOpen } from '../../../selectors/uiSelectors'
import { useAppSelector } from '../../hooks/useAppSelector'
import ProductTour from '../ProductTour'
import { disabledBeaconAndAnimation, disabledScrolling } from '../constants'
import useDelayTour from '../useDelayTour'
import { prependHash } from '../utility'

export enum AddContentsTourTarget {
  STEP_1 = 'ACT_STEP_1',
  STEP_2 = 'ACT_STEP_2',
}

const steps: Step[] = [
  {
    target: prependHash(AddContentsTourTarget.STEP_1),
    content: <Trans>Remember to add the date the original content was published</Trans>,
    placement: 'left-start',
    ...disabledBeaconAndAnimation,
    ...disabledScrolling,
  },
  {
    target: prependHash(AddContentsTourTarget.STEP_2),
    content: <Trans>Add a tag to the content to find it in the platform</Trans>,
    placement: 'top-start',
    ...disabledBeaconAndAnimation,
    ...disabledScrolling,
  },
]

const AddContentsTour = () => {
  const isAddContentModalOpen = useAppSelector(getAddArticleModalOpen)
  const show = useDelayTour(isAddContentModalOpen, 1000) // Delay the tour, to make sure the elements inside of the modal are rendered.

  if (!show) {
    return null
  }

  return <ProductTour tourKey={ADD_CONTENTS_TOUR_KEY} steps={steps} />
}

export default AddContentsTour
