export enum UmamiEvent {
  // Alerts
  ALERTS_CREATE_BUTTON_CLICK = 'Create alert button clicked',
  ALERTS_CREATE_FIRST_BUTTON_CLICK = 'Create first alert button clicked',

  // Search
  SEARCH_FILTER_PILL_EXCLUDED = 'Search filter pill excluded',
  SEARCH_FILTER_PILL_INCLUDED = 'Filter pill included',
  SEARCH_FILTER_PILL_INFO_VIEWED = 'Search filter pill information viewed',
  SEARCH_FILTER_PILL_REMOVED = 'Search filter pill removed',
  SEARCH_FILTER_TOGGLED = 'Search filter toggled',
  SEARCH_PERIOD_CHANGED = 'Search period changed',
}

export const trackUmamiEvent = (event: UmamiEvent, data?: Record<string, any>) => {
  if (typeof window.umami?.track === 'function') {
    window.umami.track(event, data)
  }
}
