import {
  cn,
  ScrollArea,
  ScrollAreaViewPort,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'
import classNames from 'classnames'
import {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  HTMLAttributes,
  LiHTMLAttributes,
  MouseEventHandler,
} from 'react'
import { Link, NavLink } from 'react-router-dom'

import { useNavigationContext } from '../NavigationContext'
import { getActiveNavigationSection, NavigationSectionKey } from '../../../../helpers/navigation'

const NavigationSection = forwardRef<HTMLLIElement, LiHTMLAttributes<HTMLLIElement>>(
  ({ className, children, ...props }, ref) => {
    const { searchQuery } = useNavigationContext()

    return (
      <li
        ref={ref}
        className={cn(
          'relative',
          {
            'mb-4': searchQuery,
          },
          className,
        )}
        {...props}
      >
        {children}
      </li>
    )
  },
)
NavigationSection.displayName = 'NavigationSection'

const NavigationSectionHeaderLink = forwardRef<
  React.ElementRef<typeof NavLink>,
  React.ComponentPropsWithoutRef<typeof NavLink> & {
    section: NavigationSectionKey
    isExpanded: boolean
    label: string
  }
>(({ onClick: onClickCallback, className, children, isExpanded, label, section, ...props }, ref) => {
  const { searchQuery, setSearchQuery } = useNavigationContext()

  const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    setSearchQuery('')

    if (typeof onClickCallback === 'function') {
      onClickCallback(event)
    }
  }

  const activeSection = getActiveNavigationSection()

  return (
    <TooltipProvider>
      {/* Block tooltip when menu is expanded, assume it is expanded if variable doesn't exist */}
      <Tooltip open={isExpanded === true || undefined ? false : undefined}>
        <TooltipTrigger className="w-full" asChild>
          <NavLink
            ref={ref}
            onClick={handleOnClick}
            className={cn(
              'peer flex h-11 w-full items-center gap-x-3 px-4 py-[0.3125rem] text-title text-sky.1 hover:bg-grey.7 hover:text-sky.1 focus:text-sky.1 focus:ring-0 focus-visible:bg-grey.7 focus-visible:text-sky.1',
              {
                'h-[3.75rem]': searchQuery,
                'bg-grey.7': !searchQuery && activeSection === section,
              },
              className,
            )}
            {...props}
          >
            {children}
            {(isExpanded === true || undefined) && <span className="text-title">{label}</span>}
          </NavLink>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="right">{label}</TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
})
NavigationSectionHeaderLink.displayName = 'NavigationSectionHeaderLink'

export const NavigationSectionHeaderButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> & { isExpanded?: boolean }
>(({ type = 'button', className, children, isExpanded, ...props }, ref) => (
  <TooltipProvider>
    {/* Block tooltip when menu is expanded, assume it is expanded if variable doesn't exist */}
    <Tooltip open={isExpanded === true || undefined ? false : undefined}>
      <TooltipTrigger className="h-11 w-full" asChild>
        <button
          ref={ref}
          type={type}
          className={classNames(
            'peer flex h-11 w-full items-center gap-x-3 px-4 py-[0.3125] text-title text-sky.1 hover:bg-grey.7 hover:text-sky.1 focus:ring-0 focus-visible:bg-grey.7 focus-visible:text-sky.1',
            className,
          )}
          {...props}
        >
          {children}
        </button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent side="right">
          <Trans>Dashboard</Trans>
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  </TooltipProvider>
))
NavigationSectionHeaderButton.displayName = 'NavigationSectionHeaderButton'

const NavigationSectionContent = forwardRef<ElementRef<typeof ScrollArea>, ComponentPropsWithoutRef<typeof ScrollArea>>(
  ({ className, children, ...props }, ref) => (
    <ScrollArea ref={ref} className={cn('ml-9 flex flex-col', className)} {...props}>
      <ScrollAreaViewPort>
        <ul>{children}</ul>
      </ScrollAreaViewPort>
    </ScrollArea>
  ),
)
NavigationSectionContent.displayName = 'NavigationSectionContent'

const NavigationSectionSearchItem = forwardRef<HTMLLIElement, LiHTMLAttributes<HTMLLIElement>>(
  ({ className, children, ...props }, ref) => (
    <li ref={ref} className={cn('flex h-8 items-center px-4', className)} {...props}>
      {children}
    </li>
  ),
)
NavigationSectionSearchItem.displayName = 'NavigationSectionSearchItem'

const NavigationSectionSearchItemLink = forwardRef<ElementRef<typeof Link>, ComponentPropsWithoutRef<typeof Link>>(
  ({ onClick: onClickCallback, className, children, ...props }, ref) => {
    const { setSearchQuery } = useNavigationContext()

    const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
      setSearchQuery('')

      if (typeof onClickCallback === 'function') {
        onClickCallback(event)
      }
    }

    return (
      <Link
        ref={ref}
        onClick={handleOnClick}
        className={classNames('block truncate text-title font-medium text-sky.1 hover:text-sky.1', className)}
        {...props}
      >
        {children}
      </Link>
    )
  },
)
NavigationSectionSearchItemLink.displayName = 'NavigationSectionSearchItemLink'

const NavigationSectionEmptySearchMessage = forwardRef<
  HTMLDivElement,
  Omit<HTMLAttributes<HTMLDivElement>, 'children'>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('ml-4 text-sm text-sky.1', className)} {...props}>
    <Trans>No results matching your search</Trans>
  </div>
))
NavigationSectionEmptySearchMessage.displayName = 'NavigationSectionEmptySearchMessage'

export {
  NavigationSection,
  NavigationSectionHeaderLink,
  NavigationSectionContent,
  NavigationSectionSearchItem,
  NavigationSectionSearchItemLink,
  NavigationSectionEmptySearchMessage,
}
