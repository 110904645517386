import { DOM } from 'rx-dom'

import { __, assoc, contains, dissoc, map, merge } from 'ramda'
import { AlertPreviewResponse } from '../../components/types/alert'
import { CommonRecipientFilter, ContactFilter } from '../../components/types/contact'
import { AlertTag } from '../../components/types/tag'
import config from '../common/config'
import type { AlertDetailed } from '../flow'
import { NextAlert } from '../flow'
import { TAG_TYPES, TAG_VISIBILITY } from '../tags'
import { Alert, AlertContactOverview, AlertCreate, ResendAlertRecipients } from '../../api/opoint.schemas'

import { M360Article } from '../articles/types'
import { AlertHistoryObjectItem, AlertHistoryPreviewItem, AlertNextPreview } from './types'

/**
 * Constants
 */

export const WORKING_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
export const WEEKEND_DAYS = ['saturday', 'sunday']

export const ALL_DAYS = [...WORKING_DAYS, ...WEEKEND_DAYS, 'holidays']

export const WHEN_ALERT = {
  WEEKDAYS_AT_8: 1,
  WEEKDAYS_AT_8_AND_2: 2,
  AS_SOON_AS_POSSIBLE: 3,
  ADVANCED: 4,
}

export const isWorkingDay = () => contains(__, WORKING_DAYS)
export const isWeekendDay = () => contains(__, WEEKEND_DAYS)

export const WhenAlert = {
  weekdays_at_8: 0,
  weekdays_at_8_and_2: 1,
  as_soon_as_possible: 3,
  advanced: 4,
}

export const recipientsEntitiesApiNormalize = (
  recipients: (ContactFilter | CommonRecipientFilter)[],
): ResendAlertRecipients[] =>
  recipients.map(({ type, entity: { id } }) => ({ type: type === 'phoneNumber' ? 'mobile' : type, value: `${id}` }))

/**
 * Functions
 */

export const getAlerts = async (): Promise<Alert[]> => {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/alerts/'),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const getAlertsRecipients = async (): Promise<Array<AlertContactOverview>> => {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/alerts/alert-contact-details/'),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const getAlert = async (id: number): Promise<AlertDetailed> => {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${id}/`),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const getAlertNext = async (id: number): Promise<NextAlert> => {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${id}/next/`),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const getAlertNextPreview = async (id: number): Promise<AlertNextPreview> => {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${id}/next/preview/`),
    method: 'POST',
    body: JSON.stringify({ id, view: 'preview' }),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

// TODO: Proper flowtype for history @Honza
export const getAlertHistory = async (id: number, page?: string): Promise<AlertHistoryObjectItem> => {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${id}/history/${page ? `?page=${page}` : ''}`),
    method: 'GET',
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const getAlertInfo = async (mailLogId: number, hasJson: boolean = true) => {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alert_info/${mailLogId}?json=${hasJson}`),
    method: 'GET',
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const getAlertTags = async (articles: any, baskets: any) => {
  const body = {
    baskets,
    articles,
  }

  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/tags/detect_tagged/'),
    method: 'POST',
    body: JSON.stringify(body),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

// TODO: Proper flowtype for history @Honza
export const getAlertHistoryItem = async (
  alertId: number,
  historyId: number,
  timestamp: number,
): Promise<AlertHistoryPreviewItem> => {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${alertId}/history/${historyId}/${timestamp}/`),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function saveAlert(alert: AlertCreate): Promise<AlertDetailed> {
  const isNewAlert = alert.id === undefined

  const method = isNewAlert ? 'POST' : 'PUT'
  const url = isNewAlert ? '/alerts/' : `/alerts/${alert.id}/`
  const body = isNewAlert ? dissoc('id', alert) : alert

  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    method,
    url: config.url.api(url),
    body: JSON.stringify(body),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function deleteAlert(alertId: string): Promise<AlertDetailed> {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${alertId}/`),
    method: 'DELETE',
    responseType: 'text',
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function sendNow(alertId: number): Promise<AlertDetailed> {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${alertId}/send-now/`),
    method: 'POST',
    responseType: 'text',
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function getArticlesToRemove(alertId: string, pageId: number): Promise<Array<M360Article>> {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${alertId}/articles/?page=${pageId}`),
    method: 'GET',
    responseType: 'text',
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function removeArticlesFromAlert(alertId: number, articles): Promise<Array<M360Article>> {
  const body = {
    articles: Object.values(articles),
  }
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/alerts/${alertId}/articles/`),
    method: 'POST',
    responseType: 'text',
    body: JSON.stringify(body),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

// TODO: write tests
export function processAlertTags(alerts: Alert[]): Array<AlertTag> {
  // @ts-expect-error Muted so we could enable TS strict mode
  return alerts?.map((alert) => ({
    id: alert.id,
    children: map((a) => assoc('type', TAG_TYPES.ALERT, a), alert.baskets),
    subject: alert.subject,
    type: TAG_TYPES.ALERT,
    visibility: TAG_VISIBILITY.ALWAYS,
    expanded: false,
  }))
}

export const getAlertJsonPreview = async (id: number): Promise<AlertPreviewResponse[]> => {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    method: 'POST',
    url: config.url.api(`/alerts/${id}/next/preview/?json=1`),
    body: JSON.stringify({ id, view: 'preview' }),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
