import { produce } from 'immer'

import { AppActions } from '../actions'
import { Trash } from '../components/types/trash'

export type TrashState = {
  list: Array<Trash>
}

export const initialState: TrashState = {
  list: [],
}

const trashReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'TRASH_FETCH_SUCCESS': {
      draftState.list = action.payload.trashTags
      break
    }

    default: {
      break
    }
  }
}, initialState)

export default trashReducer
