import { getUserLicensePermissions, getUserPermissions } from '../../../selectors/settingsSelectors'
import store from '../../../store'
import { LicenseModules, LicenseOptions, Modules, PermissionOptions } from '../../constants/permissions'

import { ModulePermissionsProps } from './types'

/**
 * Returns a boolean depending on whether the user is permitted or not.
 * @returns {boolean} Returns true or false
 */

const isUserPermitted = ({ module, permissions, type = 'module' }: ModulePermissionsProps): boolean => {
  if (type === 'module') {
    const permission = getUserPermissions(module as Modules)(store.getState())
    return (permissions as PermissionOptions[]).includes(permission)
  }

  const license = getUserLicensePermissions(module as LicenseModules)(store.getState())
  return !!license && (permissions as LicenseOptions[]).includes(license)
}

export default isUserPermitted
