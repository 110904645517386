import { Step } from 'react-joyride'

export const disabledBeacon: Pick<Step, 'disableBeacon'> = {
  disableBeacon: true,
}

export const disabledScrolling: Pick<Step, 'disableScrolling'> = {
  disableScrolling: true,
}

export const disabledAnimation: Pick<Step, 'floaterProps'> = {
  floaterProps: {
    disableAnimation: true,
  },
}

export const disabledBeaconAndAnimation: Pick<Step, 'disableBeacon' | 'floaterProps'> = {
  ...disabledBeacon,
  ...disabledAnimation,
}
