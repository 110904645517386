import { Link, UIMatch, useMatches } from 'react-router-dom'
import React from 'react'
import { cn } from '@opoint/infomedia-storybook'

type HandleType = {
  crumb: React.JSX.Element | string
  dynamic?: boolean
}
const Breadcrumbs = () => {
  const matches = useMatches() as UIMatch<undefined, HandleType | undefined>[]
  const validMatches = matches.filter((match) => !!match.handle?.crumb) as UIMatch<undefined, HandleType>[]

  return (
    <ol className="flex w-full overflow-x-hidden px-3">
      {validMatches.map((match, index) => {
        const last = index + 1 === validMatches.length

        return (
          <li key={index} className={cn('flex items-center', { 'overflow-x-hidden': !!match.handle?.dynamic })}>
            {last ? (
              <span
                className={cn('block items-center whitespace-pre font-medium', { truncate: !!match.handle?.dynamic })}
              >
                {match.handle.crumb}
              </span>
            ) : (
              <>
                <Link
                  to={match.pathname}
                  className={cn(
                    'flex items-center rounded-sm text-grey.2 underline ring-offset-2  transition-colors hover:text-sky.1 focus:text-sky.1 focus:outline-none focus-visible:ring focus-visible:ring-sky.6',
                    { 'overflow-x-hidden': !!match.handle?.dynamic },
                  )}
                >
                  <span className={cn({ truncate: !!match.handle?.dynamic })}>{match.handle.crumb}</span>
                </Link>
                <span className="pointer-events-none px-2 text-grey.4">/</span>
              </>
            )}
          </li>
        )
      })}
    </ol>
  )
}

export default Breadcrumbs
