import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@opoint/infomedia-storybook'

import { PuzzlePiece24Regular } from '@fluentui/react-icons'
import { getActiveLanguage } from '../../../selectors/settingsSelectors'
import { useAppSelector } from '../../hooks/useAppSelector'
import TopNavActionButton from '../Header/Actions/Button'

const newSources = {
  'da-DK': 'https://infomedia.dk/book-en-demo/?platform_hide=true',
  'nb-NO': 'https://infomedia.no/book-en-demo/?platform_hide=true',
  'nn-NO': 'https://infomedia.no/book-en-demo/?platform_hide=true',
  'sv-SE': 'https://infomedia.se/boka-en-demo/?platform_hide=true',
}

const ProductsDialog = () => {
  const { t } = useTranslation()
  const locale = useAppSelector(getActiveLanguage)

  const productIframeSource = newSources[locale] ?? 'https://infomedia.org/book-a-demo/?platform_hide=true'

  return (
    <Dialog>
      <DialogTrigger asChild>
        <TopNavActionButton>
          <PuzzlePiece24Regular />
        </TopNavActionButton>
      </DialogTrigger>
      <DialogContent className="pb-0" variant="fullscreen">
        <DialogHeader>
          <DialogTitle>{t('Product Offerings')}</DialogTitle>
        </DialogHeader>
        <DialogBody className="p-0">
          <iframe title={t('Product Offerings')} className="h-full" src={productIframeSource} />
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default ProductsDialog
