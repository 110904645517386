import { InputHTMLAttributes, forwardRef } from 'react'
import classNames from 'classnames'
import { FluentIcon } from '@fluentui/react-icons'

import { FieldReduxInputType, FieldReduxMetaType } from '../field-types'

import styles from './index.module.scss'

export type TextInputProps = {
  icon?: FluentIcon
  type?: 'text' | 'number' | 'password' | 'url'
  input?: FieldReduxInputType
  meta?: FieldReduxMetaType
  errorMsg?: string
  error?: string
} & InputHTMLAttributes<HTMLInputElement>

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      input,
      meta,
      icon: PropsIcon,
      errorMsg,
      error, //? This a workaround, until storybook is ready to be imported.
      type = 'text',
      ...inputProps
    },
    ref,
  ) => (
    <div>
      <div className={classNames(styles.inputWrapper, { [styles.hasIcon]: PropsIcon })}>
        <input
          ref={ref}
          className={classNames(styles.input, { [styles.isInvalid]: !!error })}
          type={type}
          {...inputProps}
          {...input}
        />
        {PropsIcon && (
          <span className={styles.iconWrapper} data-testid="icon">
            <PropsIcon />
          </span>
        )}
      </div>
      {((meta && meta.touched && (errorMsg || meta.error)) || error) && (
        <p className={styles.errorMsg}>{error || errorMsg || meta?.error}</p>
      )}
    </div>
  ),
)

TextInput.displayName = 'TextInput'

export default TextInput
