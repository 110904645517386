import { TabsTrigger } from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'

import { useSearchParams } from 'react-router-dom'
import { useInviteDirectList } from '../../../../../api/invite/invite'
import { UserManagementTab } from '../../../../types/userManagement'

const InvitationsTabTrigger = () => {
  const [searchParams] = useSearchParams()
  const searchTerm = searchParams.get('search') ?? ''
  const { data: invitationsList } = useInviteDirectList({ q: searchTerm })

  return (
    <TabsTrigger value={UserManagementTab.INVITATIONS}>
      <Trans>Invitations</Trans>
      {invitationsList && <span className="ml-1"> ({invitationsList.count})</span>}
    </TabsTrigger>
  )
}

export default InvitationsTabTrigger
