import { produce } from 'immer'
import { AppActions } from '../actions'
import { SettingsStateChoices, SettingsStateList } from '../components/interfaces/settings'

export type SettingsState = {
  list: Partial<SettingsStateList>
  choices: Partial<SettingsStateChoices>
  isoCode: string | null
  isSaving: boolean | string
}

export const initialState: SettingsState = {
  list: {},
  choices: {},
  isoCode: null,
  isSaving: false,
}

const settingsReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'SETTINGS_FETCH_SUCCESS': {
      draftState.choices = action.payload.reduce((acc, setting) => {
        if ('choices' in setting && setting.choices?.length) {
          acc[setting.name] = setting.choices
        }
        return acc
      }, {}) as SettingsStateChoices

      draftState.list = action.payload.reduce((acc, setting) => {
        acc[setting.name] = setting.value
        return acc
      }, {}) as SettingsStateList
      break
    }

    case 'SETTINGS_SAVE': {
      // only set isSaving to true if the setting is NOT a UI setting
      draftState.isSaving = !action.payload?.uiSettings
      break
    }

    case 'SETTINGS_SAVE_SUCCESS': {
      const { settings } = action.payload

      draftState.list = {
        ...draftState.list!,
        ...settings.reduce((acc, { name, value }) => {
          acc[name] = value
          return acc
        }, {}),
      }
      draftState.isSaving = false
      break
    }

    case 'SETTINGS_SAVE_FAILURE': {
      draftState.isSaving = false
      break
    }

    case 'COUNTRY_CODE_FETCH_SUCCESS': {
      draftState.isoCode = action.payload.isoCode
      break
    }

    default:
      break
  }
}, initialState)

export default settingsReducer
