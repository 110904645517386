export const setLocalStorageItem = (key: string, value: string) => {
  try {
    window.localStorage.setItem(key, value)

    // We need to dispatch this event so that the useLocalStorage hooks pick up the change
    window.dispatchEvent(new StorageEvent('local-storage', { key }))
  } catch (e) {
    console.error(`Failed to set localStorage item ${key}:`, e)
  }
}

export const getLocalStorageItem = (key: string) => {
  try {
    return window.localStorage.getItem(key)
  } catch (e) {
    console.error(`Failed to get localStorage item ${key}:`, e)
    return null
  }
}
