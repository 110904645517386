import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'
import { t } from "i18next";
import {
  FetchStatisticsFailureAction,
  StatisticsActions,
  StatisticsExportFailureAction,
  StatisticsExportSuccessAction,
  StatisticsViewDeleteFailureAction,
  StatisticsViewDeleteSuccessAction,
  StatisticsViewExportPDFFailureAction,
  StatisticsViewExportPPTXFailureAction,
  StatisticsViewExportXLSXFailureAction,
  StatisticsViewSaveFailureAction,
  StatisticsViewSaveSuccessAction,
} from '../../../actions/statistics'
import { ErrorEpic, ErrorPayload, SuccessPayload, handlerError } from '.'

const statisticsErrorTypes = (
  payload?: SuccessPayload & ErrorPayload,
): ErrorEpic<
  | FetchStatisticsFailureAction['type']
  | StatisticsViewDeleteSuccessAction['type']
  | StatisticsViewDeleteFailureAction['type']
  | StatisticsViewSaveSuccessAction['type']
  | StatisticsViewSaveFailureAction['type']
  | StatisticsViewExportPDFFailureAction['type']
  | StatisticsViewExportPPTXFailureAction['type']
  | StatisticsViewExportXLSXFailureAction['type']
  | StatisticsExportSuccessAction['type']
  | StatisticsExportFailureAction['type']
> => {
  return {
    ['FETCH_STATISTICS_FAILURE']: {
      isSuccess: false,
      message: t('We were not able to fetch statistics for this search'),
      toastId: 'FETCH_STATISTICS_FAILURE',
    },
    ['STATISTICS_VIEW_DELETE_SUCCESS']: {
      isSuccess: true,
      message: t('Statistic view was successfully deleted'),
      toastId: 'STATISTICS_VIEW_DELETE_SUCCESS',
    },
    ['STATISTICS_VIEW_DELETE_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to delete selected statistic view'),
      toastId: 'STATISTICS_VIEW_DELETE_FAILURE',
    },
    ['STATISTICS_VIEW_SAVE_SUCCESS']: {
      isSuccess: true,
      message: t('Statistic view was successfully saved'),
      toastId: 'STATISTICS_VIEW_SAVE_SUCCESS',
    },
    ['STATISTICS_VIEW_SAVE_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to save selected statistic view'),
      toastId: 'STATISTICS_VIEW_SAVE_FAILURE',
    },
    ['STATISTICS_VIEW_EXPORT_PDF_FAILURE']: {
      isSuccess: false,
      message: t('Export statistics as PDF failed'),
      toastId: 'STATISTICS_VIEW_EXPORT_PDF_FAILURE',
    },
    ['STATISTICS_VIEW_EXPORT_PPTX_FAILURE']: {
      isSuccess: false,
      message: t('Export statistics as PPTX failed'),
      toastId: 'STATISTICS_VIEW_EXPORT_PPTX_FAILURE',
    },
    ['STATISTICS_VIEW_EXPORT_XLSX_FAILURE']: {
      isSuccess: false,
      message: t('Export statistics as XLSX failed'),
      toastId: 'STATISTICS_VIEW_EXPORT_XLSX_FAILURE',
    },
    // The below two errorTypes, are a general success/error message for all export types.
    ['STATISTICS_EXPORT_SUCCESS']: {
      isSuccess: true,
      // @ts-expect-error: Muted so we could enable TS strict mode
      message: payload?.message,
      toastId: 'STATISTICS_EXPORT_SUCCESS',
    },
    ['STATISTICS_EXPORT_FAILURE']: {
      isSuccess: false,
      // @ts-expect-error: Muted so we could enable TS strict mode
      message: payload?.error,
      toastId: 'STATISTICS_EXPORT_FAILURE',
    },
  }
}

const statisticsErrorEpic: (action: ActionsObservable<StatisticsActions>) => void = (action$) =>
  action$.pipe(
    switchMap((action) =>
      'payload' in action
        ? handlerError(statisticsErrorTypes, action.type, action.payload as ErrorPayload)
        : handlerError(statisticsErrorTypes, action.type),
    ),
  )

export default statisticsErrorEpic
