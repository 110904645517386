import { DropdownMenuItem } from '@opoint/infomedia-storybook'
import { forwardRef, ElementRef, ComponentPropsWithoutRef } from 'react'

// Radix uses mouse movement to control focus. This component stops that
// so action bar menu popovers stay open even when focus changes.
export const ActionBarDropdownMenuItem = forwardRef<
  ElementRef<typeof DropdownMenuItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuItem>
>(({ onPointerMove, onPointerLeave, ...props }, ref) => (
  <DropdownMenuItem
    ref={ref}
    onPointerMove={(e) => {
      e.preventDefault()

      if (typeof onPointerMove === 'function') {
        onPointerMove(e)
      }
    }}
    onPointerLeave={(e) => {
      e.preventDefault()

      if (typeof onPointerLeave === 'function') {
        onPointerLeave(e)
      }
    }}
    {...props}
  />
))

ActionBarDropdownMenuItem.displayName = 'ActionBarDropdownMenuItem'
