import { Outlet } from 'react-router-dom'
import Header from '../../components/topNav/Header'
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'

const CustomerViewPage = () => (
  <>
    <Header>
      <Breadcrumbs />
    </Header>
    <div className="h-full overflow-y-auto overflow-x-hidden">
      <Outlet />
    </div>
  </>
)

export default CustomerViewPage
