import { produce } from 'immer'

import { AppActions } from '../actions'
import { WatchQuery } from '../api/opoint.schemas'

export type WatchIndexState = {
  searchWatchId?: string | null
  fetchArticlesWithWatchIdInProgress: boolean
  watchQueries: Record<string, WatchQuery | null>
  profilesToWatchIds: Record<number, string>
}

export const initialState: WatchIndexState = {
  searchWatchId: null,
  fetchArticlesWithWatchIdInProgress: false,
  watchQueries: {},
  profilesToWatchIds: {},
}

/**
 * This reducer contains logic of watch indexes. Watch index system is a system that controls
 * counts of new articles coming from a server after a search was sent.
 */
const watchIndexReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'LOGOUT':
      Object.assign(draftState, initialState)
      break

    case 'FETCH_ARTICLES': {
      draftState.searchWatchId = null
      break
    }

    case 'UPDATE_CURRENT_WATCH_ID': {
      draftState.searchWatchId = action.payload.watchId
      break
    }

    case 'UPDATE_WATCH_INDEXES_MAPPING': {
      const { watchId, profileId } = action.payload
      draftState.profilesToWatchIds[profileId] = watchId
      break
    }

    case 'FETCH_ARTICLES_WITH_WATCH_ID': {
      draftState.fetchArticlesWithWatchIdInProgress = true
      break
    }

    case 'FETCH_ARTICLES_WITH_WATCH_ID_FAILURE': {
      draftState.fetchArticlesWithWatchIdInProgress = false
      break
    }

    case 'FETCH_ARTICLES_WITH_WATCH_ID_CANCEL': {
      const watchQuery = draftState.watchQueries[action.payload.watchId]

      if (watchQuery) {
        watchQuery.cnt = 0
      }

      draftState.watchQueries[action.payload.watchId] = watchQuery
      draftState.fetchArticlesWithWatchIdInProgress = false
      break
    }

    case 'FETCH_ARTICLES_WITH_WATCH_ID_SUCCESS': {
      const watchQuery = draftState.watchQueries[action.payload.searchresult.watch_id]

      if (watchQuery) {
        watchQuery.cnt = 0
      }

      draftState.watchQueries[action.payload.searchresult.watch_id] = watchQuery
      draftState.fetchArticlesWithWatchIdInProgress = false
      break
    }

    case 'UPDATE_WATCH_INDEXES_SUCCESS': {
      const { watchQueries } = action.payload
      const watchQueriesIndexed = watchQueries.reduce((acc, query) => {
        acc[query.id] = query
        return acc
      }, {})

      draftState.watchQueries = { ...draftState.watchQueries, ...watchQueriesIndexed }
      break
    }

    case 'RESET_WATCH_INDEX_FOR_PROFILE': {
      draftState.watchQueries[action.payload.watchId] = null
      break
    }

    default:
      break
  }
}, initialState)

export default watchIndexReducer
