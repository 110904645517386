import { ArticleIconType, TagColor } from '@opoint/infomedia-storybook'
import { t } from "i18next";
import { SCREENSHOT_TYPES } from '../../opoint/common/constants'
import type { ArticleScreenshot } from '../types/article'

import { M360Article } from '../../opoint/articles/types'
import { IconName } from '../types/theme'
import { changeImageUrlBase } from './common'

export const articleWithCorrectedImagePaths = (article: M360Article) => ({
  ...article,
  screenshots: article.screenshots?.map((screenshot: ArticleScreenshot) => ({
    ...screenshot,
    text: changeImageUrlBase(screenshot.text),
  })),
})

export const getMediatypeName = (mediatype?: ArticleIconType | null, sitename?: string): string => {
  switch (mediatype) {
    case 'SOCIAL':
      return sitename || ''
    case 'RADIO':
    case 'WEB_RADIO':
      return t('Radio')
    case 'TV':
    case 'WEB_TV':
      return t('TV')
    case 'PRINT':
      return t('Print')
    default:
      return t('Web')
  }
}

export const getMediaIconName = (mediatype?: ArticleIconType | null, sitename?: string): IconName => {
  const getSocialType = (site: string) => {
    switch (site) {
      case 'Twitter':
        return 'twitter_small'
      case 'Facebook':
        return 'facebook_small'
      case 'Instagram':
        return 'instagram_small'
      default:
        return 'browser_small'
    }
  }
  switch (mediatype) {
    case 'SOCIAL':
      // @ts-expect-error: Muted so we could enable TS strict mode
      return getSocialType(sitename)
    case 'RADIO':
    case 'WEB_RADIO':
      return 'radio_small'
    case 'TV':
    case 'WEB_TV':
      return 'tv_small'
    case 'PRINT':
      return 'print_small'
    case 'SUMMARY':
      return 'web_small'
    default:
      return 'browser_small'
  }
}

export const giveTagsColor = (tagType: number, color: TagColor) => {
  switch (tagType) {
    case 3:
      return TagColor.BLUE
    case 0:
    case 4:
      return TagColor.RED
    default:
      return color
  }
}

const tailwindColorMapper = {
  blue: 'bg-sky.4',
  darkblue: 'bg-sea.1',
  green: 'bg-forest.3',
  orange: 'bg-sand.3',
  pink: 'bg-berry.4',
  red: 'bg-berry.2',
  turquoise: 'bg-sea.4',
}

export const giveTagsTailwindColor = (tagType: number, color: TagColor) => {
  switch (tagType) {
    case 3:
      return tailwindColorMapper.blue
    case 0:
    case 4:
      return tailwindColorMapper.red
    default:
      return tailwindColorMapper[color]
  }
}

export const getScreenshotThumbnail = (screenshots) =>
  screenshots?.find(({ id_type: idType }) => idType === SCREENSHOT_TYPES.THUMBNAIL)

export const hasIdenticalArticles = (article: M360Article) => !!article.identical_documents?.cnt

export const getIdenticalArticles = (article: M360Article) =>
  Array.isArray(article.identical_documents?.document) ? article.identical_documents.document : []
