export type StoredSearchType = {
  id: string
  art_limit: string
  /*
     0: stored_search is deactivated
    -1: Activate stored_search if exist, create new if it doesn't
    -2: Just for calls to backend - unlinks stored_search and delete it
   */
  active: -2 | -1 | 0
}

export type Profile = {
  blessed: boolean
  filtersUsed: string
  hasTrash: boolean
  id: number
  name: string
  owner: number
  parent: number
  score: number
  statistics: any
  url: string
  category: number
  expanded: boolean
  folder: number
  type: number
  stored_search?: null | StoredSearchType
}

export type ProfileWithChildren = Profile & {
  children: ProfileWithChildren[]
}

export type DependencyItem = {
  id: number | string
  name: string
}

export type Dependencies = {
  alerts?: DependencyItem[]
  feeds?: DependencyItem[]
  profiles?: Profile[]
  parent: boolean
}

export enum DeleteModalVariantKey {
  Manage = 'manage',
  Editor = 'editor',
}

/*
'R': search line is required
'E': search line is excluded
'O': search line is optional
*/
export type LineMode = 'R' | 'E' | 'O'
