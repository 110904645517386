import { createSelector } from 'reselect'

import { getSentiment as getState } from './rootSelectors'

export const getUpdatedCompanySentiments = createSelector(
  getState,
  (sentimentsState) => sentimentsState.updatedCompanySentiments,
)

export const getSentimentGroups = createSelector(getState, (sentimentState) => sentimentState.sentimentGroups)

export const getSentimentSuggestions = createSelector(getState, (sentimentsState) => sentimentsState.suggestions)
