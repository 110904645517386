import { Contact, ContactDetail, Group } from '../components/types/contact'

const isContact = (contactOrGroup: Contact | Group): contactOrGroup is Contact => {
  if ((contactOrGroup as Contact).email) {
    return true
  }

  return false
}

const isGroup = (contactDetailOrGroup: ContactDetail | Group): contactDetailOrGroup is Group => {
  if ((contactDetailOrGroup as Group).name) {
    return true
  }

  return false
}

export { isContact, isGroup }
