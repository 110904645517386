import { MutableRefObject } from 'react'

import { TAG_TYPES } from '../../opoint/tags'
import { AlertTag as AlertTagType, GeneralTag } from '../types/tag'

import { M360Article } from '../../opoint/articles/types'
import AlertTag from './AlertTag'
import KeywordTag from './KeywordTag'
import MentometerTag from './MentometerTag'

export type TagSwitchProps = {
  article: M360Article
  tag: GeneralTag
  index: number
  tagRefs?: MutableRefObject<(HTMLDivElement | null)[]>
  isOverflow?: boolean
  setOverflowDrilldown?: (arg: AlertTagType) => void
  setIdenticalArticleTag?: () => void
  handleUpdateTagWeight?: (increment: boolean) => void
}

const TagSwitch = ({ tag, index, tagRefs, isOverflow = false, setOverflowDrilldown, ...tagProps }: TagSwitchProps) => {
  if (tag.type === TAG_TYPES.KEYWORD) {
    return (
      <KeywordTag
        {...tagProps}
        tag={tag}
        key={index}
        isOverflow={isOverflow}
        ref={(ref) => {
          tagRefs ? (tagRefs.current[index] = ref) : undefined
        }}
      />
    )
  }

  if (tag.type === TAG_TYPES.MENTOMETER) {
    return (
      <MentometerTag
        {...tagProps}
        tag={tag}
        key={index}
        isOverflow={isOverflow}
        ref={(ref) => {
          tagRefs ? (tagRefs.current[index] = ref) : undefined
        }}
      />
    )
  }

  if (tag.type === TAG_TYPES.ALERT) {
    return (
      <AlertTag
        {...tagProps}
        tag={tag as AlertTagType}
        key={index}
        isOverflow={isOverflow}
        setOverflowDrilldown={setOverflowDrilldown}
        ref={(ref) => {
          tagRefs ? (tagRefs.current[index] = ref) : undefined
        }}
      />
    )
  }

  return null
}

export default TagSwitch
