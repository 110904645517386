import { HighlightedMatchesAndEntities, Loader, cn } from '@opoint/infomedia-storybook'
import { useState } from 'react'

import useElementSize from '../../../../hooks/useElementSize'

export type ArticleImageProps = {
  altText?: string
  caption?: string
  editable: boolean
  imgSrc?: string
  opointImgSrc?: string
  keyVal: string | number
  onClick?: (...args: any[]) => void
  articleId?: string
}

const ArticleImage = ({ altText, caption, editable, imgSrc, opointImgSrc, keyVal, onClick }: ArticleImageProps) => {
  const [hasError, setHasError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [currentImgSrc, setCurrentImgSrc] = useState(opointImgSrc ?? imgSrc)
  const [ref, { width }] = useElementSize()

  const handleError = () => {
    if (!!opointImgSrc && currentImgSrc !== imgSrc) {
      setCurrentImgSrc(imgSrc)

      return
    }

    if (currentImgSrc === imgSrc) {
      setHasError(true)
    }
  }

  const handleImageLoaded = () => setIsLoaded(true)

  if (hasError) {
    return null
  }

  let content = currentImgSrc && (
    <div
      className={cn('relative', {
        'min-h-[18rem]': !isLoaded,
      })}
      style={{ width }}
    >
      {!isLoaded && (
        <div className="absolute left-0 top-0 flex size-full items-center justify-center">
          <Loader />
        </div>
      )}

      <img
        src={currentImgSrc}
        alt={altText || 'Preview'}
        onError={handleError}
        onLoad={handleImageLoaded}
        className="mb-0"
      />
    </div>
  )

  if (onClick) {
    content = (
      <button type="button" onClick={onClick} className="block cursor-zoom-in">
        {content}
      </button>
    )
  }

  return (
    <figure ref={ref} key={keyVal} className="mb-6">
      {editable && 'X'}
      {content}
      {caption && (
        <figcaption className="mb-0 mt-3 font-slab text-label-l text-sky.cloudy">
          <HighlightedMatchesAndEntities text={caption} />
        </figcaption>
      )}
    </figure>
  )
}

export default ArticleImage
