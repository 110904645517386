import { CalendarLtrRegular } from '@fluentui/react-icons'
import {
  cn,
  SingleFilterDropdown,
  SingleFilterDropdownContent,
  SingleFilterDropdownEmpty,
  SingleFilterDropdownItem,
  SingleFilterDropdownLoading,
  SingleFilterDropdownTrigger,
} from '@opoint/infomedia-storybook'
import { Trans, useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

import { useAlertsNextRetrieve } from '../../../../../api/alerts/alerts'
import { formatMailsByDate } from '../../../../../helpers/alerts'
import { getAlertHistoryById, getNextAlertLoading } from '../../../../../selectors/alertsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useRequiredParams } from '../../../../hooks/useRequiredParams'

const AlertPeriodFilter = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { alertId, mailLogId } = useRequiredParams(['alertId', 'mailLogId'])
  const { data: nextAlert } = useAlertsNextRetrieve(parseInt(alertId))
  const alertHistory = useAppSelector(getAlertHistoryById(parseInt(alertId)))
  const mailsByDate = useMemo(() => formatMailsByDate(alertHistory), [alertHistory])
  const isNextAlertFetching = useAppSelector(getNextAlertLoading)
  const isNextAlert = pathname.includes('next')

  const nextPossibleSend = nextAlert?.nextPossibleSend
  const nextAlertDateFormatted = nextPossibleSend ? `(${format(new Date(nextPossibleSend), 'dd.MM | HH:mm')}` : ''

  const isDateFieldDisabled = () => {
    if ((!alertHistory || alertHistory?.count === 0) && !isNextAlert) {
      return true
    }

    return isNextAlert && isNextAlertFetching
  }

  const options = Object.values(mailsByDate)
    .map(({ times }) => times)
    .flat()

  const datePickerOptions = options.map((mail) => ({
    value: mail.date,
    label: format(new Date(mail.date), 'dd.MM | HH:mm'),
    failed: mail.failed,
  }))
  datePickerOptions.unshift({
    value: 'next',
    label: `${t('Next')} ${nextAlertDateFormatted}`,
    failed: false,
  })

  const handleDateClick = (date: string) => {
    if (date === 'next') {
      navigate(`/alerts/${alertId}/next`)

      return
    }

    const firstSuccessMail = options.find((option) => option.date === date)
    navigate(`/alerts/${alertId}/${firstSuccessMail?.maillog_id}`)
  }

  const currentDatePickerOption = useMemo(() => {
    const mail = alertHistory?.results.find(({ maillog_id }) => maillog_id === parseInt(mailLogId))

    return datePickerOptions.find(({ value }) => value === mail?.date) || datePickerOptions[0]
  }, [datePickerOptions, alertHistory, mailLogId])

  return (
    <SingleFilterDropdown
      value={currentDatePickerOption}
      disabled={isDateFieldDisabled()}
      onChange={({ value }) => handleDateClick(String(value))}
    >
      <SingleFilterDropdownTrigger
        value={currentDatePickerOption}
        label=""
        title={t('Date')}
        icon={<CalendarLtrRegular className="size-s" />}
        className="mr-[0.3125rem] h-11"
      />
      <SingleFilterDropdownContent>
        {!alertHistory ? (
          <SingleFilterDropdownLoading />
        ) : datePickerOptions.length === 0 ? (
          <SingleFilterDropdownEmpty>
            <Trans>No dates</Trans>
          </SingleFilterDropdownEmpty>
        ) : (
          datePickerOptions.map(({ value, label, failed }) => (
            <SingleFilterDropdownItem
              key={value}
              value={{
                value,
                label,
              }}
              className={cn({
                'text-grey.4': failed,
              })}
            />
          ))
        )}
      </SingleFilterDropdownContent>
    </SingleFilterDropdown>
  )
}

export default AlertPeriodFilter
