import { produce } from 'immer'

import type { Feed, FeedSetting } from '../opoint/flow'
import { AppActions } from '../actions'

export type FeedsState = {
  list: Feed[]
  activeFeed?: Feed
  activeFeedInitialOptions?: FeedSetting
  activeFeedInitialContent?: number[]
  loading: boolean
  failed: boolean
}

export const initialState: FeedsState = {
  list: [],
  // @ts-expect-error: Muted so we could enable TS strict mode
  activeFeed: null,
  // @ts-expect-error: Muted so we could enable TS strict mode
  activeFeedInitialOptions: null,
  // @ts-expect-error: Muted so we could enable TS strict mode
  activeFeedInitialContent: null,
  failed: false,
  loading: false,
}

const feedsReducer = produce((draftState: FeedsState, action: AppActions) => {
  switch (action.type) {
    case 'FEEDS_FETCH': {
      draftState.loading = true
      break
    }

    case 'FEEDS_FETCH_FAILURE': {
      draftState.loading = false
      draftState.failed = true
      break
    }

    case 'FEEDS_FETCH_SUCCESS': {
      draftState.list = action.payload
      draftState.loading = false
      draftState.failed = false
      break
    }

    case 'FEED_SET_ACTIVE_SUCCESS': {
      const { feed, searchLine } = action.payload

      draftState.activeFeedInitialOptions = feed?.feedSettings
      draftState.activeFeedInitialContent = feed?.mifluzIdLists
      draftState.activeFeed = {
        ...feed,
        searchterm: '',
        mifluzIdLists: feed?.mifluzIdLists ?? searchLine,
      }
      break
    }

    case 'FEED_REMOVE_ACTIVE': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.activeFeed = null
      break
    }

    case 'FEED_SAVE_SUCCESS': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.list = draftState.list.reduce((acc, feed) => {
        if (feed.id === action.payload.id) {
          return [...acc, action.payload]
        }

        return [...acc, feed]
      }, [])
      draftState.activeFeed = { ...draftState.activeFeed, ...action.payload }
      draftState.activeFeedInitialOptions = action.payload.feedSettings
      draftState.activeFeedInitialContent = action.payload.mifluzIdLists

      break
    }

    case 'FEED_NEW_SUCCESS': {
      draftState.list = [...draftState.list, action.payload]
      draftState.activeFeed = action.payload
      break
    }

    case 'FEED_DELETE_SUCCESS': {
      draftState.list = draftState.list.filter(({ id }) => id !== action.payload)
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.activeFeed = null
      break
    }

    case 'FEED_EDITOR_CANCEL_CHANGES': {
      draftState.activeFeed = {
        ...draftState.activeFeed,
        // @ts-expect-error: Muted so we could enable TS strict mode
        mifluzIdLists: draftState.activeFeedInitialContent,
        searchterm: '',
        // @ts-expect-error: Muted so we could enable TS strict mode
        feedSettings: draftState.activeFeedInitialOptions,
      }
      break
    }
  }
}, initialState)

export default feedsReducer
