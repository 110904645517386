import { useEffect, useRef } from 'react'

const useEventListener = (
  eventName: keyof WindowEventMap,
  handler: (event) => void,
  element: HTMLElement | (Window & typeof globalThis) = window,
): void => {
  // @ts-expect-error: Muted so we could enable TS strict mode
  const savedHandler: { current: (event) => void } = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) {
      return
    }

    const eventListener = (event) => savedHandler.current(event)

    element.addEventListener(eventName, eventListener)

    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}

export default useEventListener
