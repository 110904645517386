import { DOM } from 'rx-dom'

import { compose, isEmpty, length, map, merge, reject, sum, take, values } from 'ramda'
import { GroupedArticles, ReportObject } from '../../components/types/reports'
import { handleErrors } from '../common'
import config from '../common/config'
import type { SearchItem } from '../flow'

export const REPORT_STATUS = {
  UPDATE_ERROR: -3,
  ERROR: -1,
  IN_PROGRESS: 0,
  FINISHED: 1,
}

export const REPORT_STEP_SELECT_CONTENT = 0
export const REPORT_STEP_SET_TEMPLATE = 1
export const REPORT_STEP_SORT_CONTENT = 2
export const REPORT_STEP_CREATE_REPORT = 3
export const REPORT_STEP_DOWNLOAD_SHARE = 4

export const reportsActionStep = {
  ACTION_SELECTION: 1,
  SHARE_ACTION: 2,
  SHARING_REPORT: 3,
  SHARE_REPORT_SUCCESS: 4,
  SHARE_REPORT_FAILURE: 5,
}

export const groupedArticlesLength = (groupedArticles: GroupedArticles) =>
  // @ts-expect-error: Muted so we could enable TS strict mode
  compose(sum, values, map(length))(groupedArticles)

export const takeGroupedArticles = (groupedArticles: GroupedArticles, number: number): any => {
  let count = number

  // @ts-expect-error: Muted so we could enable TS strict mode
  const selectedGroupedArticles = map((articleGroup) => {
    const selectedArticles = take(count, articleGroup)
    count -= selectedArticles.length

    return selectedArticles
  }, groupedArticles)

  return reject(isEmpty, selectedGroupedArticles)
}

export async function getReportsTagHistory(id: number) {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/tags/${id}/reports/`),
    method: 'GET',
  })

  return DOM.ajax(requestHeaders).toPromise()
}

export async function createReport(params: {
  articles?: Array<{ id_site: number; id_article: number }>
  expressions?: Array<SearchItem>
  newest?: number
  oldest?: number
  sortedArticles?: Array<{ id_site: number; id_article: number }>
  templateId: number
  timezone: string
  title: string
  locale: string
  excludearticles: Array<{ id_site: number; id_article: number }>
}) {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/reports/?task=async'),
    method: 'POST',
    // @ts-expect-error: Muted so we could enable TS strict mode
    body: JSON.stringify({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, ...params }),
  })

  requestHeaders.headers['accept-language'] = params?.locale

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then((response) => response)
}

export const shareReport = async (params: {
  id?: Array<number>
  message?: string
  recipients?: {
    type: 'email' | 'person' | 'group' | 'user' | 'phoneNumber'
    id: string | number
    value?: string
  }[]
  shareAttachment?: boolean
}): Promise<object> => {
  const { headers } = await config.request.getRequestHeaders()

  const requestHeaders = merge(
    { headers },
    {
      url: config.url.api('/reports/share/'),
      method: 'POST',
      body: JSON.stringify({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, ...params }),
    },
  )

  return DOM.ajax(requestHeaders).toPromise()
}

export const deleteReport = async (params: { id?: Array<number> }): Promise<object> =>
  fetch(config.url.api(`/reports/${params.id}/`), {
    ...(await config.request.getRequestHeaders()),
    method: 'DELETE',
  }).then(handleErrors)

export const checkReportStatus = async (params: { id: number }): Promise<ReportObject> => {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/reports/${params.id}/`),
    method: 'GET',
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then((response) => response.response)
}
