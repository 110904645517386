/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Opoint API
 * OpenAPI spec version: 1.0.0
 */
export type SearchBody =
  | SearchRequestBodySearchtermOnly
  | SearchRequestBodyExpressionsSearchlineString
  | SearchRequestBodyExpressionsSearchlineObject

export interface Updated {
  updated: number
}

export interface InviteDirectPostResponseChild {
  email: string
  status: InviteDirectPostResponseChildStatusEnum
}

export interface InviteDirectPostResponse {
  invites: InviteDirectPostResponseChild[]
  status: InviteDirectPostResponseStatusEnum
}

export interface InviteDirectPostRequest {
  alerts?: number[]
  email: string[]
  invitation_text?: string
  owner: number
}

export interface UserTypeField {
  name: string
  value: number
}

export interface UserInvitationSearch {
  count: number
  next: string
  previous: string
  results: InvitationSearch[]
}

export interface UUIDCommaSeparatedList {
  id: string
  name: string
}

export interface TagsArticle {
  id_article: number
  id_site: number
  matchinfo?: string
  stimestamp: number
}

export interface TaggedArticleDetectionResponseArticles {
  id_article: number
  id_site: number
  /**
   * @minimum 0
   * @maximum 255
   */
  weight: number
}

export interface TaggedArticleDetectionResponse {
  articles: TaggedArticleDetectionResponseArticles[]
  id_basket: number
}

export interface TaggedArticleDetection {
  articles: ArticleVisited[]
  baskets: number[]
}

export interface TaggedArticle {
  has_articles: boolean
}

export interface TagSort {
  position: number
  tagId: number
}

/**
 * * `tagged` - tagged
 * `used` - used
 */
export type TagReportSelectionTypeEnum = (typeof TagReportSelectionTypeEnum)[keyof typeof TagReportSelectionTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TagReportSelectionTypeEnum = {
  tagged: 'tagged',
  used: 'used',
} as const

/**
 * Ways of selecting content from tags for reports.

Represented by a tagged union choosing between ways of selecting content,
either from an old report or all the unused content, or only some of the
unused articles.
 */
export interface TagReportSelection {
  from_timestamp?: number
  timestamp?: number
  to_timestamp?: number
  type: TagReportSelectionTypeEnum
}

export type TagReportTimestamp = number | string

export interface TagReport {
  count: number
  selection: TagReportSelection
  stimestampUsed: number
  readonly timestamp: TagReportTimestamp
  readonly timestampLastArt: number
}

/**
 * @nullable
 */
export type TagDetailStoredSearch = StoredSearchField | null

export type TagDetailAccess = {
  readonly edit: boolean
  readonly tag_articles: boolean
}

export interface TagDetail {
  readonly access: TagDetailAccess
  readonly category: number
  /** @nullable */
  color: string | null
  /**
   * @maxLength 2
   * @nullable
   */
  combo?: string | null
  readonly feeds: UUIDCommaSeparatedList
  folder?: number
  readonly id: number
  readonly last_set: string
  readonly lastSort: string
  /**
   * Name of tag
   * @maxLength 255
   */
  name: string
  readonly new_position: number
  readonly owner: number
  readonly score: number
  /** @nullable */
  statistics?: string | null
  /** @nullable */
  stored_search?: TagDetailStoredSearch
  type?: OneTwoEnum
  readonly url: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  visibility?: OneTwoEnum
}

export interface SupportIssue {
  /** @minimum 0 */
  context_id: number
  context_name: string
  context_type: string
  country: string
  description: string
  email?: string
  /** @minimum 0 */
  id_article: number
  /** @minimum 0 */
  id_site: number
  issue_type: IssueTypeEnum[]
  original_headline: string
  publication_date: number
  source: string
  translated_headline: string
}

/**
 * Serializer describing the SummarizerParamField
 */
export interface SummarizerParamField {
  max_number_of_words_to_show: number
  max_percentage_of_characters_to_show: number
  number_of_sentences: number
  type: number
}

export interface SummarizerParam {
  license: SummarizerParamField
  mail_license: SummarizerParamField
  report_license: SummarizerParamField
  share_license: SummarizerParamField
}

export interface StoredSearchField {
  active: number
  art_limit: number
  id: number
}

export interface SourceRequestChangeAgreement {
  /** @nullable */
  id_agreement: number | null
  id_source: number
}

export interface SourceOptionsPatchRequest {
  licence?: number
  licence_enforce?: number
  licence_enforce_mail?: number
  licence_enforce_report?: number
  licence_enforce_share?: number
  licence_mail?: number
  licence_report?: number
  licence_share?: number
}

export interface SourceDetail {
  readonly id_source: number
  licence: number
  licence_enforce: number
  licence_enforce_mail: number
  licence_enforce_report: number
  licence_enforce_share: number
  licence_mail: number
  licence_report: number
  licence_share: number
  readonly name: string
}

export interface Source {
  /** @nullable */
  agreement?: number | null
  readonly id_source: number
  readonly name: string
}

/**
 * * `priority` - priority
 * `date` - date
 */
export type SortTypeEnum = (typeof SortTypeEnum)[keyof typeof SortTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortTypeEnum = {
  priority: 'priority',
  date: 'date',
} as const

/**
 * * `desc` - desc
 * `asc` - asc
 */
export type SortOrderEnum = (typeof SortOrderEnum)[keyof typeof SortOrderEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortOrderEnum = {
  desc: 'desc',
  asc: 'asc',
} as const

export interface SortTagContent {
  fromTimestamp?: number
  sortOrder?: SortOrderEnum
  sortType?: SortTypeEnum
  stimestampUsed?: number[]
  toTimestamp?: number
}

export interface SitesListPaginated {
  /** @nullable */
  next: string | null
  /** @nullable */
  previous: string | null
  results: SiteList[]
}

/**
 * @nullable
 */
export type SiteOptionsPatchRequestSummarizerParam = SummarizerParam | null

export interface SiteOptionsPatchRequest {
  /** @nullable */
  max_archive_age?: number | null
  /** @nullable */
  max_free_search_age?: number | null
  /** @nullable */
  summarizer_param?: SiteOptionsPatchRequestSummarizerParam
}

export interface SiteList {
  readonly country: string
  global_rank: number
  /** @nullable */
  readonly id_agreement: number | null
  readonly id_site: number
  readonly languages: readonly string[]
  readonly name: string
  readonly sources: readonly Source[]
  url: string
}

/**
 * @nullable
 */
export type SiteDetailSummarizerParam = SummarizerParam | null

export interface SiteDetail {
  /** @nullable */
  readonly id_agreement: number | null
  id_site: number
  /** @nullable */
  max_archive_age: number | null
  /** @nullable */
  max_free_search_age: number | null
  readonly name: string
  readonly sources: readonly SourceDetail[]
  /** @nullable */
  summarizer_param: SiteDetailSummarizerParam
}

export interface SiteChangeAgreement {
  /** @nullable */
  id_agreement: number | null
  id_site: number
  sources?: SourceRequestChangeAgreement[]
}

export interface SiteAndSourceDetailResponse {
  site_options: SiteDetail
  source_options: SourceDetail
}

export interface Site {
  readonly id_site: number
  readonly name: string
}

export interface SingleUserInvitePaginated {
  count: number
  /** @nullable */
  next: string | null
  /** @nullable */
  previous: string | null
  results: SingleUserInvitation[]
}

/**
 * * `0` - Failed
 * `1` - Pending
 * `2` - Accepted
 * `3` - Expired
 */
export type SingleUserInvitationStatusEnum =
  (typeof SingleUserInvitationStatusEnum)[keyof typeof SingleUserInvitationStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SingleUserInvitationStatusEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const

export interface SingleUserInvitation {
  /**
   * id of newly created user.
   * @nullable
   */
  created_user?: number | null
  /** @maxLength 255 */
  email: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  expires?: number
  readonly id: number
  /** @nullable */
  invitation_text?: string | null
  /** The parent user in the tree when user is created */
  owner: number
  /** @nullable */
  readonly reason: string | null
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  sent?: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  status?: SingleUserInvitationStatusEnum
  /**
   * @minLength 43
   * @maxLength 43
   */
  token: string
  readonly usable: boolean
}

/**
 * * `and` - AND
 * `or` - OR
 * `near` - NEAR
 * `iql` - IQL
 */
export type SearchmodeEnum = (typeof SearchmodeEnum)[keyof typeof SearchmodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchmodeEnum = {
  and: 'and',
  or: 'or',
  near: 'near',
  iql: 'iql',
} as const

export interface SearchLineField {
  filters: Filters
  searchmode?: SearchmodeEnum
}

export interface Response {
  token: string
}

export interface ResendEmailResponse {
  email_delivered: boolean
}

export interface ResendAlertResponse {
  emails: string[]
}

export interface ResendAlertRequest {
  recipients: string[]
}

export interface ReportStatistics {
  category: string
  id: number
  name: string
  uncalculated: number
  unprocessed: number
}

export interface Report {
  readonly articles: number
  readonly download: string
  id: number
  pdfBytes: number
  pdfPages: number
  readonly status: number
  timeFinished: number
  readonly timestampFrom: number
  readonly timestampTo: number
  title: string
  readonly type: number
}

export interface RemainingInvitesResponseOld {
  remaining_invites: number
}

export interface RemainingInvitesResponseChild {
  remaining_invites: number
  target_id: number
}

export interface RemainingInvitesResponse {
  '': RemainingInvitesResponseChild[]
}

export interface ProfileWatchResponse {
  username: string
}

export interface ProfileWatchRequest {
  day?: number
  from_day?: number
  readership?: boolean
}

export interface ProfileUnpersistResponse {
  message: string
}

export interface ProfileSort {
  parent: number
  position?: number
  profileId: number
}

export interface ProfileItem {
  id?: number
  linemode: LinemodeEnum
  searchline: SearchLineField
  searchmode: string
}

export interface ProfileHistoryItemsResponse {
  id_list: number
  items: ProfileItem
  /** @nullable */
  make_persistent: boolean | null
  name: string
  timestamp: number
}

export interface ProfileHistoryResponse {
  count: number
  results: ProfileHistoryItemsResponse[]
}

/**
 * @nullable
 */
export type ProfileDetailStoredSearch = StoredSearchField | null

export interface ProfileDetail {
  blessed?: string
  readonly category: number
  folder?: number
  id?: number
  items?: ProfileItem[]
  /** @nullable */
  make_persistent?: boolean | null
  /** @maxLength 255 */
  name: string
  /** @nullable */
  readonly oldest_article: number | null
  readonly owner: number
  parent?: number
  /**
   * deprecated. see backfill_start
   * @minimum 0
   */
  persistent_backfill?: number
  /** @minimum 0 */
  persistent_backfill_end?: number
  /** @minimum 0 */
  persistent_backfill_start?: number
  /** @nullable */
  readonly persistent_profile: number | null
  readonly sm_phrases: string
  sm_rate?: MifluzRate[]
  /** @nullable */
  statistics?: string | null
  /** @nullable */
  stored_search?: ProfileDetailStoredSearch
  readonly type: number
  readonly url: string
}

export interface ProfileDependenciesResponse {
  alerts: AlertProfileDependencies
  feeds: FeedProfileDependencies
  parent: boolean
  profiles: number[]
}

export interface Profile {
  blessed?: string
  readonly category: number
  readonly feeds: UUIDCommaSeparatedList
  readonly filtersUsed: string
  folder?: number
  readonly hasTrash: boolean
  id?: number
  readonly is_persistent: boolean
  /** @maxLength 255 */
  name: string
  readonly owner: number
  parent?: number
  readonly score: number
  /** @nullable */
  statistics?: string | null
  readonly type: number
  readonly url: string
}

/**
 * @nullable
 */
export type PatchedTagDetailStoredSearch = StoredSearchField | null

export type PatchedTagDetailAccess = {
  readonly edit: boolean
  readonly tag_articles: boolean
}

export interface PatchedTagDetail {
  readonly access?: PatchedTagDetailAccess
  readonly category?: number
  /** @nullable */
  color?: string | null
  /**
   * @maxLength 2
   * @nullable
   */
  combo?: string | null
  readonly feeds?: UUIDCommaSeparatedList
  folder?: number
  readonly id?: number
  readonly last_set?: string
  readonly lastSort?: string
  /**
   * Name of tag
   * @maxLength 255
   */
  name?: string
  readonly new_position?: number
  readonly owner?: number
  readonly score?: number
  /** @nullable */
  statistics?: string | null
  /** @nullable */
  stored_search?: PatchedTagDetailStoredSearch
  type?: OneTwoEnum
  readonly url?: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  visibility?: OneTwoEnum
}

export interface PatchedSiteAndSourceDetailRequest {
  site_options?: SiteOptionsPatchRequest
  source_options?: SourceOptionsPatchRequest
}

/**
 * @nullable
 */
export type PatchedNotificationObject = NotificationFieldValue | null

export interface PatchedNotification {
  readonly id?: number
  /** @nullable */
  object?: PatchedNotificationObject
  read?: boolean
  /** @maxLength 255 */
  title?: string
}

export interface PatchedMultiUserInvitation {
  active?: boolean
  readonly expires?: number
  readonly id?: number
  /** The parent user in the tree when user is created */
  owner?: number
  /** @nullable */
  readonly reason?: string | null
  readonly sent?: number
  token?: string
  url?: string
  readonly usable?: boolean
}

export interface PatchedCustomerDetail {
  readonly id?: number
  /** Customers active status. When the customer is inactive, users can no longer login. Currently not enforced */
  is_active?: boolean
  /** Whether customer is an educational institution */
  is_educational?: boolean
  /**
   * @minimum 1
   * @nullable
   */
  max_num_users?: number | null
  /**
   * Customers name
   * @maxLength 255
   */
  name?: string
  /** Customers navision id */
  navision_id?: string
  /** Number of users the customer has signed up */
  readonly num_users?: number
  /** @minimum 0 */
  partner?: number
  /** Customers salesforce id */
  salesforce_id?: string
}

/**
 * @nullable
 */
export type PatchedAgreementDetailSummarizerParam = SummarizerParam | null

export interface PatchedAgreementDetail {
  readonly id_agreement?: number
  licence?: number
  licence_enforce?: number
  licence_enforce_mail?: number
  licence_enforce_report?: number
  licence_enforce_share?: number
  licence_mail?: number
  licence_report?: number
  licence_share?: number
  /** @nullable */
  max_archive_age?: number | null
  /** @nullable */
  max_free_search_age?: number | null
  /**
   * @minLength 2
   * @maxLength 255
   */
  name?: string
  /** @nullable */
  summarizer_param?: PatchedAgreementDetailSummarizerParam
}

export interface OpointUser {
  customer: string
  /** @nullable */
  display_name?: string | null
  /**
   * Id from external Identity Provider
   * @maxLength 255
   */
  external_id?: string
  readonly id_user: number
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  owner?: number
  user_type?: UserTypeField
  /** @maxLength 255 */
  username: string
}

export interface OpointCustomer {
  readonly id_customer: number
  /**
   * Customers name
   * @maxLength 255
   */
  name: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  partner: number
}

/**
 * * `1` - 1
 * `2` - 2
 */
export type OneTwoEnum = (typeof OneTwoEnum)[keyof typeof OneTwoEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OneTwoEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const

export interface OTBRule {
  description?: string
  header?: string
  id_rule: number
  startvalue: number
}

export interface OTBList {
  description?: string
  header?: string
  id_rule: number
  startvalue: number
}

/**
 * @nullable
 */
export type NotificationFieldReportValue = Report | null

/**
 * Single purpose serializer: Servers only to correctly describe a NotificationRelatedField field
where the value is ReportSerializer.
 */
export interface NotificationFieldReport {
  type: string
  /** @nullable */
  value: NotificationFieldReportValue
}

export type NotificationFieldValue = NotificationFieldExport | NotificationFieldReport

/**
 * @nullable
 */
export type NotificationFieldExportValue = Export | null

/**
 * Single purpose serializer: Servers only to correctly describe a NotificationRelatedField field
where the value is ExportSerializer.
 */
export interface NotificationFieldExport {
  type: string
  /** @nullable */
  value: NotificationFieldExportValue
}

/**
 * @nullable
 */
export type NotificationObject = NotificationFieldValue | null

export interface Notification {
  readonly id: number
  /** @nullable */
  object?: NotificationObject
  read?: boolean
  /** @maxLength 255 */
  title: string
}

export interface MultiUserInvitation {
  active?: boolean
  readonly expires: number
  readonly id: number
  /** The parent user in the tree when user is created */
  owner: number
  /** @nullable */
  readonly reason: string | null
  readonly sent: number
  token?: string
  url?: string
  readonly usable: boolean
}

export interface MultiUserInvitePaginated {
  count: number
  /** @nullable */
  next: string | null
  /** @nullable */
  previous: string | null
  results: MultiUserInvitation[]
}

export interface MifluzRate {
  readonly actual_rate: string
  /** @maxLength 3 */
  feed_id: string
  readonly feed_name: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  maxrate: number
  readonly rate_used: string
}

export interface MapIFMProfilesResponse {
  IFMProfileName: number
}

/**
 * Serves for describing the /notifications response. forced_singular_serializer can't work with inline_serializer
 */
export interface ListNotifications {
  count: number
  /** @nullable */
  next: string | null
  /** @nullable */
  previous: string | null
  results: Notification[]
}

/**
 * * `R` - Required
 * `O` - Optional
 * `E` - Exclude
 */
export type LinemodeEnum = (typeof LinemodeEnum)[keyof typeof LinemodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinemodeEnum = {
  R: 'R',
  O: 'O',
  E: 'E',
} as const

export interface LicenseCategory {
  readonly id: number
  name: string
}

/**
 * @nullable
 */
export type LicenseCategoryProperty = LicenseCategory | null

export interface License {
  /** @nullable */
  category: LicenseCategoryProperty
  readonly description: string
  readonly label: string
  readonly name: string
  readonly num_users: number
  readonly value: number
}

export interface LastSortedResponse {
  timestamp: string
  /** @nullable */
  unixTimestamp: number | null
}

/**
 * * `1` - Factual mistake
 * `2` - Translation error
 * `3` - Metadata error
 * `4` - Other issue
 */
export type IssueTypeEnum = (typeof IssueTypeEnum)[keyof typeof IssueTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IssueTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const

/**
 * * `SUCCESS` - SUCCESS
 * `PARTIAL` - PARTIAL
 * `FAILED` - FAILED
 */
export type InviteDirectPostResponseStatusEnum =
  (typeof InviteDirectPostResponseStatusEnum)[keyof typeof InviteDirectPostResponseStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteDirectPostResponseStatusEnum = {
  SUCCESS: 'SUCCESS',
  PARTIAL: 'PARTIAL',
  FAILED: 'FAILED',
} as const

/**
 * * `SENT` - SENT
 * `ALREADY_INVITED` - ALREADY_INVITED
 * `FAILED` - FAILED
 */
export type InviteDirectPostResponseChildStatusEnum =
  (typeof InviteDirectPostResponseChildStatusEnum)[keyof typeof InviteDirectPostResponseChildStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteDirectPostResponseChildStatusEnum = {
  SENT: 'SENT',
  ALREADY_INVITED: 'ALREADY_INVITED',
  FAILED: 'FAILED',
} as const

/**
 * drf-spectacular picks up both possible types for the 'id' field this way
 * @nullable
 */
export type InvitationSearchId = number | string | null

export interface InvitationSearch {
  /**
   * drf-spectacular picks up both possible types for the 'id' field this way
   * @nullable
   */
  readonly id: InvitationSearchId
  is_user: boolean
  name: string
}

export interface ImpersonationTarget {
  customer: OpointCustomer
  /** User id of impersonation target. */
  id_user: number
  /** If the target is of self type, meaning it represents the logged in user in another context. */
  self: boolean
  /** Username of impersonation target. */
  username: string
}

export interface IFMOrganizationProfileNumbers {
  profiles: number[]
}

export interface IFMLoginUser {
  archive_length: number
  create_date: number
  login: string
  name?: string
  profiles: number[]
  /**
   * @minLength 36
   * @maxLength 36
   */
  uuid: string
}

export interface IFMLoginRights {
  alerts: boolean
  is_organization_admin: boolean
  media_report: boolean
  multimedia: boolean
  print: boolean
  share: boolean
  statistics: boolean
}

export interface IFMLoginOrganization {
  /** @minimum 0 */
  debitor_id: number
  name: string
  /**
   * @minLength 36
   * @maxLength 36
   */
  uuid: string
}

export interface IFMLogin {
  organization: IFMLoginOrganization
  remember_me?: boolean
  rights: IFMLoginRights
  user: IFMLoginUser
}

export interface FolderSortRequestBody {
  /** Id of folder to change sort position of. */
  id: number
  /** Desired position of folder, 0-based. */
  position: number
}

export interface Filters {
  id?: string
  index?: number
  name?: string
  type: string
}

export interface FeedProfileDependencies {
  id: number
  name: string
}

/**
 * Serializer for returning statistics export data from notifications
 */
export interface Export {
  readonly articles: number
  readonly download: string
  id: number
  readonly status: number
  timeFinished: number
  readonly timestampFrom: number
  readonly timestampTo: number
  title: string
  readonly type: number
}

export interface DeletedProfilesItemsResponse {
  id_list: number
  items: ProfileItem
  /** @nullable */
  make_persistent: boolean | null
  name: string
  timestamp: number
}

export interface DeletedProfilesResponse {
  count: number
  results: DeletedProfilesItemsResponse
}

export interface DeleteTagResponse {
  data: string
  message: string
}

export interface DeleteProfileResponse400 {
  data: string
  message: string
}

export interface CustomerDetail {
  readonly id: number
  /** Customers active status. When the customer is inactive, users can no longer login. Currently not enforced */
  is_active?: boolean
  /** Whether customer is an educational institution */
  is_educational?: boolean
  /**
   * @minimum 1
   * @nullable
   */
  max_num_users: number | null
  /**
   * Customers name
   * @maxLength 255
   */
  name: string
  /** Customers navision id */
  navision_id?: string
  /** Number of users the customer has signed up */
  readonly num_users: number
  /** @minimum 0 */
  partner: number
  /** Customers salesforce id */
  salesforce_id?: string
}

export interface Customer {
  readonly id: number
  /**
   * Customers name
   * @maxLength 255
   */
  name: string
  /** @minimum 0 */
  partner: number
}

export interface BaseArticle {
  id_article: number
  id_site: number
}

export interface AsyncReportCreateResponse {
  new_timestamp?: number
  report_id: number
}

export interface Articles {
  articles: Article[]
}

export interface ArticleVisited {
  action?: ActionEnum[]
  id_article: number
  id_site: number
}

export interface ArticleBasketDelete {
  articles: BaseArticle[]
}

export interface ArticleBasket {
  articles: TagsArticle[]
  weight: number
}

export interface Article {
  id_article: number
  id_site: number
  matchinfo?: string
  stimestamp?: number
  xmltext?: string
}

/**
 * * `4` - Email
 * `6` - FTP
 * `30` - Stored search
 * `7` - Feed
 * `42` - Update search
 * `50` - Persistent
 */
export type AlertTypeEnum = (typeof AlertTypeEnum)[keyof typeof AlertTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlertTypeEnum = {
  NUMBER_4: 4,
  NUMBER_6: 6,
  NUMBER_30: 30,
  NUMBER_7: 7,
  NUMBER_42: 42,
  NUMBER_50: 50,
} as const

export interface AlertProfileDependencies {
  id: number
  name: string
}

export interface AlertCountResponse {
  count: number
}

export interface Alert {
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  active?: number
  readonly baskets: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  class_field?: number
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  editable?: number
  readonly has_push_subscription: string
  /** @nullable */
  readonly id: number | null
  readonly isCreatedFromApp: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  max_age?: number
  /** @maxLength 255 */
  subject?: string
  /**
   * @minimum -2147483648
   * @maximum 2147483647
   */
  type: AlertTypeEnum
  readonly url: string
}

/**
 * @nullable
 */
export type AgreementDetailSummarizerParam = SummarizerParam | null

export interface AgreementDetail {
  readonly id_agreement: number
  licence: number
  licence_enforce: number
  licence_enforce_mail: number
  licence_enforce_report: number
  licence_enforce_share: number
  licence_mail: number
  licence_report: number
  licence_share: number
  /** @nullable */
  max_archive_age: number | null
  /** @nullable */
  max_free_search_age: number | null
  /**
   * @minLength 2
   * @maxLength 255
   */
  name: string
  /** @nullable */
  summarizer_param: AgreementDetailSummarizerParam
}

export interface Agreement {
  readonly id_agreement: number
  /** @nullable */
  id_agreement_template?: number | null
  /** @nullable */
  id_site?: number | null
  /**
   * @minLength 2
   * @maxLength 255
   */
  name: string
  readonly sites: readonly Site[]
}

/**
 * * `2` - Article consumed
 * `3` - Article viewed for 3 sec
 * `4` - Article viewed for 10 sec
 * `5` - Article clicked
 * `6` - PDF viewed
 * `7` - Media clip played
 * `8` - Image clicked
 * `9` - Article added to tag
 * `10` - Entry fetched from Go log
 */
export type ActionEnum = (typeof ActionEnum)[keyof typeof ActionEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionEnum = {
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_9: 9,
  NUMBER_10: 10,
} as const

export type ParamsTextLimits = {
  maxdist?: number
  maxtimediff?: number
  minwordlength?: number
}

export type ParamsSummaryLimits = {
  maxdist?: number
  maxtimediff?: number
  minwordlength?: number
}

export type ParamsSortCustomItem = {
  id_article?: number
  id_site?: number
}

/**
 * @deprecated
 */
export type ParamsRelated = { [key: string]: any }

export type ParamsMatchesopt = {
  columns?: number
  lines?: number
  width?: number
  withatt?: boolean
  wordindent?: number
}

/**
 * Include a @doc/body field in the response with the articles body text if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags.
 */
export type ParamsMainText = (typeof ParamsMainText)[keyof typeof ParamsMainText]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParamsMainText = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const

/**
 * Include a @doc/summary field in the response with the articles summaries if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags.
 */
export type ParamsMainSummary = (typeof ParamsMainSummary)[keyof typeof ParamsMainSummary]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParamsMainSummary = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const

/**
 * Include a @doc/header field in the response with the articles headers if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags.
 */
export type ParamsMainHeader = (typeof ParamsMainHeader)[keyof typeof ParamsMainHeader]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParamsMainHeader = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const

/**
 * Settings related to the text and matches of each article.

In order return text from the articles, either @param/main.header, @param/main.summary or @param/main.body must be set to a non-zero value. All are off by default.
 */
export type ParamsMain = {
  all?: boolean
  colorbar?: boolean
  /** Include a @doc/header field in the response with the articles headers if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags. */
  header?: ParamsMainHeader
  inherit?: boolean
  /** Include @doc/matches and @doc/matches_table fields in the response, with information about phrases matching the search string. */
  matches?: boolean
  /** Include a @doc/sources field in the response with information about all the sources the article was observed on. */
  othersources?: boolean
  /** Include a @doc/quotes field in the response, containing text surrounding each of the mathcing phrases. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags. */
  quotes?: number
  /** The sections to include quotes from. */
  quotesfrom?: number
  /** Include a @doc/short_body field in the response, with a shortened version of the body text, of at most @param/main.shortbodylength characters. */
  shortbodylength?: number
  shortfirstsrcname?: number
  /** Include a @doc/short_header field in the response, with a shortened version of the header, of at most @param/main.shortheaderlength characters. */
  shortheaderlength?: number
  shortothersrcname?: number
  /** Include a @doc/short_summary field in the response, with a shortened version of the summary, of at most @param/main.shortsummarylength characters. */
  shortsummarylength?: number
  /** Include a @doc/summary field in the response with the articles summaries if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags. */
  summary?: ParamsMainSummary
  /** Include a @doc/body field in the response with the articles body text if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags. */
  text?: ParamsMainText
}

export type ParamsIdenticalSortingItem = {
  /** Field to sort on.

  0. Paywall
  1. Global rank */
  field?: number
  /** How to order the results. Defaults to ascending order.

  0. Ascending order
  1. Descending order */
  order?: number
}

/**
 * Content to include for the identical documents. Adds an @doc/identical_documents field to the response with identical documents. The format is the same as for @param/main, but with an additional @param/identical.inherit option, which copies all of the settings, meaning the same fields are returned for the identical articles as those for the main article.
 */
export type ParamsIdentical = {
  /** Use the same settings as @param/main. */
  inherit?: boolean
}

export type ParamsHeaderLimits = {
  maxdist?: number
  maxtimediff?: number
  minwordlength?: number
}

export type ParamsExcludearticlesItem = {
  id_article?: number
  id_site?: number
}

export type ParamsColorbaropt = {
  a?: number
  max_color?: number
  min_quote_width?: number
  min_sep?: number
  min_width?: number
  p?: number
  pixels?: number
}

export type ParamsCategoriesItemItem = {
  profile?: number
}

/**
 * Group articles in search results based on either profile, tag, tb list or subject list ids.

Information about the groups themselves are included in the @res/sort_groups field, with each document contains a reference to the group it belongs to in @doc/sort_group_ref.

Mainly used to feed results into report generation.
 */
export type ParamsCategories = {
  /** What to group by:
  1. Profile ids
  2. Topic builder lists
  3. Subject ids
  4. Tag ids */
  grouping?: number
  /** Id determining the articles membership in the group. Ids relate to @param/categories.grouping, e.g. if the grouping should be based on subject id, these ids are interpreted as subject id that form the basis of each group. */
  item?: ParamsCategoriesItemItem[]
}

export type ParamsArticlesItem = {
  id_article?: number
  id_site?: number
}

export interface Params {
  acceptedcacheage?: number
  access_groups?: number
  access_groups_teaser?: number
  access_info?: number
  add_content_topics?: boolean
  /** Add a @doc/meta_data field to the response. */
  allmeta?: boolean
  /** Add a @doc/topics field to the response, with information about the articles subjects. Passing 0 returns all of the associated subjects. Passing a string with comma separated ids will only return subjects that is also in the list. */
  allsubject?: string
  article_mode?: boolean
  /** Only return matching articles with particular ids. The number of results is upper bounded by the @param/requestedarticles parameter, not the length of this list. */
  articles?: ParamsArticlesItem[]
  aspect_combo?: number
  aspect_info_limit?: number
  aspect_requested?: number
  aspect_sep_limit?: number
  baskets?: number[]
  /** Group articles in search results based on either profile, tag, tb list or subject list ids.

Information about the groups themselves are included in the @res/sort_groups field, with each document contains a reference to the group it belongs to in @doc/sort_group_ref.

Mainly used to feed results into report generation. */
  categories?: ParamsCategories
  colorbaropt?: ParamsColorbaropt
  commentgroups?: number[]
  /** Compare the articles header to a string. Add a @doc/header.compare field to the response with the edit distance. */
  compare_header?: string
  /** Compare the articles combined summary and body text to a string. Add a @doc/summary.compare field to the response with the edit distance. */
  compare_text?: string
  /** Compare the articles url to a string. Add a @doc/compare field to the response with the edit distance. */
  compare_url?: string
  compute_aspects?: number
  /** Pagination settings. Leave empty or out on the first search. Subsequent searches can use the value returned in the responses @res/context field. If the returned value is non-empty, pass the value unchanged, along with the same search parameters, in order to fetch the next batch of results. */
  context?: string
  different_colors?: number
  edited_article_user?: string
  emailsmssearch?: number
  emailsmssearchmaxage?: number
  exclude_check?: boolean
  exclude_profile_tag?: boolean
  /** List of articles to explicitly exclude from the results. */
  excludearticles?: ParamsExcludearticlesItem[]
  /** Include providers organization specific ids in the search results. */
  external_article_provider?: number
  extralink_level?: number
  forceuniquefyontblist?: number
  /** Include a @doc/linkobs field for every article, containing information about the sources the article was observed on. */
  getlinkobs?: boolean
  /** Include a @doc/crawlinfo field for every article, but only if also @param/getlinkobs is set. */
  getlinks?: boolean
  /** Add an @doc/otblists field to the response. */
  getotblists?: string
  /** Consider the articles group. */
  groupidentical?: boolean
  header_limits?: ParamsHeaderLimits
  icon_resolution?: number
  id_application?: number
  id_partner?: number
  id_user?: number
  /** Content to include for the identical documents. Adds an @doc/identical_documents field to the response with identical documents. The format is the same as for @param/main, but with an additional @param/identical.inherit option, which copies all of the settings, meaning the same fields are returned for the identical articles as those for the main article. */
  identical?: ParamsIdentical
  /** Sort articles in group. It is specified as a list of object with field to sort by and in which order. Articles are first sorted by the first item in the list, then the second item, etc. */
  identical_sorting?: ParamsIdenticalSortingItem[]
  in_validation?: boolean
  /** Include the articles OGAE group id in an @doc/equalgroup field in the response. */
  include_equalgroup_id?: number
  /** Include @doc/html_code and @doc/html_len fields in the response, with the articles HTML content. */
  include_html?: number
  include_mentioned_names?: boolean
  include_ogae_identical?: string
  /** Also include articles identical to the match from TB lists. */
  includeidentical?: boolean
  /** The translated fields that should also return the original text. The original text is included in fields with a `_orig` suffix. The value is interpreted the same way as @param/translate_fields. */
  keep_orig_fields?: number
  lastseenarticle?: number
  licence_enforce?: number
  licence_enforce_cond?: number
  licence_enforce_cond_login?: number
  licence_enforce_cond_teaser?: number
  licence_enforce_login?: number
  licence_enforce_teaser?: number
  licence_filter?: number
  licence_filter_login?: number
  licence_filter_teaser?: number
  licence_filter_user?: number
  licence_filter_user_login?: number
  licence_filter_user_teaser?: number
  list_change_sort?: boolean
  locale?: string
  /** Settings related to the text and matches of each article.

In order return text from the articles, either @param/main.header, @param/main.summary or @param/main.body must be set to a non-zero value. All are off by default. */
  main?: ParamsMain
  mark_matches_url?: number
  mark_old_sent?: boolean
  matchesopt?: ParamsMatchesopt
  /** Number of characters to truncate the summary and text fields in the response to. The limit is on the sum of the two fields. If the summary field exists, the truncation is first applied to it. If the length of the summary field is less than @param/max_article_length, the remaining characters are fetched from the body text. */
  max_article_length?: number
  max_copyright_age?: number
  /** Maximum number of characters to translate from each article. */
  max_gt_article_length?: number
  max_search_len?: number
  /** Maximum number of similar articles to return. If the number of similar articles exceeds the limit, the resulting list is capped. */
  max_similar_articles?: number
  natural_order_by?: number
  /** Unix timestamp bounding the newest article to return.

When grouping articles, unless the @param/strict_time_interval is set, the included articles can be outside of the time interval. */
  newest?: number
  newest_newest?: number
  /** Unix timestamp bounding the oldest article to return.

When grouping articles, unless the @param/strict_time_interval is set, the included articles can be outside of the time interval. */
  oldest?: number
  oldest_oldest?: number
  output_media?: number
  parse_analysis?: boolean
  pay_site_access?: number[]
  pay_source_access?: number[]
  /** Comma separated list of picture ids, default 2,4. Types of screenshots to include in @doc/screenshots field.

  2. Preview */
  picture_types?: string
  quoteslack?: number
  quotewishlength?: number
  rawformat?: boolean
  /** Include @doc/similarweb.readership and @doc/similarweb.article_readership fields in the response, with readership estimates. */
  readership?: boolean
  referrer?: string
  /** @deprecated */
  related?: ParamsRelated
  /** Number of articles to return. */
  requestedarticles?: number
  result_encoding?: string
  /** Timezone to display @doc/local_time and @doc/local_rcf822_time fields in. Only applies for xml return format. Also adds @doc/date_report_date and @doc/date_report_time fields to the response. */
  return_timezone?: string
  search_encoding?: string
  /** Discard the search string and fetch articles based only on the ids listed in @param/articles. This parameter improves efficiency when getting specific articles based **only** on ids, as the search string is of no interest. */
  select_by_ids?: boolean
  site_access?: number[]
  smslength?: number
  social_media?: boolean
  /** List of ids specifying the order of the resulting documents. The first item in the list will also be the first document in the results. Articles not mentioned in the list will be added to the end of the results. */
  sort_custom?: ParamsSortCustomItem[]
  /** Instead of returning articles sorted from newest to oldest, return the oldest articles first. */
  sort_oldest_first?: boolean
  source_access?: number[]
  specialtblist?: number[]
  statistics_mode?: boolean
  /** When including identical articles, remove any articles not published within the time interval given by the @param/oldest and @param/newest parameters. */
  strict_time_interval?: boolean
  summary_limits?: ParamsSummaryLimits
  text_limits?: ParamsTextLimits
  /** Add data about topics and entities to the response. The parameter is interpreted as a four digit binary number, each digit toggling the inclusion or exclusion of data.

Setting any of the first three digits adds a @doc/topics_and_entities field to each document in the response. Each bit sets what data to include:

  1. Topics
  2. Entities
  3. Entities without a wikidata id

Setting the fourth bit adds entity ids to match tags, using ent attributes, `<match ent="1,23...">`. Since these tags are activated by @param/main.matches, you should set that as well. It also adds two dictionaries, @res/wikinames and @res/wikidescriptions, with human readable names and descriptions of the entities.

Some useful values:

  - Setting it to 7 will include all the available data, but no highlighting information.
  - Setting it 8 will include only highlighting information.
  - Setting it to 15 will include everything. */
  textrazor?: number
  track_id_article?: number
  /** Get a tracking articles matching articles. Used in conjunction with @param/track_id_article. */
  track_id_site?: number
  translate_cfg?: string
  /** The fields that should be translated. The parameter is intepreted as a binary number, each digit toggling translation of the corresponding field.

Each bit sets the fields to translate:
  1. @doc/header
  2. @doc/short_header
  3. @doc/summary
  4. @doc/short_summary
  5. @doc/body
  6. @doc/short_body
  7. @doc/caption
  8. @doc/articlemedia.text
  9. @doc/quotes
  10. @doc/colorbar
  11. @doc/matches
  12. Translate headline even if a manually translated header has been written and is provided in the @doc/translated_header field.
  13. Translate the summary even if a manually written summary has been written and is provided in the @doc/manual_summary field.
  14. @doc/manual_summary

The original fields can also be included if the @param/keep_orig_fields parameter is set. */
  translate_fields?: number
  translate_to?: string
  translate_type?: number
  update_search?: boolean
  use_image_proxy?: number
  use_recurring_search?: boolean
  /** Use a shortened URL format in the responses @doc/url field. */
  use_short_links?: boolean
  user_options?: number
  wap_mode?: number
  /** Pass -1 to start a process watching for new articles matching the search. The watch id process is added to the search results in the @res/watch_id field. */
  watch_id?: number
}

export type DocumentUsercommentsUsercommentItem = {
  /** Comment text. */
  comment: string
  email: string
  first_name: string
  id_article: number
  /** Comment group comment belongs to. */
  id_group: number
  id_site: number
  /** User who made the comment. */
  id_user: number
  last_name: string
  signature: string
  /** When the comment was made. */
  timestamp: number
  username: string
}

/**
 * Articles comments.
 */
export type DocumentUsercomments = {
  /** Number of comments. */
  count: number
  usercomment: DocumentUsercommentsUsercommentItem[]
}

/**
 * The articles header manually translated.
 */
export type DocumentTranslatedHeader = {
  language?: string
  matches?: boolean
  text?: string
}

/**
 * If the document is a tracking match, details about the upload it matched and additional data regarding the relationship between the upload and the document.
 */
export type DocumentTracking = {
  /** Partner specific id of the upload the document matched. */
  partner_article_id: string
  /**
   * A percentage estimate of how much of the matching article that stems from the uploaded article.
   * @minimum 0
   * @maximum 100
   */
  percent_of_match: number
  /**
   * A percentage estimate of how much of the uploaded article that were referenced in the matching article.
   * @minimum 0
   * @maximum 100
   */
  percent_used: number
  /** Tracking score, a measure of how likely it is that the document is linked to the uploaded article. */
  score: number
}

/**
 * IPTC Media Topics found in the article.
 */
export type DocumentTopicsAndEntitiesTopics = {
  /** IPTC Media Topic name. Topic names are hierarchically structured, each level separated by a >, and with the levels ordered from the most general to the most specific. */
  label?: string
  /** IPTC Media Code. */
  mediatopic_id?: string
  /** Confidence in the article revolving around the topic. */
  score?: number
}

/**
 * This shows the overall sentiment of an article with respect to the article’s main point/topic or subject matter, taking into account its heading, summary, and body text.

 */
export type DocumentTopicsAndEntitiesSentiment =
  (typeof DocumentTopicsAndEntitiesSentiment)[keyof typeof DocumentTopicsAndEntitiesSentiment]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentTopicsAndEntitiesSentiment = {
  Positive: 'Positive',
  Negative: 'Negative',
  Neutral: 'Neutral',
} as const

/**
 * Topics and entities found in the article.

**Note**: Will only be present if there are topics and entities data to return for the article.
 */
export type DocumentTopicsAndEntities = {
  /** Entities found in the article. There are three kinds of entities registered: locations, organizations and persons. */
  entities?: DocumentTopicsAndEntitiesEntities
  /** This shows the overall sentiment of an article with respect to the article’s main point/topic or subject matter, taking into account its heading, summary, and body text.
   */
  sentiment?: DocumentTopicsAndEntitiesSentiment
  /**
   * This is computed at sentence level to then aggregate those values into a final output.

   * @minimum -1
   * @maximum 1
   */
  sentiment_score?: number
  /** IPTC Media Topics found in the article. */
  topics?: DocumentTopicsAndEntitiesTopics
}

export type DocumentTopicsAndEntitiesEntitiesPerson = {
  /** Name in the crunchbase platform. */
  crunchbase?: string
  /** Name of the person. */
  entity?: string
  /** The Financial Instrument Global Identifier (FIGI) (formerly Bloomberg Global Identifier (BBGID)) is an open standard, unique identifier of financial instruments. */
  figi?: string
  /** Legal Entity Identifier (or LEI) is a international identifier made up of a 20-character identifier that identifies distinct legal entities that engage in financial transactions. */
  lei?: string
  /** links to Thomson Reuters Open PermID. PermID provides comprehensive identification across a wide variety of entity types including organizations, instruments, funds, issuers and people. */
  permid?: string
  /** Classified sentiment for the entity in the article. */
  sentiment?: string
  /** Id in the Wikidata knowledge graph. */
  wikidata_id?: string
}

export type DocumentTopicsAndEntitiesEntitiesOrganization = {
  /** Name in the crunchbase platform. */
  crunchbase?: string
  /** Name of the organization. */
  entity?: string
  /** The Financial Instrument Global Identifier (FIGI) (formerly Bloomberg Global Identifier (BBGID)) is an open standard, unique identifier of financial instruments. */
  figi?: string
  /** Legal Entity Identifier (or LEI) is a international identifier made up of a 20-character identifier that identifies distinct legal entities that engage in financial transactions. */
  lei?: string
  /** links to Thomson Reuters Open PermID. PermID provides comprehensive identification across a wide variety of entity types including organizations, instruments, funds, issuers and people. */
  permid?: string
  /** Classified sentiment for the entity in the article. */
  sentiment?: string
  /** Id in the Wikidata knowledge graph. */
  wikidata_id?: string
}

export type DocumentTopicsAndEntitiesEntitiesLocation = {
  /** Name in the crunchbase platform. */
  crunchbase?: string
  /** Name of the location. */
  entity?: string
  /** The Financial Instrument Global Identifier (FIGI) (formerly Bloomberg Global Identifier (BBGID)) is an open standard, unique identifier of financial instruments. */
  figi?: string
  /** Legal Entity Identifier (or LEI) is a international identifier made up of a 20-character identifier that identifies distinct legal entities that engage in financial transactions. */
  lei?: string
  /** links to Thomson Reuters Open PermID. PermID provides comprehensive identification across a wide variety of entity types including organizations, instruments, funds, issuers and people. */
  permid?: string
  /** Classified sentiment for the entity in the article. */
  sentiment?: string
  /** Id in the Wikidata knowledge graph. */
  wikidata_id?: string
}

/**
 * Entities found in the article. There are three kinds of entities registered: locations, organizations and persons.
 */
export type DocumentTopicsAndEntitiesEntities = {
  location?: DocumentTopicsAndEntitiesEntitiesLocation
  organization?: DocumentTopicsAndEntitiesEntitiesOrganization
  person?: DocumentTopicsAndEntitiesEntitiesPerson
}

export type DocumentTopicsItem = {
  /** Subject id. */
  id: number
  linktype_max?: number
  linktype_now?: number
  /** Meta category, an integer representing the type of the subject. */
  metacat?: number
  /** Meta category abstraction level, ordered from 0 and up. */
  metacat_level?: number
  /** Subject name. */
  text: string
}

export type DocumentTagsItem = {
  equal_sent: number
  id: number
  is_owner: number
  sent: number
  set: number
}

/**
 * The articles lede. Does not contain HTML.
 */
export type DocumentSummary = {
  /** Edit distance between the @param/compare_text parameter and the combined summary and body text. */
  compare?: number
  /** Whether matches should be marked in the summary, i.e. if @param/main.summary is set to 2. */
  matches?: boolean
  text?: string
  /** Whether the field was truncated or not. */
  truncated?: boolean
}

export type DocumentSourcesSource = {
  /** Id of the source. */
  id: number
  linktype_max?: number
  linktype_now?: number
  /** Name of the source. */
  name: string
  /** Name of the site. */
  sitename: string
  /** Url of the site. */
  siteurl: string
  /** Url of the source. */
  url: string
}

/**
 * Information about sources the article was observed on.
 */
export type DocumentSources = {
  source?: DocumentSourcesSource
}

export type DocumentSiteRank = {
  rank_country?: number
  rank_global?: number
}

/**
 * Site level readership estimates.
 */
export type DocumentSimilarwebReadership = {
  P?: number
  value?: number
}

/**
 * Article level readership estimates.
 */
export type DocumentSimilarwebArticleReadership = {
  /** Predicted fraction of the sites readers that will read the article in the next hour. */
  DeltaP?: number
  /** Predicted number of additional readers in the next hour, assuming its visibility does not change. */
  DeltaPValue?: number
  /** Predicted fraction of the sites readers that will eventually read the article. */
  ExpectedP?: number
  /** Predicted number of people that will eventually read the article. */
  ExpectedPValue?: number
  /** Estimated fraction of the sites readers having read the article so far. */
  P?: number
  /** Standard deviation of ExpectedP. */
  StdExpectedP?: number
  /** Standard deviation of `ExpectedPValue`. Higher values indicate lower confidence in the final value.

For recently published articles, this value can be high, since little is know about how long it will stay visible etc. */
  StdExpectedPValue?: number
  /** Estimated number of readers of the article so far. */
  value?: number
}

/**
 * Traffic data for the domain and readership estimates.

**Note**: Will only be present if there is traffic data to return for the article.
 */
export type DocumentSimilarweb = {
  /** Article level readership estimates. */
  article_readership?: DocumentSimilarwebArticleReadership
  /** Fraction of visits that only viewed a single page before going to another site. */
  bounce_rate?: number
  category?: string
  category_rank?: number
  domain?: string
  /** Number of montly visits. */
  mvisit?: number
  /** Average number of pages viewed per visit. */
  page_per_visit?: number
  /** Site level readership estimates. */
  readership?: DocumentSimilarwebReadership
  sanitize?: number
  /** Average time spent on the site per visit, in seconds. */
  time_on_site?: number
  unique_desktop_visits?: number
  unique_mobile_visits?: number
}

export type DocumentShortSummary = {
  length?: number
  matches?: boolean
  removed?: number
  text?: string
}

export type DocumentShortHeader = {
  length?: number
  matches?: boolean
  removed?: number
  text?: string
}

export type DocumentShortBody = {
  length?: number
  matches?: boolean
  removed?: number
  text?: string
}

export type DocumentScreenshotsItem = {
  height: number
  id_type: number
  /** Url */
  text: string
  width: number
}

export type DocumentQuotesQuote = {
  matches?: boolean
  text?: string
}

/**
 * Text surrounding search matches.
 */
export type DocumentQuotes = {
  quote?: DocumentQuotesQuote
}

export type DocumentPdfFilePdfPartsItem = {
  args: string
  pages: number
  text: string
}

/**
 * Data about print content.
 */
export type DocumentPdfFile = {
  all: string
  args: string
  pdf_parts: DocumentPdfFilePdfPartsItem[]
}

export type DocumentOtblists = { [key: string]: any }

export interface Document {
  adprice?: number
  /** Similarity information about the almost identical documents. The documents themselves are added to the @doc/identical_documents field. */
  almost_identical?: DocumentAlmostIdentical
  /** The articles images. */
  articleimages?: DocumentArticleimages
  /** The articles multimedia content. */
  articlemedia?: DocumentArticlemediaItem[]
  /** The articles authors. */
  author?: string
  /** The articles body text. Typically contains HTML. */
  body?: DocumentBody
  /** All the images' captions concatenated. */
  caption?: DocumentCaption
  circulation?: number
  compare?: number
  content_protected?: number
  countrycode?: string
  countryname?: string
  distribute_conditions?: string
  /** OGAE group id. */
  equalgroup?: number
  existinbasket?: string
  /** Whether text is an extract or not. */
  extract?: boolean
  /** Information about the source the article was first observed on. */
  first_source: DocumentFirstSource
  /** The articles header. Does not contain HTML. */
  header?: DocumentHeader
  hidden?: boolean
  /** Site specific article id. Unique under each site. */
  id_article: number
  /** Id of the site the article was published. */
  id_site: number
  /** Collection of identical and almost identical documents. */
  identical_documents?: DocumentIdenticalDocuments
  internal_search_reply: DocumentInternalSearchReply
  language: DocumentLanguage
  linkobs?: DocumentLinkobs
  /** List of states that the articles is in per each profile. */
  list_states?: DocumentListStatesItem[]
  /** Local time the article was published. */
  local_rcf822_time: DocumentLocalRcf822Time
  /** Local time the article was published. */
  local_time: DocumentLocalTime
  /** A manually written summary of the article. */
  manual_summary?: DocumentManualSummary
  /** List of search matches. */
  matches?: DocumentMatchesItem[]
  matches_table?: DocumentMatchesTableItem[]
  mediafile?: number
  /** Description of the origin of the article. */
  mediatype?: DocumentMediatype
  /** Various meta data about the article, like social media engagement. */
  meta_data?: DocumentMetaData
  /** Url where the article was found. */
  orig_url?: string
  otblists?: DocumentOtblists
  /** Data about print content. */
  pdf_file?: DocumentPdfFile
  /** Length of timemap, if present, in seconds. */
  playlength?: number
  /** Index of the document in the list. One-based. */
  position: number
  profile_id?: number
  profile_name?: string
  /** Text surrounding search matches. */
  quotes?: DocumentQuotes
  reach?: number
  screenshots?: DocumentScreenshotsItem[]
  short_body?: DocumentShortBody
  short_header?: DocumentShortHeader
  short_summary?: DocumentShortSummary
  /** Traffic data for the domain and readership estimates.

**Note**: Will only be present if there is traffic data to return for the article. */
  similarweb?: DocumentSimilarweb
  site_rank?: DocumentSiteRank
  /** Reference to categorization group. */
  sort_group_ref?: number
  /** Information about sources the article was observed on. */
  sources?: DocumentSources
  /** Timestamp used internally. */
  stimestamp: number
  /** Timestamp used internally. */
  stimestamp_index: number
  /** Id of the stored search the article matched, if requested through the StoredSearch feed. */
  stored_search_id?: number
  /** The articles lede. Does not contain HTML. */
  summary?: DocumentSummary
  tags?: DocumentTagsItem[]
  /** Information about the articles subjects, such as language, country and the medium it was published in. Articles are assigned subjects based on the site and source it was found. */
  topics?: DocumentTopicsItem[]
  /** Topics and entities found in the article.

**Note**: Will only be present if there are topics and entities data to return for the article. */
  topics_and_entities?: DocumentTopicsAndEntities
  /** If the document is a tracking match, details about the upload it matched and additional data regarding the relationship between the upload and the document. */
  tracking?: DocumentTracking
  /** The articles header manually translated. */
  translated_header?: DocumentTranslatedHeader
  /** Unix timestamp of when the article was published. */
  unix_timestamp: number
  /** Link to tool displaying the crawled article, with options for highlighting matches etc. */
  url?: string
  /** Domain name of the site where the article was found. */
  url_common?: string
  /** Articles comments. */
  usercomments?: DocumentUsercomments
  word_count: number
}

/**
 * Last time Twitter meta data was updated.
 */
export type DocumentMetaDataSmTwStimestamp = { [key: string]: any }

/**
 * Number of shares on Twitter. Only for regular articles.
 */
export type DocumentMetaDataSmTwShareCount = { [key: string]: any }

/**
 * Number of retweets.
 */
export type DocumentMetaDataSmTwRetweetCount = { [key: string]: any }

/**
 * Number of favorites.
 */
export type DocumentMetaDataSmTwFavoriteCount = { [key: string]: any }

/**
 * Twitter engagement count, the sum of retweets, favorites and shares.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions.
 */
export type DocumentMetaDataSmTwEngagementCount = { [key: string]: any }

/**
 * Last time Instagram meta data was updated.
 */
export type DocumentMetaDataSmIgStimestamp = { [key: string]: any }

/**
 * Number of likes on Instagram. Only for Instagram posts.
 */
export type DocumentMetaDataSmIgLikeCount = { [key: string]: any }

/**
 * Instagram engagement count, the sum of likes and comments. Only for Instagram posts.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions.
 */
export type DocumentMetaDataSmIgEngagementCount = { [key: string]: any }

/**
 * Number of comments on Instagram. Only for Instagram posts.
 */
export type DocumentMetaDataSmIgCommentCount = { [key: string]: any }

/**
 * Last time Facebook meta data was updated.
 */
export type DocumentMetaDataSmFbStimestamp = { [key: string]: any }

/**
 * Number of shares on Facebook. Only for Facebook posts.
 */
export type DocumentMetaDataSmFbShareCount = { [key: string]: any }

/**
 * Number of reactions on Facebook. Only for Facebook posts.
 */
export type DocumentMetaDataSmFbReactionCount = { [key: string]: any }

/**
 * Facebook engagement count, the sum of reactions, shares and comments.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions.
 */
export type DocumentMetaDataSmFbEngagementCount = { [key: string]: any }

/**
 * Number of comments on Facebook. Only for Facebook posts.
 */
export type DocumentMetaDataSmFbCommentCount = { [key: string]: any }

/**
 * Various meta data about the article, like social media engagement.
 */
export type DocumentMetaData = {
  /** Number of comments on Facebook. Only for Facebook posts. */
  sm_fb_comment_count?: DocumentMetaDataSmFbCommentCount
  /** Facebook engagement count, the sum of reactions, shares and comments.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions. */
  sm_fb_engagement_count?: DocumentMetaDataSmFbEngagementCount
  /** Number of reactions on Facebook. Only for Facebook posts. */
  sm_fb_reaction_count?: DocumentMetaDataSmFbReactionCount
  /** Number of shares on Facebook. Only for Facebook posts. */
  sm_fb_share_count?: DocumentMetaDataSmFbShareCount
  /** Last time Facebook meta data was updated. */
  sm_fb_stimestamp?: DocumentMetaDataSmFbStimestamp
  /** Number of comments on Instagram. Only for Instagram posts. */
  sm_ig_comment_count?: DocumentMetaDataSmIgCommentCount
  /** Instagram engagement count, the sum of likes and comments. Only for Instagram posts.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions. */
  sm_ig_engagement_count?: DocumentMetaDataSmIgEngagementCount
  /** Number of likes on Instagram. Only for Instagram posts. */
  sm_ig_like_count?: DocumentMetaDataSmIgLikeCount
  /** Last time Instagram meta data was updated. */
  sm_ig_stimestamp?: DocumentMetaDataSmIgStimestamp
  /** Twitter engagement count, the sum of retweets, favorites and shares.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions. */
  sm_tw_engagement_count?: DocumentMetaDataSmTwEngagementCount
  /** Number of favorites. */
  sm_tw_favorite_count?: DocumentMetaDataSmTwFavoriteCount
  /** Number of retweets. */
  sm_tw_retweet_count?: DocumentMetaDataSmTwRetweetCount
  /** Number of shares on Twitter. Only for regular articles. */
  sm_tw_share_count?: DocumentMetaDataSmTwShareCount
  /** Last time Twitter meta data was updated. */
  sm_tw_stimestamp?: DocumentMetaDataSmTwStimestamp
}

/**
 * Description of the origin of the article.
 */
export type DocumentMediatype = {
  clip: boolean
  /** Length of timemap, if present, in seconds. */
  duration?: number
  fulltext: boolean
  haslogo: boolean
  hastext: boolean
  /** Whether article was fetched from behind a paywall. */
  paywall: boolean
  text: string
  /** Whether timemap is present. */
  timemap: boolean
}

export type DocumentMatchesTableItem = { [key: string]: any }

export type DocumentMatchesItem = {
  color?: number
  /** Offset of match in video or radio clip, if present, in seconds. */
  play_offset?: number
  /** Text that was matched. If you use wildcards e.g. this will contain the actual word or phrase matched. */
  text?: string
  type?: string
}

/**
 * A manually written summary of the article.
 */
export type DocumentManualSummary = {
  language?: string
  matches?: boolean
  text?: string
}

/**
 * Local time the article was published.
 */
export type DocumentLocalTime = {
  GMT: number
  /** ISO 8601 formatted time. */
  text: string
}

/**
 * Local time the article was published.
 */
export type DocumentLocalRcf822Time = {
  /** RFC 822 formatted time */
  text: string
}

export type DocumentListStatesItem = {
  id_list?: number
  state?: number
  state_name?: string
}

export type DocumentLinkobsSourceObsImages = { [key: string]: any }

/**
 * Details about each observation.
 */
export type DocumentLinkobsSourceObs = {
  /** Unix timestamp */
  from?: number
  images?: DocumentLinkobsSourceObsImages
  level?: string
  /** Unix timestamp */
  to?: number
}

export type DocumentLinkobsSource = {
  depth?: number
  /** Id of the source. */
  id?: number
  /** Name of the source. */
  name?: string
  /** Details about each observation. */
  obs?: DocumentLinkobsSourceObs
}

export type DocumentLinkobs = {
  cnt: number
  source: DocumentLinkobsSource
}

export type DocumentLanguage = {
  encoding: string
  text: string
}

export type DocumentInternalSearchReply = {
  id_article: number
  id_site: number
  text: string
}

export type DocumentIdenticalDocumentsDocumentItem = { [key: string]: any }

/**
 * Collection of identical and almost identical documents.
 */
export type DocumentIdenticalDocuments = {
  /** Number of identical documents, that is the length of `identical_documents.document`. */
  cnt: number
  /** Identical documents. */
  document: DocumentIdenticalDocumentsDocumentItem[]
}

/**
 * The articles header. Does not contain HTML.
 */
export type DocumentHeader = {
  /** Edit distance between the @param/compare_header parameter and the header text. */
  compare?: number
  /** Whether matches should be marked in the header, i.e. if @param/main.header is set to 2. */
  matches?: boolean
  text?: string
}

/**
 * Information about the source the article was first observed on.
 */
export type DocumentFirstSource = {
  /** Id of the source. */
  id: number
  /** Name of the source. */
  name: string
  /** Name of the site. */
  sitename: string
  /** Url of the site. */
  siteurl: string
  /** Url of the source. */
  url: string
}

/**
 * All the images' captions concatenated.
 */
export type DocumentCaption = {
  text?: string
}

/**
 * The articles body text. Typically contains HTML.
 */
export type DocumentBody = {
  /** Whether matches should be marked in the body text, i.e. if @param/main.text is set to 2. */
  matches?: boolean
  readmore?: boolean
  text?: string
  /** Whether the field was truncated or not. */
  truncated?: boolean
}

export type DocumentArticlemediaItem = {
  localurl?: string
  text?: string
  thumbnail?: string
  url?: string
}

export type DocumentArticleimagesArticleimageItemCaption = {
  matches?: boolean
  text?: string
}

export type DocumentArticleimagesArticleimageItem = {
  caption?: DocumentArticleimagesArticleimageItemCaption
  /** Url of the image. */
  url?: string
}

/**
 * The articles images.
 */
export type DocumentArticleimages = {
  /** Data about each image. Note that it could be that there is a caption, but for some reason there were no url. */
  articleimage: DocumentArticleimagesArticleimageItem[]
  /** Number of images, that is the length of @param/articleimage. */
  count: number
}

/**
 * Similarity information about the almost identical documents. The documents themselves are added to the @doc/identical_documents field.
 */
export type DocumentAlmostIdentical = {
  id_article: number
  id_site: number
  /** Similarity score. Articles having a high score are more similar than those with a lower score. */
  sim: number
}

export interface SearchMatch {
  color?: number
  text?: string
  type?: string
}

export interface MatchItem {
  active?: boolean
  color?: number
  colspan?: number
  length?: number
  removed?: number
  rowspan?: number
  text?: string
  type?: string
}

export interface MatchTableRow {
  match_item?: MatchItem
}

export interface MatchTable {
  cols?: number
  row?: MatchTableRow
  rows?: number
}

/**
 * A dictionary mapping entity ids to entity names.
 */
export type SearchResponseWikinames = { [key: string]: any }

/**
 * A dictionary mapping entity ids to entity descriptions.
 */
export type SearchResponseWikidescriptions = { [key: string]: any }

export type SearchResponseSortGroups = {
  /** Number of articles in group. */
  count?: number
  /** Id the group was filtered on. 0 if it's the group containing the articles not matching any group. */
  group?: number
  /** Name of the group. */
  name?: string
  weight?: number
}

export type SearchResponseDebugLinesItem = {
  /** What the query was interpreted as, if the line had any errors corrected. */
  query?: string
  /** 1 if the line had an error. 0 otherwise. */
  state?: number
}

/**
 * Information about search term corrections. Whenever there are minor mistakes in the seach term, e.g. a missing closing quote, the parser attempts to correct it.
 */
export type SearchResponseDebug = {
  /** Number of lines. */
  count?: number
  lines?: SearchResponseDebugLinesItem[]
}

export interface SearchResponse {
  /** Name of the current release of the search system. Not in use at the moment. Returns NOT ACTIVE for all requests. */
  branch: string
  cacheage: number
  compiledate: string
  /** String value used for pagination, representing where the current search ended. For use by the @param/context parameter in the request.

If the value is empty there are no more articles to fetch. */
  context: string
  count: number
  cputime: number
  /** Information about search term corrections. Whenever there are minor mistakes in the seach term, e.g. a missing closing quote, the parser attempts to correct it. */
  debug?: SearchResponseDebug
  /** Articles matching search. */
  document: Document[]
  /** Number of documents in the result, that is the length of the @res/document list. */
  documents: number
  /** Unix timestamp of the first document matching the search also in the response. Matches are found going backwards in time, so it will also be the document published the latest. */
  first_timestamp: number
  /** Server having processed the request. */
  host: string
  /** Unix timestamp of the last document matching the search also in the response. Matches are found going backwards in time, so it will also be the document published the earliest. */
  last_timestamp: number
  matches_table?: MatchTable
  notimeout: boolean
  /** Estimate on the total number of documents matching the search in the range given by @res/range_start and @res/range_end. */
  range_count?: number
  /** Unix timestamp of the end of the range used for computing @res/range_count. A @res/range_end of 0 means that the end of the range is now. */
  range_end?: number
  /** Unix timestamp of the start of the range used for computing @res/range_count. */
  range_start?: number
  search_matches?: SearchMatch[]
  /** Unix timestamp a new search can start from were it to get newer content. Thus, it can be passed for @param/oldest. Will never be larger than current time. */
  search_start?: number
  sort_groups?: SearchResponseSortGroups
  /** Id of watch process. */
  watch_id?: number
  /** A dictionary mapping entity ids to entity descriptions. */
  wikidescriptions?: SearchResponseWikidescriptions
  /** A dictionary mapping entity ids to entity names. */
  wikinames?: SearchResponseWikinames
}

export interface SearchError {
  compiledate: string
  errors: string
  host: string
}

export type SearchResultSearchresult = SearchError | SearchResponse

export interface SearchResult {
  searchresult: SearchResultSearchresult
}

export type SearchGlueRequestBodyItemsItem = {
  glue_end: string
  glue_middle?: string
  glue_start: string
  list_one: SearchGlueRequestBodyItemsItemListOne
  list_two?: SearchGlueRequestBodyItemsItemListTwo
}

export interface SearchGlueRequestBody {
  items: SearchGlueRequestBodyItemsItem[]
  params: Params
}

export type SearchRequestBodyExpressionsSearchlineObjectExpressionsItemSearchlineFiltersItem = {
  id?: string
  type?: string
}

export type SearchRequestBodyExpressionsSearchlineObjectExpressionsItemSearchline = {
  filters?: SearchRequestBodyExpressionsSearchlineObjectExpressionsItemSearchlineFiltersItem[]
  searchterm?: string
}

export type SearchRequestBodyExpressionsSearchlineObjectExpressionsItem = {
  linemode?: string
  searchline?: SearchRequestBodyExpressionsSearchlineObjectExpressionsItemSearchline
}

export interface SearchRequestBodyExpressionsSearchlineObject {
  expressions?: SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[]
  params?: Params
}

export type SearchRequestBodyExpressionsSearchlineStringExpressionsItem = {
  linemode?: string
  searchline?: string
}

export interface SearchRequestBodyExpressionsSearchlineString {
  expressions?: SearchRequestBodyExpressionsSearchlineStringExpressionsItem[]
  params?: Params
}

export interface SearchRequestBodySearchtermOnly {
  params?: Params
  searchterm?: string
}

export type SearchGlueRequestBodyItemsItemListTwo =
  | SearchRequestBodySearchtermOnly
  | SearchRequestBodyExpressionsSearchlineString
  | SearchRequestBodyExpressionsSearchlineObject

export type SearchGlueRequestBodyItemsItemListOne =
  | SearchRequestBodySearchtermOnly
  | SearchRequestBodyExpressionsSearchlineString
  | SearchRequestBodyExpressionsSearchlineObject

/**
 * A suggestion item, the non-required field will be returned from the normal suggest-endpoints, but not by the getids endpoint.
 */
export interface FilterSuggestion {
  /** Access needed to view the item. */
  accessGroup?: number
  /** Highlighting information about matches to the `name. Only added if there is a match. */
  hl?: string
  /** Highlighting information about matches to the `url`. Only added if there is a match.

On the same format as `hl`. */
  hlu?: string
  /** Filter value.

If the `type` is `site`, some items have their `id` prefixed with `00`. These are `source`-ids and not `site`-ids.
You most likely don't have to worry about this, as most other systems automatically translates these for you. */
  id: string
  lang: string
  /** `mo`, but any word apart from the last in the search term must be a full match. 
Searching for `n york` will return `01` in `match` but `11` in `mo` for `New York`.

Omitted if the entry is only there to fill up the list. */
  match?: string
  /** Language code of the site if `type` is `site`. */
  meta: string
  /** Information about which of the words in the search term matched and where.

Returns a string with one digit for each word in the search term.
Each digit convey information about what the word in the search term matched.
0 means no match, 1 means match in name and 2 means match in the url.
Entries added to fill up the list return `n/a` */
  mo: string
  /** Localized name of the filter value. */
  name: string
  /** Rank id of the site if the `type` is `site`. */
  rank: string
  /** Filter type. Use this if you want to use the item some other place, like this `<type>:<id>`. */
  type: string
  /** Localized name of the filter type. */
  typeName: string
  /** URL of the site if the `type` is `site`. */
  url: string
  weight: number
}

export interface FilterSuggestionMultiple {
  action: string
  /** @minimum 0 */
  col: number
  /** @minimum 0 */
  seq: number
  suggest: FilterSuggestion[]
  typeName: string
}

export interface SuggestionResponseMultiple {
  content?: FilterSuggestionMultiple
  geo?: FilterSuggestionMultiple
  /** Number of results returned for single mode. `0` for multiple mode. */
  hits: number
  lang?: FilterSuggestionMultiple
  media?: FilterSuggestionMultiple
  /** Pagination cursor. */
  next_offset: string
  rank?: FilterSuggestionMultiple
  /** The search term. */
  search: string
  site?: FilterSuggestionMultiple
  'time(ms)': number
  timePeriod?: FilterSuggestionMultiple
  tokens: number
  warnings: string
}

export interface SuggestionResponse {
  /** Number of results returned for single mode. `0` for multiple mode. */
  hits: number
  /** Pagination cursor. */
  next_offset: string
  /** List of information about matching filters. For multiple mode this field is replaced by lists with the filter types names. */
  results: FilterSuggestion[]
  /** The search term. */
  search: string
  'time(ms)': number
  tokens: number
  warnings: string
}
