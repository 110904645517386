import { ArrowForward24Regular } from '@fluentui/react-icons'
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@opoint/infomedia-storybook'

import { extractArticlesFromAlert, getIsShareDisabled } from '../../../../../helpers/alerts'
import { getAlertHistoryById, getCurrentAlertMail } from '../../../../../selectors/alertsSelectors'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { ActionBarDropdownMenuItem } from '../../ActionBarDropdownMenuItem'
import { ActionBarActionsProps, ActionVariant } from '../../types'
import { useRequiredParams } from '../../../../hooks/useRequiredParams'

const AlertForwardAction = ({ variant = ActionVariant.LONG, name }: ActionBarActionsProps) => {
  const dispatch = useAppDispatch()
  const { alertId } = useRequiredParams(['alertId'])

  const currentAlertMail = useAppSelector(getCurrentAlertMail)
  const alertHistory = useAppSelector(getAlertHistoryById(+alertId))

  const articles = extractArticlesFromAlert(currentAlertMail)
  const isShareDisabled = getIsShareDisabled(currentAlertMail, articles, alertHistory)
  const onClickAction = () => dispatch({ type: 'ALERT_CHANGE_RECIPIENTS_OPEN' })

  if (variant === ActionVariant.MENU) {
    return (
      <ActionBarDropdownMenuItem onClick={onClickAction} data-cy="alertForwardBtn">
        <ArrowForward24Regular />
        {name}
      </ActionBarDropdownMenuItem>
    )
  }

  if (variant === ActionVariant.LONG) {
    return (
      <Button variant="outline" onClick={onClickAction} data-cy="alertForwardBtn">
        <ArrowForward24Regular />
        {name}
      </Button>
    )
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          onClick={onClickAction}
          disabled={isShareDisabled}
          data-cy="alertForwardBtn"
        >
          <ArrowForward24Regular />
          <span className="sr-only">{name}</span>
        </Button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{name}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}

export default AlertForwardAction
