import { AppsList24Regular } from '@fluentui/react-icons'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { t } from "i18next";
import { NavigationComponentProps, NavigationSectionKey } from '../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'

export const FolderManagementNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => (
    <NavigationSection>
      <NavigationSectionHeaderLink
        label={t('Folder management')}
        isExpanded={Boolean(isExpanded)}
        ref={ref}
        to="/folders"
        section={NavigationSectionKey.FOLDER_MANAGEMENT}
        {...props}
      >
        <AppsList24Regular className="w-7 shrink-0 text-spring.1" />
      </NavigationSectionHeaderLink>
    </NavigationSection>
  ),
)
FolderManagementNavigationSection.displayName = 'FolderManagementNavigationSection'
