import { ListingStyle } from '@opoint/infomedia-storybook'
import { createSelector } from 'reselect'

import { type LicenseModules, MODULES, type Modules } from '../components/constants/permissions'
import { autoTranslateLanguages } from '../components/constants/settings'
import { keyValueParse } from '../components/helpers/common'
import { AllowedQueryLanguageValues, AppUISettings, ProfileEditorAccessValues } from '../components/interfaces/settings'
import { LocaleSuggestionLocale } from '../components/types/locale'
import type { FilterType } from '../components/types/settings'
import { getLocale } from '../helpers/locale'
import { clearBit, isBitSet } from '../opoint/common'
import { getFullnameLanguage, locales, TRANSLATE_ARTICLE } from '../opoint/common/constants'
import { ArticleMetadataType, Translate } from '../opoint/flow'
import { QueryLanguage } from '../components/profile/builder/types'
import { getReports as getReportState, getSettings as getState } from './rootSelectors'

// The application does not finish loading until this is true (and no other selectors should be in use)
export const isSettingsFetched = createSelector(getState, (settingsState) => !!Object.keys(settingsState.list).length)

export const getSettingsList = createSelector(getState, (settingsState) => settingsState.list)

const getSettingsChoices = createSelector(getState, (settingsState) => settingsState.choices)

export const getUISetting = <T extends keyof AppUISettings>(uiSetting: T) =>
  createSelector(getSettingsList, (list): AppUISettings[T] | undefined => list.APP_UI_SETTINGS?.[uiSetting])

export const getIsSavingSettings = createSelector(getState, (settingsState) => settingsState.isSaving)

export const getDefaultHome = createSelector(getSettingsList, (list) => list.APP_DEFAULT_HOME)

export const getNewPortAutoload = createSelector(getSettingsList, (list) => !!list.NEW_PORTAL_AUTOLOAD)

export const getDefaultSearch = createSelector(getSettingsList, (list) => list.DEFAULT_SEARCH || null)

export const getSimilarArticlesVisible = createSelector(
  getSettingsList,
  (list) => list.SIMILAR_SETTING_VISIBLE === MODULES.SIMILAR_SETTING_VISIBLE.ON,
)

export const getDefaultFolderNameProfiles = createSelector(getSettingsList, (list) => list.DEFAULT_FOLDER_NAME_PROFILES)

export const getDefaultFolderNameTags = createSelector(getSettingsList, (list) => list.DEFAULT_FOLDER_NAME_TAGS)

export const getDefaultFolderNameSentimentTags = createSelector(
  getSettingsList,
  (list) => list.DEFAULT_FOLDER_NAME_SENTIMENT_TAGS,
)

export const getDefaultFolderNameStatistics = createSelector(
  getSettingsList,
  (list) => list.DEFAULT_FOLDER_NAME_STATISTICS,
)

export const getGroupingEnabled = createSelector(getSettingsList, (list) => !!list.IDENTICAL)

export const getOpointLocale = createSelector(getState, getLocale)

/**
 * This selector shouldn't be used anywere but in a settings modal
 * because it can return 'browser' value
 */
export const getOpointLocaleSetting = createSelector(getSettingsList, getOpointLocale, (list, opointLocaleResult) =>
  //TODO: handle 'browser' value
  //https://infomediacorp.atlassian.net/browse/FE-11542
  //@ts-expect-error: I am not sure where 'browser' originates from, we should double check API to see if this check is needed
  !list.LANGUAGE || list.LANGUAGE === 'browser' ? 'browser ' : opointLocaleResult,
)

export const getSuggestionLocale = createSelector(
  getSettingsList,
  getOpointLocale,
  (list, localeString): LocaleSuggestionLocale => {
    const suggestServerSorting = list.SUGGESTSERVER_SORTING

    const { localeName } = locales[localeString]

    return `${localeName}_${suggestServerSorting}` as LocaleSuggestionLocale
  },
)

export const getArticleListingStyle = createSelector(getSettingsList, (list) => +(list.NEW_PORTAL_LAYOUT ?? 0))

export const getPortalLogo = createSelector(getSettingsList, (list) => list.NEW_PORTAL_LOGO)

export const getRelativeOffset = createSelector(getSettingsList, (list) => list.NEW_PORTAL_RELATIVE_DATE_LIMIT ?? 0)

export const getDateVariant = createSelector(getSettingsList, (list) =>
  (Number(list.NEW_PORTAL_RELATIVE_DATE_LIMIT) || 0) > 0 ? 'relative' : 'absolute',
)

export const getUserPermissions = (module: Modules) => createSelector(getSettingsList, (list) => list[module])

export const getUserLicensePermissions = (licenseModule: LicenseModules) =>
  createSelector(getSettingsList, (list) => list.OCG_RL_license?.find(({ name }) => name === licenseModule)?.value)

export const getAutoTranslationQuota = createSelector(getSettingsList, (list) => list.GT_QUOTA?.quota ?? 0)

export const getAutoTranslationRemainingQuota = createSelector(getSettingsList, (list) => {
  if (list.GT_QUOTA === undefined) {
    return 0
  }

  return Math.max(list.GT_QUOTA.quota - list.GT_QUOTA.google_translated, 0)
})

export const getTranslateType = createSelector(getSettingsList, (list) => list.TRANSLATE_TYPE ?? 0)

export const isAutoTranslationsOn = createSelector(
  getTranslateType,
  getAutoTranslationRemainingQuota,
  (translateType, remainingQuota) => Boolean(isBitSet(translateType, TRANSLATE_ARTICLE) && remainingQuota),
)

export const getTranslateTo = createSelector(getSettingsList, (list) => list.TRANSLATE_TO || 'en')

export const getAutoTranslationLanguageName = createSelector(getSettingsList, (list) => {
  if (!list.TRANSLATE_TO) {
    return 'English'
  }

  // returns 'English' if the language is not in the list of available languages
  const language = autoTranslateLanguages.find((langObj) => Object.keys(langObj)[0] === list.TRANSLATE_TO)
  return language ? language[list.TRANSLATE_TO] : 'English'
})

export const isShareHtmlOnly = createSelector(getSettingsList, (list) => list.SHARE_HTML_ONLY)

export const getTranslateAuto = createSelector(getSettingsList, (list) => list.TRANSLATE_AUTO)

export const getTranslateCfg = createSelector(getSettingsList, (list) => list.TRANSLATE_CFG ?? '-')

export const getMaxGtArticleLength = createSelector(getSettingsList, (list) => list.MAX_GT_ARTICLE_LENGTH ?? 2000)

export const getAutoTranslateSearchParams = createSelector(
  getAutoTranslationRemainingQuota,
  getTranslateAuto,
  getReportState,
  getTranslateType,
  getTranslateTo,
  getTranslateCfg,
  getMaxGtArticleLength,
  (remainingQuota, translateAuto, reportState, translate_type, translate_to, translate_cfg, max_gt_article_length) => {
    const autoTranslateSearchParams: Translate = {
      translate_type,
      translate_to,
      translate_cfg,
      max_gt_article_length,
    }

    // Adjust translate type based on other settings
    if (!remainingQuota) {
      // do not translate articles if no quota available
      autoTranslateSearchParams.translate_type = clearBit(translate_type, TRANSLATE_ARTICLE)
    }
    if (!translateAuto) {
      // disable search translations if not enabled in action line
      autoTranslateSearchParams.translate_type = 0
    }
    if (reportState.autoTranslate) {
      autoTranslateSearchParams.translate_type = 3
    }

    return autoTranslateSearchParams
  },
)

//TODO: is this a duplicate of getAutoTranslateLanguageName?
export const getTranslateToLanguageInFullname = createSelector(
  getTranslateTo,
  (translateTo) => getFullnameLanguage([translateTo])[0],
)

export const getUserInviteTargets = createSelector(getSettingsList, (list) => list.USER_INVITE_TARGETS || [])

export const getEditOrgAlerts = createSelector(getSettingsList, (list) => list.EDIT_ORG_ALERTS)

export const getNewPortalDashboardName = createSelector(getSettingsList, (list) => list.NEW_PORTAL_DASHBOARD_NAME)

export const getNewPortalDashboardURL = createSelector(getSettingsList, (list) => list.NEW_PORTAL_DASHBOARD_URL)

export const getLastReportTimestamp = createSelector(getSettingsList, (list) => list.LAST_ART_TIMESTAMP)

export const getColorbarColors = createSelector(getSettingsList, (list) => list.COLORBAR_COLORS)

export const getActiveLanguage = createSelector(getSettingsList, (list) => list.LANGUAGE || 'en-GB')

export const getAllowedLayouts = createSelector(getSettingsChoices, (choices) => choices.NEW_PORTAL_LAYOUT || [])

export const getIsArchiveView = createSelector(
  getSettingsList,
  (list) =>
    list.NEW_PORTAL_LAYOUT === ListingStyle.ARCHIVE_LEFT || list.NEW_PORTAL_LAYOUT === ListingStyle.ARCHIVE_RIGHT,
)

export const isUserArchiveOnly = createSelector(getAllowedLayouts, (layouts) => {
  // Archive-only user gets 2 layout options from backend => Archive-right and Archive-left
  return (
    layouts.length === 2 &&
    layouts.some(({ value }) => value === ListingStyle.ARCHIVE_LEFT) &&
    layouts.some(({ value }) => value === ListingStyle.ARCHIVE_RIGHT)
  )
})

// If undefined, there is no limit
export const getMaxProfiles = createSelector(getSettingsList, (list) => list.NEW_PORTAL_MAX_PROFILES)

export const getFilterDrilldown = createSelector(getSettingsList, (list) => !!list.FILTER_DRILLDOWN)

// If undefined, there is no limit
export const getMaxAlertRecipients = createSelector(getSettingsList, (list) => list.NEW_PORTAL_MAX_ALERTS_RECIPIENTS)

export const canShowEntitiesHightlight = createSelector(getSettingsChoices, (choices) => {
  const entitiesValues = choices.ENTITIES_SELECTED?.map(({ value }) => value)

  // Only users with both choices for ENTITIES_SELECTED can see entity highlighting in settings
  return entitiesValues?.length === 2 && entitiesValues.includes(1) && entitiesValues.includes(0)
})

export const getAnyEntitiesSelected = createSelector(getSettingsList, (list) => !!list.ENTITIES_SELECTED)

export const getArticleEditAccess = createSelector(getSettingsList, (list) => list.ARTICLE_EDIT_ACCESS)

export const hasOnlyPdfReportAccess = createSelector(getSettingsList, (list) => {
  const templateAccess = list.TEMPLATE_ACCESS
  const accessibleTemplates = Object.values(templateAccess || {}).filter((hasAccess) => hasAccess)

  return accessibleTemplates.length === 1 && templateAccess?.pdf
})

export const getStatisticsExportAccess = createSelector(
  getSettingsList,
  (list) => list.NEW_PORTAL_STATISTICS_EXPORT_FORMATS,
)

export const hasDisplayPrintPdfLink = createSelector(
  getSettingsList,
  (list) => list.DISPLAY_PRINT_PDF_LINK === MODULES.DISPLAY_PRINT_PDF_LINK.ON,
)

export const hasLimitedSearch = createSelector(
  getSettingsList,
  (list) => list.LIMITED_SEARCH === MODULES.LIMITED_SEARCH.ON,
)

export const hasLimitedCreateProfiles = createSelector(
  getSettingsList,
  (list) => list.LIMITED_CREATE_PROFILES === MODULES.LIMITED_CREATE_PROFILES.ON,
)

export const hasRestrictedReportHistory = createSelector(
  getSettingsList,
  (list) => list.RESTRICTED_REPORT_HISTORY === MODULES.RESTRICTED_REPORT_HISTORY.ON,
)

export const getNewPortalSupport = createSelector(getSettingsList, (list) => list.NEW_PORTAL_SUPPORT)

export const getDefaultTemplateId = createSelector(getSettingsList, (list) => list.NEW_PORTAL_DEFAULT_TEMPLATE)

export const getSentimentOptions = createSelector(getSettingsList, (list) => {
  if (!list.NEW_PORTAL_SHOW_SENTIMENT) {
    return {
      company: 0,
      modifyGroups: 0,
      override: 0,
      mostPopular: 0,
      general: 0,
    }
  }
  return {
    company: list.NEW_PORTAL_SHOW_SENTIMENT & 1,
    modifyGroups: list.NEW_PORTAL_SHOW_SENTIMENT & 2,
    override: list.NEW_PORTAL_SHOW_SENTIMENT & 4,
    mostPopular: list.NEW_PORTAL_SHOW_SENTIMENT & 8,
    general: list.NEW_PORTAL_SHOW_SENTIMENT & 16,
  }
})

export const getCommentGroups = createSelector(getSettingsList, (list) => {
  const commentGroups = list.NEW_PORTAL_COMMENT_GROUPS ?? []

  return [...commentGroups].sort((a, b) => a.id - b.id)
})

// The email domain whitelist. If undefined, then there are no restrictions
export const getAllowedDomains = createSelector(getSettingsList, (list) => list.ALLOWED_DOMAINS)

export const getFilterDrilldownChoices = createSelector(getSettingsChoices, (choices) => choices.FILTER_DRILLDOWN)

export const getNewPortalAutoShowSuggestion = createSelector(
  getSettingsList,
  (list) => !!list.NEW_PORTAL_AUTOSHOW_SUGGESTION,
)

export const getShowGeneralSentiment = createSelector(
  getUISetting('SHOW_GENERAL_SENTIMENT'),
  // we show general sentiment by default, even if undefined
  (showGeneralSentiment) => showGeneralSentiment ?? true,
)

// return as array of objects eg. [{ key: 'timeperiod', value: 'true' }, { key: 'geo', value: 'true' }...]
export const getFiltersOrder = createSelector(getUISetting('NEW_PORTAL_FILTERS'), (newPortalFilters) => {
  return keyValueParse(newPortalFilters)
    .filter(({ value }) => value === 'true')
    .map(({ key }) => key) as FilterType[]
})

// return as array of objects eg. [{ key: 'readers_so_far', value: 'true' }, { key: 'final_readers', value: 'true' }...]
export const getArticleMetadata = createSelector(getUISetting('NEW_PORTAL_SHOW_METADATA'), (metadataString) =>
  keyValueParse(metadataString)
    .filter(({ value }) => value === 'true')
    .map(({ key }) => key as ArticleMetadataType),
)

export const canAccessLegacyProfileEditor = createSelector(
  getSettingsList,
  (list) =>
    list.PROFILE_EDITOR_ACCESS === ProfileEditorAccessValues.OnlyLegacy ||
    list.PROFILE_EDITOR_ACCESS === ProfileEditorAccessValues.Both,
)

export const canAccessNewProfileEditor = createSelector(
  getSettingsList,
  (list) =>
    list.PROFILE_EDITOR_ACCESS === ProfileEditorAccessValues.OnlyNew ||
    list.PROFILE_EDITOR_ACCESS === ProfileEditorAccessValues.Both,
)

export const canAccessBothProfileEditors = createSelector(
  getSettingsList,
  (list) => list.PROFILE_EDITOR_ACCESS === ProfileEditorAccessValues.Both,
)

export const getAllowedQueryLanguage = createSelector(getSettingsList, (list) => list.ALLOWED_QUERY_LANGUAGE)

export const getDefaultQueryLanguage = createSelector(getSettingsList, (list) => {
  if (
    list.ALLOWED_QUERY_LANGUAGE === AllowedQueryLanguageValues.OnlyOQL ||
    list.ALLOWED_QUERY_LANGUAGE === AllowedQueryLanguageValues.DefaultOQL
  ) {
    return QueryLanguage.OQL
  }

  return QueryLanguage.IQL
})

export const getCanAccessBothQueryLanguages = createSelector(
  getSettingsList,
  (list) =>
    list.ALLOWED_QUERY_LANGUAGE === AllowedQueryLanguageValues.DefaultOQL ||
    list.ALLOWED_QUERY_LANGUAGE === AllowedQueryLanguageValues.DefaultIQL,
)

export const isAllTagsHidden = createSelector(getUISetting('HIDE_ALL_TAGS'), (hideAllTags) => hideAllTags ?? false)
