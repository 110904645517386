import { useParams } from 'react-router-dom'

export const useRequiredParams = <P extends string>(requiredParamNames: P[]): Record<P, string> => {
  const routeParams = useParams()

  for (const paramName of requiredParamNames) {
    if (!routeParams[paramName]) {
      throw new Error(`This component should not be rendered on a route which does not have the ${paramName} parameter`)
    }
  }

  return routeParams as Record<P, string>
}
