import React from 'react'
import { useRequiredParams } from '../hooks/useRequiredParams'
import { useAppSelector } from '../hooks/useAppSelector'
import { feedsFailed, getFeedById, isLoading } from '../../selectors/feedsSelector'
import { ErrorBreadcrumb } from '../breadcrumbs/ErrorBreadcrumb'

const FeedDetailBreadcrumb = (): React.JSX.Element => {
  const params = useRequiredParams(['feedId'])

  const feed = useAppSelector(getFeedById(params.feedId))
  const error = useAppSelector(feedsFailed)
  const loading = useAppSelector(isLoading)

  if (loading) {
    return <div className="h-4 w-24 animate-pulse rounded-sm bg-grey.6" />
  }

  if (error || !feed) {
    return <ErrorBreadcrumb />
  }

  return <>{feed.name}</>
}

export default FeedDetailBreadcrumb
