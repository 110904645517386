import { produce } from 'immer'
import { AppActions } from '../actions'
import type { Template, TemplateDetail, TemplateModule, TemplatePreview, Widget } from '../opoint/flow'

export type TemplatesState = {
  list: Array<Template>
  previewModalIsOpen: boolean
  sortableModules: { [key: string]: Array<Widget> }
  activeSortableModule: string
  lastFetchedTemplate: TemplatePreview
  templateDetails: {
    [key: string]: TemplateDetail
  }
  activeTemplate: Pick<Template, 'id' | 'type' | 'readonly'>
}

export const initialState: TemplatesState = {
  list: [],
  previewModalIsOpen: false,
  // @ts-expect-error: Muted so we could enable TS strict mode
  lastFetchedTemplate: null,
  templateDetails: {},
  activeSortableModule: '',
  sortableModules: {},
  // @ts-expect-error: Muted so we could enable TS strict mode
  activeTemplate: null,
}

const templatesReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'TEMPLATES_FETCH_SUCCESS':
      draftState.list = action.payload
      break

    case 'TEMPLATE_PREVIEW_FETCH_SUCCESS':
      draftState.lastFetchedTemplate = action.payload
      break

    case 'TEMPLATE_PREVIEW_MODAL_CLOSE':
      draftState.previewModalIsOpen = false
      break

    case 'TEMPLATE_PREVIEW_MODAL_OPEN':
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.lastFetchedTemplate = null
      draftState.previewModalIsOpen = true
      break

    case 'FETCH_TEMPLATE_DETAIL_SUCCESS': {
      const { id, maxLevel, modules } = action.payload

      const sortableModules: { [moduleName: string]: Widget[] } = {}

      for (const [moduleName, module] of Object.entries<TemplateModule>(modules)) {
        if (module.sortable) {
          let moduleSettings = module.widgets
          if (moduleSettings.every((setting) => setting.sortIndex === 0)) {
            moduleSettings = moduleSettings.map((setting, index) => ({ ...setting, sortIndex: index }))
          }
          sortableModules[moduleName] = moduleSettings
        }
      }

      draftState.templateDetails[id] = { modules, maxLevel }
      draftState.sortableModules = sortableModules
      break
    }

    case 'UPDATE_ACTIVE_TEMPLATE': {
      const { activeTemplate } = action.payload

      draftState.activeTemplate = activeTemplate
      break
    }

    case 'TEMPLATE_EDITOR_MODULE_SORT_MODAL_OPEN': {
      const { module } = action.payload

      draftState.activeSortableModule = module
      break
    }

    case 'TEMPLATE_EDITOR_MODULE_SORT_MODAL_CLOSE':
      draftState.activeSortableModule = ''
      break

    case 'TEMPLATE_EDITOR_FORM_MODAL_CLOSE':
      draftState.sortableModules = {}
      break

    case 'REPORTS_MODAL_CLOSE':
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.activeTemplate = null
      break

    case 'TEMPLATE_EDITOR_MODULE_SORT_MOVE_SETTING': {
      const { moduleName, widgets } = action.payload

      draftState.sortableModules[moduleName] = widgets.map((setting, index) => ({ ...setting, sortIndex: index }))
      break
    }

    default:
      break
  }
}, initialState)

export default templatesReducer
