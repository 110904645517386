import { cn } from '@opoint/infomedia-storybook'
import { IconName } from '../../types/theme'

export interface Props {
  id?: string
  isDecorative?: boolean
  name: IconName
  title?: string
  verticalCenter?: boolean
  className?: string
}

/**
 * @deprecated Use Fluent Icons directly.
 * https://infomediacorp.atlassian.net/browse/FE-11785
 */
const Icon = ({ id, isDecorative, name, title, verticalCenter = false, className }: Props) => (
  <span
    aria-hidden={isDecorative}
    className={cn({ 'flex items-center fill-sky-1': verticalCenter }, className)}
    id={id}
    title={title}
  >
    <svg className="size-5" pointerEvents="none">
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  </span>
)

export default Icon
