import { ReactNode } from 'react'
import { MODULES, SHORT_MODULES } from '../../components/constants/permissions.ts'
import useIsUserPermitted from '../../components/hooks/useIsUserPermitted.ts'
import { AccessDeniedErrorPage } from '../errors/AccessDeniedErrorPage.tsx'

export const ModulePermissionCheck = ({
  module,
  children,
}: {
  module: keyof typeof SHORT_MODULES
  children: ReactNode
}) => {
  const isPermitted = useIsUserPermitted({
    module,
    permissions: [MODULES[module].ON, MODULES[module].READ_ONLY],
  })

  if (!isPermitted) {
    return <AccessDeniedErrorPage />
  }

  return <>{children}</>
}
