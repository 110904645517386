import { ArticleTag, getArticleId } from '@opoint/infomedia-storybook'
import produce from 'immer'

import { AppActions } from '../actions'
import { ArticleMatches } from '../components/types/article'
import { reduceDuplicateSnippetOccurrence } from '../helpers/common'
import { eqArticles, identicalReducer, preprocessArticles } from '../opoint/articles'
import { OpointTimestampToTimestamp } from '../opoint/common/time'
import type { SetActiveArticle, Source, XhrStatus } from '../opoint/flow'
import { TAG_TYPES } from '../opoint/tags'
import { M360Article } from '../opoint/articles/types'
import { DocumentIdenticalDocumentsDocumentItem } from '../api/opoint-search-suggest.schemas'

const getAffectedArticles = (
  mapFunction: (data: M360Article) => M360Article,
  articles: M360Article[],
  articlesList: M360Article[],
) => {
  const articleIds = articles.map((a) => getArticleId(a))
  const filteredArticles = articlesList.filter((f) => articleIds.includes(getArticleId(f)))
  const mappedArticles = filteredArticles.map(mapFunction)

  const indexedArticles = mappedArticles.reduce((acc, article) => {
    if (!article.id) {
      return acc
    }

    acc[article.id] = article
    return acc
  }, {})

  return indexedArticles
}

export type ArticlesStateType = {
  list: Array<M360Article>
  identical: Record<string, number>
  checked: string[]
  active: SetActiveArticle
  addArticleForm: {
    isSaving?: boolean
  }
  editedArticle: M360Article | null
  editedArticleXHRStatus: XhrStatus
  shareArticleXHRStatus: XhrStatus
  shareArticleMessage: string
  shareArticleAttachmentFlag: boolean
  editedArticleState: Array<string>
  updatedArticle: M360Article | null
  deleteModalOpen: boolean
  checkAllFiltered: boolean
}

export const initialState: ArticlesStateType = {
  list: [],
  identical: {},
  checked: [],
  active: {
    index: 0,
    source: 'other',
  },
  addArticleForm: {},
  editedArticle: null,
  editedArticleXHRStatus: 'NOT_INITIATED',
  shareArticleXHRStatus: 'NOT_INITIATED',
  shareArticleMessage: '',
  shareArticleAttachmentFlag: true,
  editedArticleState: [],
  updatedArticle: null,
  deleteModalOpen: false,
  checkAllFiltered: false,
}

const articlesReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'LOGOUT':
      return initialState

    case 'FETCH_ARTICLES':
      draftState.list = []
      draftState.checked = []
      break

    //TODO: Do refactor similar logic to another epics
    case 'FETCH_ARTICLES_WITH_WATCH_ID_SUCCESS': {
      const interceptedDocuments = preprocessArticles(action.payload.searchresult.document) as M360Article[]

      interceptedDocuments?.forEach((article: M360Article) => {
        article.matches = reduceDuplicateSnippetOccurrence(article.matches || []) as unknown as ArticleMatches
      })

      const concatenated = [...interceptedDocuments, ...draftState.list]

      draftState.identical = action.payload.searchresult.document.reduce(identicalReducer, {})
      draftState.list = Array.from(concatenated.reduce((map, obj) => map.set(obj.id, obj), new Map()).values())

      break
    }

    case 'FETCH_MORE_ARTICLES_SUCCESS': {
      const interceptedDocuments = preprocessArticles(action.payload.response.searchresult.document) as M360Article[]

      interceptedDocuments?.forEach((article: M360Article) => {
        article.matches = reduceDuplicateSnippetOccurrence(article.matches || []) as unknown as ArticleMatches
      })

      draftState.identical = action.payload.response.searchresult.document.reduce(identicalReducer, {})
      draftState.list = [...draftState.list, ...interceptedDocuments]

      break
    }

    case 'FETCH_ARTICLES_SUCCESS': {
      // TODO - this is the same as here PROFILE_EDITOR_PREVIEW_SUCCESS
      // we should factor this out.

      const interceptedDocuments = preprocessArticles(action.payload.response.searchresult.document) as M360Article[]

      // TODO: Investigate why are articles processed differently in different parts of the application
      // https://infomediacorp.atlassian.net/browse/FE-10066

      interceptedDocuments?.forEach((article: M360Article) => {
        article.matches = reduceDuplicateSnippetOccurrence(article.matches || []) as unknown as ArticleMatches
      })

      draftState.identical = action.payload.response.searchresult.document.reduce(identicalReducer, {})
      draftState.list = interceptedDocuments

      break
    }

    case 'ALERT_FETCH_CONTENT_SUCCESS': {
      const { data } = action.payload

      // Sort identical articles by date in descending order
      // Only if data has JSON content
      if (Array.isArray(data.content)) {
        let documents: M360Article[] = []
        data.content.forEach((profile) => {
          if (profile?.content?.searchresult?.document) {
            documents = [...documents, ...profile.content.searchresult.document]
          }
        })

        documents = documents.filter(({ identical_documents }) => identical_documents)
        draftState.identical = documents.reduce(identicalReducer, {})
      }

      break
    }

    case 'FETCH_SINGLE_ARTICLE_FOR_TRANSLATION_SUCCESS': {
      const { response, translate, isIdentical, originalArticle } = action.payload
      const preprocessedArticle = preprocessArticles(response.searchresult.document)[0] as M360Article

      if (isIdentical) {
        const {
          // @ts-expect-error: Muted so we could enable TS strict mode
          identical_documents: { document },
          identical_documents,
        } = originalArticle

        const newIdenticalDocument = document?.map((item: M360Article) => {
          if (getArticleId(item) === getArticleId(preprocessedArticle)) {
            return { ...preprocessedArticle, translated: translate } as M360Article
          }

          return item
        })

        draftState.list = draftState.list?.map((item) => {
          if (getArticleId(item) === getArticleId(originalArticle)) {
            return {
              ...originalArticle,
              identical_documents: { ...identical_documents, document: newIdenticalDocument },
            }
          }

          return item
        }) as M360Article[]
      } else {
        draftState.list = draftState.list?.map((item) => {
          if (getArticleId(item) === getArticleId(preprocessedArticle)) {
            return { ...preprocessedArticle, translated: translate }
          }

          return item
        })
      }

      break
    }

    case 'FETCH_SINGLE_ARTICLE_FOR_ARTICLE_VIEW_SUCCESS': {
      const { response } = action.payload
      const preprocessedArticle = preprocessArticles(response.searchresult.document)[0] as M360Article

      draftState.list = preprocessedArticle ? [preprocessedArticle] : []
      break
    }

    case 'CLEAR_ARTICLES': {
      draftState.list = []
      draftState.identical = {}
      draftState.checked = []
      draftState.checkAllFiltered = false
      draftState.active = {
        index: 0,
        source: 'other',
      }

      break
    }

    case 'CHECK_ALL_FILTERED_ARTICLES': {
      const filteredArticles = action.payload

      const checkedArticles = filteredArticles.map((article) => getArticleId(article))

      draftState.checked = checkedArticles
      draftState.checkAllFiltered = true

      break
    }

    case 'CHECK_ARTICLE_TOGGLE': {
      const id = getArticleId(action.payload)
      const isArticleChecked = draftState.checked.some((articleId) => articleId === id)

      if (isArticleChecked) {
        draftState.checked = draftState.checked.filter((articleId) => articleId !== id)
      } else {
        draftState.checked = [...draftState.checked, getArticleId(action.payload)]
      }

      break
    }

    case 'UNCHECK_ALL_ARTICLES': {
      draftState.checked = []
      draftState.checkAllFiltered = false

      break
    }

    case 'NEXT_IDENTICAL': {
      const identicalArticles = action.payload?.article?.identical_documents?.document

      if (identicalArticles) {
        const currentItem = getArticleId(action.payload.article)
        const next = draftState.identical[currentItem] + 1
        draftState.identical = { ...draftState.identical, [currentItem]: next % identicalArticles.length }
      }

      break
    }

    case 'PREVIOUS_IDENTICAL': {
      const identicalArticles = action.payload?.article?.identical_documents?.document

      if (identicalArticles) {
        const currentItem = getArticleId(action.payload.article)

        draftState.identical = {
          ...draftState.identical,
          [getArticleId(action.payload.article)]:
            (draftState.identical[currentItem] + identicalArticles.length - 1) % identicalArticles.length,
        }
      }

      break
    }

    case 'SET_ACTIVE_IDENTICAL': {
      const { article, index } = action.payload

      draftState.identical = {
        ...draftState.identical,
        [getArticleId(article)]: index,
      }

      break
    }

    case 'NEXT_ACTIVE_ARTICLE': {
      draftState.active = {
        index: Math.min(Math.max(draftState.active.index + 1, 0), draftState.list.length),
        source: 'keyPress' as Source,
      }

      break
    }

    case 'PREVIOUS_ACTIVE_ARTICLE': {
      draftState.active = {
        index: Math.min(Math.max(draftState.active.index - 1, 0), draftState.list.length),
        source: 'keyPress' as Source,
      }

      break
    }

    case 'SET_ACTIVE_ARTICLE': {
      const { index, source } = action.payload

      draftState.active = {
        index: Math.min(Math.max(0, index), draftState.list.length),
        source,
      }

      break
    }

    case 'CATEGORIZATION_TAG_ARTICLE':
    case 'TAG_ARTICLES': {
      const {
        articles,
        tag,
        weight = tag?.type === TAG_TYPES.MENTOMETER ? 0 : 1,
        toTagOnlyMainArticle,
      } = action.payload || {}
      const articleArray = (Array.isArray(articles) ? articles : [articles]).filter(Boolean)

      const editedArticleId = draftState.editedArticle?.id
      const isItEditableArticle = articleArray.find(({ id }) => id === editedArticleId)

      const synthTag = {
        id: tag.id,
        is_owner: 1,
        weight,
        set: OpointTimestampToTimestamp(),
      }

      const setJustForMainArticle = (article: M360Article) => ({
        ...article,
        tags: {
          ...article.tags,
          [tag.id]: synthTag as ArticleTag,
        },
      })

      const setTag = (data: DocumentIdenticalDocumentsDocumentItem | M360Article) => {
        const updatedData = { ...data } as M360Article

        updatedData.tags = {
          ...updatedData.tags,
          [tag.id]: synthTag as ArticleTag,
        }

        if (updatedData.identical_documents && updatedData.identical_documents.cnt !== 0) {
          const { cnt, document: identicalArticles } = updatedData.identical_documents
          const updatedIdenticals = identicalArticles?.map(setTag)
          updatedData.identical_documents = { cnt, document: updatedIdenticals }
        }

        return updatedData
      }

      const updateList = (articlesList) => {
        const affectedArticles = getAffectedArticles(
          toTagOnlyMainArticle ? setJustForMainArticle : setTag,
          articleArray,
          articlesList,
        )

        return articlesList.reduce((a, v) => a.concat(affectedArticles[v.id] ? affectedArticles[v.id] : v), [])
      }

      draftState.list = updateList(draftState.list)
      draftState.editedArticle =
        isItEditableArticle && draftState.editedArticle ? setTag(draftState.editedArticle) : draftState.editedArticle

      break
    }

    case 'CATEGORIZATION_UNTAG_ARTICLE':
    case 'UNTAG_ARTICLES': {
      let { articles } = action.payload

      if (!articles) {
        break
      }

      if (Array.isArray(articles)) {
        articles = articles.filter(Boolean)
      }

      if (!Array.isArray(articles)) {
        articles = [articles]
      }

      const editedArticleId = draftState.editedArticle?.id
      const isItEditableArticle = articles.find(({ id }) => id === editedArticleId)

      const unsetTag = (data: M360Article) => {
        const { tags, identical_documents } = data

        const newTags = { ...tags } as Record<string | number, ArticleTag>
        delete newTags[action.payload.tagId]

        const updatedIdenticalDocuments = identical_documents
          ? {
              cnt: identical_documents?.cnt,
              document:
                identical_documents?.cnt === 0
                  ? identical_documents.document
                  : identical_documents.document.map((article) => unsetTag(article)),
            }
          : null

        return {
          ...data,
          tags: newTags,
          ...(updatedIdenticalDocuments && { identical_documents: updatedIdenticalDocuments }),
        }
      }

      const updateList = (articlesList: M360Article[]) => {
        const affectedArticles = getAffectedArticles(unsetTag, articles as M360Article[], articlesList)

        // replace the newly tagged articles
        return articlesList.reduce((a, v) => a.concat(v.id && affectedArticles[v.id] ? affectedArticles[v.id] : v), [])
      }

      draftState.list = updateList(draftState.list)
      draftState.editedArticle =
        isItEditableArticle && draftState.editedArticle ? unsetTag(draftState.editedArticle) : draftState.editedArticle

      break
    }

    case 'TAG_SINGLE_ARTICLE': {
      const { article, tag, originalArticle, weight = tag.type === TAG_TYPES.MENTOMETER ? 0 : 1 } = action.payload || {}

      const synthTag = {
        id: tag.id,
        is_owner: 1,
        weight,
        set: OpointTimestampToTimestamp(),
      }

      const updateList = (articlesList) => {
        const affectedArticles = {
          // @ts-expect-error: Muted so we could enable TS strict mode
          [originalArticle.id]: (() => {
            const identicalDocuments = originalArticle.identical_documents
            // @ts-expect-error: Muted so we could enable TS strict mode
            if (identicalDocuments.cnt === 0) {
              return originalArticle
            }

            // @ts-expect-error: Muted so we could enable TS strict mode
            const updatedIdenticals = identicalDocuments.document.map((identArticle) => {
              if (identArticle.id_article === article.id_article) {
                const updatedTags = {
                  ...identArticle.tags,
                  [tag.id]: synthTag,
                }

                return { ...identArticle, tags: updatedTags }
              }

              return identArticle
            })

            return {
              ...originalArticle,
              identical_documents: {
                ...identicalDocuments,
                document: updatedIdenticals,
              },
            }
          })(),
        }

        // Replace the newly tagged articles
        return articlesList.reduce((acc, currentArticle) => {
          const updatedArticle = affectedArticles[currentArticle.id]
          if (updatedArticle) {
            return [...acc, updatedArticle]
          }

          return [...acc, currentArticle]
        }, [])
      }

      draftState.list = updateList(draftState.list)
      break
    }

    case 'UNTAG_SINGLE_ARTICLE': {
      const { article, tag, originalArticle } = action.payload

      const updateList = (articlesList) => {
        const affectedArticles = articlesList.map((listArticle) => {
          if (listArticle.id === originalArticle.id) {
            if (originalArticle.identical_documents?.cnt === 0) {
              return originalArticle
            }

            // @ts-expect-error: Muted so we could enable TS strict mode
            const updatedIdenticals = originalArticle.identical_documents.document.map((identArticle) => {
              if (identArticle.id_article === article.id_article) {
                const updatedTags = { ...identArticle.tags }
                delete updatedTags[tag.id]

                return { ...identArticle, tags: updatedTags }
              }

              return identArticle
            })

            return {
              ...originalArticle,
              identical_documents: {
                ...originalArticle.identical_documents,
                document: updatedIdenticals,
              },
            }
          }

          return listArticle
        })

        return affectedArticles
      }

      draftState.list = updateList(draftState.list)

      break
    }

    case 'DELETE_ARTICLES': {
      let { articles } = action.payload || {}

      if (!Array.isArray(articles)) {
        articles = [articles]
      }

      const synthTag = {
        id: action.payload.tag?.id,
        is_owner: 1,
        set: OpointTimestampToTimestamp(),
      }

      const updateList = (articlesList: M360Article[]) => {
        const af = getAffectedArticles(
          (article) => ({
            ...article,
            tags: {
              ...article.tags,
              [action.payload.tag.id]: synthTag as ArticleTag,
            },
          }),
          articles as M360Article[],
          articlesList,
        )

        return articlesList.reduce((a, v) => a.concat(v.id && af[v.id] ? af[v.id] : v), [])
      }

      draftState.list = updateList(draftState.list)

      break
    }

    case 'UNDELETE_ARTICLES': {
      const { tag } = action.payload
      let { articles } = action.payload

      if (!Array.isArray(articles)) {
        articles = [articles]
      }

      const updateList = (articlesList) => {
        const af = getAffectedArticles(
          (article) => {
            const newArticle = { ...article }
            delete newArticle.tags[tag.id]

            return newArticle
          },
          articles as M360Article[],
          articlesList,
        )

        // replace the newly tagged articles
        return articlesList.reduce((a, v) => [...a, af[v.id] ? af[v.id] : v], [])
      }

      draftState.list = updateList(draftState.list)

      break
    }

    case 'ADD_ARTICLE_MODAL_CLOSE':
      draftState.addArticleForm = {}
      break

    case 'ADD_ARTICLE_FILE_UPLOAD_SUCCESS': {
      draftState.addArticleForm.isSaving = false
      break
    }

    case 'ADD_ARTICLE':
      draftState.addArticleForm.isSaving = true
      break

    case 'ADD_ARTICLE_FAILURE':
      draftState.addArticleForm.isSaving = false
      break

    case 'ADD_ARTICLE_SUCCESS':
      draftState.addArticleForm.isSaving = false
      break

    case 'EDIT_ARTICLE_MODAL_OPEN': {
      const { article } = action.payload

      draftState.editedArticle = article
      break
    }

    case 'UPDATED_ARTICLE': {
      draftState.updatedArticle = action.payload
      break
    }

    case 'EDIT_ARTICLE': {
      draftState.editedArticleXHRStatus = 'IN_PROGRESS' as XhrStatus
      break
    }

    case 'EDIT_ARTICLE_FAILURE': {
      draftState.editedArticleXHRStatus = 'NOT_INITIATED' as XhrStatus
      break
    }

    case 'EDIT_ARTICLE_SUCCESS': {
      const { article } = action.payload

      draftState.editedArticleXHRStatus = 'NOT_INITIATED' as XhrStatus
      draftState.list = draftState.list.map((item) => {
        if (eqArticles(item, article)) {
          return article
        }
        return item
      })
      draftState.editedArticle = article

      break
    }
    case 'EDIT_ECB_ARTICLE_SUCCESS': {
      const { article } = action.payload

      draftState.list = draftState.list.map((item) => {
        if (eqArticles(item, article)) {
          return article
        }
        return item
      })
      break
    }

    case 'SHARE_ARTICLE_MODAL_CLOSE': {
      draftState.shareArticleXHRStatus = 'NOT_INITIATED' as XhrStatus
      draftState.shareArticleMessage = ''
      draftState.shareArticleAttachmentFlag = false

      break
    }

    case 'SHARE_ARTICLES': {
      draftState.shareArticleXHRStatus = 'IN_PROGRESS' as XhrStatus
      break
    }

    case 'SHARE_ARTICLES_SUCCESS': {
      draftState.shareArticleXHRStatus = 'SUCCESS' as XhrStatus
      break
    }

    case 'SHARE_ARTICLES_FAILURE': {
      draftState.shareArticleXHRStatus = 'FAILURE' as XhrStatus
      break
    }

    case 'SHARE_ARTICLES_TOGGLE_ATTACHMENT_TOGGLE': {
      draftState.shareArticleAttachmentFlag = !draftState.shareArticleAttachmentFlag
      break
    }

    case 'SHARE_ARTICLES_CHANGE_MESSAGE': {
      const { message } = action.payload

      draftState.shareArticleMessage = message
      break
    }

    case 'EDIT_ARTICLES_STATE': {
      draftState.editedArticleState = [action.payload]

      break
    }

    case 'SET_DELETE_ARTICLE_MODAL': {
      draftState.deleteModalOpen = action.payload
      break
    }

    case 'SEARCH_CANCELED': {
      draftState.list = []

      break
    }

    default:
      return draftState
  }
}, initialState)

export default articlesReducer
