import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from 'react'
import ErrorBoundaryContent, { ErrorBoundaryContentProps } from './ErrorBoundaryContent'

type ErrorBoundaryContextType = {
  resetAttempted: boolean
  setResetAttempted: Dispatch<SetStateAction<boolean>>
}

const ErrorBoundaryContext = createContext<ErrorBoundaryContextType>({
  resetAttempted: false,
  setResetAttempted: () => {},
})

// Custom hook to access the error state context
export const useErrorBoundaryState = () => useContext(ErrorBoundaryContext)

type ErrorBoundaryProps = PropsWithChildren<ErrorBoundaryContentProps>

// Error State Provider component
const ErrorBoundary = ({ children, ...props }: ErrorBoundaryProps) => {
  const [resetAttempted, setResetAttempted] = useState(false)

  return (
    <ErrorBoundaryContext.Provider value={{ resetAttempted, setResetAttempted }}>
      <ErrorBoundaryContent {...props}>{children}</ErrorBoundaryContent>
    </ErrorBoundaryContext.Provider>
  )
}

export default ErrorBoundary
