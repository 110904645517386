import { toast } from '@opoint/infomedia-storybook'
import { of } from 'rxjs'

import { AppActions } from '../../../actions'
import alertErrorEpic from './alertErrorEpic'
import articlesErrorEpic from './articlesErrorEpic'
import commentErrorEpic from './commentErrorEpic'
import contactErrorEpic from './contactErrorEpic'
import feedErrorEpic from './feedErrorEpic'
import foldersErrorEpic from './foldersErrorEpic'
import portalErrorEpic from './portalErrorEpic'
import profileEditorErrorEpic from './profileEditorErrorEpic'
import profilesErrorEpic from './profilesErrorEpic'
import reportErrorEpic from './reportErrorEpic'
import routerErrorEpic from './routerErrorEpic'
import searchErrorEpic from './searchErrorEpic'
import statisticsErrorEpic from './statisticsErrorEpic'
import tagErrorEpics from './tagErrorEpics'
import templateErrorEpics from './templateErrorEpics'
import settingsErrorEpic from './settingsErrorEpic'

export type ErrorPayload = {
  error?: string
}

export type SuccessPayload = {
  message?: string
}

export type ErrorEpic<T extends AppActions['type']> = Record<T, ToastProps>

export type ToastProps =
  | {
      isSuccess: false
      message: string
      toastId: string
    }
  | {
      isSuccess: true
      message: string
      toastId: string
    }

export default [
  alertErrorEpic,
  articlesErrorEpic,
  commentErrorEpic,
  contactErrorEpic,
  feedErrorEpic,
  portalErrorEpic,
  profileEditorErrorEpic,
  profilesErrorEpic,
  reportErrorEpic,
  routerErrorEpic,
  searchErrorEpic,
  statisticsErrorEpic,
  tagErrorEpics,
  templateErrorEpics,
  foldersErrorEpic,
  settingsErrorEpic,
]

export const handlerError = (
  errorTypes:
    | Partial<ErrorEpic<AppActions['type']>>
    | ((payload?: ErrorPayload) => Partial<ErrorEpic<AppActions['type']>>),
  type: AppActions['type'],
  payload?: ErrorPayload,
) => {
  const errorData = typeof errorTypes === 'function' ? errorTypes(payload || {})[type] : errorTypes[type]

  if (errorData) {
    toast({
      title: errorData.message,
      variant: errorData.isSuccess ? 'success' : 'destructive',
      // TODO: just use `toastId: type` instead? it's always the same
      // @ts-expect-error: Muted so we could enable TS strict mode
      toastId: errorData.toastId,
    })
  }

  return of()
}
