import { createSelector } from 'reselect'

import { AlertTag, Tag } from '../components/types/tag'
import { generateBaskets, getTrashTagsVisibleOnArticle, getVisibleTrashTags } from '../opoint/tags/trash'

import { getAlertTags, getAllAlertTags } from './alertsSelectors'
import { getAnalyticsTags } from './analyticsSelectors'
import { getArticleTrashTags } from './articlesSelectors'
import { getRootState } from './rootSelectors'
import { getSelectedProfilesIds, getTrashTagFilterIds } from './searchSelectors'
import { getUISetting } from './settingsSelectors'
import { getLastUsedTagId, getTags } from './tagsSelectors'
import { getAllTrashTags } from './trashTagsSelectors'

export const getTagById = (tagId: number) =>
  createSelector(
    getTags,
    getAllAlertTags,
    getAnalyticsTags,
    (tags, alertTags, analyticsTags) =>
      [...tags, ...alertTags, ...analyticsTags].find(({ id }) => tagId === id) as Tag | AlertTag | undefined,
  )

export const getLastUsedTag = createSelector(getRootState, getLastUsedTagId, (state, lastUsedTagId) =>
  // @ts-expect-error: Muted so we could enable TS strict mode
  getTagById(lastUsedTagId)(state),
)

// get trash tags displayed in entity listing
export const getVisibleTrashTagsInListing = createSelector(
  getAllTrashTags,
  getSelectedProfilesIds,
  getTrashTagFilterIds,
  (trashTags, profiles, trashTagFilterIds) => getVisibleTrashTags(trashTags, profiles, trashTagFilterIds),
)

// get trash tags which are displayed in article footer
export const getActiveTrashTags = createSelector(
  getAllTrashTags,
  getVisibleTrashTagsInListing,
  // TODO: we no longer set visible trash tags, is this still needed?
  //https://infomediacorp.atlassian.net/browse/FE-11540
  getUISetting('TRASH_TAGS_VISIBLE'),
  getTrashTagFilterIds,
  getArticleTrashTags,
  (trashState, trashInListing, active, trashTagFilterIds, articleTrash) => (article) =>
    getTrashTagsVisibleOnArticle(trashState, trashInListing, active, trashTagFilterIds, articleTrash(article)),
)

export const getBaskets = createSelector(getTags, getAllTrashTags, getAllAlertTags, (tags, trashTags, alertTags) =>
  generateBaskets(tags, trashTags, alertTags),
)

export const getAllTagLikeEntities = createSelector(
  getTags,
  getActiveTrashTags,
  getAlertTags,
  (tags, trashTags, alertTags) => (article) => [...tags, ...trashTags(article), ...alertTags],
)
