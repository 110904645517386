import { Trans } from 'react-i18next'
import { Step } from 'react-joyride'

import { TAGS_TOUR_KEY } from '../../../constants/localStorage'
import { getActiveNavigationSection, NavigationSectionKey } from '../../../helpers/navigation'
import { disabledBeaconAndAnimation, disabledScrolling } from '../constants'
import ProductTour from '../ProductTour'
import useDelayTour from '../useDelayTour'
import { prependHash } from '../utility'

export enum TagsTourTarget {
  STEP_1 = 'TT_STEP_1',
  STEP_2 = 'TT_STEP_2',
}

const steps: Step[] = [
  {
    target: prependHash(TagsTourTarget.STEP_1),
    content: <Trans>Select one or multiple tags to view mentions associated with them.</Trans>,
    placement: 'right-start',
    ...disabledBeaconAndAnimation,
    ...disabledScrolling,
  },
  {
    target: prependHash(TagsTourTarget.STEP_2),
    title: <Trans>Here you can create a new tag or edit your existing ones.</Trans>,
    content: <Trans>You can also generate a media report or view statistics for the selected tag(s) from here.</Trans>,
    ...disabledBeaconAndAnimation,
    ...disabledScrolling,
  },
]

const TagsTour = () => {
  const activeNavigation = getActiveNavigationSection()
  const show = useDelayTour(activeNavigation === NavigationSectionKey.TAGS, 1000)

  if (!show) {
    return null
  }

  return <ProductTour tourKey={TAGS_TOUR_KEY} steps={steps} />
}

export default TagsTour
