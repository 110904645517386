import { browserLocaleToOpointLocale } from '../opoint/common/constants'
import { SettingsState } from '../reducers/settings'

export function getLocale(settingsState: SettingsState) {
  // this is used in both settingsSelectors and customAxiosInstance for Orval

  // TODO this logic needs revisiting

  //TODO: do we need to handle 'browser'?
  //https://infomediacorp.atlassian.net/browse/FE-11542
  //@ts-expect-error: I am not sure where 'browser' originates from, we should double check API to see if this check is needed
  if (settingsState.list?.LANGUAGE && settingsState.list.LANGUAGE !== 'browser') {
    return settingsState.list.LANGUAGE
  }

  // @ts-expect-error Muted so we could enable TS strict mode
  const browserLanguage = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage
  const opointLocale = browserLocaleToOpointLocale(browserLanguage)
  const { isoCode } = settingsState

  // in case opoint locale is english and isoCode was fetched, we provide the language based
  // on a browser language
  if (opointLocale === 'en-GB' && isoCode) {
    switch (isoCode) {
      case 'NO':
        return 'nb-NO'
      case 'SE':
        return 'sv-SE'
      case 'DK':
        return 'da-DK'
      case 'FI':
        return 'fi-FI'
      default:
    }
  }

  return 'en-GB' // english as a default language
}
