import { Link } from 'react-router-dom'
import { Navigation20Regular } from '@fluentui/react-icons'
import { cn } from '@opoint/infomedia-storybook'

import Logo from '../../common/Logo'
import { useDefaultHomeUrl } from '../../hooks/useDefaultHomeUrl'

import { NAVIGATION_MENU_EXPANDED } from '../../../constants/localStorage'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { NavigationContextProvider } from './NavigationContext'
import { NavigationSearch } from './NavigationSearch'
import { NavigationSections } from './sections/NavigationSections'

const NavigationContent = () => {
  const defaultHomeUrl = useDefaultHomeUrl()
  const [isExpanded, setIsExpanded] = useLocalStorage(NAVIGATION_MENU_EXPANDED, true)

  return (
    <div
      className={cn(
        'relative box-content flex h-screen w-[18.75rem] shrink-0 flex-col border-r-[0.625rem] border-grey.6 bg-white',
        {
          'w-[3.75rem]': !isExpanded,
        },
      )}
    >
      <div className="flex flex-row gap-4">
        <button
          className="size-xxl hover:bg-grey.7"
          onClick={() => setIsExpanded((prev: boolean) => !prev)}
        >
          <Navigation20Regular />
        </button>
        {isExpanded && (
          <Link to={defaultHomeUrl} className="flex items-center focus-visible:ring-sky.6">
            <Logo className="max-h-10" />
          </Link>
        )}
      </div>

      <nav className="flex grow flex-col overflow-y-hidden">
        <NavigationSections />
      </nav>

      <div className={cn({ 'p-4': isExpanded, 'pb-2': !isExpanded })}>
        <NavigationSearch />
      </div>
    </div>
  )
}

export const Navigation = () => {
  return (
    <NavigationContextProvider>
      <NavigationContent />
    </NavigationContextProvider>
  )
}
