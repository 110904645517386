import { produce } from 'immer'
import { AppActions } from '../actions'
import { Tag } from '../components/types/tag'

export interface TagsState {
  list: Tag[]
  lastUsedTagId: number | null
  tagFormLoading: boolean
}

export const initialState: TagsState = {
  list: [],
  lastUsedTagId: null,
  tagFormLoading: false,
}

const tagsReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'TAGS_FETCH_SUCCESS': {
      draftState.list = action.payload.map((tag, index) => ({ ...tag, index }))
      break
    }

    case 'ADD_TAG':
    case 'EDIT_TAG': {
      draftState.tagFormLoading = true
      break
    }

    case 'SET_LAST_USED_TAG_ID':
    case 'TAG_SINGLE_ARTICLE':
    case 'TAG_ARTICLES': {
      draftState.lastUsedTagId = action.payload.tag.id
      break
    }

    case 'RESET_LAST_USED_TAG_ID': {
      draftState.lastUsedTagId = null
      break
    }

    case 'EDIT_TAG_SUCCESS': {
      const { tag } = action.payload

      // Find the index of the tag to be updated
      const indexToUpdate = draftState.list.findIndex((item) => item.id === tag.id)

      // If the tag is not found, return the current state unchanged
      if (indexToUpdate === -1) {
        return
      }

      draftState.list[indexToUpdate] = { ...tag, index: indexToUpdate }
      draftState.tagFormLoading = false

      break
    }

    case 'ADD_TAG_SUCCESS': {
      draftState.list.push(action.payload.tag)
      draftState.list = draftState.list.map((tag, index) => ({ ...tag, index }))
      draftState.tagFormLoading = false

      break
    }

    case 'DELETE_TAG_SUCCESS': {
      const { tagId } = action.payload

      draftState.list = draftState.list.filter((item) => item.id !== tagId)
      draftState.list = draftState.list.map((tag, index) => ({ ...tag, index }))

      break
    }

    default: {
      return
    }
  }
}, initialState)

export default tagsReducer
