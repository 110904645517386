/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Infomedia API
 * OpenAPI spec version: 1.0.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  Alert,
  AlertContactOverview,
  AlertCreate,
  AlertDetail,
  AlertsHistoryResendCreateParams,
  AlertsHistoryRetrieveParams,
  AlertsNextPreviewCreateParams,
  AlertsNextPreviewListParams,
  AlertsRecipientsListParams,
  Articles,
  NextAlertPreview,
  NextMetadata,
  PaginatedMailLog,
  Recipient,
  ResendAlertRequest,
  ResendAlertResponse,
} from '../opoint.schemas'
import { useCustomAxiosInstance } from '.././custom-client'
import type { ErrorType } from '.././custom-client'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

/**
 * @summary List alerts
 */
export const useAlertsListHook = () => {
  const alertsList = useCustomAxiosInstance<Alert[]>()

  return useCallback(
    (signal?: AbortSignal) => {
      return alertsList({ url: `/alerts/`, method: 'GET', signal })
    },
    [alertsList],
  )
}

export const getAlertsListQueryKey = () => {
  return [`/alerts/`] as const
}

export const useAlertsListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsListHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsListHook>>>, TError, TData>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAlertsListQueryKey()

  const alertsList = useAlertsListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAlertsListHook>>>> = ({ signal }) =>
    alertsList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AlertsListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAlertsListHook>>>>
export type AlertsListQueryError = ErrorType<unknown>

/**
 * @summary List alerts
 */
export const useAlertsList = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsListHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsListHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAlertsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Create an alert
 */
export const useAlertsCreateHook = () => {
  const alertsCreate = useCustomAxiosInstance<AlertDetail>()

  return useCallback(
    (alertCreate: NonReadonly<AlertCreate>) => {
      return alertsCreate({
        url: `/alerts/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: alertCreate,
      })
    },
    [alertsCreate],
  )
}

export const useAlertsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsCreateHook>>>,
    TError,
    { data: NonReadonly<AlertCreate> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAlertsCreateHook>>>,
  TError,
  { data: NonReadonly<AlertCreate> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const alertsCreate = useAlertsCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAlertsCreateHook>>>,
    { data: NonReadonly<AlertCreate> }
  > = (props) => {
    const { data } = props ?? {}

    return alertsCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AlertsCreateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAlertsCreateHook>>>>
export type AlertsCreateMutationBody = NonReadonly<AlertCreate>
export type AlertsCreateMutationError = ErrorType<unknown>

/**
 * @summary Create an alert
 */
export const useAlertsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsCreateHook>>>,
    TError,
    { data: NonReadonly<AlertCreate> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAlertsCreateHook>>>,
  TError,
  { data: NonReadonly<AlertCreate> },
  TContext
> => {
  const mutationOptions = useAlertsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Get alert by id
 */
export const useAlertsRetrieveHook = () => {
  const alertsRetrieve = useCustomAxiosInstance<AlertDetail>()

  return useCallback(
    (id: number, signal?: AbortSignal) => {
      return alertsRetrieve({ url: `/alerts/${id}/`, method: 'GET', signal })
    },
    [alertsRetrieve],
  )
}

export const getAlertsRetrieveQueryKey = (id: number) => {
  return [`/alerts/${id}/`] as const
}

export const useAlertsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsRetrieveHook>>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAlertsRetrieveQueryKey(id)

  const alertsRetrieve = useAlertsRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAlertsRetrieveHook>>>> = ({ signal }) =>
    alertsRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AlertsRetrieveQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAlertsRetrieveHook>>>>
export type AlertsRetrieveQueryError = ErrorType<unknown>

/**
 * @summary Get alert by id
 */
export const useAlertsRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsRetrieveHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAlertsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update an alert
 */
export const useAlertsUpdateHook = () => {
  const alertsUpdate = useCustomAxiosInstance<AlertDetail>()

  return useCallback(
    (id: number, alertCreate: NonReadonly<AlertCreate>) => {
      return alertsUpdate({
        url: `/alerts/${id}/`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: alertCreate,
      })
    },
    [alertsUpdate],
  )
}

export const useAlertsUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsUpdateHook>>>,
    TError,
    { id: number; data: NonReadonly<AlertCreate> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAlertsUpdateHook>>>,
  TError,
  { id: number; data: NonReadonly<AlertCreate> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const alertsUpdate = useAlertsUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAlertsUpdateHook>>>,
    { id: number; data: NonReadonly<AlertCreate> }
  > = (props) => {
    const { id, data } = props ?? {}

    return alertsUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AlertsUpdateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAlertsUpdateHook>>>>
export type AlertsUpdateMutationBody = NonReadonly<AlertCreate>
export type AlertsUpdateMutationError = ErrorType<unknown>

/**
 * @summary Update an alert
 */
export const useAlertsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsUpdateHook>>>,
    TError,
    { id: number; data: NonReadonly<AlertCreate> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAlertsUpdateHook>>>,
  TError,
  { id: number; data: NonReadonly<AlertCreate> },
  TContext
> => {
  const mutationOptions = useAlertsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Delete an alert
 */
export const useAlertsDestroyHook = () => {
  const alertsDestroy = useCustomAxiosInstance<void>()

  return useCallback(
    (id: number) => {
      return alertsDestroy({ url: `/alerts/${id}/`, method: 'DELETE' })
    },
    [alertsDestroy],
  )
}

export const useAlertsDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsDestroyHook>>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAlertsDestroyHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const alertsDestroy = useAlertsDestroyHook()

  const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAlertsDestroyHook>>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {}

    return alertsDestroy(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type AlertsDestroyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAlertsDestroyHook>>>>

export type AlertsDestroyMutationError = ErrorType<unknown>

/**
 * @summary Delete an alert
 */
export const useAlertsDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsDestroyHook>>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAlertsDestroyHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = useAlertsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
export const useAlertsActivateCreateHook = () => {
  const alertsActivateCreate = useCustomAxiosInstance<void>()

  return useCallback(
    (id: number) => {
      return alertsActivateCreate({ url: `/alerts/${id}/activate/`, method: 'POST' })
    },
    [alertsActivateCreate],
  )
}

export const useAlertsActivateCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsActivateCreateHook>>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAlertsActivateCreateHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const alertsActivateCreate = useAlertsActivateCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAlertsActivateCreateHook>>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return alertsActivateCreate(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type AlertsActivateCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsActivateCreateHook>>>
>

export type AlertsActivateCreateMutationError = ErrorType<unknown>

export const useAlertsActivateCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsActivateCreateHook>>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAlertsActivateCreateHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = useAlertsActivateCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const useAlertsArticlesRetrieveHook = () => {
  const alertsArticlesRetrieve = useCustomAxiosInstance<Alert>()

  return useCallback(
    (id: number, signal?: AbortSignal) => {
      return alertsArticlesRetrieve({ url: `/alerts/${id}/articles/`, method: 'GET', signal })
    },
    [alertsArticlesRetrieve],
  )
}

export const getAlertsArticlesRetrieveQueryKey = (id: number) => {
  return [`/alerts/${id}/articles/`] as const
}

export const useAlertsArticlesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsArticlesRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsArticlesRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAlertsArticlesRetrieveQueryKey(id)

  const alertsArticlesRetrieve = useAlertsArticlesRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAlertsArticlesRetrieveHook>>>> = ({ signal }) =>
    alertsArticlesRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsArticlesRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AlertsArticlesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsArticlesRetrieveHook>>>
>
export type AlertsArticlesRetrieveQueryError = ErrorType<unknown>

export const useAlertsArticlesRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsArticlesRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsArticlesRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAlertsArticlesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useAlertsArticlesCreateHook = () => {
  const alertsArticlesCreate = useCustomAxiosInstance<void>()

  return useCallback(
    (id: number, articles: Articles) => {
      return alertsArticlesCreate({
        url: `/alerts/${id}/articles/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: articles,
      })
    },
    [alertsArticlesCreate],
  )
}

export const useAlertsArticlesCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsArticlesCreateHook>>>,
    TError,
    { id: number; data: Articles },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAlertsArticlesCreateHook>>>,
  TError,
  { id: number; data: Articles },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const alertsArticlesCreate = useAlertsArticlesCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAlertsArticlesCreateHook>>>,
    { id: number; data: Articles }
  > = (props) => {
    const { id, data } = props ?? {}

    return alertsArticlesCreate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AlertsArticlesCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsArticlesCreateHook>>>
>
export type AlertsArticlesCreateMutationBody = Articles
export type AlertsArticlesCreateMutationError = ErrorType<unknown>

export const useAlertsArticlesCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsArticlesCreateHook>>>,
    TError,
    { id: number; data: Articles },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAlertsArticlesCreateHook>>>,
  TError,
  { id: number; data: Articles },
  TContext
> => {
  const mutationOptions = useAlertsArticlesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const useAlertsDeactivateCreateHook = () => {
  const alertsDeactivateCreate = useCustomAxiosInstance<void>()

  return useCallback(
    (id: number) => {
      return alertsDeactivateCreate({ url: `/alerts/${id}/deactivate/`, method: 'POST' })
    },
    [alertsDeactivateCreate],
  )
}

export const useAlertsDeactivateCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsDeactivateCreateHook>>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAlertsDeactivateCreateHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const alertsDeactivateCreate = useAlertsDeactivateCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAlertsDeactivateCreateHook>>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return alertsDeactivateCreate(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type AlertsDeactivateCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsDeactivateCreateHook>>>
>

export type AlertsDeactivateCreateMutationError = ErrorType<unknown>

export const useAlertsDeactivateCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsDeactivateCreateHook>>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAlertsDeactivateCreateHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = useAlertsDeactivateCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * History is limited to 120 entries because some users receive an abnormally high
amount of alerts, and the table cannot handle it.
 * @summary Get alert history items
 */
const useAlertsHistoryRetrieveHook = () => {
  const alertsHistoryRetrieve = useCustomAxiosInstance<PaginatedMailLog>()

  return useCallback(
    (id: number, params?: AlertsHistoryRetrieveParams) => {
      return alertsHistoryRetrieve({ url: `/alerts/${id}/history/`, method: 'GET', params })
    },
    [alertsHistoryRetrieve],
  )
}

export const getAlertsHistoryRetrieveQueryKey = (id: number, params?: AlertsHistoryRetrieveParams) => {
  return [`/alerts/${id}/history/`, ...(params ? [params] : [])] as const
}

export const useAlertsHistoryRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: AlertsHistoryRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAlertsHistoryRetrieveQueryKey(id, params)

  const alertsHistoryRetrieve = useAlertsHistoryRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>> = ({
    pageParam,
  }) => alertsHistoryRetrieve(id, { ...params, page: pageParam || params?.['page'] })

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AlertsHistoryRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>
>
export type AlertsHistoryRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get alert history items
 */
export const useAlertsHistoryRetrieveInfinite = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: AlertsHistoryRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>, TError, TData>
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAlertsHistoryRetrieveInfiniteQueryOptions(id, params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useAlertsHistoryRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: AlertsHistoryRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAlertsHistoryRetrieveQueryKey(id, params)

  const alertsHistoryRetrieve = useAlertsHistoryRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>> = () =>
    alertsHistoryRetrieve(id, params)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AlertsHistoryRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>
>
export type AlertsHistoryRetrieveQueryError = ErrorType<unknown>

/**
 * @summary Get alert history items
 */
export const useAlertsHistoryRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: AlertsHistoryRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAlertsHistoryRetrieveQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useAlertsHistoryRetrieve2Hook = () => {
  const alertsHistoryRetrieve2 = useCustomAxiosInstance<Alert>()

  return useCallback(
    (id: number, idItem: string, stimestamp: string, signal?: AbortSignal) => {
      return alertsHistoryRetrieve2({ url: `/alerts/${id}/history/${idItem}/${stimestamp}/`, method: 'GET', signal })
    },
    [alertsHistoryRetrieve2],
  )
}

export const getAlertsHistoryRetrieve2QueryKey = (id: number, idItem: string, stimestamp: string) => {
  return [`/alerts/${id}/history/${idItem}/${stimestamp}/`] as const
}

export const useAlertsHistoryRetrieve2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieve2Hook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  idItem: string,
  stimestamp: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieve2Hook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAlertsHistoryRetrieve2QueryKey(id, idItem, stimestamp)

  const alertsHistoryRetrieve2 = useAlertsHistoryRetrieve2Hook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieve2Hook>>>> = ({ signal }) =>
    alertsHistoryRetrieve2(id, idItem, stimestamp, signal)

  return { queryKey, queryFn, enabled: !!(id && idItem && stimestamp), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieve2Hook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AlertsHistoryRetrieve2QueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieve2Hook>>>
>
export type AlertsHistoryRetrieve2QueryError = ErrorType<unknown>

export const useAlertsHistoryRetrieve2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieve2Hook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  idItem: string,
  stimestamp: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsHistoryRetrieve2Hook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAlertsHistoryRetrieve2QueryOptions(id, idItem, stimestamp, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useAlertsHistoryResendCreateHook = () => {
  const alertsHistoryResendCreate = useCustomAxiosInstance<ResendAlertResponse>()

  return useCallback(
    (
      id: number,
      idItem: string,
      stimestamp: string,
      resendAlertRequest: ResendAlertRequest,
      params?: AlertsHistoryResendCreateParams,
    ) => {
      return alertsHistoryResendCreate({
        url: `/alerts/${id}/history/${idItem}/${stimestamp}/resend/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: resendAlertRequest,
        params,
      })
    },
    [alertsHistoryResendCreate],
  )
}

export const useAlertsHistoryResendCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsHistoryResendCreateHook>>>,
    TError,
    {
      id: number
      idItem: string
      stimestamp: string
      data: ResendAlertRequest
      params?: AlertsHistoryResendCreateParams
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAlertsHistoryResendCreateHook>>>,
  TError,
  {
    id: number
    idItem: string
    stimestamp: string
    data: ResendAlertRequest
    params?: AlertsHistoryResendCreateParams
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const alertsHistoryResendCreate = useAlertsHistoryResendCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAlertsHistoryResendCreateHook>>>,
    {
      id: number
      idItem: string
      stimestamp: string
      data: ResendAlertRequest
      params?: AlertsHistoryResendCreateParams
    }
  > = (props) => {
    const { id, idItem, stimestamp, data, params } = props ?? {}

    return alertsHistoryResendCreate(id, idItem, stimestamp, data, params)
  }

  return { mutationFn, ...mutationOptions }
}

export type AlertsHistoryResendCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsHistoryResendCreateHook>>>
>
export type AlertsHistoryResendCreateMutationBody = ResendAlertRequest
export type AlertsHistoryResendCreateMutationError = ErrorType<unknown>

export const useAlertsHistoryResendCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsHistoryResendCreateHook>>>,
    TError,
    {
      id: number
      idItem: string
      stimestamp: string
      data: ResendAlertRequest
      params?: AlertsHistoryResendCreateParams
    },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAlertsHistoryResendCreateHook>>>,
  TError,
  {
    id: number
    idItem: string
    stimestamp: string
    data: ResendAlertRequest
    params?: AlertsHistoryResendCreateParams
  },
  TContext
> => {
  const mutationOptions = useAlertsHistoryResendCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Returns a number of articles that should be sent in a next alert and when next
possible send time is.
 * @summary Next alert metadata
 */
export const useAlertsNextRetrieveHook = () => {
  const alertsNextRetrieve = useCustomAxiosInstance<NextMetadata>()

  return useCallback(
    (id: number, signal?: AbortSignal) => {
      return alertsNextRetrieve({ url: `/alerts/${id}/next/`, method: 'GET', signal })
    },
    [alertsNextRetrieve],
  )
}

export const getAlertsNextRetrieveQueryKey = (id: number) => {
  return [`/alerts/${id}/next/`] as const
}

export const useAlertsNextRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsNextRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsNextRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAlertsNextRetrieveQueryKey(id)

  const alertsNextRetrieve = useAlertsNextRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAlertsNextRetrieveHook>>>> = ({ signal }) =>
    alertsNextRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsNextRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AlertsNextRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsNextRetrieveHook>>>
>
export type AlertsNextRetrieveQueryError = ErrorType<unknown>

/**
 * @summary Next alert metadata
 */
export const useAlertsNextRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsNextRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsNextRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAlertsNextRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useAlertsNextPreviewListHook = () => {
  const alertsNextPreviewList = useCustomAxiosInstance<NextAlertPreview[]>()

  return useCallback(
    (id: number, params?: AlertsNextPreviewListParams, signal?: AbortSignal) => {
      return alertsNextPreviewList({ url: `/alerts/${id}/next/preview/`, method: 'GET', params, signal })
    },
    [alertsNextPreviewList],
  )
}

export const getAlertsNextPreviewListQueryKey = (id: number, params?: AlertsNextPreviewListParams) => {
  return [`/alerts/${id}/next/preview/`, ...(params ? [params] : [])] as const
}

export const useAlertsNextPreviewListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewListHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: AlertsNextPreviewListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewListHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAlertsNextPreviewListQueryKey(id, params)

  const alertsNextPreviewList = useAlertsNextPreviewListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewListHook>>>> = ({ signal }) =>
    alertsNextPreviewList(id, params, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AlertsNextPreviewListQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewListHook>>>
>
export type AlertsNextPreviewListQueryError = ErrorType<unknown>

export const useAlertsNextPreviewList = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewListHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: AlertsNextPreviewListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewListHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAlertsNextPreviewListQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useAlertsNextPreviewCreateHook = () => {
  const alertsNextPreviewCreate = useCustomAxiosInstance<NextAlertPreview[]>()

  return useCallback(
    (id: number, params?: AlertsNextPreviewCreateParams) => {
      return alertsNextPreviewCreate({ url: `/alerts/${id}/next/preview/`, method: 'POST', params })
    },
    [alertsNextPreviewCreate],
  )
}

export const useAlertsNextPreviewCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewCreateHook>>>,
    TError,
    { id: number; params?: AlertsNextPreviewCreateParams },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewCreateHook>>>,
  TError,
  { id: number; params?: AlertsNextPreviewCreateParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const alertsNextPreviewCreate = useAlertsNextPreviewCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewCreateHook>>>,
    { id: number; params?: AlertsNextPreviewCreateParams }
  > = (props) => {
    const { id, params } = props ?? {}

    return alertsNextPreviewCreate(id, params)
  }

  return { mutationFn, ...mutationOptions }
}

export type AlertsNextPreviewCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewCreateHook>>>
>

export type AlertsNextPreviewCreateMutationError = ErrorType<unknown>

export const useAlertsNextPreviewCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewCreateHook>>>,
    TError,
    { id: number; params?: AlertsNextPreviewCreateParams },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAlertsNextPreviewCreateHook>>>,
  TError,
  { id: number; params?: AlertsNextPreviewCreateParams },
  TContext
> => {
  const mutationOptions = useAlertsNextPreviewCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Get alert recipients
 */
export const useAlertsRecipientsListHook = () => {
  const alertsRecipientsList = useCustomAxiosInstance<Recipient[]>()

  return useCallback(
    (id: number, params?: AlertsRecipientsListParams, signal?: AbortSignal) => {
      return alertsRecipientsList({ url: `/alerts/${id}/recipients/`, method: 'GET', params, signal })
    },
    [alertsRecipientsList],
  )
}

export const getAlertsRecipientsListQueryKey = (id: number, params?: AlertsRecipientsListParams) => {
  return [`/alerts/${id}/recipients/`, ...(params ? [params] : [])] as const
}

export const useAlertsRecipientsListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsListHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: AlertsRecipientsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsListHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAlertsRecipientsListQueryKey(id, params)

  const alertsRecipientsList = useAlertsRecipientsListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsListHook>>>> = ({ signal }) =>
    alertsRecipientsList(id, params, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AlertsRecipientsListQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsListHook>>>
>
export type AlertsRecipientsListQueryError = ErrorType<unknown>

/**
 * @summary Get alert recipients
 */
export const useAlertsRecipientsList = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsListHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: AlertsRecipientsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsListHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAlertsRecipientsListQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Remove recipient from an alert
 */
export const useAlertsRecipientsDestroyHook = () => {
  const alertsRecipientsDestroy = useCustomAxiosInstance<void>()

  return useCallback(
    (id: number, idRecipient: string) => {
      return alertsRecipientsDestroy({ url: `/alerts/${id}/recipients/${idRecipient}/`, method: 'DELETE' })
    },
    [alertsRecipientsDestroy],
  )
}

export const useAlertsRecipientsDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsDestroyHook>>>,
    TError,
    { id: number; idRecipient: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsDestroyHook>>>,
  TError,
  { id: number; idRecipient: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const alertsRecipientsDestroy = useAlertsRecipientsDestroyHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsDestroyHook>>>,
    { id: number; idRecipient: string }
  > = (props) => {
    const { id, idRecipient } = props ?? {}

    return alertsRecipientsDestroy(id, idRecipient)
  }

  return { mutationFn, ...mutationOptions }
}

export type AlertsRecipientsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsDestroyHook>>>
>

export type AlertsRecipientsDestroyMutationError = ErrorType<unknown>

/**
 * @summary Remove recipient from an alert
 */
export const useAlertsRecipientsDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsDestroyHook>>>,
    TError,
    { id: number; idRecipient: string },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAlertsRecipientsDestroyHook>>>,
  TError,
  { id: number; idRecipient: string },
  TContext
> => {
  const mutationOptions = useAlertsRecipientsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Immediately sends articles that was found for
the next delivery.
:param request: request object
:param pk: class id of an alert
:return:
 * @summary Send the alert now
 */
export const useAlertsSendNowCreateHook = () => {
  const alertsSendNowCreate = useCustomAxiosInstance<void>()

  return useCallback(
    (id: number) => {
      return alertsSendNowCreate({ url: `/alerts/${id}/send-now/`, method: 'POST' })
    },
    [alertsSendNowCreate],
  )
}

export const useAlertsSendNowCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsSendNowCreateHook>>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAlertsSendNowCreateHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const alertsSendNowCreate = useAlertsSendNowCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAlertsSendNowCreateHook>>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return alertsSendNowCreate(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type AlertsSendNowCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsSendNowCreateHook>>>
>

export type AlertsSendNowCreateMutationError = ErrorType<unknown>

/**
 * @summary Send the alert now
 */
export const useAlertsSendNowCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsSendNowCreateHook>>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAlertsSendNowCreateHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = useAlertsSendNowCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const useAlertsAlertContactDetailsListHook = () => {
  const alertsAlertContactDetailsList = useCustomAxiosInstance<AlertContactOverview[]>()

  return useCallback(
    (signal?: AbortSignal) => {
      return alertsAlertContactDetailsList({ url: `/alerts/alert-contact-details/`, method: 'GET', signal })
    },
    [alertsAlertContactDetailsList],
  )
}

export const getAlertsAlertContactDetailsListQueryKey = () => {
  return [`/alerts/alert-contact-details/`] as const
}

export const useAlertsAlertContactDetailsListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsAlertContactDetailsListHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsAlertContactDetailsListHook>>>, TError, TData>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAlertsAlertContactDetailsListQueryKey()

  const alertsAlertContactDetailsList = useAlertsAlertContactDetailsListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAlertsAlertContactDetailsListHook>>>> = ({
    signal,
  }) => alertsAlertContactDetailsList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsAlertContactDetailsListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AlertsAlertContactDetailsListQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsAlertContactDetailsListHook>>>
>
export type AlertsAlertContactDetailsListQueryError = ErrorType<unknown>

export const useAlertsAlertContactDetailsList = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsAlertContactDetailsListHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsAlertContactDetailsListHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAlertsAlertContactDetailsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useAlertsLogoRetrieveHook = () => {
  const alertsLogoRetrieve = useCustomAxiosInstance<Alert>()

  return useCallback(
    (signal?: AbortSignal) => {
      return alertsLogoRetrieve({ url: `/alerts/logo/`, method: 'GET', signal })
    },
    [alertsLogoRetrieve],
  )
}

export const getAlertsLogoRetrieveQueryKey = () => {
  return [`/alerts/logo/`] as const
}

export const useAlertsLogoRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsLogoRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsLogoRetrieveHook>>>, TError, TData>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAlertsLogoRetrieveQueryKey()

  const alertsLogoRetrieve = useAlertsLogoRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAlertsLogoRetrieveHook>>>> = ({ signal }) =>
    alertsLogoRetrieve(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAlertsLogoRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AlertsLogoRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAlertsLogoRetrieveHook>>>
>
export type AlertsLogoRetrieveQueryError = ErrorType<unknown>

export const useAlertsLogoRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useAlertsLogoRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAlertsLogoRetrieveHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAlertsLogoRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
