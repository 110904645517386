import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { isEmpty } from 'ramda'
import { ArticleMetadataType, SetActiveArticle } from '../../opoint/flow'
import { noArticlesFound as articlesFound } from '../../selectors/articlesSearchSelectors'
import { getActive, getArticles, getCheckedArticles, getIdenticalArticles } from '../../selectors/articlesSelectors'
import { noPreviewArticlesFound } from '../../selectors/profilesSearchSelectors'
import { getPreviewActiveArticle, getPreviewArticles, getPreviewIdentical } from '../../selectors/profilesSelectors'
import {
  getLoadMoreSearchInProgress,
  isSearchExhausted,
  isSearchInProgress,
  isSearchNotEmpty,
} from '../../selectors/searchSelectors'
import { isLimitedSearch as limitedSearch } from '../../selectors/settingsSearchSelectors'
import {
  getActiveLanguage,
  getArticleListingStyle,
  getArticleMetadata,
  getSimilarArticlesVisible,
} from '../../selectors/settingsSelectors'
import { getShowFilteredArticles } from '../../selectors/statisticsSelectors'

import { M360Article } from '../../opoint/articles/types'
import { useAppSelector } from './useAppSelector'
import useDeepCompareMemoize from './useDeepCompareMemoize'

type ArticleBaseProps = {
  active: SetActiveArticle
  articleListingStyle: number
  articleMetadata: Array<ArticleMetadataType>
  articles: Array<M360Article>
  articleSource: string
  checkedArticles: string[]
  identicalArticles: { [key: string]: number }
  isProfileEditor: boolean
  isSearch: boolean
  locale: string
  noArticlesFound: boolean
  searchInProgress: boolean
  showSimilarArticles?: boolean
  isLimitedSearch: boolean
  loadMoreSearchInProgress: boolean
  searchIsExhausted: boolean
}

const useArticleBaseProps = (): ArticleBaseProps => {
  const location = useLocation()
  const articleMetadata = useAppSelector(getArticleMetadata)
  const checkedArticles = useAppSelector(getCheckedArticles)
  const isSearch = useAppSelector(isSearchNotEmpty)
  const loadMoreSearchInProgress = useAppSelector(getLoadMoreSearchInProgress)
  const locale = useAppSelector(getActiveLanguage)
  const searchInProgress = useAppSelector(isSearchInProgress)
  const searchIsExhausted = useAppSelector(isSearchExhausted)
  const showSimilarArticles = useAppSelector(getSimilarArticlesVisible)
  const isLimitedSearch = useAppSelector(limitedSearch)
  const regularArticles = useAppSelector(getArticles)
  const regularIdentical = useAppSelector(getIdenticalArticles)

  const previewArticles = useAppSelector(getPreviewArticles)
  const previewIdentical = useAppSelector(getPreviewIdentical)

  // TODO: there is a selector called getIsPreviewOpened, can we streamline the logic by combining them / removing one of them?
  // (as part of Profile Reducer migration)
  //https://infomediacorp.atlassian.net/browse/FE-11315
  const isPreview = useMemo(() => {
    const isProfileEditorOpen = location.pathname === '/search/edit/' || location.pathname === '/search/new'

    return isProfileEditorOpen && !isEmpty(previewArticles)
  }, [location.pathname, previewArticles])

  const active = useAppSelector(isPreview ? getPreviewActiveArticle : getActive)
  const articles: Array<M360Article> = isPreview ? previewArticles : regularArticles
  const articleSource = isPreview ? 'profilePreview' : 'articles'
  const identical = isPreview ? previewIdentical : regularIdentical
  const identicalArticles = isPreview ? previewIdentical : regularIdentical
  const isProfileEditor = isPreview
  const noArticlesFound = useAppSelector(isPreview ? noPreviewArticlesFound : articlesFound)
  const showFilteredArticles = useAppSelector(getShowFilteredArticles)

  const articleListingStyle = useAppSelector(getArticleListingStyle)

  const memoizedArticles: Array<M360Article> = useDeepCompareMemoize(articles)

  const articleBaseProps = useMemo(
    () => ({
      articleMetadata,
      checkedArticles,
      isSearch,
      loadMoreSearchInProgress,
      locale,
      searchInProgress,
      searchIsExhausted,
      showSimilarArticles,
      isLimitedSearch,
      active,
      articles: memoizedArticles,
      articleSource,
      identical,
      identicalArticles,
      isProfileEditor,
      noArticlesFound,
      showFilteredArticles,
      articleListingStyle,
    }),
    [
      articleMetadata,
      checkedArticles,
      isSearch,
      loadMoreSearchInProgress,
      locale,
      searchInProgress,
      searchIsExhausted,
      showSimilarArticles,
      isLimitedSearch,
      active,
      memoizedArticles,
      articleSource,
      identical,
      identicalArticles,
      isProfileEditor,
      noArticlesFound,
      showFilteredArticles,
      articleListingStyle,
    ],
  )

  return articleBaseProps
}

export default useArticleBaseProps
