import { WatchQuery } from '../../api/opoint.schemas'
import { handleErrors } from '../common'
import config from '../common/config'

export async function updateWatchIndexes(watchIds: Array<string>): Promise<WatchQuery[]> {
  return fetch(config.url.api(`/watchqueries/${watchIds.join(',')}/`), {
    ...(await config.request.getRequestHeaders()),
  })
    .then(handleErrors)
    .then((response) => response.json())
}
