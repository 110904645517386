const MODULE_OPTION = { OFF: 'A', OFF_FOR_USER: 'B', READ_ONLY: 'C', ON: 'D' } as const
const ATTRIBUTE_OPTION = { OFF: 0, ON: 1 } as const
const CATEGORIZATION_OPTION = { OFF: 0, IN_MENU: 1, AS_ICON: 2, AS_LIST: 3 } as const

export const SHORT_MODULES = {
  SOURCE_LIST_MODULE: MODULE_OPTION,
  FEED_MODULE: MODULE_OPTION,
  ALERT_MODULE: MODULE_OPTION,
  SETTING_MODULE: MODULE_OPTION,
  REPORT_MODULE: MODULE_OPTION,
  STATISTICS_MODULE: MODULE_OPTION,
  TRASH_MODULE: MODULE_OPTION,
  CREATE_ARTICLE_MODULE: MODULE_OPTION,
  PROFILE_MODULE: MODULE_OPTION,
  TAG_MODULE: MODULE_OPTION,
  CONTACT_MODULE: MODULE_OPTION,
  TEMPLATE_EDITOR_MODULE: MODULE_OPTION,
} as const

export const MODULES = {
  ...SHORT_MODULES,
  SIMILAR_SETTING_VISIBLE: ATTRIBUTE_OPTION,
  SHOW_COPYRIGHT_DISCLAIMER: ATTRIBUTE_OPTION,
  STATISTICS_ENABLE_EAC_AND_CIRCULATION: ATTRIBUTE_OPTION,
  ENABLE_TEXT_SMS_ALERTS: ATTRIBUTE_OPTION,
  LIMITED_ADD_CONTENT: ATTRIBUTE_OPTION,
  ENABLE_REACH_ON_ARTICLES: ATTRIBUTE_OPTION,
  ENABLE_EAC_ON_ARTICLE: ATTRIBUTE_OPTION,
  NON_PDF_REPORT_ACCESS: ATTRIBUTE_OPTION,
  DISPLAY_PRINT_PDF_LINK: ATTRIBUTE_OPTION,
  LIMITED_SEARCH: ATTRIBUTE_OPTION,
  ENABLE_EDIT_ARTICLES: ATTRIBUTE_OPTION,
  ENABLE_CATEGORIZATION: CATEGORIZATION_OPTION,
  LIMITED_CREATE_PROFILES: ATTRIBUTE_OPTION,
  ENABLE_SINGLE_ARTICLE_MODE: ATTRIBUTE_OPTION,
  FOLDER_ADMIN: ATTRIBUTE_OPTION,
  PRODUCT_TOUR: ATTRIBUTE_OPTION,
  RESTRICTED_REPORT_HISTORY: ATTRIBUTE_OPTION,
  DISPLAY_ARTICLE_COUNT: ATTRIBUTE_OPTION,
  HIDE_ALL_TAGS: ATTRIBUTE_OPTION,
} as const

export type Modules = keyof typeof MODULES

export type ModuleOptions = (typeof MODULE_OPTION)[keyof typeof MODULE_OPTION]
export type AttributeOptions = (typeof ATTRIBUTE_OPTION)[keyof typeof ATTRIBUTE_OPTION]
export type CategorizationOptions = (typeof CATEGORIZATION_OPTION)[keyof typeof CATEGORIZATION_OPTION]

export type PermissionOptions = ModuleOptions | AttributeOptions | CategorizationOptions

const LICENSE_MODULE_OPTION = { UNSET: 'UNSET', SET: 'SET', ENFORCE: 'ENFORCE' } as const

// There are others returned from BE, these are the only ones we use
export const LICENSE_MODULES = {
  INFOMEDIA_SENTIMENT_DATA: LICENSE_MODULE_OPTION,
  TEXTRAZOR_DATA: LICENSE_MODULE_OPTION,
}

export type LicenseModules = keyof typeof LICENSE_MODULES
export type LicenseOptions = (typeof LICENSE_MODULE_OPTION)[keyof typeof LICENSE_MODULE_OPTION]
