import { Clock16Filled, Play16Filled } from '@fluentui/react-icons'
import { HighlightedMatchesAndEntities } from '@opoint/infomedia-storybook'
import { MediaMatch } from '../../../../types/article'
import { DocumentArticlemediaItem } from '../../../../../api/opoint-search-suggest.schemas'

type MatchItemProps = {
  articlemedia: DocumentArticlemediaItem[]
  match: MediaMatch
  onClick: (seconds: number) => void
  onConvertSeconds: (seconds: number) => string
  shownVideo: { id: number; url: string }
}

const MatchItem = ({ articlemedia, match, onClick, onConvertSeconds, shownVideo }: MatchItemProps) => {
  const matches = match.matches ?? match.text
  const seconds = match.seconds ?? match.play_offset
  const snippet = match?.snippet ?? match?.quote?.text

  return (
    <li className="border-b border-b-grey.6 last:border-none">
      <div className="mt-3 flex justify-between">
        <div className="mb-3 flex flex-col font-sans text-label-m font-bold">
          <div>
            {Array.isArray(matches) ? (
              matches.map((match, i) => {
                const isLast = matches.length === i + 1

                return (
                  <span key={i}>
                    {match}
                    {!isLast && ', '}
                  </span>
                )
              })
            ) : (
              <span>{matches}</span>
            )}
          </div>

          {!!seconds && (
            <div className="mt-3 flex items-center space-x-1.5 text-label-m font-bold text-sky.1">
              <Clock16Filled className="shrink-0" />
              <span>{onConvertSeconds(seconds)}</span>
            </div>
          )}
        </div>

        {(articlemedia[shownVideo.id]?.localurl || articlemedia[shownVideo.id]?.url) && !!seconds && (
          <button
            type="button"
            onClick={() => onClick(seconds)}
            className="flex size-8 shrink-0 items-center justify-center rounded-full bg-sky.1 text-white"
          >
            <Play16Filled />
          </button>
        )}
      </div>

      {snippet && (
        <div className="mb-3 font-slab text-label-s text-sky.cloudy">
          (...) <HighlightedMatchesAndEntities text={snippet} /> (...)
        </div>
      )}
    </li>
  )
}

export default MatchItem
