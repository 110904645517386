import { ActionsObservable, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { AppActions } from '../actions'
import { AnalyticsTagsFetchFailureAction, AnalyticsTagsFetchSuccessAction } from '../actions/analytics'
import { ImpersonateSuccessAction, LogInSuccessAction } from '../actions/auth'
import { Tag } from '../components/types/tag'
import { getTags } from '../opoint/analytics'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

export const fetchEpic = (action$: ActionsObservable<AppActions>) => {
  return action$.pipe(
    ofType<AppActions, LogInSuccessAction | ImpersonateSuccessAction>('LOG_IN_SUCCESS', 'IMPERSONATE_SUCCESS'),
    switchMap(() =>
      from(getTags()).pipe(
        map(
          (data: Tag[]) =>
            ({
              type: 'ANALYSIS_TAGS_FETCH_SUCCESS',
              payload: data,
            } as AnalyticsTagsFetchSuccessAction),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<AnalyticsTagsFetchFailureAction>({ type: 'ANALYSIS_TAGS_FETCH_FAILURE' })),
      ),
    ),
  )
}

export default [fetchEpic]
