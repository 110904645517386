import { useOpointUser } from '@opoint/authjs-react'
import { ImgHTMLAttributes, forwardRef } from 'react'

import { getLogoUrl } from '../../helpers/common'
import { firstToUpperCase } from '../../opoint/common'
import { getPortalLogo } from '../../selectors/settingsSelectors'
import { useAppSelector } from '../hooks/useAppSelector'

const Logo = forwardRef<HTMLImageElement, Omit<ImgHTMLAttributes<HTMLImageElement>, 'alt' | 'src' | 'loading'>>(
  ({ width = 136, height = 28, ...props }, ref) => {
    const user = useOpointUser()
    const portalLogo = useAppSelector(getPortalLogo)

    const host = window.location.host.split('.')[0]

    return (
      <img
        ref={ref}
        width={width}
        height={height}
        alt={`${firstToUpperCase(host)}'s logo`}
        // @ts-expect-error: Muted so we could enable TS strict mode
        src={getLogoUrl(host, user, portalLogo)}
        loading="lazy"
        {...props}
      />
    )
  },
)

Logo.displayName = 'Logo'

export default Logo
