import { Trans } from 'react-i18next'
import React, { MouseEvent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDefaultHomeUrl } from '../../../hooks/useDefaultHomeUrl'
import useSearchRouteBuilder from '../../../hooks/useSearchRouteBuilder'
import { SearchFilterKey } from '../../../hooks/useSearchFilters'

export const ProfilesBreadcrumb = (): React.JSX.Element => {
  const { getSearchRoute } = useSearchRouteBuilder()
  const params = useParams()
  const defaultHomeUrl = useDefaultHomeUrl()
  const navigate = useNavigate()

  const handleClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    // If the user is editing their profile, redirect them to the profile page, otherwise, redirect them to the default home URL.
    navigate(
      params.profileId ? getSearchRoute(SearchFilterKey.PROFILES, parseInt(params.profileId, 10)) : defaultHomeUrl,
    )
  }

  return (
    <span onClick={handleClick}>
      <Trans>Profiles</Trans>
    </span>
  )
}
