import { produce } from 'immer'

import { AppActions } from '../actions'
import { CommentType } from '../opoint/flow'

export type CommentsState = {
  comments: Array<CommentType>
  commentsModalOpen: boolean
  articleDetails: { id_site: number; id_article: number; article_header?: string } | null
  alteredArticleComments: Record<string, number>
}

export const initialState: CommentsState = {
  comments: [],
  commentsModalOpen: false,
  articleDetails: null,
  alteredArticleComments: {},
}

const commentsReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'FETCH_ARTICLE_COMMENTS_SUCCESS': {
      const { comments, articleId } = action.payload

      draftState.comments = comments.sort((a, b) => b.timestamp - a.timestamp)
      draftState.alteredArticleComments[articleId] = comments.length
      break
    }

    case 'OPEN_ARTICLE_COMMENTS_MODAL': {
      draftState.articleDetails = action.payload
      draftState.commentsModalOpen = true
      break
    }

    case 'CLOSE_ARTICLE_COMMENTS_MODAL': {
      draftState.commentsModalOpen = false
      break
    }

    default: {
      break
    }
  }
}, initialState)

export default commentsReducer
