import { DOM } from 'rx-dom'
import { merge } from 'ramda'
import { ImpersonationTarget } from '../../components/types/user'
import config from '../common/config'

/**
 * Retrieves user's alternate organizations for impersonation from API and transforms them into a promise.
 * @returns {*}
 */
export async function getImpersonationOrganizations(): Promise<Array<ImpersonationTarget>> {
  const queryParams = `?only_self=1` //TODO: We should probably handle page_size in some way. Default is 20

  const requestHeaders = merge(await config.request.getRequestHeaders(true), {
    url: config.url.api(`/users/impersonation_targets/${queryParams}`),
    method: 'GET',
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

/**
 * Retrieves user targets for impersonation from API and transforms them into a promise.
 * Results are ordered by user id, so use lastId for pagination.
 * @returns {*}
 */
export async function getImpersonationUsers(userSearch?: string): Promise<Array<ImpersonationTarget>> {
  const queryParams = `?search=${userSearch}&exclude_self=1&last_id_user=0`

  const requestHeaders = merge(await config.request.getRequestHeaders(true), {
    url: config.url.api(`/users/impersonation_targets/${queryParams}`),
    method: 'GET',
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
