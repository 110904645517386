import { produce } from 'immer'
import type { Report } from '../opoint/flow'
import { AppActions } from '../actions'

export type ReportsHistoryState = {
  loading: boolean
  reportObject: Report | null
  modalReportsHistory: Report[]
  totalCount: number
  page: number
}

export const initialState: ReportsHistoryState = {
  loading: false,
  reportObject: null,
  modalReportsHistory: [],
  totalCount: 0,
  page: 1,
}

const reportsHistoryReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'REPORTHISTORY_FETCH_SUCCESS': {
      const { reports, totalCount } = action.payload

      draftState.modalReportsHistory = [...draftState.modalReportsHistory, ...reports]
      draftState.loading = false
      draftState.totalCount = totalCount
      draftState.page++
      break
    }

    case 'REPORTHISTORY_SET_ACTIVE': {
      const { reportObject } = action.payload
      draftState.reportObject = reportObject
      break
    }

    case 'REPORTHISTORY_FETCH': {
      draftState.loading = true
      break
    }

    case 'REPORTHISTORY_CLEAR': {
      return initialState
    }

    case 'DELETE_REPORT_SUCCESS': {
      const { id } = action.payload
      draftState.modalReportsHistory = draftState.modalReportsHistory.filter((report) => report.id !== id)
      draftState.totalCount--
      break
    }
    default:
      break
  }
}, initialState)

export default reportsHistoryReducer
