import { useEffect, useMemo, useRef, useState } from 'react'
import { PlayFilled, StarRegular } from '@fluentui/react-icons'
import { cn } from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'
import { Subject } from 'rxjs'

import { articleIdFromIds, LogActions } from '../../../../../opoint/articles'
import config from '../../../../../opoint/common/config'
import type { FirstSource, MediaMatch } from '../../../../types/article'

import { DocumentArticlemediaItem } from '../../../../../api/opoint-search-suggest.schemas'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import MatchList from './MatchList'

type Props = {
  articlemedia: DocumentArticlemediaItem[]
  articleVideoPosterUrl: { url: string }[]
  id_article: number
  id_site: number
  matches: MediaMatch[]
  orig_url?: string
  position: number
  sourceName: string
  isSocial: boolean
  first_source: FirstSource
  summaryText: string
  headerText: string
}

const ArticleMedia = ({
  articlemedia,
  id_article,
  id_site,
  matches,
  orig_url,
  sourceName,
  isSocial,
  first_source,
  summaryText,
  headerText,
  articleVideoPosterUrl,
}: Props) => {
  const dispatch = useAppDispatch()
  const mediaElementRef = useRef<HTMLVideoElement>()
  const stopVideosObserver = new Subject()

  const articleMediaData = articlemedia[0]

  const [shownVideo, setShownVideo] = useState(
    articlemedia && { id: 0, url: config.url.app(articleMediaData?.localurl || '') },
  )

  const articleId = useMemo(() => articleIdFromIds(id_site, id_article), [id_site, id_article])

  useEffect(() => {
    const stopVideosSubscriber = stopVideosObserver.subscribe((senderArticleId) => {
      if (articleId !== senderArticleId) {
        mediaElementRef.current?.pause()
      }
    })

    return () => {
      stopVideosSubscriber.unsubscribe()
    }
  }, [shownVideo])

  const handlePlayVideo = () => {
    stopVideosObserver.next(articleIdFromIds(id_site, id_article))
    dispatch({
      type: 'LOG_ARTICLE_ACTION',
      payload: { id_site, id_article, action: [LogActions.MediaClipPlayed] },
    })
  }

  const handlePlayAtSpecificTime = (seconds) => {
    handlePlayVideo()

    if (mediaElementRef.current) {
      mediaElementRef.current.currentTime = seconds
      mediaElementRef.current.paused && mediaElementRef.current.play()
    }
  }

  const handleShownVideoDataChange = ({ id, url }) => {
    setShownVideo({ id, url })
    mediaElementRef.current?.load()
  }

  const getFilteredMatchesLength = (id) => matches?.filter((filtered) => filtered.video_id === id).length

  const mediaMatches = useMemo(
    () => matches?.filter((filtered) => filtered.video_id === shownVideo?.id),
    [matches, shownVideo?.id],
  )

  const thumbnail = useMemo(() => {
    if (articleMediaData?.thumbnail?.startsWith('http')) {
      return articleMediaData?.thumbnail
    }

    return config.url.app(articleMediaData?.thumbnail || '')
  }, [articleMediaData?.thumbnail])

  return (
    <>
      <div className="flex flex-col items-center">
        {articlemedia?.length < 1 || !articleMediaData?.localurl || !articleMediaData?.url ? (
          <div
            className={cn(
              'mb-3 flex h-[17rem] w-full flex-col items-center justify-center gap-2.5 bg-grey.7 px-4 text-center',
              {
                'mt-0': !summaryText,
              },
            )}
          >
            <a href={orig_url} target="_blank" rel="noopener noreferrer">
              <div className="flex size-12 items-center justify-center rounded-full bg-sky.1 text-white">
                <PlayFilled fontSize={28} />
              </div>
            </a>
            <span className="font-sans text-sm text-sky.cloudy">
              <Trans>Play the media at {sourceName}</Trans>
            </span>
          </div>
        ) : (
          <video
            className={cn('mb-3 max-h-[22.5rem] w-full bg-black', { 'mt-0': !summaryText })}
            // @ts-expect-error: Muted so we could enable TS strict mode
            poster={thumbnail || articleVideoPosterUrl[shownVideo.id === 0 && shownVideo.id]?.url}
            onPlay={handlePlayVideo}
            preload="metadata"
            // @ts-expect-error: Muted so we could enable TS strict mode
            ref={mediaElementRef}
            controls
          >
            <source src={shownVideo.url} type="video/mp4" />
            <Trans>Your browser does not support the video tag.</Trans>
          </video>
        )}

        {articlemedia.length > 1 && (
          <div className="flex w-full flex-wrap">
            {articlemedia?.map((videoData, i) => (
              <div
                key={i}
                className={cn(
                  'mb-1.5 mr-1.5 flex h-20 w-28 items-start justify-center bg-black first:ml-0.5',
                  shownVideo.id === i &&
                    'outline-2 outline-sand.3 hover:cursor-pointer hover:outline-2 hover:outline-sand.3',
                )}
                onClick={() => handleShownVideoDataChange({ id: i, url: config.url.app(videoData.localurl || '') })}
              >
                <img className="m-0 max-h-20 w-auto max-w-full" src={config.url.app(videoData.thumbnail || '')} />
                {getFilteredMatchesLength(i) > 0 && (
                  <div className="absolute flex h-20 w-28 items-start">
                    <div className="flex h-6 items-center justify-center rounded-[0_0_3px_3px] bg-white">
                      <StarRegular fontSize={16} />
                      <span className="ml-1 text-xs">{getFilteredMatchesLength(i)}</span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <MatchList
        isSocial={isSocial}
        first_source={first_source}
        articlemedia={articlemedia}
        mediaMatches={mediaMatches}
        shownVideo={shownVideo}
        onClick={handlePlayAtSpecificTime}
        headerText={headerText}
      />
    </>
  )
}

export default ArticleMedia
