import { Trans } from 'react-i18next'
import { Button } from '@opoint/infomedia-storybook'

import imgUrl from '../../images/errorRobotAnimated.gif'
import Logo from '../../components/common/Logo'
import useIsInsiders, { useLeaveInsiders } from '../../components/hooks/useIsInsiders'

const ServiceUnavailableErrorPage = ({ statusCode }: { statusCode: number }) => {
  const isInsiders = useIsInsiders()
  const handleLeaveInsiders = useLeaveInsiders()

  return (
    <div className="flex size-full flex-col justify-center">
      <div className="flex justify-center pt-16">
        <Logo />
      </div>

      <div className="flex size-full flex-col items-center justify-center gap-4 text-center">
        <img src={imgUrl} width={200} alt="Error robot image" />
        <div>
          <p className={'mb-1 mt-6 text-heading-1 font-bold text-sky.1'}>
            <Trans>We are currently down</Trans>
          </p>
          <p className="max-w-sm text-center text-sky.1">
            <Trans>Our service is currently unavailable. We are as shocked as you are, but our developers are working like crazy to resolve the issue. Please try again later.</Trans>
          </p>
        </div>
        <div>
          <p className="mb-1 text-heading-2">
            <Trans>Service unavailable</Trans>
          </p>
          <p className="text-sm text-sky.cloudy">
            <Trans>Error code: {statusCode}</Trans>
          </p>
        </div>
        {!isInsiders && (
          <Button variant="text" onClick={handleLeaveInsiders}>
            <Trans>Leave insiders</Trans>
          </Button>
        )}
      </div>
    </div>
  )
}

export default ServiceUnavailableErrorPage
