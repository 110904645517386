/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Infomedia API
 * OpenAPI spec version: 1.0.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  InviteDirectListParams,
  InviteDirectPostRequest,
  InviteDirectPostResponse,
  MultiUserInvitation,
  MultiUserInvitePaginated,
  PatchedMultiUserInvitation,
  SingleUserInvitation,
  SingleUserInvitePaginated,
} from '../opoint.schemas'
import { useCustomAxiosInstance } from '.././custom-client'
import type { ErrorType } from '.././custom-client'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

/**
 * Get all email invitations for requesting user
 */
const useInviteDirectListHook = () => {
  const inviteDirectList = useCustomAxiosInstance<SingleUserInvitePaginated>()

  return useCallback(
    (params?: InviteDirectListParams) => {
      return inviteDirectList({ url: `/invite/direct/`, method: 'GET', params })
    },
    [inviteDirectList],
  )
}

export const getInviteDirectListQueryKey = (params?: InviteDirectListParams) => {
  return [`/invite/direct/`, ...(params ? [params] : [])] as const
}

export const useInviteDirectListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>,
  TError = ErrorType<void>,
>(
  params?: InviteDirectListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInviteDirectListQueryKey(params)

  const inviteDirectList = useInviteDirectListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>> = ({ pageParam }) =>
    //@ts-expect-error : wrong schema
    inviteDirectList({ ...params, page: pageParam || params?.['page'] })

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InviteDirectListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>
>
export type InviteDirectListInfiniteQueryError = ErrorType<void>

export const useInviteDirectListInfinite = <
  TData = Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>,
  TError = ErrorType<void>,
>(
  params?: InviteDirectListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>, TError, TData>
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useInviteDirectListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useInviteDirectListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>,
  TError = ErrorType<void>,
>(
  params?: InviteDirectListParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInviteDirectListQueryKey(params)

  const inviteDirectList = useInviteDirectListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>> = () =>
    inviteDirectList(params)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InviteDirectListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>>
export type InviteDirectListQueryError = ErrorType<void>

export const useInviteDirectList = <
  TData = Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>,
  TError = ErrorType<void>,
>(
  params?: InviteDirectListParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useInviteDirectListHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useInviteDirectListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create a new email invitation and send it by email
 */
export const useInviteDirectCreateHook = () => {
  const inviteDirectCreate = useCustomAxiosInstance<InviteDirectPostResponse>()

  return useCallback(
    (inviteDirectPostRequest: InviteDirectPostRequest) => {
      return inviteDirectCreate({
        url: `/invite/direct/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: inviteDirectPostRequest,
      })
    },
    [inviteDirectCreate],
  )
}

export const useInviteDirectCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectCreateHook>>>,
    TError,
    { data: InviteDirectPostRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useInviteDirectCreateHook>>>,
  TError,
  { data: InviteDirectPostRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const inviteDirectCreate = useInviteDirectCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectCreateHook>>>,
    { data: InviteDirectPostRequest }
  > = (props) => {
    const { data } = props ?? {}

    return inviteDirectCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type InviteDirectCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useInviteDirectCreateHook>>>
>
export type InviteDirectCreateMutationBody = InviteDirectPostRequest
export type InviteDirectCreateMutationError = ErrorType<void>

export const useInviteDirectCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectCreateHook>>>,
    TError,
    { data: InviteDirectPostRequest },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useInviteDirectCreateHook>>>,
  TError,
  { data: InviteDirectPostRequest },
  TContext
> => {
  const mutationOptions = useInviteDirectCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Get a specific email invitation with a id
 */
export const useInviteDirectRetrieveHook = () => {
  const inviteDirectRetrieve = useCustomAxiosInstance<SingleUserInvitation>()

  return useCallback(
    (token: string, signal?: AbortSignal) => {
      return inviteDirectRetrieve({ url: `/invite/direct/${token}/`, method: 'GET', signal })
    },
    [inviteDirectRetrieve],
  )
}

export const getInviteDirectRetrieveQueryKey = (token: string) => {
  return [`/invite/direct/${token}/`] as const
}

export const useInviteDirectRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useInviteDirectRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  token: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useInviteDirectRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInviteDirectRetrieveQueryKey(token)

  const inviteDirectRetrieve = useInviteDirectRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useInviteDirectRetrieveHook>>>> = ({ signal }) =>
    inviteDirectRetrieve(token, signal)

  return { queryKey, queryFn, enabled: !!token, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InviteDirectRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useInviteDirectRetrieveHook>>>
>
export type InviteDirectRetrieveQueryError = ErrorType<void>

export const useInviteDirectRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useInviteDirectRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  token: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useInviteDirectRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useInviteDirectRetrieveQueryOptions(token, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Deleting an pending, failed or expired email invitation. Accepted invites can't be deleted
Don't forget the trailing slash
 */
export const useInviteDirectDestroyHook = () => {
  const inviteDirectDestroy = useCustomAxiosInstance<void>()

  return useCallback(
    (token: string) => {
      return inviteDirectDestroy({ url: `/invite/direct/${token}/`, method: 'DELETE' })
    },
    [inviteDirectDestroy],
  )
}

export const useInviteDirectDestroyMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectDestroyHook>>>,
    TError,
    { token: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useInviteDirectDestroyHook>>>,
  TError,
  { token: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const inviteDirectDestroy = useInviteDirectDestroyHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectDestroyHook>>>,
    { token: string }
  > = (props) => {
    const { token } = props ?? {}

    return inviteDirectDestroy(token)
  }

  return { mutationFn, ...mutationOptions }
}

export type InviteDirectDestroyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useInviteDirectDestroyHook>>>
>

export type InviteDirectDestroyMutationError = ErrorType<void>

export const useInviteDirectDestroy = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectDestroyHook>>>,
    TError,
    { token: string },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useInviteDirectDestroyHook>>>,
  TError,
  { token: string },
  TContext
> => {
  const mutationOptions = useInviteDirectDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Accept an email invite and create a new user
 */
export const useInviteDirectAcceptCreateHook = () => {
  const inviteDirectAcceptCreate = useCustomAxiosInstance<SingleUserInvitation>()

  return useCallback(
    (token: string, singleUserInvitation: NonReadonly<SingleUserInvitation>) => {
      return inviteDirectAcceptCreate({
        url: `/invite/direct/${token}/accept/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: singleUserInvitation,
      })
    },
    [inviteDirectAcceptCreate],
  )
}

export const useInviteDirectAcceptCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectAcceptCreateHook>>>,
    TError,
    { token: string; data: NonReadonly<SingleUserInvitation> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useInviteDirectAcceptCreateHook>>>,
  TError,
  { token: string; data: NonReadonly<SingleUserInvitation> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const inviteDirectAcceptCreate = useInviteDirectAcceptCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectAcceptCreateHook>>>,
    { token: string; data: NonReadonly<SingleUserInvitation> }
  > = (props) => {
    const { token, data } = props ?? {}

    return inviteDirectAcceptCreate(token, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type InviteDirectAcceptCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useInviteDirectAcceptCreateHook>>>
>
export type InviteDirectAcceptCreateMutationBody = NonReadonly<SingleUserInvitation>
export type InviteDirectAcceptCreateMutationError = ErrorType<unknown>

export const useInviteDirectAcceptCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteDirectAcceptCreateHook>>>,
    TError,
    { token: string; data: NonReadonly<SingleUserInvitation> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useInviteDirectAcceptCreateHook>>>,
  TError,
  { token: string; data: NonReadonly<SingleUserInvitation> },
  TContext
> => {
  const mutationOptions = useInviteDirectAcceptCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Get all link invitations for requesting user
 */
export const useInviteOpenListHook = () => {
  const inviteOpenList = useCustomAxiosInstance<MultiUserInvitePaginated>()

  return useCallback(
    (signal?: AbortSignal) => {
      return inviteOpenList({ url: `/invite/open/`, method: 'GET', signal })
    },
    [inviteOpenList],
  )
}

export const getInviteOpenListQueryKey = () => {
  return [`/invite/open/`] as const
}

export const useInviteOpenListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useInviteOpenListHook>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useInviteOpenListHook>>>, TError, TData>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInviteOpenListQueryKey()

  const inviteOpenList = useInviteOpenListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useInviteOpenListHook>>>> = ({ signal }) =>
    inviteOpenList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InviteOpenListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInviteOpenListHook>>>>
export type InviteOpenListQueryError = ErrorType<void>

export const useInviteOpenList = <
  TData = Awaited<ReturnType<ReturnType<typeof useInviteOpenListHook>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useInviteOpenListHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useInviteOpenListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create a new link invitation
 */
export const useInviteOpenCreateHook = () => {
  const inviteOpenCreate = useCustomAxiosInstance<void>()

  return useCallback(
    (multiUserInvitation: NonReadonly<MultiUserInvitation>) => {
      return inviteOpenCreate({
        url: `/invite/open/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: multiUserInvitation,
      })
    },
    [inviteOpenCreate],
  )
}

export const useInviteOpenCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenCreateHook>>>,
    TError,
    { data: NonReadonly<MultiUserInvitation> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenCreateHook>>>,
  TError,
  { data: NonReadonly<MultiUserInvitation> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const inviteOpenCreate = useInviteOpenCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenCreateHook>>>,
    { data: NonReadonly<MultiUserInvitation> }
  > = (props) => {
    const { data } = props ?? {}

    return inviteOpenCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type InviteOpenCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenCreateHook>>>
>
export type InviteOpenCreateMutationBody = NonReadonly<MultiUserInvitation>
export type InviteOpenCreateMutationError = ErrorType<void>

export const useInviteOpenCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenCreateHook>>>,
    TError,
    { data: NonReadonly<MultiUserInvitation> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenCreateHook>>>,
  TError,
  { data: NonReadonly<MultiUserInvitation> },
  TContext
> => {
  const mutationOptions = useInviteOpenCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Get a specific link invitation with a id
 */
export const useInviteOpenRetrieveHook = () => {
  const inviteOpenRetrieve = useCustomAxiosInstance<MultiUserInvitation>()

  return useCallback(
    (token: string, signal?: AbortSignal) => {
      return inviteOpenRetrieve({ url: `/invite/open/${token}/`, method: 'GET', signal })
    },
    [inviteOpenRetrieve],
  )
}

export const getInviteOpenRetrieveQueryKey = (token: string) => {
  return [`/invite/open/${token}/`] as const
}

export const useInviteOpenRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useInviteOpenRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  token: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useInviteOpenRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInviteOpenRetrieveQueryKey(token)

  const inviteOpenRetrieve = useInviteOpenRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useInviteOpenRetrieveHook>>>> = ({ signal }) =>
    inviteOpenRetrieve(token, signal)

  return { queryKey, queryFn, enabled: !!token, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InviteOpenRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenRetrieveHook>>>
>
export type InviteOpenRetrieveQueryError = ErrorType<void>

export const useInviteOpenRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useInviteOpenRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  token: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useInviteOpenRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useInviteOpenRetrieveQueryOptions(token, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useInviteOpenUpdateHook = () => {
  const inviteOpenUpdate = useCustomAxiosInstance<MultiUserInvitation>()

  return useCallback(
    (token: string, multiUserInvitation: NonReadonly<MultiUserInvitation>) => {
      return inviteOpenUpdate({
        url: `/invite/open/${token}/`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: multiUserInvitation,
      })
    },
    [inviteOpenUpdate],
  )
}

export const useInviteOpenUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenUpdateHook>>>,
    TError,
    { token: string; data: NonReadonly<MultiUserInvitation> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenUpdateHook>>>,
  TError,
  { token: string; data: NonReadonly<MultiUserInvitation> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const inviteOpenUpdate = useInviteOpenUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenUpdateHook>>>,
    { token: string; data: NonReadonly<MultiUserInvitation> }
  > = (props) => {
    const { token, data } = props ?? {}

    return inviteOpenUpdate(token, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type InviteOpenUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenUpdateHook>>>
>
export type InviteOpenUpdateMutationBody = NonReadonly<MultiUserInvitation>
export type InviteOpenUpdateMutationError = ErrorType<unknown>

export const useInviteOpenUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenUpdateHook>>>,
    TError,
    { token: string; data: NonReadonly<MultiUserInvitation> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenUpdateHook>>>,
  TError,
  { token: string; data: NonReadonly<MultiUserInvitation> },
  TContext
> => {
  const mutationOptions = useInviteOpenUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const useInviteOpenPartialUpdateHook = () => {
  const inviteOpenPartialUpdate = useCustomAxiosInstance<MultiUserInvitation>()

  return useCallback(
    (token: string, patchedMultiUserInvitation: NonReadonly<PatchedMultiUserInvitation>) => {
      return inviteOpenPartialUpdate({
        url: `/invite/open/${token}/`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: patchedMultiUserInvitation,
      })
    },
    [inviteOpenPartialUpdate],
  )
}

export const useInviteOpenPartialUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenPartialUpdateHook>>>,
    TError,
    { token: string; data: NonReadonly<PatchedMultiUserInvitation> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenPartialUpdateHook>>>,
  TError,
  { token: string; data: NonReadonly<PatchedMultiUserInvitation> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const inviteOpenPartialUpdate = useInviteOpenPartialUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenPartialUpdateHook>>>,
    { token: string; data: NonReadonly<PatchedMultiUserInvitation> }
  > = (props) => {
    const { token, data } = props ?? {}

    return inviteOpenPartialUpdate(token, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type InviteOpenPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenPartialUpdateHook>>>
>
export type InviteOpenPartialUpdateMutationBody = NonReadonly<PatchedMultiUserInvitation>
export type InviteOpenPartialUpdateMutationError = ErrorType<unknown>

export const useInviteOpenPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenPartialUpdateHook>>>,
    TError,
    { token: string; data: NonReadonly<PatchedMultiUserInvitation> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenPartialUpdateHook>>>,
  TError,
  { token: string; data: NonReadonly<PatchedMultiUserInvitation> },
  TContext
> => {
  const mutationOptions = useInviteOpenPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const useInviteOpenDestroyHook = () => {
  const inviteOpenDestroy = useCustomAxiosInstance<void>()

  return useCallback(
    (token: string) => {
      return inviteOpenDestroy({ url: `/invite/open/${token}/`, method: 'DELETE' })
    },
    [inviteOpenDestroy],
  )
}

export const useInviteOpenDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenDestroyHook>>>,
    TError,
    { token: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenDestroyHook>>>,
  TError,
  { token: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const inviteOpenDestroy = useInviteOpenDestroyHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenDestroyHook>>>,
    { token: string }
  > = (props) => {
    const { token } = props ?? {}

    return inviteOpenDestroy(token)
  }

  return { mutationFn, ...mutationOptions }
}

export type InviteOpenDestroyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenDestroyHook>>>
>

export type InviteOpenDestroyMutationError = ErrorType<unknown>

export const useInviteOpenDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenDestroyHook>>>,
    TError,
    { token: string },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenDestroyHook>>>,
  TError,
  { token: string },
  TContext
> => {
  const mutationOptions = useInviteOpenDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Accept a link invitation
 */
export const useInviteOpenAcceptCreateHook = () => {
  const inviteOpenAcceptCreate = useCustomAxiosInstance<MultiUserInvitation>()

  return useCallback(
    (token: string, multiUserInvitation: NonReadonly<MultiUserInvitation>) => {
      return inviteOpenAcceptCreate({
        url: `/invite/open/${token}/accept/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: multiUserInvitation,
      })
    },
    [inviteOpenAcceptCreate],
  )
}

export const useInviteOpenAcceptCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenAcceptCreateHook>>>,
    TError,
    { token: string; data: NonReadonly<MultiUserInvitation> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenAcceptCreateHook>>>,
  TError,
  { token: string; data: NonReadonly<MultiUserInvitation> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const inviteOpenAcceptCreate = useInviteOpenAcceptCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenAcceptCreateHook>>>,
    { token: string; data: NonReadonly<MultiUserInvitation> }
  > = (props) => {
    const { token, data } = props ?? {}

    return inviteOpenAcceptCreate(token, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type InviteOpenAcceptCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenAcceptCreateHook>>>
>
export type InviteOpenAcceptCreateMutationBody = NonReadonly<MultiUserInvitation>
export type InviteOpenAcceptCreateMutationError = ErrorType<unknown>

export const useInviteOpenAcceptCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInviteOpenAcceptCreateHook>>>,
    TError,
    { token: string; data: NonReadonly<MultiUserInvitation> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useInviteOpenAcceptCreateHook>>>,
  TError,
  { token: string; data: NonReadonly<MultiUserInvitation> },
  TContext
> => {
  const mutationOptions = useInviteOpenAcceptCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
