import { ReactNode, createContext, useContext, useMemo, useState } from 'react'

type NavigationContextValue = {
  searchQuery: string
  setSearchQuery: (searchQuery: string) => void
}

// @ts-expect-error: Muted so we could enable TS strict mode
const NavigationContext = createContext<NavigationContextValue>(undefined)

const NavigationContextProvider = ({ children }: { children: ReactNode }) => {
  const [searchQuery, setSearchQuery] = useState<string>('')

  const contextValue = useMemo<NavigationContextValue>(
    () => ({
      searchQuery,
      setSearchQuery,
    }),
    [searchQuery],
  )

  return <NavigationContext.Provider value={contextValue}>{children}</NavigationContext.Provider>
}

const useNavigationContext = (): NavigationContextValue => {
  const context = useContext(NavigationContext)

  if (!context) {
    throw new Error('useNavigation must be used within NavigationContext')
  }

  return context
}

export { type NavigationContextValue, useNavigationContext, NavigationContext, NavigationContextProvider }
