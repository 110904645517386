import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react'

import { cn } from '@opoint/infomedia-storybook'
import type { IconName } from '../../types/theme'
import Icon from '../Icon'

export type ButtonProps = {
  children?: ReactNode
  className?: string
  icon?: IconName
  label?: string
  name: 'default' | 'solid' | 'text' | 'link'
  onClick?: (e?: any) => void
} & ButtonHTMLAttributes<HTMLButtonElement>

/**
 * @deprecated Use the Storybook variant instead.
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, icon, label, name, onClick, type = 'button', ...rest }, ref) => (
    <button
      aria-label={!children ? label : ''}
      ref={ref}
      className={cn(
        'flex h-8 cursor-pointer items-center justify-center rounded px-4 py-1.5 text-sm focus-visible:shadow-none',
        {
          'bg-white text-sky.1 fill-sky.1 border border-sky.1 h-auto hover:border-sky.cloudy focus:border-sky.cloudy':
            name === 'default',
        },
        {
          'text-white fill-white bg-sky.1 border-sky.1 border [&>svg]:fill-white hover:opacity-90 hover:shadow-sm focus:shadow-sm focus:opacity-90':
            name === 'solid',
        },
        {
          'bg-none border-0 text-sky.4 fill-sky.4 no-underline [&>span]:align-middle hover:text-sky.cloudy':
            name === 'text',
        },
        {
          'bg-none border-0 text-sky.1 fill-sky.1 [&>span]:align-middle hover:text-sky.cloudy hover:fill-sand.3 focus:text-send.4 focus:fill-send.4':
            name === 'link',
        },
        { 'opacity-50 cursor-not-allowed': rest.disabled },
        {
          'text-2xl leading-[0px] py-0 px-1': icon && !children,
          'flex flex-row items-center': icon && children,
        },
        className,
      )}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick={onClick || (() => {})}
      type={type}
      {...rest}
    >
      {icon && <Icon name={icon} isDecorative={true} />}
      {children}
    </button>
  ),
)

Button.displayName = 'Button'

export default Button
