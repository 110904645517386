import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { combineLatest, forkJoin, from, of } from 'rxjs'
import { catchError, delay, map as rxMap, mapTo, switchMap, take } from 'rxjs/operators'

import { assoc, groupBy, map, prop } from 'ramda'
import { AppActions } from '../actions'
import { ImpersonateSuccessAction, LogInSuccessAction } from '../actions/auth'
import {
  CreateFolderAction,
  CreateFolderFailureAction,
  CreateFoldersTreeSuccessAction,
  CreateFolderSuccessAction,
  DeleteFolderAction,
  DeleteFolderFailureAction,
  DeleteFolderSuccessAction,
  EditFolderAction,
  EditFolderFailureAction,
  EditFolderSuccessAction,
  FetchFoldersAction,
  FetchFoldersFailureAction,
  FetchFolderSharesAction,
  FetchFolderSharesFailureAction,
  FetchFoldersSuccessAction,
  FetchSpecificFolderAction,
  FetchSpecificFolderFailureAction,
  FetchSpecificFolderSuccessAction,
  ReorderFolderAction,
  ReorderFolderFailureAction,
  ReorderFolderSuccessAction,
} from '../actions/folders'
import { ProfileEditorSaveProfileSuccessAction, ProfilesFetchSuccessAction } from '../actions/profiles'
import { SettingsFetchSuccessAction } from '../actions/settings'
import { StatisticsViewDeleteSuccessAction, StatisticsViewFetchSuccessAction } from '../actions/statistics'
import { AddTagSuccessAction, DeleteTagSuccessAction, TagsFetchSuccessAction } from '../actions/tags'
import { Folder, FolderType } from '../components/types/folder'
import { Profile } from '../components/types/profile'
import { isECBUser } from '../helpers/auth'
import { handleEditChanges } from '../helpers/common'
import config from '../opoint/common/config'
import { StatisticsView } from '../opoint/flow'
import {
  createNewFolder,
  deleteFolder,
  getFolders,
  getFolderShares,
  getSpecificFolder,
  reorderFolderPosition,
  updateFolder,
} from '../opoint/folders'
import { RootState } from '../reducers'
import { getAllFolders, getChosenFolder, getFolderFormValues } from '../selectors/foldersSelectors'
import { getProfiles } from '../selectors/profilesSelectors'
import { getStatisticsList } from '../selectors/statisticsSelectors'
import { getTags } from '../selectors/tagsSelectors'

import { Tag } from '../components/types/tag'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

//TODO: replace with useFolderTree hook?
export const buildFoldersTree = (
  folders: Array<Folder>,
  profiles: Array<Profile>,
  tags: Array<Tag>,
  statistics: Array<StatisticsView>,
): Array<Folder> => {
  const foldersWithChildren = folders?.map((folder) => {
    let items
    switch (folder.type) {
      case FolderType.PROFILES:
        items = profiles
        break
      case FolderType.TAGS:
      case FolderType.SPECIAL_TAGS:
        items = tags
        break
      case FolderType.STATISTICS:
        items = statistics
        break
    }

    const filteredItems = items
      .filter((item) => item.folder === folder.id && item.type === folder.type)
      .map((item) => ({ ...item })) // Create a new object for each item, avoids state mutation

    // @ts-expect-error: Muted so we could enable TS strict mode
    const parentTree = groupBy(prop('parent'), filteredItems)
    const rootProfiles = parentTree[0]
    const setChildrenForProfile = (profile) =>
      assoc('children', (parentTree[profile.id] || [])?.map(setChildrenForProfile), profile)

    return {
      ...folder,
      children: rootProfiles ? map(setChildrenForProfile)(rootProfiles) : filteredItems,
    }
  })

  return foldersWithChildren
}

const fetchFoldersOnLogIn = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, LogInSuccessAction | ImpersonateSuccessAction>('LOG_IN_SUCCESS', 'IMPERSONATE_SUCCESS'),
    mapTo({ type: 'FETCH_FOLDERS' } as FetchFoldersAction),
  )

const fetchFoldersOnEditAndCreationEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<
      AppActions,
      CreateFolderSuccessAction | EditFolderSuccessAction | ReorderFolderSuccessAction | DeleteFolderSuccessAction
    >('CREATE_FOLDER_SUCCESS', 'EDIT_FOLDER_SUCCESS', 'REORDER_FOLDER_SUCCESS', 'DELETE_FOLDER_SUCCESS'),
    mapTo({ type: 'FETCH_FOLDERS' } as FetchFoldersAction),
  )

const foldersFetchEpic: (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$) =>
  combineLatest([
    action$.pipe(ofType<AppActions, FetchFoldersAction>('FETCH_FOLDERS')),
    action$.pipe(ofType<AppActions, SettingsFetchSuccessAction>('SETTINGS_FETCH_SUCCESS'), take(1)),
  ]).pipe(
    switchMap(() =>
      forkJoin({
        // @ts-expect-error: Muted so we could enable TS strict mode
        user: from(config.auth?.getUser()),
        folders: from(getFolders()),
      }).pipe(
        switchMap(({ user, folders }) => {
          return of<FetchFoldersSuccessAction>({
            type: 'FETCH_FOLDERS_SUCCESS',
            payload: { isECBUser: isECBUser(user), folders },
          })
        }),
      ),
    ),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<FetchFoldersFailureAction>({ type: 'FETCH_FOLDERS_FAILURE' })),
  )

const createFoldersTreeEpic: (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$, { state$ }) =>
  action$.pipe(
    ofType<
      AppActions,
      | TagsFetchSuccessAction
      | ProfileEditorSaveProfileSuccessAction
      | ProfilesFetchSuccessAction
      | AddTagSuccessAction
      | FetchFoldersSuccessAction
      | DeleteTagSuccessAction
      | StatisticsViewFetchSuccessAction
      | StatisticsViewDeleteSuccessAction
    >(
      'TAGS_FETCH_SUCCESS',
      'PROFILE_EDITOR_SAVE_PROFILE_SUCCESS',
      'PROFILES_FETCH_SUCCESS',
      'ADD_TAG_SUCCESS',
      'DELETE_TAG_SUCCESS',
      'STATISTIC_VIEWS_FETCH_SUCCESS',
      'STATISTICS_VIEW_DELETE_SUCCESS',
      'FETCH_FOLDERS_SUCCESS',
    ),
    delay(1000),
    switchMap(() => {
      const state = state$.value
      const tagsList = getTags(state)
      const profilesList = getProfiles(state)
      const foldersList = getAllFolders(state)
      const statisticsList = getStatisticsList(state)

      const groupsTreeWithChildren = buildFoldersTree(foldersList, profilesList, tagsList, statisticsList)

      return of<CreateFoldersTreeSuccessAction>({
        type: 'CREATE_FOLDERS_TREE_SUCCESS',
        payload: groupsTreeWithChildren,
      })
    }),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
  )

const createFolderEpic: (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$, { state$ }) =>
  action$.pipe(
    ofType<AppActions, CreateFolderAction>('CREATE_FOLDER'),
    switchMap(() => {
      const state = state$.value
      const formValues = getFolderFormValues(state)

      return from(createNewFolder(formValues)).pipe(
        switchMap((folder) =>
          of<CreateFolderSuccessAction | FetchSpecificFolderSuccessAction>(
            { type: 'CREATE_FOLDER_SUCCESS' },
            { type: 'FETCH_SPECIFIC_FOLDER_SUCCESS', payload: folder },
          ),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<CreateFolderFailureAction>({ type: 'CREATE_FOLDER_FAILURE' })),
      )
    }),
  )

const fetchSpecificFolderEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, FetchSpecificFolderAction>('FETCH_SPECIFIC_FOLDER'),
    switchMap(({ payload }) => {
      const { id } = payload

      return from(getSpecificFolder(id)).pipe(
        rxMap((folder) => ({ type: 'FETCH_SPECIFIC_FOLDER_SUCCESS', payload: folder })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<FetchSpecificFolderFailureAction>({ type: 'FETCH_SPECIFIC_FOLDER_FAILURE' })),
      )
    }),
  )

const updateFolderEpic: (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$, { state$ }) =>
  action$.pipe(
    ofType<AppActions, EditFolderAction>('EDIT_FOLDER'),
    switchMap(() => {
      const state = state$.value
      const formValues = getFolderFormValues(state)
      const chosenFolder = getChosenFolder(state)
      const chosenFolderId = chosenFolder?.id

      const changes = handleEditChanges({ oldValues: chosenFolder, newValues: formValues })

      return from(updateFolder({ id: chosenFolderId, body: { ...changes } })).pipe(
        switchMap((folder) =>
          of<FetchSpecificFolderSuccessAction | EditFolderSuccessAction>(
            { type: 'FETCH_SPECIFIC_FOLDER_SUCCESS', payload: folder },
            { type: 'EDIT_FOLDER_SUCCESS' },
          ),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<EditFolderFailureAction>({ type: 'EDIT_FOLDER_FAILURE' })),
      )
    }),
  )

const reorderFolderEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ReorderFolderAction>('REORDER_FOLDER'),
    switchMap(({ payload }) =>
      from(reorderFolderPosition(payload)).pipe(
        rxMap(() => ({ type: 'REORDER_FOLDER_SUCCESS' } as ReorderFolderSuccessAction)),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<ReorderFolderFailureAction>({ type: 'REORDER_FOLDER_FAILURE' })),
      ),
    ),
  )

const deleteFolderEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, DeleteFolderAction>('DELETE_FOLDER'),
    switchMap(({ payload }) =>
      from(deleteFolder(payload)).pipe(
        rxMap(() => ({ type: 'DELETE_FOLDER_SUCCESS' } as DeleteFolderSuccessAction)),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<DeleteFolderFailureAction>({ type: 'DELETE_FOLDER_FAILURE' })),
      ),
    ),
  )

const fetchFolderSharesEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, FetchFolderSharesAction>('FETCH_FOLDER_SHARES'),
    switchMap(({ payload }) =>
      from(getFolderShares(payload)).pipe(
        rxMap((users) => ({ type: 'FETCH_FOLDER_SHARES_SUCCESS', payload: users })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<FetchFolderSharesFailureAction>({ type: 'FETCH_FOLDER_SHARES_FAILURE' })),
      ),
    ),
  )

export default [
  fetchFoldersOnLogIn,
  foldersFetchEpic,
  createFoldersTreeEpic,
  createFolderEpic,
  fetchSpecificFolderEpic,
  updateFolderEpic,
  reorderFolderEpic,
  deleteFolderEpic,
  fetchFoldersOnEditAndCreationEpic,
  fetchFolderSharesEpic,
]
