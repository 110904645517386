import { createSelector } from 'reselect'

import { getFolders as getState } from './rootSelectors'

export const sentimentFolderId = 1000000041

export const getAllFolders = createSelector(getState, (foldersState) => foldersState.folders)

export const getFoldersTree = createSelector(getState, (foldersState) => foldersState.foldersTree)

export const getSentimentFolder = createSelector(getState, (foldersState) =>
  foldersState.foldersTree.find(({ id }) => id === sentimentFolderId),
)

export const getChosenFolder = createSelector(getState, (foldersState) => foldersState.chosenFolder)

export const getFolderFormValues = createSelector(getState, (foldersState) => foldersState.formValues)

export const getShowFolderContent = createSelector(getState, (foldersState) => foldersState.showFolderContent)

export const getShareableUsers = createSelector(getState, (foldersState) => foldersState.shareableUsers)

export const getUserSearchLoading = createSelector(getState, (foldersState) => foldersState.userSearchLoading)
