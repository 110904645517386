import { useOpointAuthState, useOpointUser } from '@opoint/authjs-react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  cn,
} from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'

import { getImpersonationOrganizations } from '../../../selectors/impersonationSelectors'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'

const CustomerViewOverflow = () => {
  const authState = useOpointAuthState()
  const impersonationOrganizations = useAppSelector(getImpersonationOrganizations)
  const user = useOpointUser()

  const dispatch = useAppDispatch()

  // @ts-expect-error: Muted so we could enable TS strict mode
  const isImpersonationActive = !!authState.impersonating
  // @ts-expect-error: Muted so we could enable TS strict mode
  const isImpersonatingOrg = impersonationOrganizations.some((org) => org.id_user === user.user_id)

  if (!isImpersonationActive || isImpersonatingOrg || !user) {
    return null
  }

  const endImpersonation = () => dispatch({ type: 'END_IMPERSONATION' })

  return (
    <div
      className={cn('absolute bottom-3 right-3 z-50 rounded-sm border border-grey.7 bg-grey.8 p-3 shadow-md', {
        'bottom-20': document.location.hostname.startsWith('insiders'),
      })}
    >
      <p className="mb-1 text-sm font-semibold">
        <Trans values={{username: user.username}}>{'Viewing as: {{username}}'}</Trans>
      </p>

      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button variant="text" className="h-5 p-0 text-xs">
            <Trans>End customer view</Trans>
          </Button>
        </AlertDialogTrigger>

        <AlertDialogContent className="max-w-[40rem] break-all">
          <AlertDialogHeader>
            <AlertDialogTitle>
              <Trans values={{username: user.username}}>{'Do you want to end customer view for {{username}}?'}</Trans>
            </AlertDialogTitle>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogCancel>
              <Trans>Cancel</Trans>
            </AlertDialogCancel>
            <AlertDialogAction onClick={endImpersonation}>
              <Trans>Confirm</Trans>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

export default CustomerViewOverflow
