import { Add16Filled, ChevronRight16Filled } from '@fluentui/react-icons'
import { cn } from '@opoint/infomedia-storybook'

/*
    Filter Separator
 */
export enum Separator {
  none = -1,
  last,
  category,
  filter,
}

type FilterSeparatorProps = {
  separator: Separator
}

const FilterSeparator = (props: FilterSeparatorProps) => {
  switch (props.separator) {
    case Separator.category:
      return (
        <div className="flex items-center justify-center px-1.5">
          <Add16Filled className="text-grey.5" />
        </div>
      )
    case Separator.filter:
    case Separator.last:
      return (
        <div
          className={cn('flex items-center justify-center px-1.5', {
            'pr-0': props.separator === Separator.last,
          })}
        >
          <ChevronRight16Filled className="text-grey.5" />
        </div>
      )
    case Separator.none:
    default:
      return null
  }
}

export default FilterSeparator
