import { isSocialArticle } from '@opoint/infomedia-storybook'
import { articleIdFromIds, getArticleUrl } from '../../../../opoint/articles'
import { BROADCAST_MEDIA_TYPES } from '../../../../opoint/common/constants'
import * as URLService from '../../../../services/urlService'
import { getScreenshotThumbnail } from '../../../helpers/article'
import type { MediaMatch } from '../../../types/article'

import { DocumentArticleimagesArticleimageItem } from '../../../../api/opoint-search-suggest.schemas'
import { M360Article } from '../../../../opoint/articles/types'
import ArticleImage from './ArticleImage'
import ArticleLink from './ArticleLink'
import ArticleMedia from './ArticleMedia'

const getArticleHeroImage = (
  useImageProxy: boolean,
  articleImages?: M360Article['articleimages'],
  socialMeta?: M360Article['social_meta'],
): DocumentArticleimagesArticleimageItem | null => {
  if (!articleImages?.articleimage?.length && !socialMeta?.attachments?.media?.length) {
    return null
  }

  if (articleImages?.articleimage?.length) {
    const image = articleImages.articleimage[0]

    return {
      caption: image.caption,
      url:
        useImageProxy && import.meta.env.DEV
          ? URLService.getSoMeImageProxyUrl(image.url)
          : // @ts-expect-error: Muted so we could enable TS strict mode
            URLService.getFullImageUrl(image.url),
    }
  }

  if (socialMeta?.attachments?.media?.length) {
    const image = socialMeta.attachments.media[0]

    return {
      caption: {
        matches: false,
        text: image?.description,
      },
      url:
        useImageProxy && image.image
          ? import.meta.env.DEV
            ? URLService.getSoMeImageProxyUrl(image.image)
            : URLService.getFullImageUrl(image.image)
          : image.image,
    }
  }

  return null
}

type Props = {
  article: M360Article
  onArticleHeroClick: () => void
  onArticleLinkClick: () => void
}

const ArticleHero = ({ article, onArticleHeroClick, onArticleLinkClick }: Props) => {
  const {
    articleimages,
    articlemedia,
    caption,
    content_protected,
    first_source,
    header,
    id_article,
    id_site,
    matches,
    mediatype,
    orig_url,
    position,
    screenshots,
    social_meta,
    summary,
    url,
  } = article

  const screenshotThumbnail = getScreenshotThumbnail(screenshots)
  const useImageProxy = ['Instagram', 'Twitter']?.includes(first_source.sitename)

  const isBroadcast = BROADCAST_MEDIA_TYPES?.includes(mediatype?.text || '')

  const image = getArticleHeroImage(useImageProxy, articleimages, social_meta)

  if (articlemedia || isBroadcast) {
    return (
      <ArticleMedia
        // @ts-expect-error: Muted so we could enable TS strict mode
        articleVideoPosterUrl={
          isBroadcast
            ? [{ url: `/images/videoposters/${id_site}_${first_source.id}.png` }]
            : image
            ? [{ url: image.url }]
            : []
        }
        articlemedia={articlemedia ?? []}
        id_article={id_article}
        id_site={id_site}
        matches={matches as MediaMatch[]}
        orig_url={orig_url}
        position={position}
        sourceName={first_source.name}
        isSocial={isSocialArticle(article)}
        first_source={first_source}
        // @ts-expect-error: Muted so we could enable TS strict mode
        summaryText={summary.text}
        // @ts-expect-error: Muted so we could enable TS strict mode
        headerText={header.text}
      />
    )
  }

  if (image) {
    return (
      <ArticleImage
        keyVal={`image-${id_article}-${image.url}`}
        editable={false}
        imgSrc={image.url}
        opointImgSrc={URLService.getThumbnailUrl(image.url, 700)}
        caption={image.caption?.text}
        onClick={onArticleHeroClick}
        articleId={articleIdFromIds(article.id_site, article.id_article)}
      />
    )
  }

  if (screenshotThumbnail) {
    return (
      // @ts-expect-error: Muted so we could enable TS strict mode
      <ArticleLink url={getArticleUrl({ content_protected, orig_url, url })} onClick={onArticleLinkClick}>
        <ArticleImage
          articleId={articleIdFromIds(article.id_site, article.id_article)}
          keyVal={`screenshot-${id_article}`}
          editable={false}
          imgSrc={screenshotThumbnail?.text}
          caption={caption?.text}
        />
      </ArticleLink>
    )
  }

  return null
}

export default ArticleHero
