import type { Report } from '../../opoint/flow'
import { handleErrors } from '../common'
import config from '../common/config'

export async function getReportsHistory(id: number): Promise<{
  results: Report[]
  count: number
}> {
  const url = config.url.api(`/reports/?page=${id}`)

  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}
