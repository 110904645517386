import { Rss24Regular } from '@fluentui/react-icons'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { t } from "i18next";
import { NavigationComponentProps, NavigationSectionKey } from '../../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'

import { FeedsNavigationList } from './FeedsNavigationList'

export const FeedsNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => {
    return (
      <NavigationSection>
        <NavigationSectionHeaderLink
          label={t('Feeds')}
          isExpanded={Boolean(isExpanded)}
          ref={ref}
          to={{ pathname: '/feeds' }}
          section={NavigationSectionKey.FEEDS}
          {...props}
        >
          <Rss24Regular className="w-7 shrink-0 text-spring.1" />
        </NavigationSectionHeaderLink>

        <FeedsNavigationList />
      </NavigationSection>
    )
  },
)
FeedsNavigationSection.displayName = 'FeedsNavigationSection'
