import { Mail24Regular } from '@fluentui/react-icons'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import { t } from "i18next";
import { NavigationComponentProps, NavigationSectionKey } from '../../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'
import { AlertsNavigationList } from './AlertsNavigationList'

export const AlertsNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => {
    return (
      <NavigationSection>
        <NavigationSectionHeaderLink
          label={t('Alerts')}
          isExpanded={Boolean(isExpanded)}
          ref={ref}
          to="/alerts"
          section={NavigationSectionKey.ALERTS}
          {...props}
        >
          <Mail24Regular className="w-7 shrink-0 text-berry.4" />
        </NavigationSectionHeaderLink>

        <AlertsNavigationList />
      </NavigationSection>
    )
  },
)

AlertsNavigationSection.displayName = 'AlertsNavigationSection'
