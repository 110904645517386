import { Tag } from '../components/types/tag'

// This function is incorrect, as it in fact returns if the object is GeneralTag
// Consider using isTag function in /components/tags/common instead or move the checking functions here once this is no longer in use
// TODO: Migrate usage of this function and remove it
const isTag = (tag: Tag | { id: number }): tag is Tag => {
  if ((tag as Tag)?.type) {
    return true
  }

  return false
}

export { isTag }
