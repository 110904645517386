import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'
import { t } from "i18next";
import {
  FeedDeleteErrorAction,
  FeedDeleteSuccessAction,
  FeedNewErrorAction,
  FeedNewSuccessAction,
  FeedSaveErrorAction,
  FeedSaveSuccessAction,
  FeedSetActiveFailureAction,
  FeedsActions,
} from '../../../actions/feeds'
import { ErrorEpic, handlerError } from '.'

const feedErrorTypes: ErrorEpic<
  | FeedSaveSuccessAction['type']
  | FeedNewSuccessAction['type']
  | FeedSetActiveFailureAction['type']
  | FeedSaveErrorAction['type']
  | FeedNewErrorAction['type']
  | FeedDeleteSuccessAction['type']
  | FeedDeleteErrorAction['type']
> = {
  ['FEED_SAVE_SUCCESS']: {
    isSuccess: true,
    message: t('Feed successfully saved'),
    toastId: 'FEED_SAVE_SUCCESS',
  },
  ['FEED_NEW_SUCCESS']: {
    isSuccess: true,
    message: t('New feed successfully created'),
    toastId: 'FEED_NEW_SUCCESS',
  },
  ['FEED_SET_ACTIVE_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to select this feed'),
    toastId: 'FEED_SET_ACTIVE_FAILURE',
  },
  ['FEED_SAVE_ERROR']: {
    isSuccess: false,
    message: t('We were unable to save this feed'),
    toastId: 'FEED_SAVE_ERROR',
  },
  ['FEED_NEW_ERROR']: {
    isSuccess: false,
    message: t('We were not able to create new feed'),
    toastId: 'FEED_NEW_ERROR',
  },
  ['FEED_DELETE_SUCCESS']: {
    isSuccess: true,
    message: t('Feed was successfully deleted'),
    toastId: 'FEED_DELETE_SUCCESS',
  },
  ['FEED_DELETE_ERROR']: {
    isSuccess: false,
    message: t('We were unable to delete this feed'),
    toastId: 'FEED_DELETE_ERROR',
  },
}

const feedErrorEpic: (action: ActionsObservable<FeedsActions>) => void = (action$) =>
  action$.pipe(switchMap(({ type }) => handlerError(feedErrorTypes, type)))

export default feedErrorEpic
