import { SearchFilterKey, useSearchFilters, UseSearchFiltersResult } from './useSearchFilters'

export enum SearchRouteBuilderAction {
  Override,
  Toggle,
}

export const searchFilterToString = ({ type, id, inverted }) => `${inverted ? '-' : ''}${type}:${id}`

export const getUrlStringFormatFromSearchFilters = (searchFilters: UseSearchFiltersResult) => {
  const withoutTimePeriod = searchFilters.filter(({ type }) => type !== SearchFilterKey.TIME_PERIOD)
  const filterStrings = withoutTimePeriod.map(searchFilterToString)

  return filterStrings.join(';')
}

const useSearchRouteBuilder = () => {
  const searchFilters = useSearchFilters()

  /**
   * @param {SearchFilterKey} searchFilterKey - The type of search filter being manipulated.
   * @param {SearchRouteBuilderAction} action - The action being performed, which affects how the time period is handled.
   *      `Override` indicates that the existing filters are being completely replaced.
   *      `Toggle` means that the filter specified by `searchFilterKey` might be added or removed based on its current state.
   *
   * @returns {string} A string representing the time period if conditions are met, or an empty string otherwise.
   *
   * @example
   * getTimePeriod(SearchFilterKey.PROFILES, SearchRouteBuilderAction.Toggle);
   *
   * // When no conditions are met or no time period filter is set
   * getTimePeriod(SearchFilterKey.PROFILES, SearchRouteBuilderAction.Override);
   */
  const getTimePeriod = (searchFilterKey: SearchFilterKey, action: SearchRouteBuilderAction) => {
    const isProfileFirstFilter = searchFilters[0]?.type === SearchFilterKey.PROFILES
    const timePeriod = searchFilters.find(({ type }) => type === SearchFilterKey.TIME_PERIOD)
    const override = action === SearchRouteBuilderAction.Override
    const onlyTimePeriodIsSet = searchFilters.length === 1 && timePeriod
    const toggleWithProfile =
      !override &&
      (((searchFilters.length === 0 || onlyTimePeriodIsSet) && searchFilterKey === SearchFilterKey.PROFILES) ||
        isProfileFirstFilter)

    if (toggleWithProfile) {
      return timePeriod && `${searchFilterToString(timePeriod)}`
    }

    return ''
  }

  /**
   * @param {SearchFilterKey} searchFilterKey - The key that identifies the type of search filter to apply.
   * @param {string | number} filterID - The identifier for the filter, which will be converted to a string.
   * @param {SearchRouteBuilderAction} action - Determines the behavior of how filters are applied. The default action is `Override`.
   *      `Override` replaces all current filters with the new filter.
   *      `Toggle` will add the filter if it doesn't exist, or remove it if it does.
   *
   * @returns {string} A string representing the constructed URL which integrates the specified filters and optionally, an expression parameter
   * from the current URL's query string.
   *
   * @example
   * // Returns a URL string that sets or overrides the search filters
   * getSearchRoute(SearchFilterKey.PROFILES, '123', SearchRouteBuilderAction.Override);
   */
  const getSearchRoute = (
    searchFilterKey: SearchFilterKey,
    filterID: string | number,
    action: SearchRouteBuilderAction = SearchRouteBuilderAction.Override,
  ) => {
    const pathname =
      location.pathname.startsWith('/statistics') && searchFilterKey === SearchFilterKey.PROFILES
        ? 'statistics'
        : 'search'

    if (action === SearchRouteBuilderAction.Override) {
      const filter = `${searchFilterKey}:${filterID}`
      const searchString = [filter, getTimePeriod(searchFilterKey, action)].filter((item) => item).join(';')

      return `/${pathname}/?${new URLSearchParams({ filters: searchString }).toString()}`
    }

    const filters = [...searchFilters]
    const filterId = filterID.toString()
    const filterIndex = filters.findIndex(({ id, type }) => id === filterId && type === searchFilterKey)

    if (filterIndex > -1) {
      filters.splice(filterIndex, 1)
    } else {
      filters.push({ type: searchFilterKey, id: filterId, inverted: false })
    }

    const searchString = [getUrlStringFormatFromSearchFilters(filters), getTimePeriod(searchFilterKey, action)]
      .filter((item) => item)
      .join(';')

    const params = new URLSearchParams()

    if (searchString.length) {
      params.append('filters', searchString)
    }

    return `/${pathname}/?${params.toString()}`
  }

  const getProfileEditorRoute = (profileId: number) => {
    if (location.pathname.includes('/profiles')) {
      return `/profiles/${profileId}`
    }

    if (location.pathname.includes('/search/edit') || location.pathname.includes('/search/new')) {
      return `/search/edit/?filters=profile:${profileId}`
    }

    return getSearchRoute(SearchFilterKey.PROFILES, profileId)
  }

  return { getSearchRoute, getProfileEditorRoute }
}

export default useSearchRouteBuilder
