import { produce } from 'immer'
import { AppActions } from '../actions'
import { FilterSuggestion } from '../api/opoint-search-suggest.schemas'
import { UpdatedSentimentType, manualToUpdatedSentimentMapping } from '../components/types/article'
import { articleIdFromIds } from '../opoint/articles'
import type { SentimentGroup } from '../opoint/flow'

export type SentimentState = {
  sentimentGroups: SentimentGroup[]
  // We use this to update an article's company sentiment without refetching the article
  // TODO: could we update the company sentiment in the article state instead?
  updatedCompanySentiments: Record<string, UpdatedSentimentType>
  suggestions: FilterSuggestion[]
}

export const initialState: SentimentState = {
  sentimentGroups: [],
  updatedCompanySentiments: {},
  suggestions: [],
}

const sentimentReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'SENTIMENT_FETCH_GROUPS_SUCCESS': {
      draftState.sentimentGroups = action.payload
      break
    }

    case 'SENTIMENT_FETCH_ENTITIES_SUCCESS': {
      draftState.suggestions = action.payload
      break
    }

    case 'SENTIMENT_UPDATE_GROUP_SUCCESS': {
      draftState.sentimentGroups = draftState.sentimentGroups.map((group) =>
        group.id_group === action.payload.id_group ? action.payload : group,
      )
      break
    }

    case 'SENTIMENT_CREATE_GROUP_SUCCESS': {
      draftState.sentimentGroups = [action.payload]
      break
    }

    case 'OVERRIDE_COMPANY_SENTIMENT_SUCCESS': {
      const {
        article: { id_article, id_site, manual_sentiment },
      } = action.payload

      const articleId = articleIdFromIds(id_site, id_article)
      draftState.updatedCompanySentiments[articleId] = manualToUpdatedSentimentMapping[manual_sentiment]

      break
    }

    default: {
      return
    }
  }
}, initialState)

export default sentimentReducer
