import { ContactFormValues } from '../contacts/ContactsForm/ContactEditor/types'

export type Contact = {
  id: number
  firstName: string
  lastName: string
  email: string
  groups: Array<Group>
}

export type SimpleContact<T = unknown> = {
  items: T[]
  status: number
  type: string
  value: string
}

export type Group = {
  id: number
  name: string
  url: string
  contacts?: Array<Contact>
}

export type ContactDetail = {
  id: number | null
  activated: number
  address: string | null
  firstName: string
  lastName: string
  email: string
  mobile: string | null
  pause: number | null
  groups: Group[]
  email_forwards: boolean
  status: string
  url: string
}

export type ContactConfirm = { flag?: boolean; contact: ContactFormValues }

export type ErrorMessage = {
  message: string
  data: Record<string, unknown>
}

export type CommonRecipientFilter = {
  type: 'phoneNumber' | 'email'
  entity: {
    id: string
    value: string
  }
}

export type ContactFilter = {
  type: 'person' |'user'
  entity: ContactDetail
} | {
  type: 'group'
  entity: Group
}

export type ContactFilterWithSimple = {
  type: 'person'
  entity: ContactDetail | SimpleContact
} | {
  type: 'user'
  entity: ContactDetail
} | {
  type: 'group'
  entity: Group
}

export enum ContactsPath {
  EditedAlertEmail = 'editedAlertEmail',
  EditedAlertSms = 'editedAlertSms',
  ShareArticlesContacts = 'shareArticlesContacts',
  ShareReportContacts = 'shareReportContacts',
}
