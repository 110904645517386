import { Select, SelectContent, SelectItem, SelectTrigger } from '@opoint/infomedia-storybook'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { t } from "i18next";
import { Tag } from '../../../types/tag'
import { CategorizationFolder } from '.'

const defaultOption = [{ id: 0, value: 0, label: t('No tag'), name: t('No tag') }]

type CategorizationSingleSelectProps = {
  onHandleTagArticle: ({ tag }: { tag: Tag | undefined }) => void
  onHandleUntagArticle: ({ tag }: { tag: Tag | undefined }) => void
  visibleListsOption: boolean
} & CategorizationFolder

const CategorizationSingleSelect = ({
  chosenTags,
  folderName,
  folderTags,
  isVisible,
  onHandleTagArticle,
  onHandleUntagArticle,
  visibleListsOption,
}: CategorizationSingleSelectProps): React.JSX.Element => {
  const { t } = useTranslation()

  const value = chosenTags[0]?.id.toString()

  const singleSelectFolderTags = folderTags.map((tag) => ({ value: tag.id, label: tag.name }))

  const handleTagChange = (tags: Array<Tag>, value: number, chosenTag: Tag) => {
    if (value > 0) {
      const selectedTag = tags.find((tag) => tag.id === value)
      onHandleTagArticle({ tag: selectedTag })
    }

    if (chosenTag && chosenTag.id !== value) {
      onHandleUntagArticle({ tag: chosenTag })
    }
  }

  return (
    <Select
      key={value} // A bit hacky, but it resets the dropdown when the tag is removed.
      value={value}
      onChange={(newValue) => handleTagChange(folderTags, Number(newValue), chosenTags[0])}
      data-cy="dropdown"
    >
      <SelectTrigger
        name={`categorization_select_${folderName}`}
        id={`categorization_select_${folderName}`}
        displayValue={(value) =>
          [...defaultOption, ...folderTags].find((tag) => tag.id.toString() === value)?.name || value
        }
        placeholder={t('Select tag')}
      />

      <SelectContent className={classNames({ relative: visibleListsOption })} static={isVisible}>
        {[...defaultOption, ...singleSelectFolderTags].map(({ label, value }) => (
          <SelectItem key={value} value={value?.toString()}>
            {label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default CategorizationSingleSelect
