import { ArticleMediaType, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@opoint/infomedia-storybook'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { isEmpty } from 'ramda'
import { useMatch } from 'react-router-dom'
import { SOCIAL_SOURCES } from '../../../../../opoint/common/constants'
import { Filter } from '../../../../../opoint/flow'
import { getIds } from '../../../../../opoint/search'
import Button from '../../../../common/Button'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { SearchFilterKey, useSearchFilters } from '../../../../hooks/useSearchFilters'

const SUMMARY_SITE_ID = 313766

export type ArticleSourceLineSourceProps = {
  first_source: {
    id: number
    name: string
    sitename: string
    siteurl: string
    url: string
  }
  mediatype?: ArticleMediaType
  orig_url?: string
  sourceIsLink?: boolean
  siteId?: number
  editMode?: boolean
}

const getIsFilterSelected = ({
  searchFilters,
  filterId,
  filterType,
}: {
  searchFilters: Filter[]
  filterId: number
  filterType: SearchFilterKey.SITE
}) => {
  const currentSiteData = searchFilters?.filter(({ type }: Filter) => type === filterType)

  if (isEmpty(currentSiteData)) {
    return false
  }

  const formattedSiteId = filterId.toString()
  const currentSiteIds = currentSiteData?.map(({ id }) => id)

  return currentSiteIds.some((id) => id?.toString().includes(formattedSiteId))
}

const ArticleSourceLineSource = ({
  first_source: { id, name, sitename, url },
  mediatype,
  sourceIsLink,
  siteId,
  orig_url,
  editMode,
}: ArticleSourceLineSourceProps) => {
  const searchFilters = useSearchFilters()
  const { t } = useTranslation()
  const isAlertsPage = useMatch('/alerts/*')
  const dispatch = useAppDispatch()
  const { text: mediaTypeText } = mediatype || {}

  return useMemo(() => {
    const isSocial = mediaTypeText === 'SOCIAL'
    const sourceLine = isSocial && !!SOCIAL_SOURCES[id] ? SOCIAL_SOURCES[id].name : name || sitename || ''
    const filterType = SearchFilterKey.SITE

    const isSummary = siteId === SUMMARY_SITE_ID
    const sourceUrl = isSummary ? orig_url : url

    const shouldRenderLink = isAlertsPage || isSummary || editMode

    if (!sourceIsLink || (shouldRenderLink && !sourceUrl)) {
      return <span>{sourceLine}</span>
    }

    if (shouldRenderLink) {
      return (
        <a href={sourceUrl} target="_blank" rel="noopener noreferrer" className="text-sky.1">
          {sourceLine}
        </a>
      )
    }

    const handleToggleFilter = async () => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      const result = await getIds({ id: siteId, type: filterType })

      const siteData = result && result[0]

      dispatch({ type: 'SEARCHFILTER_TOGGLED', payload: siteData })
    }

    // @ts-expect-error: Muted so we could enable TS strict mode
    const filterIsSelected = getIsFilterSelected({ searchFilters, filterId: siteId, filterType })
    const tooltipContent = filterIsSelected ? t('Remove filter') : t('Add as filter')

    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button name="link" onClick={handleToggleFilter} className="inline-block !p-0">
            {sourceLine}
          </Button>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="top">{tooltipContent}</TooltipContent>
        </TooltipPortal>
      </Tooltip>
    )
  }, [
    mediaTypeText,
    id,
    name,
    sitename,
    siteId,
    orig_url,
    url,
    isAlertsPage,
    editMode,
    sourceIsLink,
    searchFilters,
    t,
    dispatch,
  ])
}

export default ArticleSourceLineSource
