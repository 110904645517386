import { useEffect, useState } from 'react'

/**
 * This hook is used to delay the tour for a certain amount of time.
 * @param show
 * @param delay
 * @returns boolean
 */
const useDelayTour = (show: boolean, delay: number) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!show) {
      return setIsVisible(false)
    }

    const timeoutId = setTimeout(() => {
      setIsVisible(true)
    }, delay)

    return () => clearTimeout(timeoutId)
  }, [show, delay])

  return isVisible
}

export default useDelayTour
