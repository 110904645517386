import { DOM } from 'rx-dom'

import { merge } from 'ramda'
import { FeedFormData } from '../../components/feeds/FeedForm'
import { isTag } from '../../guards/tags'
import config from '../common/config'
import type { Feed, Filter } from '../flow'

/**
 * API Functions
 */
export async function getFeeds(): Promise<Array<Feed>> {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/feeds/'),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function getFeed(feedId: string): Promise<Feed> {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/feeds/${feedId}/`),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function updateFeed(feedId: string, updatedData: FeedRequestData): Promise<Array<Feed>> {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/feeds/${feedId}/`),
    method: 'put',
    body: JSON.stringify(updatedData),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function transformFeedFormDataToFeedRequestData(feedFormData: FeedFormData): FeedRequestData {
  return {
    name: feedFormData.feedName,
    feedType: feedFormData.type,
    articlesCount: feedFormData.articleCount,
    xsltFile: null,
    description: feedFormData.feedDescription,
    mifluzIdLists: feedFormData.content.map((value) => parseInt(value, 10)),
    identicalArticles: feedFormData.includeIdenticalArticles,
    feedSettings: feedFormData.feedSettings,
  }
}

type FeedSettings = {
  alternativeSource: boolean
  images: boolean
  includeContext: boolean
  neverRedir: boolean
  source: boolean
  sourceTitle: boolean
  privateLink: boolean
  useDefaultAccessGroups: boolean
}

export type FeedRequestData = {
  name: string
  feedType: string | null
  articlesCount: number | null
  xsltFile?: string | null
  description: string
  mifluzIdLists: number[]
  identicalArticles: boolean
  feedSettings?: FeedSettings | null
}

export async function createFeed(newFeed: FeedRequestData): Promise<Feed> {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/feeds/'),
    method: 'post',
    body: JSON.stringify(newFeed),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export async function deleteFeed(feedId: string): Promise<void> {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/feeds/${feedId}/`),
    method: 'delete',
    responseType: 'text',
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getActiveProfileTagList(
  profileTags: Array<{ id: number; name: string }>,
  activeIds: Array<number>,
): Filter[] {
  if (!profileTags || !profileTags.length || !activeIds || !activeIds.length) {
    return []
  }

  const filtered = profileTags.filter((profileTag) => {
    return activeIds.indexOf(profileTag.id) !== -1
  })

  return filtered.map((item) => ({ id: item.id, type: isTag(item) ? 'tag' : 'profile', name: item.name }))
}
