import { Trans } from 'react-i18next'

import HorizontalScroll from '../../../../common/HorizontalScroll/HorizontalScroll'
import ModulePermissions from '../../../../common/ModulePermissions'
import { MODULES } from '../../../../constants/permissions'
import useArticleBaseProps from '../../../../hooks/useArticleBaseProps'
import useIsUserPermitted from '../../../../hooks/useIsUserPermitted'
import { ArticleMetadataType, SentimentType } from '../../../../types/article'
import { LocaleKey } from '../../../../types/locale'
import SocialStats from '../../SocialStats'
import ArticleSentiment from '../ArticleSentiment'

import { useAppSelector } from '../../../../hooks/useAppSelector'
import { getUISetting } from '../../../../../selectors/settingsSelectors'
import { DocumentMetaData } from '../../../../../api/opoint-search-suggest.schemas'
import { M360Article } from '../../../../../opoint/articles/types'
import ArticleMetadataBox from './ArticleMetadataBox/ArticleMetadataBox'

export type ArticleMetadataProps = {
  article: M360Article
  meta_data?: DocumentMetaData
  sentiment: SentimentType
  showSentiment?: boolean
}

const ArticleMetadata = ({ article, meta_data, sentiment, showSentiment = true }: ArticleMetadataProps) => {
  const showMetrics = useAppSelector(getUISetting('SHOW_METRICS'))

  const { reach, similarweb, site_rank, word_count, countrycode, countryname, user_sentiments, adprice } = article
  const { articleMetadata: articleMetadataProps, locale: localeProp }: any = useArticleBaseProps()
  const locale: LocaleKey = localeProp
  const articleMetadata: Array<ArticleMetadataType> = articleMetadataProps

  const enableReachOnArticles = useIsUserPermitted({
    module: 'ENABLE_REACH_ON_ARTICLES',
    permissions: [MODULES.ENABLE_REACH_ON_ARTICLES.ON],
  })

  const displayingReach = !reach || enableReachOnArticles

  if (!showSentiment && (!articleMetadata || (articleMetadata.length === 0 && !meta_data && displayingReach))) {
    return null
  }

  return (
    <HorizontalScroll>
      {showSentiment && (
        <ArticleSentiment article={article} generalSentiment={sentiment} companySentimentDetails={user_sentiments} />
      )}

      {/* Reach is temporarily located here, until backend has implemented it into the metrics "section", making the user able to order and toggle it on/off */}
      {showMetrics && (
        <>
          {reach && articleMetadata?.length > 0 && (
            <ModulePermissions module="ENABLE_REACH_ON_ARTICLES" permissions={[MODULES.ENABLE_REACH_ON_ARTICLES.ON]}>
              <li className="flex flex-col space-y-2.5">
                <div className="whitespace-nowrap text-label-s font-bold text-sky.cloudy">
                  <Trans>Reach</Trans>
                </div>
                <div className="flex flex-1 items-center whitespace-nowrap text-label-l font-medium text-sky.1">
                  {reach.toLocaleString(locale)}
                </div>
              </li>
            </ModulePermissions>
          )}

          {adprice && articleMetadata?.length > 0 && (
            <ModulePermissions module="ENABLE_EAC_ON_ARTICLE" permissions={[MODULES.ENABLE_EAC_ON_ARTICLE.ON]}>
              <li className="flex flex-col space-y-2.5">
                <div className="whitespace-nowrap text-label-s font-bold text-sky.cloudy">
                  <Trans>EAC</Trans>
                </div>
                <div className="flex flex-1 items-center whitespace-nowrap text-label-l font-medium text-sky.1">
                  {adprice.toLocaleString(locale)}
                </div>
              </li>
            </ModulePermissions>
          )}

          {/* @ts-expect-error: Muted so we could enable TS strict mode */}
          <SocialStats articleId={article.id_article} meta_data={meta_data} />

          {articleMetadata?.map((metadata) => (
            <ArticleMetadataBox
              article={article}
              countrycode={countrycode}
              countryname={countryname}
              key={metadata}
              locale={locale}
              reach={reach}
              // @ts-expect-error: Muted so we could enable TS strict mode
              similarweb={similarweb}
              site_rank={site_rank}
              type={metadata}
              word_count={word_count}
            />
          ))}
        </>
      )}
    </HorizontalScroll>
  )
}

export default ArticleMetadata
