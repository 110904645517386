import React from 'react'
import { Loader } from '@opoint/infomedia-storybook'
import { useUsersManagementRetrieve } from '../../../api/users/users.ts'
import { useRequiredParams } from '../../hooks/useRequiredParams.ts'
import { ErrorBreadcrumb } from '../../breadcrumbs/ErrorBreadcrumb.tsx'

export const UserDetailBreadcrumb = (): React.JSX.Element => {
  const params = useRequiredParams(['userId'])
  const userId = parseInt(params.userId, 10)

  const { data, isError } = useUsersManagementRetrieve(userId)

  if (isError) {
    return <ErrorBreadcrumb />
  }

  if (!data) {
    return <Loader size="tiny" />
  }

  return <>{data.username}</>
}
