import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'
import { t } from "i18next";
import {
  AddArticleCommentFailureAction,
  AddArticleCommentSuccessAction,
  CommentsActions,
  DeleteArticleCommentFailureAction,
  DeleteArticleCommentSuccessAction,
  EditArticleCommentFailureAction,
  EditArticleCommentSuccessAction,
} from '../../../actions/comments'
import { ErrorEpic, handlerError } from '.'

const commentErrorTypes: ErrorEpic<
  | AddArticleCommentSuccessAction['type']
  | AddArticleCommentFailureAction['type']
  | DeleteArticleCommentSuccessAction['type']
  | DeleteArticleCommentFailureAction['type']
  | EditArticleCommentSuccessAction['type']
  | EditArticleCommentFailureAction['type']
> = {
  ['ADD_ARTICLE_COMMENT_SUCCESS']: {
    isSuccess: true,
    message: t('We successfully added a new comment'),
    toastId: 'ADD_ARTICLE_COMMENT_SUCCESS',
  },
  ['ADD_ARTICLE_COMMENT_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to add a comment on this article'),
    toastId: 'ADD_ARTICLE_COMMENT_FAILURE',
  },
  ['DELETE_ARTICLE_COMMENT_SUCCESS']: {
    isSuccess: true,
    message: t('We successfully deleted a comment'),
    toastId: 'DELETE_ARTICLE_COMMENT_SUCCESS',
  },
  ['DELETE_ARTICLE_COMMENT_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to delete your comment'),
    toastId: 'DELETE_ARTICLE_COMMENT_FAILURE',
  },
  ['EDIT_ARTICLE_COMMENT_SUCCESS']: {
    isSuccess: true,
    message: t('We successfully edited the comment'),
    toastId: 'EDIT_ARTICLE_COMMENT_SUCCESS',
  },
  ['EDIT_ARTICLE_COMMENT_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to edit this comment'),
    toastId: 'EDIT_ARTICLE_COMMENT_FAILURE',
  },
}

const commentErrorEpic: (action: ActionsObservable<CommentsActions>) => void = (action$) =>
  action$.pipe(switchMap(({ type }) => handlerError(commentErrorTypes, type)))

export default commentErrorEpic
