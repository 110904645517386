import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { concat, defer, EMPTY, forkJoin, from, interval, of } from 'rxjs'
import { catchError, filter, map, mapTo, mergeMap, switchMap, tap, throttle } from 'rxjs/operators'

import { __, always, compose, contains, evolve, prop } from 'ramda'
import { t } from "i18next";
import { AppActions } from '../actions'
import { ImpersonateSuccessAction, LogInSuccessAction } from '../actions/auth'
import {
  FilterMetasFetchAction,
  FilterMetasFetchFailureAction,
  FilterMetasFetchSuccessAction,
  GoToDefaultProfileAction,
  LoadEditedProfileSuccessAction,
  ProfileDeleteAction,
  DeletedProfilesFetchFailureAction,
  DeletedProfilesHistoryFetchAction,
  ProfileDeleteFailureAction,
  ProfileDeleteSuccessAction,
  ProfileEditorDeleteConfirmAction,
  ProfileEditorFailureAction,
  ProfileEditorFiltersFetchMultipleFailureAction,
  ProfileEditorFiltersFetchSimpleFailureAction,
  ProfileEditorInvalidSearchlineAction,
  ProfileEditorPreviewAction,
  ProfileEditorPreviewSuccessAction,
  ProfileEditorSaveProfileAction,
  ProfileEditorSaveProfileFailureAction,
  ProfileEditorSaveProfileSuccessAction,
  ProfileFetchFailureAction,
  ProfileGetDependenciesAction,
  ProfileHistoryFetchAction,
  ProfileHistoryFetchFailureAction,
  LoadEditedProfileAction,
  LoadEditedProfileFailureAction,
  SaveAsProfileAction,
  SaveAsProfileFailureAction,
  SaveAsProfileSuccessAction,
  ProfilesFetchAction,
  ProfilesGetDependenciesSuccessAction,
  SearchtermChangedProfileEditorAction,
  ProfileEditorFocusedLineChangedAction,
} from '../actions/profiles'
import { SearchDataClearAction } from '../actions/search'
import { FilterMetasShowDetailAction, ScrollToTopAction } from '../actions/ui'
import { SearchFilterKey } from '../components/hooks/useSearchFilters'
import { cannotDeleteEntityReason } from '../opoint/common'
import { Filter, ProfileDetail } from '../opoint/flow'
import {
  deleteProfile,
  getDeletedProfiles,
  getFilterMetasDetail,
  getProfileDependencies,
  getProfileDetail,
  getProfileHistory,
  getProfiles,
  saveProfile,
} from '../opoint/profiles'
import { cutFiltersTypeMinus, getMultipleSuggestions, getSimpleSuggestions, search } from '../opoint/search'
import { RootState } from '../reducers'
import {
  getActiveProfileEditorLine,
  getEditedProfile,
  getIsProfileEditorFiltersPanelOpen,
  getSaveAsProfile,
  getSearchDataForSearchline,
} from '../selectors/profilesSelectors'
import { getFiltersShowMore, getMainSearchLine } from '../selectors/searchSelectors'
import {
  getDefaultSearch,
  getOpointLocale,
  getSuggestionLocale,
  getUISetting,
  hasLimitedCreateProfiles,
} from '../selectors/settingsSelectors'
import { getBaskets } from '../selectors/tagsComposedSelectors'

import { ClearArticlesAction, FetchArticlesAction } from '../actions/articles'
import { router } from '../routes'
import { tagOrProfileChosen } from '../helpers/common'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const fetchProfilesOnLogIn = (action$: ActionsObservable<AppActions>) => {
  return action$.pipe(
    ofType<AppActions, LogInSuccessAction | ImpersonateSuccessAction>('LOG_IN_SUCCESS', 'IMPERSONATE_SUCCESS'),
    mapTo({ type: 'PROFILES_FETCH' }),
  )
}

const profilesFetchEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ProfilesFetchAction>('PROFILES_FETCH'),
    switchMap(() =>
      from(getProfiles()).pipe(map((profiles) => ({ type: 'PROFILES_FETCH_SUCCESS', payload: profiles }))),
    ),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<ProfileFetchFailureAction>({ type: 'PROFILES_FETCH_FAILURE' })),
  )

const filterMetasOpenDetailModal = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, FilterMetasShowDetailAction>('FILTER_METAS_SHOW_DETAIL'),
    switchMap((action) => of<FilterMetasFetchAction>({ type: 'FILTER_METAS_FETCH', payload: action.payload })),
  )

function filterMetasFetchDetailEpic(
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) {
  return action$.pipe(
    ofType<AppActions, FilterMetasFetchAction>('FILTER_METAS_FETCH'),
    switchMap(
      ({
        payload: {
          filter: { id, type: unprocessedType, name },
        },
      }) => {
        const locale = getOpointLocale(state$.value)
        // @ts-expect-error: Muted so we could enable TS strict mode
        const type = cutFiltersTypeMinus(unprocessedType)

        return from(getFilterMetasDetail(id.toString(), type, locale)).pipe(
          map(
            (filterMetadataDetail) =>
              ({
                type: 'FILTER_METAS_FETCH_SUCCESS',
                payload: { filterMetadata: filterMetadataDetail, name, type },
              } as FilterMetasFetchSuccessAction),
          ),
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError((e) =>
            of<FilterMetasFetchFailureAction>({
              type: 'FILTER_METAS_FETCH_FAILURE',
              payload: { status: e.status },
            }),
          ),
        )
      },
    ),
  )
}

function getSuggestionsObservable(
  searchterm: string,
  filters: Filter[],
  suggestionsLocale: { suggestionLocale: string },
  fetchMultiple: boolean,
  id: number,
  defaultSearchScope: any,
  filterSuggestParameter: number,
  searchSuggestParameter: number,
) {
  return fetchMultiple
    ? from(
        getMultipleSuggestions(
          searchterm,
          filters,
          suggestionsLocale,
          defaultSearchScope,
          undefined,
          filterSuggestParameter,
        ),
      ).pipe(
        map((results) => ({
          type: 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_SUCCESS',
          payload: {
            id,
            results,
          },
        })),
        catchError(() =>
          of<ProfileEditorFiltersFetchMultipleFailureAction>({
            type: 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE',
          }),
        ),
      )
    : from(
        getSimpleSuggestions(
          searchterm,
          filters,
          suggestionsLocale,
          defaultSearchScope,
          undefined,
          searchSuggestParameter,
        ),
      ).pipe(
        // @ts-expect-error: Muted so we could enable TS strict mode
        map(({ results }) => ({
          type: 'PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_SUCCESS',
          payload: {
            id,
            results,
          },
        })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() =>
          of<ProfileEditorFiltersFetchSimpleFailureAction>({
            type: 'PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE',
          }),
        ),
      )
}

const profileEditorSearchChangedEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    filter(
      compose(
        /* eslint-disable-next-line no-underscore-dangle */
        contains(__, ['SEARCHTERM_CHANGED_PROFILE_EDITOR', 'PROFILE_EDITOR_FOCUSED_LINE_CHANGED']),
        prop('type'),
      ),
    ),
    throttle(({ type }) => (type === 'SEARCHTERM_CHANGED_PROFILE_EDITOR' ? interval(100) : interval(0))),
    // @ts-expect-error: Muted so we could enable TS strict mode
    switchMap(({ payload: { id } }) => {
      const state = state$.value
      const searchData = getSearchDataForSearchline(id)(state)
      if (!searchData) {
        return EMPTY
      }
      const { searchterm, filters } = searchData
      const isFiltersOpen = getIsProfileEditorFiltersPanelOpen(state)
      const suggestionLocale = getSuggestionLocale(state)
      const defaultSearchScope = getDefaultSearch(state)
      const filterSuggestParameter = getUISetting('NEW_PORTAL_FILTER_SUGGEST')(state)
      const searchSuggestParameter = getUISetting('NEW_PORTAL_SEARCH_SUGGEST')(state)

      return getSuggestionsObservable(
        // @ts-expect-error: Muted so we could enable TS strict mode
        searchterm,
        filters,
        { suggestionLocale },
        isFiltersOpen,
        id,
        defaultSearchScope,
        filterSuggestParameter,
        searchSuggestParameter,
      )
    }),
  )

const onProfileEditorSearchDataChangeFiltersMore = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, SearchtermChangedProfileEditorAction | ProfileEditorFocusedLineChangedAction>(
      'SEARCHTERM_CHANGED_PROFILE_EDITOR',
      'PROFILE_EDITOR_FOCUSED_LINE_CHANGED',
    ),
    switchMap(() => {
      const state = state$.value
      const id = getActiveProfileEditorLine(state)
      const searchData = getSearchDataForSearchline(id)(state)
      if (!searchData) {
        return EMPTY
      }
      const { searchterm, filters } = searchData

      const suggestionLocale = getSuggestionLocale(state)
      const filtersShowMore = getFiltersShowMore(state)
      const lastFilter = filtersShowMore.slice(-1)[0]

      const defaultSearchScope = getDefaultSearch(state)
      if (lastFilter?.action) {
        const { action, header, width } = lastFilter
        const isSingleColumn = Number(action) < 0
        let responseCount = 10
        if (isSingleColumn) {
          switch (width) {
            case 2:
              responseCount = 60
              break
            case 3:
              responseCount = 40
              break
            default:
              responseCount = 140
          }
        }

        return from(
          getMultipleSuggestions(
            searchterm,
            filters,
            { suggestionLocale },
            defaultSearchScope,
            responseCount,
            Number(action),
          ),
        ).pipe(
          map((response) => ({
            type: 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS',
            payload: [
              ...filtersShowMore.slice(0, filtersShowMore.length - 1),
              {
                // @ts-expect-error: Muted so we could enable TS strict mode
                ...response,
                header,
                isSingleColumn,
                action,
              },
            ],
          })),
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError(() => of()),
        )
      }

      return EMPTY
    }),
  )

const deleteProfilesEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, ProfileDeleteAction>('PROFILE_DELETE'),
    mergeMap(({ payload: { profileIds, force } }) => {
      const profilesToDelete$ = from(profileIds)
      const deletedProfiles$ = profilesToDelete$.pipe(
        mergeMap((id) =>
          from(deleteProfile({ id, locale: getOpointLocale(state$.value), force })).pipe(
            switchMap(() =>
              of(
                { type: 'PROFILE_DELETE_SUCCESS', payload: id },
                {
                  type: 'SEARCHFILTER_REMOVED',
                  payload: {
                    id,
                    type: 'profile',
                  },
                },
              ),
            ),
            catchError(logOutOnExpiredToken),
            catchError(serverIsDown),
            catchError((e) => {
              let error: string
              try {
                const parsedResponse = JSON.parse(e.originalEvent.target.response)
                error = cannotDeleteEntityReason(parsedResponse)
              } catch (innerError) {
                error = t('We were unable to delete this contact')
              }

              return of<ProfileDeleteFailureAction>({
                type: 'PROFILE_DELETE_FAILURE',
                payload: { error },
              })
            }),
          ),
        ),
      )

      // Return status of each profile delete and then cancel delete mode
      return concat(deletedProfiles$, of<ProfilesFetchAction>({ type: 'PROFILES_FETCH' }))
    }),
  )

const profileEditorSaveEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, ProfileEditorSaveProfileAction>('PROFILE_EDITOR_SAVE_PROFILE'),
    switchMap(() => {
      const state = state$.value
      // @ts-expect-error: Muted so we could enable TS strict mode
      const name = getSaveAsProfile(state)?.name
      const isLimitedCreateProfiles = hasLimitedCreateProfiles(state)

      const editedProfile = name
        ? {
            ...getEditedProfile(state),
            id: null,
            name,
          }
        : getEditedProfile(state)

      const isTagOrProfileChosen = tagOrProfileChosen(editedProfile.items)

      if (!editedProfile.name) {
        return of<ProfileEditorSaveProfileFailureAction>({
          type: 'PROFILE_EDITOR_SAVE_PROFILE_FAILURE',
          payload: {
            error: t('You must enter a profile name'),
          },
        })
      }

      if (isLimitedCreateProfiles && !isTagOrProfileChosen) {
        return of<ProfileEditorSaveProfileFailureAction>({
          type: 'PROFILE_EDITOR_SAVE_PROFILE_FAILURE',
          payload: {
            error: t('You must select a profile or tag in filters'),
          },
        })
      }

      // Adjusting the search - we need to convert filters with type: "tag" to type: "basket"
      const adjustedSearch = editedProfile.items.map((searchItem) => {
        const filtersConverted = searchItem.searchline.filters.map((filterItem) => {
          if (filterItem.type === SearchFilterKey.TAG) {
            return {
              ...filterItem,
              type: SearchFilterKey.BASKET,
            }
          }

          return filterItem
        })

        return {
          ...searchItem,
          searchline: {
            ...searchItem.searchline,
            filters: filtersConverted,
          },
        }
      })

      const adjustedProfile = {
        ...editedProfile,
        items: adjustedSearch,
      }

      const fetchProfiles$ = defer(() => getProfiles()).pipe(
        map((profiles) => ({ type: 'PROFILES_FETCH_SUCCESS', payload: profiles })),
      )

      // @ts-expect-error: Muted so we could enable TS strict mode
      const saveProfile$ = from(saveProfile(adjustedProfile)).pipe(
        map((profile) =>
          profile.debug
            ? ({
                type: 'PROFILE_EDITOR_INVALID_SEARCHLINE',
                payload: { debug: profile.debug },
              } as ProfileEditorInvalidSearchlineAction)
            : ({
                type: 'PROFILE_EDITOR_SAVE_PROFILE_SUCCESS',
                payload: { profile },
              } as ProfileEditorSaveProfileSuccessAction),
        ),
      )

      return saveProfile$.pipe(
        switchMap((saveProfileAction) => {
          if (saveProfileAction.type === 'PROFILE_EDITOR_SAVE_PROFILE_SUCCESS') {
            const newPath = `/search/?filters=profile:${saveProfileAction.payload.profile.id}`
            return concat(
              of(saveProfileAction),
              fetchProfiles$,
              of(newPath).pipe(
                tap(() => router.navigate(newPath)),
                map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
              ),
            )
          }
          return of(saveProfileAction)
        }),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() =>
          of<ProfileEditorSaveProfileFailureAction>({ type: 'PROFILE_EDITOR_SAVE_PROFILE_FAILURE', payload: {} }),
        ),
      )
    }),
  )

const profileEditorDeleteEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, ProfileEditorDeleteConfirmAction>('PROFILE_EDITOR_DELETE_CONFIRM'),
    switchMap(() => {
      const editedProfile = getEditedProfile(state$.value)
      const fetchProfiles$ = defer(() => getProfiles()).pipe(
        map((profiles) => ({ type: 'PROFILES_FETCH_SUCCESS', payload: profiles })),
      )

      // @ts-expect-error: Muted so we could enable TS strict mode
      return from(deleteProfile({ id: editedProfile.id, locale: getOpointLocale(state$.value), force: true })).pipe(
        mergeMap(() =>
          concat(
            // @ts-expect-error: Muted so we could enable TS strict mode
            of<ProfileDeleteSuccessAction>({ type: 'PROFILE_DELETE_SUCCESS', payload: editedProfile.id }),
            fetchProfiles$,
            of<GoToDefaultProfileAction>({ type: 'GO_TO_DEFAULT_PROFILE' }),
          ),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError((e) => {
          let error: string
          try {
            const parsedResponse = JSON.parse(e.originalEvent.target.response)
            error = cannotDeleteEntityReason(parsedResponse)
          } catch (innerError) {
            error = t('We were unable to delete this contact')
          }

          return of<ProfileDeleteFailureAction>({
            type: 'PROFILE_DELETE_FAILURE',
            payload: { error },
          })
        }),
      )
    }),
  )

const profileEditorPreviewEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, ProfileEditorPreviewAction>('PROFILE_EDITOR_PREVIEW'),
    switchMap(() => {
      const editedProfile = getEditedProfile(state$.value)
      const baskets = { baskets: getBaskets(state$.value) }

      return from(search(editedProfile.items, baskets)).pipe(
        map(
          (response) =>
            ({ type: 'PROFILE_EDITOR_PREVIEW_SUCCESS', payload: response } as ProfileEditorPreviewSuccessAction),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<ProfileEditorFailureAction>({ type: 'PROFILE_EDITOR_PREVIEW_FAILURE' })),
      )
    }),
  )

const profileEditorLoadProfileEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, LoadEditedProfileAction>('LOAD_EDITED_PROFILE'),
    switchMap(({ payload: { profileId } }) => {
      if (profileId === null) {
        return of<LoadEditedProfileSuccessAction>({
          type: 'LOAD_EDITED_PROFILE_SUCCESS',
          payload: {
            // @ts-expect-error: Muted so we could enable TS strict mode
            id: null,
            items: [
              {
                searchline: {
                  searchterm: '',
                  filters: [],
                },
                linemode: 'R',
              },
            ],
          },
        })
      }

      const locale = getOpointLocale(state$.value)
      // #TODO check if works correctly

      return from(getProfileDetail(profileId, locale))
        .pipe(
          switchMap((response) =>
            of<LoadEditedProfileSuccessAction | ScrollToTopAction>(
              { type: 'LOAD_EDITED_PROFILE_SUCCESS', payload: response },
              { type: 'SCROLL_TO_TOP' },
            ),
          ),
          catchError(logOutOnExpiredToken),
        )
        .pipe(
          catchError(serverIsDown),
          catchError(() =>
            concat(
              of(-1).pipe(
                tap((route) => router.navigate(route)),
                map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
              ),
              of<LoadEditedProfileFailureAction>({ type: 'LOAD_EDITED_PROFILE_FAILURE' }),
            ),
          ),
        )
    }),
  )

const reloadArticles = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ProfileEditorSaveProfileSuccessAction | SearchDataClearAction>(
      'PROFILE_EDITOR_SAVE_PROFILE_SUCCESS',
      'SEARCHDATA_CLEAR',
    ),
    switchMap(() =>
      concat(of<ClearArticlesAction>({ type: 'CLEAR_ARTICLES' }), of<FetchArticlesAction>({ type: 'FETCH_ARTICLES' })),
    ),
  )

const saveAsProfileEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, SaveAsProfileAction>('SAVE_AS_PROFILE'),
    switchMap(({ payload: { name } }) => {
      const state = state$.value
      const searchline = getMainSearchLine(state)
      const editedProfile = getEditedProfile(state)
      const profile = editedProfile
        ? evolve({
            id: always(null),
            name: always(name),
          })(editedProfile)
        : {
            id: null,
            items: [
              {
                linemode: 'R',
                searchline,
              },
            ],
            name,
          }

      const fetchProfiles$ = defer(() => getProfiles()).pipe(
        map((profiles) => ({ type: 'PROFILES_FETCH_SUCCESS', payload: profiles })),
      )

      return from(saveProfile(profile as ProfileDetail))
        .pipe(
          switchMap((response) => {
            if (response.debug) {
              return of<ProfileEditorInvalidSearchlineAction>({
                type: 'PROFILE_EDITOR_INVALID_SEARCHLINE',
                payload: { debug: response.debug },
              })
            } else {
              const newPath = `/search/?filters=profile:${response.id}`
              return concat(
                fetchProfiles$, // Fetch profiles observable
                of(newPath).pipe(
                  tap(() => router.navigate(newPath)),
                  map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
                ),
                of<SaveAsProfileSuccessAction>({ type: 'SAVE_AS_PROFILE_SUCCESS' }), // Emit success action
              )
            }
          }),
        )
        .pipe(
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError(() => of<SaveAsProfileFailureAction>({ type: 'SAVE_AS_PROFILE_FAILURE' })),
        )
    }),
  )

const profileHistoryFetchEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ProfileHistoryFetchAction>('PROFILE_HISTORY_FETCH'),
    switchMap(({ payload: { profileId: id } }) =>
      from(getProfileHistory(id)).pipe(
        map((history) => ({ type: 'PROFILE_HISTORY_FETCH_SUCCESS', payload: { history } })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError((error) =>
          of<ProfileHistoryFetchFailureAction>({ type: 'PROFILE_HISTORY_FETCH_FAILURE', payload: error }),
        ),
      ),
    ),
  )

const deletedProfilesHistoryFetchEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, DeletedProfilesHistoryFetchAction>('DELETED_PROFILES_HISTORY_FETCH'),
    switchMap(() =>
      from(getDeletedProfiles()).pipe(
        map((history) => ({ type: 'DELETED_PROFILES_HISTORY_FETCH_SUCCESS', payload: { history } })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<DeletedProfilesFetchFailureAction>({ type: 'DELETED_PROFILES_HISTORY_FETCH_FAILURE' })),
      ),
    ),
  )

const getProfileDependenciesEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ProfileGetDependenciesAction>('PROFILES_GET_DEPENDENCIES'),
    switchMap(({ payload: { profiles } }) =>
      forkJoin(profiles?.map(({ id }) => from(getProfileDependencies(id)).pipe(map((deps) => ({ id, deps }))))),
    ),
    mergeMap((deps) =>
      of<ProfilesGetDependenciesSuccessAction>({ type: 'PROFILES_GET_DEPENDENCIES_SUCCESS', payload: deps }),
    ),
  )

export default [
  deletedProfilesHistoryFetchEpic,
  deleteProfilesEpic,
  fetchProfilesOnLogIn,
  filterMetasFetchDetailEpic,
  filterMetasOpenDetailModal,
  getProfileDependenciesEpic,
  profileEditorDeleteEpic,
  profileEditorLoadProfileEpic,
  profileEditorPreviewEpic,
  profileEditorSaveEpic,
  profileEditorSearchChangedEpic,
  profileHistoryFetchEpic,
  profilesFetchEpic,
  reloadArticles,
  saveAsProfileEpic,
  onProfileEditorSearchDataChangeFiltersMore,
]
