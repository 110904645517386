import { combineEpics } from 'redux-observable'

import alertsEpics from './alertsEpics'
import analyticsEpics from './analyticsEpics'
import articlesEpics from './articlesEpics'
import authEpics from './authEpics'
import commentsEpics from './commentsEpics'
import contactsEpics from './contactsEpics'
import feedsEpics from './feedsEpics'
import foldersEpic from './foldersEpics'
import impersonationEpics from './impersonationEpics'
import profilesEpics from './profilesEpics'
import reportsEpics from './reportsEpics'
import reportsHistoryEpics from './reportsHistoryEpics'
import searchEpics from './searchEpics'
import sentimentEpics from './sentimentEpics'
import settingsEpics from './settingsEpics'
import statisticsEpics from './statisticsEpics'
import tagsEpics from './tagsEpics'
import templatesEpics from './templatesEpics'
import watchIndexEpics from './watchIndexEpics'

export const epics = [
  ...alertsEpics,
  ...analyticsEpics,
  ...articlesEpics,
  ...authEpics,
  ...contactsEpics,
  ...feedsEpics,
  ...impersonationEpics,
  ...profilesEpics,
  ...reportsEpics,
  ...reportsHistoryEpics,
  ...searchEpics,
  ...settingsEpics,
  ...statisticsEpics,
  ...tagsEpics,
  ...templatesEpics,
  ...watchIndexEpics,
  ...foldersEpic,
  ...sentimentEpics,
  ...commentsEpics,
]

const configureEpics = (deps: Object, platformEpics: Array<any>) => (action$, state$) =>
  combineEpics(...epics, ...platformEpics)(action$, { ...deps, state$ })

export default configureEpics
