import config from '../opoint/common/config'

export const getAppleIconUrl = (icon: any) => config.url.imageproxy(`/?path=${icon}`)

export const getPreviewThumbnailUrl = (url: string | number | boolean, width: any) =>
  config.url.imageproxy(
    `/?alter_code=true&opoint_max=true&url=${btoa(unescape(encodeURIComponent(url)))}&opoint_x=${width}`,
  )

export const getThumbnailUrl = (url: any, width?: number, height?: number) => {
  let outputUrl = config.url.imageproxy(`/?alter_code=true&opoint_crop=true&url=${url}`)

  // @ts-expect-error: Muted so we could enable TS strict mode
  if (width > 0) {
    outputUrl += `&opoint_x=${width}`
  }
  // @ts-expect-error: Muted so we could enable TS strict mode
  if (height > 0) {
    outputUrl += `&opoint_y=${height}`
  }

  return outputUrl
}

export const getFullImageUrl = (url: string | number | boolean) =>
  config.url.imageproxy(`/?alter_code=true&url=${btoa(unescape(encodeURIComponent(url)))}`)

//TODO: Deleted when verified that we can use the M360 opoint proxy
export const getSoMeImageProxyUrl = (url) => {
  if (url) {
    return `https://cors-worker.m360-some-image-worker.workers.dev/${url}`
  }
}
