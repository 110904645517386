import { getArticleId } from '@opoint/infomedia-storybook'
import { formatISO } from 'date-fns'
import { uniqBy, unnest } from 'ramda'

import { abbreviateNumber } from '../components/helpers/common'
import { SentimentType, UpdatedSentimentType } from '../components/types/article'
import { Folder } from '../components/types/folder'
import { LineMode } from '../components/types/profile'
import { SearchItem } from '../opoint/flow'

import { Tag } from '../components/types/tag'
import { folderTraits } from '../constants/folders'
import { DocumentMetaData } from '../api/opoint-search-suggest.schemas'
import { M360Article } from '../opoint/articles/types'
import { capitalizeFirstLetter } from './common'
import { getSearchObj } from './location'

export const getEngagementScore = (meta_data: DocumentMetaData) => {
  const engagementProperty = meta_data
    ? Object.keys(meta_data)
        .reverse()
        .find((key) => key?.includes('engagement'))
    : null

  if (!engagementProperty) {
    return
  }

  return abbreviateNumber(parseInt(meta_data[engagementProperty].value))
}

type SearchLines = Record<LineMode, Array<[number, SearchItem]>>

export const createSearchLines = (searchItems: SearchItem[]): SearchLines => {
  const enumeratedProfiles: Array<[number, SearchItem]> = searchItems.map((item, index) => [index, item])

  const searchLines = {}

  for (const [index, profile] of enumeratedProfiles) {
    const { linemode } = profile
    if (!searchLines[linemode]) {
      searchLines[linemode] = []
    }
    searchLines[linemode].push([index, profile])
  }

  return searchLines as SearchLines
}

export const getMilliseconds: () => {
  years: (time) => number
  months: (time) => number
  days: (time) => number
  hours: (time) => number
} = () => {
  const monthsInYear = 12
  const monthInDays = 30.44
  const dayInHours = 24
  const hourInMinutes = 60
  const minuteInSeconds = 60
  const secondInMiliseconds = 1000

  const years = (time) => {
    const yearsInMiliseconds =
      time * monthsInYear * monthInDays * dayInHours * hourInMinutes * minuteInSeconds * secondInMiliseconds

    return yearsInMiliseconds
  }
  const months = (time) => {
    const monthsInMiliseconds = time * monthInDays * dayInHours * hourInMinutes * minuteInSeconds * secondInMiliseconds

    return monthsInMiliseconds
  }
  const days = (time) => {
    const daysInMiliseconds = time * dayInHours * hourInMinutes * minuteInSeconds * secondInMiliseconds

    return daysInMiliseconds
  }
  const hours = (time) => {
    const hoursInMiliseconds = time * hourInMinutes * minuteInSeconds * secondInMiliseconds

    return hoursInMiliseconds
  }

  return { years, months, days, hours }
}

export const getContextFolder: (contentId: number, folders: Array<Folder>) => Folder = (contentId, folders) =>
  // @ts-expect-error: Muted so we could enable TS strict mode
  folders.find((folder) => folder.id === contentId)

export const filterItemsByTags = (tags: Tag[], folders: Folder[], items?: number[]): (number | Tag)[] => {
  const itemsToFilter = (items || tags) as (number | Tag)[]
  return itemsToFilter.filter((item) => {
    const tag = typeof item === 'number' ? tags.find((t) => t.id === item) : item
    if (!tag) {
      return item
    }

    const folder = getContextFolder(tag.folder, folders)
    const isSpecialFolder = folderTraits[folder?.traits] === 'special'

    return tag.category === 0 || isSpecialFolder
  })
}

// Resolve the company sentiment in this order of priority:
// 1) Updated manual sentiment (saved in redux to avoid refetch of article)
//   - if it has been reset, use automatically set sentiment
// 2) Manually set sentiment
// 3) Automatically set sentiment
export const resolveCompanySentiment = (
  auto: string | undefined,
  manual: string | undefined,
  updated: UpdatedSentimentType | undefined,
): SentimentType => {
  const manualSentiment = manual && manual !== 'unset' ? capitalizeFirstLetter(manual) : SentimentType.Notset
  const autoSentiment = auto && auto !== 'unset' ? capitalizeFirstLetter(auto) : SentimentType.Notset

  if (updated) {
    return updated === 'Reset' ? autoSentiment : updated
  }

  if (manual) {
    manualSentiment
  }

  return autoSentiment
}

export const getSentimentClassName = (sentimentName: SentimentType) => sentimentName.split(' ').join('').toLowerCase()

export const containsAnyLetter: (str: string) => boolean = (str) => /[a-z]/i.test(str)

export const handleSelectedBaskets: (baskets: string, savedBaskets: string) => string | undefined = (
  baskets,
  savedBaskets,
) => {
  if (savedBaskets) {
    return savedBaskets
  }

  if (baskets) {
    return baskets
  }

  return undefined
}

export const getFiltersStringArray: () => Array<string> = () => {
  const searchObject = getSearchObj(window.location.search)
  const filtersStrings = searchObject?.filters?.split(';') ?? []

  return filtersStrings
}

export const formatRangeValue: (value: number) => string = (value) => {
  if (!value) {
    return ''
  }

  return formatISO(value)
}

export const getUniqueArticlesIds = (articles: M360Article[], sendIdentical: boolean) => {
  const almostIdentical = sendIdentical
    ? (unnest(
        articles?.map((article) => {
          if (article.identical_documents) {
            return article.identical_documents.document
          }

          return []
        }),
      ) as M360Article[])
    : ([] as M360Article[])
  const allArticles = articles.concat(almostIdentical)
  const uniqueArticles = uniqBy((article: M360Article) => getArticleId(article), allArticles)

  const uniqueArticleIds = uniqueArticles?.map(({ id_site, id_article }) => ({ id_site, id_article }))

  return uniqueArticleIds
}
