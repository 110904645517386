/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Infomedia API
 * OpenAPI spec version: 1.0.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  AlertCountResponse,
  ListNotifications,
  Notification,
  NotificationsListParams,
  PatchedNotification,
} from '../opoint.schemas'
import { useCustomAxiosInstance } from '.././custom-client'
import type { ErrorType } from '.././custom-client'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

/**
 * @summary List notifications
 */
const useNotificationsListHook = () => {
  const notificationsList = useCustomAxiosInstance<ListNotifications>()

  return useCallback(
    (params?: NotificationsListParams) => {
      return notificationsList({ url: `/notifications/`, method: 'GET', params })
    },
    [notificationsList],
  )
}

export const getNotificationsListQueryKey = (params?: NotificationsListParams) => {
  return [`/notifications/`, ...(params ? [params] : [])] as const
}

export const useNotificationsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationsListQueryKey(params)

  const notificationsList = useNotificationsListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>> = ({ pageParam }) =>
    notificationsList({ ...params, page: pageParam || params?.['page'] })

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationsListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>
>
export type NotificationsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary List notifications
 */
export const useNotificationsListInfinite = <
  TData = Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>, TError, TData>
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useNotificationsListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useNotificationsListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationsListQueryKey(params)

  const notificationsList = useNotificationsListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>> = () =>
    notificationsList(params)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationsListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>>
export type NotificationsListQueryError = ErrorType<unknown>

/**
 * @summary List notifications
 */
export const useNotificationsList = <
  TData = Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useNotificationsListHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useNotificationsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Create a notification
 */
export const useNotificationsCreateHook = () => {
  const notificationsCreate = useCustomAxiosInstance<Notification>()

  return useCallback(
    (notification: NonReadonly<Notification>) => {
      return notificationsCreate({
        url: `/notifications/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: notification,
      })
    },
    [notificationsCreate],
  )
}

export const useNotificationsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsCreateHook>>>,
    TError,
    { data: NonReadonly<Notification> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useNotificationsCreateHook>>>,
  TError,
  { data: NonReadonly<Notification> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const notificationsCreate = useNotificationsCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useNotificationsCreateHook>>>,
    { data: NonReadonly<Notification> }
  > = (props) => {
    const { data } = props ?? {}

    return notificationsCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type NotificationsCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useNotificationsCreateHook>>>
>
export type NotificationsCreateMutationBody = NonReadonly<Notification>
export type NotificationsCreateMutationError = ErrorType<unknown>

/**
 * @summary Create a notification
 */
export const useNotificationsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsCreateHook>>>,
    TError,
    { data: NonReadonly<Notification> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useNotificationsCreateHook>>>,
  TError,
  { data: NonReadonly<Notification> },
  TContext
> => {
  const mutationOptions = useNotificationsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const useNotificationsRetrieveHook = () => {
  const notificationsRetrieve = useCustomAxiosInstance<Notification>()

  return useCallback(
    (id: number, signal?: AbortSignal) => {
      return notificationsRetrieve({ url: `/notifications/${id}/`, method: 'GET', signal })
    },
    [notificationsRetrieve],
  )
}

export const getNotificationsRetrieveQueryKey = (id: number) => {
  return [`/notifications/${id}/`] as const
}

export const useNotificationsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useNotificationsRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useNotificationsRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationsRetrieveQueryKey(id)

  const notificationsRetrieve = useNotificationsRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useNotificationsRetrieveHook>>>> = ({ signal }) =>
    notificationsRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useNotificationsRetrieveHook>>>
>
export type NotificationsRetrieveQueryError = ErrorType<unknown>

export const useNotificationsRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useNotificationsRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useNotificationsRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useNotificationsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useNotificationsUpdateHook = () => {
  const notificationsUpdate = useCustomAxiosInstance<Notification>()

  return useCallback(
    (id: number, notification: NonReadonly<Notification>) => {
      return notificationsUpdate({
        url: `/notifications/${id}/`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: notification,
      })
    },
    [notificationsUpdate],
  )
}

export const useNotificationsUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsUpdateHook>>>,
    TError,
    { id: number; data: NonReadonly<Notification> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useNotificationsUpdateHook>>>,
  TError,
  { id: number; data: NonReadonly<Notification> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const notificationsUpdate = useNotificationsUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useNotificationsUpdateHook>>>,
    { id: number; data: NonReadonly<Notification> }
  > = (props) => {
    const { id, data } = props ?? {}

    return notificationsUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type NotificationsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useNotificationsUpdateHook>>>
>
export type NotificationsUpdateMutationBody = NonReadonly<Notification>
export type NotificationsUpdateMutationError = ErrorType<unknown>

export const useNotificationsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsUpdateHook>>>,
    TError,
    { id: number; data: NonReadonly<Notification> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useNotificationsUpdateHook>>>,
  TError,
  { id: number; data: NonReadonly<Notification> },
  TContext
> => {
  const mutationOptions = useNotificationsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const useNotificationsPartialUpdateHook = () => {
  const notificationsPartialUpdate = useCustomAxiosInstance<Notification>()

  return useCallback(
    (id: number, patchedNotification: NonReadonly<PatchedNotification>) => {
      return notificationsPartialUpdate({
        url: `/notifications/${id}/`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: patchedNotification,
      })
    },
    [notificationsPartialUpdate],
  )
}

export const useNotificationsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsPartialUpdateHook>>>,
    TError,
    { id: number; data: NonReadonly<PatchedNotification> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useNotificationsPartialUpdateHook>>>,
  TError,
  { id: number; data: NonReadonly<PatchedNotification> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const notificationsPartialUpdate = useNotificationsPartialUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useNotificationsPartialUpdateHook>>>,
    { id: number; data: NonReadonly<PatchedNotification> }
  > = (props) => {
    const { id, data } = props ?? {}

    return notificationsPartialUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type NotificationsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useNotificationsPartialUpdateHook>>>
>
export type NotificationsPartialUpdateMutationBody = NonReadonly<PatchedNotification>
export type NotificationsPartialUpdateMutationError = ErrorType<unknown>

export const useNotificationsPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsPartialUpdateHook>>>,
    TError,
    { id: number; data: NonReadonly<PatchedNotification> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useNotificationsPartialUpdateHook>>>,
  TError,
  { id: number; data: NonReadonly<PatchedNotification> },
  TContext
> => {
  const mutationOptions = useNotificationsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Remove a notification
 */
export const useNotificationsDestroyHook = () => {
  const notificationsDestroy = useCustomAxiosInstance<void>()

  return useCallback(
    (id: number) => {
      return notificationsDestroy({ url: `/notifications/${id}/`, method: 'DELETE' })
    },
    [notificationsDestroy],
  )
}

export const useNotificationsDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsDestroyHook>>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useNotificationsDestroyHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const notificationsDestroy = useNotificationsDestroyHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useNotificationsDestroyHook>>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return notificationsDestroy(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type NotificationsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useNotificationsDestroyHook>>>
>

export type NotificationsDestroyMutationError = ErrorType<unknown>

/**
 * @summary Remove a notification
 */
export const useNotificationsDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsDestroyHook>>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useNotificationsDestroyHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = useNotificationsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Get count of unseen notifications
 */
export const useNotificationsAlertCountRetrieveHook = () => {
  const notificationsAlertCountRetrieve = useCustomAxiosInstance<AlertCountResponse>()

  return useCallback(
    (signal?: AbortSignal) => {
      return notificationsAlertCountRetrieve({ url: `/notifications/alert-count/`, method: 'GET', signal })
    },
    [notificationsAlertCountRetrieve],
  )
}

export const getNotificationsAlertCountRetrieveQueryKey = () => {
  return [`/notifications/alert-count/`] as const
}

export const useNotificationsAlertCountRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountRetrieveHook>>>, TError, TData>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNotificationsAlertCountRetrieveQueryKey()

  const notificationsAlertCountRetrieve = useNotificationsAlertCountRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountRetrieveHook>>>> = ({
    signal,
  }) => notificationsAlertCountRetrieve(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NotificationsAlertCountRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountRetrieveHook>>>
>
export type NotificationsAlertCountRetrieveQueryError = ErrorType<unknown>

/**
 * @summary Get count of unseen notifications
 */
export const useNotificationsAlertCountRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountRetrieveHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useNotificationsAlertCountRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Mark notifications as seen
 */
export const useNotificationsAlertCountCreateHook = () => {
  const notificationsAlertCountCreate = useCustomAxiosInstance<AlertCountResponse>()

  return useCallback(() => {
    return notificationsAlertCountCreate({ url: `/notifications/alert-count/`, method: 'POST' })
  }, [notificationsAlertCountCreate])
}

export const useNotificationsAlertCountCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountCreateHook>>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountCreateHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const notificationsAlertCountCreate = useNotificationsAlertCountCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountCreateHook>>>,
    void
  > = () => {
    return notificationsAlertCountCreate()
  }

  return { mutationFn, ...mutationOptions }
}

export type NotificationsAlertCountCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountCreateHook>>>
>

export type NotificationsAlertCountCreateMutationError = ErrorType<unknown>

/**
 * @summary Mark notifications as seen
 */
export const useNotificationsAlertCountCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountCreateHook>>>,
    TError,
    void,
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useNotificationsAlertCountCreateHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useNotificationsAlertCountCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
