import {
  HighlightedMatchesAndEntities,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  ScrollAreaViewPort,
  formatDate,
} from '@opoint/infomedia-storybook'
import { ReactNode } from 'react'

import { articleIdFromIds } from '../../../opoint/articles'
import { getDateVariant, getOpointLocale } from '../../../selectors/settingsSelectors'
import { useAppSelector } from '../../hooks/useAppSelector'
import SimilarityIndicator from '../Article/common/IdenticalArticles/SimilarityIndicator'
import { M360Article } from '../../../opoint/articles/types'

type NonInteractiveIdenticalArticlesProps = {
  defaultShow: boolean
  similarArticles?: M360Article[]
  activeArticle: M360Article
  trigger: ReactNode
}

const NonInteractiveIdenticalArticles = ({
  activeArticle,
  similarArticles = [],
  defaultShow,
  trigger,
}: NonInteractiveIdenticalArticlesProps) => {
  const locale = useAppSelector(getOpointLocale)
  const dateVariant = useAppSelector(getDateVariant)

  const similarArticlesWithoutTheMainArticle = similarArticles.filter(
    ({ id_article, id_site }) => id_article !== activeArticle.id_article || id_site !== activeArticle.id_site,
  )

  return (
    <Popover defaultOpen={defaultShow}>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent side="top" className="px-0 py-5">
        <ScrollArea>
          <ScrollAreaViewPort className="max-h-80">
            <ul className="space-y-5">
              {similarArticlesWithoutTheMainArticle?.map((similarArticle) => (
                <li
                  key={articleIdFromIds(similarArticle.id_site, similarArticle.id_article)}
                  className="flex w-80 flex-col items-start justify-between gap-1.5 px-4 focus-visible:rounded-sm focus-visible:outline-none focus-visible:ring focus-visible:ring-sky.6"
                >
                  <span className="font-medium">
                    <HighlightedMatchesAndEntities
                      text={similarArticle.first_source.name}
                      showMatches={false}
                      showEntities={false}
                    />
                  </span>
                  <span>
                    <HighlightedMatchesAndEntities
                      text={similarArticle.header?.text}
                      showMatches={false}
                      showEntities={false}
                    />
                  </span>
                  <span className="text-sky.cloudy">
                    {formatDate({ dateVariant, localeName: locale, unixTimestamp: similarArticle.unix_timestamp })}
                    <SimilarityIndicator article={similarArticle} activeArticle={activeArticle} />
                  </span>
                </li>
              ))}
            </ul>
          </ScrollAreaViewPort>
        </ScrollArea>
      </PopoverContent>
    </Popover>
  )
}

export default NonInteractiveIdenticalArticles
