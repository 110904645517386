import { of, throwError } from 'rxjs'

import { LogoutAction, ServerNotRespondingAction, TokenExpiredAction } from '../actions/auth'

export const logOutOnExpiredToken = (val) =>
  val.status === 401
    ? of<LogoutAction | TokenExpiredAction>({ type: 'LOGOUT' }, { type: 'TOKEN_EXPIRED' })
    : throwError(val)

export const serverIsDown = (val) =>
  val.status === 0 || val.status >= 500
    ? of<ServerNotRespondingAction>({ type: 'SERVER_NOT_RESPONDING' })
    : throwError(val)
