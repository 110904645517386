import { Outlet } from 'react-router-dom'
import AlertActionBar from '../../components/layout/actionBar/alerts/AlertActionBar'
import { useAppSelector } from '../../components/hooks/useAppSelector'
import { getAlertRecipientsListOpen } from '../../selectors/uiSelectors'
import RecipientsListModal from '../../components/alerts/RecipientsListModal/RecipientsListModal'
import Header from '../../components/topNav/Header'
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'
import { ModulePermissionCheck } from '../permissions/ModulePermissionCheck'

const AlertsPage = () => {
  const isAlertRecipientsListOpen = useAppSelector(getAlertRecipientsListOpen)

  return (
    <ModulePermissionCheck module="ALERT_MODULE">
      <Header>
        <Breadcrumbs />
      </Header>
      <AlertActionBar />
      <div className="h-full overflow-y-auto overflow-x-hidden">
        <Outlet />
      </div>

      {isAlertRecipientsListOpen && <RecipientsListModal />}
    </ModulePermissionCheck>
  )
}

export default AlertsPage
