import { cn } from '@opoint/infomedia-storybook'

import { useOpointUser } from '@opoint/authjs-react'
import { useLocalStorage } from 'usehooks-ts'
import { PROFILES_TO_WATCH_IDS_KEY } from '../../../../constants/localStorage'
import { WATCH_INDEX_LIMIT } from '../../../../opoint/profiles'
import { getNewArticlesCountByWatchId } from '../../../../selectors/watchIndexSelectors'
import { useAppSelector } from '../../../hooks/useAppSelector'

const NavigationNewMentionsBadge = ({ profileId, inverted = false }: { profileId: number; inverted?: boolean }) => {
  const user = useOpointUser()
  const [profilesToWatchIdsByUser] = useLocalStorage(PROFILES_TO_WATCH_IDS_KEY, undefined)
  // @ts-expect-error: Muted so we could enable TS strict mode
  const watchId = profilesToWatchIdsByUser?.[user?.user_id]?.[profileId]
  const newArticlesCount = useAppSelector(getNewArticlesCountByWatchId(watchId))
  const count = newArticlesCount > WATCH_INDEX_LIMIT ? `${WATCH_INDEX_LIMIT}+` : newArticlesCount
  const hasNew = newArticlesCount > 0

  if (!hasNew) {
    return null
  }

  return (
    <div
      className={cn(
        'flex w-fit min-w-[20px] cursor-pointer items-center justify-center rounded-full bg-sky.1 px-1.5 py-0.5 text-xs font-bold text-white',
        {
          'text-sky.1 bg-white': inverted,
        },
      )}
    >
      {count}
    </div>
  )
}

export default NavigationNewMentionsBadge
