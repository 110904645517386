import { useMemo } from 'react'

import { getSentimentOptions, getShowGeneralSentiment, getUISetting } from '../../selectors/settingsSelectors'

import { useAppSelector } from './useAppSelector'

const useSentimentAttributes = (): {
  showGeneralSentiment: boolean
  showCompanySentiment: boolean
  canOverride: boolean
  showMostPopular: boolean
  canModifyGroups: boolean
} => {
  const showGeneralSentiment = useAppSelector(getShowGeneralSentiment)
  const showCompanySentiment = useAppSelector(getUISetting('SHOW_COMPANY_SENTIMENT')) ?? false
  const { general, company, override, mostPopular, modifyGroups } = useAppSelector(getSentimentOptions)

  const sentimentDisplayValues = useMemo(
    () => ({
      showGeneralSentiment: showGeneralSentiment && !!general,
      showCompanySentiment: showCompanySentiment && !!company,
      canOverride: !!override,
      showMostPopular: !!mostPopular,
      canModifyGroups: !!modifyGroups,
    }),
    [showCompanySentiment, showGeneralSentiment, company, general, override, mostPopular, modifyGroups],
  )

  return sentimentDisplayValues
}

export default useSentimentAttributes
