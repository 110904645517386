import { cn, formatDate } from '@opoint/infomedia-storybook'

import { articleIdFromIds } from '../../../../../opoint/articles'
import { TAG_TYPES } from '../../../../../opoint/tags'
import { getDateVariant, getOpointLocale } from '../../../../../selectors/settingsSelectors'
import { getLastUsedTag } from '../../../../../selectors/tagsComposedSelectors'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import ArticleTags from '../../../../tags/Tag'
import { Tag } from '../../../../types/tag'

import { M360Article } from '../../../../../opoint/articles/types'
import SimilarityIndicator from './SimilarityIndicator'

type IdenticalArticlesItemProps = {
  article: M360Article
  activeArticle: M360Article
  isProfileEditor: boolean
  isAlert: boolean
  identicalArticle: M360Article
  index: number
}

const IdenticalArticlesItem = ({
  article,
  activeArticle,
  isProfileEditor,
  identicalArticle,
  index,
}: IdenticalArticlesItemProps) => {
  const locale = useAppSelector(getOpointLocale)
  const dateVariant = useAppSelector(getDateVariant)

  const dispatch = useAppDispatch()
  const lastUsedTag = useAppSelector(getLastUsedTag)

  const defaultLastUsedTag = lastUsedTag && [TAG_TYPES.KEYWORD, TAG_TYPES.MENTOMETER]?.includes(lastUsedTag.type)
  const chosenArticle = identicalArticle === activeArticle ? activeArticle : identicalArticle
  const isActive = identicalArticle === activeArticle || (activeArticle === undefined && index === 0)
  const name = chosenArticle.first_source.name

  const handleIdenticalArticleClick = () =>
    dispatch({
      type: isProfileEditor ? 'SET_ACTIVE_PROFILE_EDITOR_PREVIEW_IDENTICAL' : 'SET_ACTIVE_IDENTICAL',
      payload: { article, index },
    })

  const toggleIdenticalArticle = () =>
    dispatch({
      type: 'TOGGLE_TAG_IDENTICAL_ARTICLE',
      payload: {
        tag: lastUsedTag as Tag,
        article: identicalArticle,
        originalArticle: article,
      },
    })

  const handleUpdateTagWeight = (increment: boolean) => {
    dispatch({
      type: increment ? 'INCREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE' : 'DECREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE',
      payload: { lastUsedTag: lastUsedTag as Tag, identicalArticle, originalArticle: article },
    })
  }

  return (
    <li
      key={articleIdFromIds(identicalArticle.id_site, identicalArticle.id_article)}
      className={cn('w-80 cursor-pointer hover:bg-sky.9', {
        'bg-sky.8 hover:bg-sky.8': isActive,
      })}
    >
      <button
        type="button"
        onClick={handleIdenticalArticleClick}
        className="flex w-full flex-col items-start justify-between gap-1.5 px-4 py-2 text-left focus-visible:rounded-sm focus-visible:outline-none focus-visible:ring focus-visible:ring-sky.6 focus-visible:ring-offset-2"
      >
        <span className={cn({ 'font-medium': isActive })}>{name}</span>

        <span className="text-sky.cloudy">
          {formatDate({ dateVariant, localeName: locale, unixTimestamp: chosenArticle.unix_timestamp })}
          <SimilarityIndicator article={identicalArticle} activeArticle={activeArticle} />
        </span>

        {defaultLastUsedTag && (
          <ArticleTags
            article={identicalArticle}
            tags={[lastUsedTag]}
            tagsContainer="uploadArticleFooter"
            setIdenticalArticleTag={toggleIdenticalArticle}
            handleUpdateTagWeight={handleUpdateTagWeight}
          />
        )}
      </button>
    </li>
  )
}

export default IdenticalArticlesItem
