import { useLocation } from 'react-router-dom'

import { ActionBarRightItem } from '../types'
import { useDefaultAlertsActions } from './useDefaultAlertsActions'
import { useEditAlertActions } from './useEditAlertActions'
import { useNewAlertActions } from './useNewAlertActions'

export const useAlertsActions = (): ActionBarRightItem[] => {
  const { pathname } = useLocation()

  const actions = {
    newAlert: useNewAlertActions(),
    ediAlert: useEditAlertActions(),
    default: useDefaultAlertsActions(),
  }

  const isCreateAlert = pathname.includes('/new')
  const isEditAlert = pathname.includes('/edit')

  if (isCreateAlert) {
    return actions.newAlert
  }

  if (isEditAlert) {
    return actions.ediAlert
  }

  return actions.default
}
