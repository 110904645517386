import { produce } from 'immer'

import { AppActions } from '../actions'
import type { ImpersonationTarget } from '../components/types/user'

export type ImpersonationState = {
  organizations: ImpersonationTarget[]
  users: ImpersonationTarget[]
  forUsersList: ImpersonationTarget[]
  initialLoading: boolean
  loading: boolean
}

export const initialState: ImpersonationState = {
  organizations: [],
  users: [],
  forUsersList: [],
  initialLoading: true,
  loading: false,
}

const impersonationReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'IMPERSONATION_ORGANIZATIONS_FETCH_SUCCESS': {
      draftState.organizations = action.payload
      break
    }

    case 'IMPERSONATION_USERS_FETCH': {
      draftState.loading = true
      break
    }

    case 'IMPERSONATION_USERS_FETCH_SUCCESS': {
      draftState.users = action.payload
      draftState.loading = false
      break
    }

    case 'FETCH_USERS_SUCCESS': {
      draftState.forUsersList = action.payload
      draftState.initialLoading = false
      break
    }
  }
}, initialState)

export default impersonationReducer
