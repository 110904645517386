import { MultiFilterDropdownOption, SingleFilterDropdownOption, TagColor } from '@opoint/infomedia-storybook'

import { SuggestionResponseMultiple } from '../api/opoint-search-suggest.schemas'
import { prepareGroup, prepareProfiles, prepareTags } from '../components/filters/FilterSuggestions/helpers'
import { Profile } from '../components/types/profile'
import { SimpleSuggestion } from '../components/types/search'
import { FilterType } from '../components/types/settings'
import { Tag } from '../components/types/tag'

export const filterOptionsByQuery = (options: MultiFilterDropdownOption[], query?: string) => {
  return !query
    ? options
    : options?.filter(({ label }) =>
        label.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
      )
}

export const createSingleFilterDropdownOptions = <T>(
  options: readonly T[],
  valueKey: keyof T,
  labelKey: keyof T,
): SingleFilterDropdownOption[] => {
  if (options) {
    return options.map((option) => ({ value: option[valueKey], label: option[labelKey] } as SingleFilterDropdownOption))
  }
  return []
}

export const createMultiFilterDropdownOptions = <T>(
  options: T[],
  valueKey: keyof T,
  labelKey: keyof T,
  getColor?: (option: T) => TagColor,
  groupId?: keyof T,
): MultiFilterDropdownOption[] => {
  return options.map(
    (option) =>
      ({
        value: option[valueKey],
        label: option[labelKey],
        color: getColor?.(option),
        // @ts-expect-error: Muted so we could enable TS strict mode
        groupId: option[groupId],
      } as MultiFilterDropdownOption),
  )
}

export const getNonDuplicateObject = (arr1: MultiFilterDropdownOption[], arr2: MultiFilterDropdownOption[]) => {
  return arr1.concat(arr2).find((item, _, combined) => combined.indexOf(item) === combined.lastIndexOf(item))
}

export type UseFiltersSuggestionsData = {
  suggestions: SimpleSuggestion[]
  isLoading: boolean
}

export const ACCESS_GROUP_TAG = 'tag'
export const ACCESS_GROUP_PROFILE = 'profile'
export const ACCESS_GROUP_PERIOD = '-1024'

export const getFilterSuggestions = (
  data: SuggestionResponseMultiple,
  isDrilldown: boolean,
  filtersOrder: FilterType[],
  tags: Tag[],
  profiles: Profile[],
  activeAccessGroup: string,
  debouncedSearchTerm: string,
  isSingleFilter: boolean,
): SimpleSuggestion[] => {
  const isTagAccessGroup = activeAccessGroup === ACCESS_GROUP_TAG
  const isProfileAccessGroup = activeAccessGroup === ACCESS_GROUP_PROFILE

  if (!isTagAccessGroup && !isProfileAccessGroup) {
    // Backend sends us timePeriod, but in all remainder of the app, ----
    // we and backend work with timeperiod - the case is different,
    // so we unite that here
    const { timePeriod, ...rest } = data
    const preparedData = { ...rest, timeperiod: timePeriod }

    if (isDrilldown) {
      const groups = Object.values(preparedData).filter(
        (group) => typeof group !== 'number' && typeof group !== 'string',
      )

      // @ts-expect-error: Muted so we could enable TS strict mode
      return groups.map((group) => prepareGroup(group as any, isSingleFilter, debouncedSearchTerm))
    }

    // @ts-expect-error: Muted so we could enable TS strict mode
    return filtersOrder
      .map((filter) => {
        if (filter === FilterType.Profiles) {
          return prepareProfiles({
            suggestions: profiles,
            isProfileAccessGroup,
            isDrilldown,
            searchTerm: debouncedSearchTerm,
          })
        }
        if (filter === FilterType.Tags) {
          return prepareTags({
            suggestions: tags,
            isTagAccessGroup,
            isDrilldown,
            searchTerm: debouncedSearchTerm,
          })
        }

        return prepareGroup(preparedData[filter], isSingleFilter, debouncedSearchTerm)
      })
      .filter(Boolean)
  }

  // @ts-expect-error: Muted so we could enable TS strict mode
  return filtersOrder
    .map((filter) => {
      if (filter === FilterType.Profiles) {
        return prepareProfiles({
          suggestions: profiles,
          isProfileAccessGroup,
          isDrilldown,
          searchTerm: debouncedSearchTerm,
        })
      }
      if (filter === FilterType.Tags) {
        return prepareTags({
          suggestions: tags,
          isTagAccessGroup,
          isDrilldown,
          searchTerm: debouncedSearchTerm,
        })
      }
    })
    .filter(Boolean)
}
