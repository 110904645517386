export enum ActionVariant {
  LONG = 'long',
  SHORT = 'short',
  MENU = 'menu',
}

export type ActionBarActionsProps = {
  variant?: ActionVariant
  name: string
  className?: string
}

export enum AvailablePosition {
  Bar = 1,
  All = 2,
  Menu = 3,
}

export type ActionBarRightItem = {
  Action: ({ variant }: ActionBarActionsProps) => JSX.Element
  name: string
  allowedPosition?: AvailablePosition
}
