import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import styles from './Expand.module.scss'
import extractTransitionClasses from './extractTransitionClasses'

const DEFAULT_TIMEOUT = 300
const SAFETY_MARGIN = 20

type ExpandProps = {
  children: React.ReactNode
  in: boolean
}

const Expand = ({ children, in: show }: ExpandProps) => {
  const sizeTargetRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (sizeTargetRef.current) {
      setHeight(sizeTargetRef.current?.offsetHeight)
    }
  }, [show, children])

  return (
    <CSSTransition
      classNames={extractTransitionClasses({
        styles,
        className: 'expand',
        appearKey: 'enter',
      })}
      in={show}
      timeout={DEFAULT_TIMEOUT}
      appear
    >
      <div
        className={styles.expand}
        style={{
          height: show && height ? height + SAFETY_MARGIN : 0,
          transitionDuration: `${DEFAULT_TIMEOUT}ms`,
        }}
      >
        <div ref={sizeTargetRef}>{children}</div>
      </div>
    </CSSTransition>
  )
}

export default Expand
