import { Trans } from 'react-i18next'
import { M360Article } from '../../../../../opoint/articles/types'

const Divider = () => <span> | </span>

type SimilarityIndicatorProps = {
  article: M360Article
  activeArticle: M360Article
}

const SimilarityIndicator = ({ article, activeArticle }: SimilarityIndicatorProps) => {
  if (activeArticle === article) {
    return <span />
  }

  const almostIdentical = activeArticle.almost_identical?.filter(
    (data) => data.id_article === article.id_article && data.id_site === article.id_site,
  )

  // @ts-expect-error: Muted so we could enable TS strict mode
  if (!activeArticle.almost_identical || !almostIdentical.length) {
    return (
      <span>
        <Divider /> <Trans>Identical</Trans>
      </span>
    )
  }

  return (
    <span>
      <Divider />
      <span>
        {/* @ts-expect-error: Muted so we could enable TS strict mode */}
        {almostIdentical[0].sim}
        % <Trans>similar</Trans>
      </span>
    </span>
  )
}

export default SimilarityIndicator
