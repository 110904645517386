import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { combineLatest, from, merge, of } from 'rxjs'
import { catchError, map, switchMap, take } from 'rxjs/operators'

import { AppActions } from '../actions'
import { ImpersonateSuccessAction, LogInSuccessAction } from '../actions/auth'
import { SettingsFetchSuccessAction } from '../actions/settings'
import {
  FetchTemplateDetailAction,
  FetchTemplateDetailFailureAction,
  FetchTemplateDetailSuccessAction,
  SaveTemplateAction,
  SaveTemplateFailureAction,
  SaveTemplateSuccessAction,
  TemplateDeleteAction,
  TemplateDeleteFailureAction,
  TemplateDeleteSuccessAction,
  TemplateNewFailureAction,
  TemplateNewSuccessAction,
  TemplatePreviewFetchAction,
  TemplatePreviewFetchFailureAction,
  TemplatePreviewFetchSuccessAction,
  TemplatePreviewModalOpenAction,
  TemplatesFetchAction,
  TemplatesFetchFailureAction,
  TemplatesFetchSuccessAction,
  TemplatesPreviewFetchFailureAction,
  TemplatesPreviewFetchSuccessAction,
} from '../actions/templates'
import {
  createTemplate,
  deleteTemplate,
  getTemplate,
  getTemplatePreview,
  getTemplates,
  saveTemplate,
} from '../opoint/templates'
import { RootState } from '../reducers'
import { getOpointLocale } from '../selectors/settingsSelectors'
import { getActiveTemplate } from '../selectors/templatesSelectors'

import { TemplateEditorFormModalCloseAction } from '../actions/ui'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

export const fetchTemplatePreviewEpic = (actions$: ActionsObservable<AppActions>) =>
  actions$.pipe(
    ofType<AppActions, TemplatePreviewModalOpenAction>('TEMPLATE_PREVIEW_MODAL_OPEN'),
    switchMap(({ payload }) =>
      from(getTemplatePreview(payload)).pipe(
        map(
          (templatePreview) =>
            ({
              type: 'TEMPLATE_PREVIEW_FETCH_SUCCESS',
              payload: templatePreview,
            } as TemplatesPreviewFetchSuccessAction),
        ),
        catchError(() => of<TemplatesPreviewFetchFailureAction>({ type: 'TEMPLATE_PREVIEW_FETCH_FAILURE' })),
      ),
    ),
  )

export const fetchPreviewEpic = (actions$: ActionsObservable<AppActions>) =>
  actions$.pipe(
    ofType<AppActions, TemplatePreviewFetchAction>('TEMPLATE_PREVIEW_FETCH'),
    switchMap(({ payload: { template } }) =>
      template
        ? from(getTemplatePreview(template.id)).pipe(
            map(
              (data) =>
                ({
                  type: 'ALERT_TEMPLATE_FETCH_SUCCESS',
                  payload: {
                    id: template.id,
                    preview: data,
                  },
                } as TemplatePreviewFetchSuccessAction),
            ),
            catchError(logOutOnExpiredToken),
            catchError(serverIsDown),
            catchError(() => of<TemplatePreviewFetchFailureAction>({ type: 'ALERT_TEMPLATE_FETCH_FAILURE' })),
          )
        : of(),
    ),
  )

export const fetchTemplates = (
  actions$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  merge(
    actions$.ofType<TemplatesFetchAction>('TEMPLATES_FETCH'),
    combineLatest([
      actions$.pipe(ofType<AppActions, SettingsFetchSuccessAction>('SETTINGS_FETCH_SUCCESS'), take(1)),
      actions$.pipe(
        ofType<AppActions, LogInSuccessAction | ImpersonateSuccessAction>('LOG_IN_SUCCESS', 'IMPERSONATE_SUCCESS'),
      ),
    ]),
  ).pipe(
    switchMap(() => {
      const locale = getOpointLocale(state$.value)

      return from(getTemplates(locale)).pipe(
        map((data) => ({ type: 'TEMPLATES_FETCH_SUCCESS', payload: data } as TemplatesFetchSuccessAction)),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<TemplatesFetchFailureAction>({ type: 'TEMPLATES_FETCH_FAILURE' })),
      )
    }),
  )

export const fetchTemplateDetail = (
  actions$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  actions$.pipe(
    ofType<AppActions, FetchTemplateDetailAction>('FETCH_TEMPLATE_DETAIL'),
    switchMap(({ payload: { id } }) => {
      const locale = getOpointLocale(state$.value)

      return from(getTemplate(id, locale)).pipe(
        map(
          ({ meta: { idTemplate, maxLevel }, modules }) =>
            ({
              type: 'FETCH_TEMPLATE_DETAIL_SUCCESS',
              payload: {
                id: idTemplate,
                maxLevel,
                modules,
              },
            } as FetchTemplateDetailSuccessAction),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<FetchTemplateDetailFailureAction>({ type: 'FETCH_TEMPLATE_DETAIL_FAILURE' })),
      )
    }),
  )

export const saveTemplateEpic = (
  actions$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  actions$.pipe(
    ofType<AppActions, SaveTemplateAction>('TEMPLATE_SAVE'),
    switchMap(({ payload: { isNewTemplate, values } }) => {
      const state = state$.value
      const activeTemplate = getActiveTemplate(state)

      const templateName = values.find((item) => item.name === 'REPORT_LIST_NAME')?.value.toString()

      if (!isNewTemplate) {
        return from(saveTemplate(values, activeTemplate?.id)).pipe(
          switchMap(({ meta: { idTemplate, maxLevel }, modules }) =>
            of<
              | SaveTemplateSuccessAction
              | TemplateEditorFormModalCloseAction
              | TemplatesFetchAction
              | FetchTemplateDetailSuccessAction
            >(
              { type: 'TEMPLATE_SAVE_SUCCESS' },
              { type: 'TEMPLATE_EDITOR_FORM_MODAL_CLOSE' },
              { type: 'TEMPLATES_FETCH' },
              {
                type: 'FETCH_TEMPLATE_DETAIL_SUCCESS',
                payload: {
                  id: idTemplate,
                  maxLevel,
                  modules,
                },
              },
            ),
          ),
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError(() =>
            of<SaveTemplateFailureAction | TemplateEditorFormModalCloseAction>(
              { type: 'TEMPLATE_SAVE_FAILURE' },
              { type: 'TEMPLATE_EDITOR_FORM_MODAL_CLOSE' },
            ),
          ),
        )
      }

      const template = {
        name: templateName ?? 'NEW TEMPLATE',
        type: activeTemplate.type,
        settings: values,
      }

      return from(createTemplate(template)).pipe(
        switchMap(() =>
          of<TemplateNewSuccessAction | TemplateEditorFormModalCloseAction | TemplatesFetchAction>(
            { type: 'TEMPLATE_NEW_SUCCESS' },
            { type: 'TEMPLATE_EDITOR_FORM_MODAL_CLOSE' },
            { type: 'TEMPLATES_FETCH' },
          ),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() =>
          of<TemplateNewFailureAction | TemplateEditorFormModalCloseAction>(
            { type: 'TEMPLATE_NEW_FAILURE' },
            { type: 'TEMPLATE_EDITOR_FORM_MODAL_CLOSE' },
          ),
        ),
      )
    }),
  )

export const deleteTemplateEpic = (
  actions$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  actions$.pipe(
    ofType<AppActions, TemplateDeleteAction>('TEMPLATE_DELETE'),
    switchMap(({ payload }) => {
      const templateIdToBeDeleted = payload || getActiveTemplate(state$.value).id

      return from(deleteTemplate(templateIdToBeDeleted)).pipe(
        switchMap(() =>
          of<TemplateDeleteSuccessAction | TemplateEditorFormModalCloseAction | TemplatesFetchAction>(
            { type: 'TEMPLATE_DELETE_SUCCESS' },
            { type: 'TEMPLATE_EDITOR_FORM_MODAL_CLOSE' },
            { type: 'TEMPLATES_FETCH' },
          ),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<TemplateDeleteFailureAction>({ type: 'TEMPLATE_DELETE_FAILURE' })),
      )
    }),
  )

export default [
  deleteTemplateEpic,
  fetchPreviewEpic,
  fetchTemplateDetail,
  fetchTemplatePreviewEpic,
  fetchTemplates,
  saveTemplateEpic,
]
