import { ActionsObservable, StateObservable, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'

import { FeedGoToNewFeedAction, FeedNewErrorAction } from '../../actions/feeds'
import { searchDataToURLParam } from '../../opoint/search/url'
import { getSearchTimePeriod } from '../../selectors/searchSelectors'
import { logOutOnExpiredToken, serverIsDown } from '../epicsHelper'
import { getCurrentLocation, getCurrentPath } from '../../helpers/locationService'
import { router } from '../../routes'
import { Searchline } from '../../opoint/flow'
import { RootState } from '../../reducers'
import { GoToCustomerViewAction } from '../../actions/impersonation'
import { SearchAction, SearchDataClearAction } from '../../actions/search'
import { AppActions } from '../../actions'
import { GoToStatisticsComparisonAction } from '../../actions/statistics'
import { GoToSearchProfileFromArticleViewAction } from '../../actions/articles'
import { RouterLocationChangeAction } from '../../actions/router'

type searchAction = {
  payload: {
    searchline: Searchline
    pathname: string
    timestamp: number
  }
}

const goToCustomerView: (action$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, GoToCustomerViewAction>('GO_TO_CUSTOMER_VIEW'),
    tap(() => router.navigate('/customer_view')),
    map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
  )

const onSearch = (action$: ActionsObservable<AppActions>) => {
  return action$.pipe(
    ofType<AppActions, SearchAction>('SEARCH'),
    tap(({ payload: { searchline, pathname } }: searchAction) =>
      router.navigate(`${pathname}${searchDataToURLParam(searchline)}`),
    ),
    map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
  )
}

const onSearchDataClear = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, SearchDataClearAction>('SEARCHDATA_CLEAR'),
    tap(() => {
      const timePeriod = getSearchTimePeriod(state$.value)
      const location = getCurrentLocation()
      const newPath = `${location?.pathname}${searchDataToURLParam({ filters: timePeriod ? [timePeriod] : [] })}`

      router.navigate(newPath)
    }),
    map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
  )

const searchGoToStatisticsComparisonEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, GoToStatisticsComparisonAction>('GO_TO_STATISTICS_COMPARISON'),
    switchMap(() => {
      const location = getCurrentLocation()
      const isSavedStatistics = location?.pathname?.match(/^\/statistics\/(\d+)(?:\/.*)?$/)
      const statisticId = isSavedStatistics && parseInt(isSavedStatistics[1], 10)

      if (statisticId) {
        router.navigate(`/statistics/${statisticId}/compare/${location?.search}`)
        return of<RouterLocationChangeAction>({ type: 'ROUTER_LOCATION_CHANGE' })
      }

      router.navigate(`/statistics/compare/${location?.search}`)
      return of<RouterLocationChangeAction>({ type: 'ROUTER_LOCATION_CHANGE' })
    }),
  )

const goToSearchProfileFromArticleView = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, GoToSearchProfileFromArticleViewAction>('GO_TO_SEARCH_PROFILE_FROM_ARTICLE_VIEW'),
    switchMap(({ payload }) => {
      const newPath = payload ? `/search/?filters=profile:${payload}` : '/'
      return of(newPath).pipe(
        tap((path) => router.navigate(path)),
        map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
      )
    }),
  )

export const goToNewFeedEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, FeedGoToNewFeedAction>('GO_TO_NEW_FEED'),
    filter(() => getCurrentPath() !== '/feeds/new'),
    tap(() => router.navigate('/feeds/new')),
    map(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<FeedNewErrorAction>({ type: 'FEED_NEW_ERROR' })),
  )

export default [
  goToNewFeedEpic,
  onSearch,
  goToSearchProfileFromArticleView,
  searchGoToStatisticsComparisonEpic,
  onSearchDataClear,
  goToCustomerView,
]
