import { CHANGELOG_DEFAULT_VERSION, CHANGELOG_VERSION } from '../changelog/Changelog'
import { useLocalStorage } from '../hooks/useLocalStorage'

import { CHANGE_LOG_LAST_VERSION_KEY } from '../../constants/localStorage'
import { useAppDispatch } from '../hooks/useAppDispatch'
import ChangeLogPopover from './ChangePopover'

// For each change in the changelog, increment the CHANGELOG_VERSION number if you want the user to see a popup
// that links to the changelog and informs the user that the changelog has been changed.
const ChangeAnnouncement = () => {
  const dispatch = useAppDispatch()
  const [lastVersion, setLastVersion] = useLocalStorage(CHANGE_LOG_LAST_VERSION_KEY, CHANGELOG_DEFAULT_VERSION)
  const isNewVersion = CHANGELOG_VERSION > lastVersion

  if (!isNewVersion) {
    return null
  }

  const closePopover = () => setLastVersion(CHANGELOG_VERSION)
  const handleOnAction = () => {
    closePopover()
    dispatch({ type: 'SET_IS_CHANGELOG_OPEN', payload: true })
  }

  return <ChangeLogPopover onAction={handleOnAction} onClose={closePopover} />
}

export default ChangeAnnouncement
