import { createSelector } from 'reselect'

import { getUI as getState } from './rootSelectors'

export const getIsSettingsModalOpen = createSelector(getState, (ui) => ui.settingsModalOpen)

export const getSettingsModalDefaultSection = createSelector(getState, (ui) => ui.settingsModalDefaultSection)

export const getIsReportHistoryModalOpen = createSelector(getState, (ui) => ui.reportHistoryModalOpen)

export const getIsSourceListsModalOpen = createSelector(getState, (ui) => ui.sourceListsModalOpen)

export const getIsContactsModalOpen = createSelector(getState, (ui) => ui.contactsModalOpen)

export const getFilterDetailModalOpen = createSelector(getState, (ui) => ui.filterDetailModalOpen)

export const isEditorModalOpen = createSelector(getState, (ui) => ui.isEditorModalOpen)

export const isEditorFormModalOpen = createSelector(getState, (ui) => ui.isEditorFormModalOpen)

export const getIsShareArticleModalOpen = createSelector(getState, (ui) => ui.shareArticleModalOpen)

export const getClickedArticle = createSelector(getState, (ui) => ui.clickedArticle)

export const isSortableModuleModalOpen = createSelector(getState, (ui) => ui.sortableModuleModalOpen)

export const isReportModalOpen = createSelector(getState, (ui) => ui.reportsModalOpen)

export const isHelpModalOpen = createSelector(getState, (ui) => ui.helpModalOpen)

export const isDatepickerModalOpen = createSelector(getState, (ui) => ui.datepickerModalOpen)

export const isEditArticleModalOpen = createSelector(getState, (ui) => ui.editArticleModalOpen)

export const isAlertRemoveArticleOpen = createSelector(getState, (ui) => ui.alertRemoveArticleOpen)

export const getDeleteProfileConfirmationModalOpen = createSelector(getState, (ui) => ui.deleteProfileConfirmationOpen)

export const getRecipientDeleteConfirmationModal = createSelector(getState, (ui) => ui.recipientDeleteConfirmationModal)

export const getAlertChangeRecipientsOpen = createSelector(getState, (ui) => ui.alertChangeRecipientsOpen)

export const getAlertRecipientsListOpen = createSelector(getState, (ui) => ui.alertRecipientsListOpen)

export const getManageProfilesModalOpen = createSelector(getState, (ui) => ui.manageProfilesModalOpen)

export const getManageTagsModalOpen = createSelector(getState, (ui) => ui.manageTagsModalOpen)

export const getDeleteProfilesModeEnabled = createSelector(getState, (ui) => ui.deleteProfilesModeEnabled)

export const getInfoCalcModalOpen = createSelector(getState, (ui) => ui.infoCalcModalOpen)

export const getSupportIssueModalOpen = createSelector(getState, (ui) => ui.supportIssueModalOpen)

export const getSupportRequestModalOpen = createSelector(getState, (ui) => ui.supportRequestModalOpen)

export const getIsStatisticsSidebarOpen = createSelector(getState, (ui) => ui.statisticsSidebar.isOpen)

export const getAddArticleModalOpen = createSelector(getState, (ui) => ui.addArticleModalOpen)

export const getStatisticsSidebarContextType = createSelector(getState, (ui) => ui.statisticsSidebar.contextType)

export const getStatisticsSidebarChartType = createSelector(getState, (ui) => ui.statisticsSidebar.chartType)

export const getStatisticsSidebarGraph = createSelector(getState, (ui) => ui.statisticsSidebar.graph)

export const getStatisticsSidebarMenuList = createSelector(getState, (ui) => ui.statisticsSidebar.menuList)

export const getStatisticsSidebarCount = createSelector(getState, (ui) => ui.statisticsSidebar.count)

export const getCorrectStatsArticleNumbers = createSelector(getState, (ui) => ui.correctStatsArticleNumbers)

export const getStatsInfoModalOpen = createSelector(getState, (ui) => ui.statsInfoModalOpen)

export const getArticleModalStatus = createSelector(getState, (ui) => ui.articleModalStatus)

export const getDashboardModalState = createSelector(getState, (ui) => ui.dashboardModalState)

export const getIsStatisticsExportModalOpen = createSelector(getState, (ui) => ui.isStatisticsExportModalOpen)

export const getIsChangeLogOpen = createSelector(getState, (ui) => ui.isChangeLogOpen)

export const getIsUserInvitationModalOpen = createSelector(getState, (ui) => ui.isUserInvitationsModalOpen)
