import { Trans } from 'react-i18next'
import { Step } from 'react-joyride'

import { useLocation } from 'react-router-dom'
import { STATISTICS_TOUR_KEY } from '../../../constants/localStorage'
import { getStatDocuments } from '../../../selectors/statisticsSelectors'
import { useAppSelector } from '../../hooks/useAppSelector'
import ProductTour from '../ProductTour'
import { disabledBeaconAndAnimation, disabledScrolling } from '../constants'
import useDelayTour from '../useDelayTour'
import { prependHash } from '../utility'

export enum StatisticsTourTarget {
  STEP_1 = 'ST_STEP_1',
  STEP_2 = 'ST_STEP_2',
  STEP_3 = 'ST_STEP_3',
  STEP_4 = 'ST_STEP_4',
  STEP_5 = 'ST_STEP_5',
}
const steps: Step[] = [
  {
    target: prependHash(StatisticsTourTarget.STEP_1),
    content: <Trans>Here you can compare two time periods to e.g. see the effect of a press release</Trans>,
    placement: 'right-start',
    ...disabledBeaconAndAnimation,
    ...disabledScrolling,
  },
  {
    target: prependHash(StatisticsTourTarget.STEP_2),
    content: <Trans>With Edit Widgets, you can customize your statistics view with the insights relevant to you.</Trans>,
    placement: 'left-start',
    ...disabledBeaconAndAnimation,
    ...disabledScrolling,
  },
  {
    target: prependHash(StatisticsTourTarget.STEP_3),
    content: (
      <Trans>If you have customized the statistics, you can save it. You afterwards find it in the left-hand menu.</Trans>
    ),
    placement: 'left-start',
    ...disabledBeaconAndAnimation,
    ...disabledScrolling,
  },
  {
    target: prependHash(StatisticsTourTarget.STEP_4),
    content: <Trans>You can change the widget to another type of chart or curve here.</Trans>,
    placement: 'right-start',
    ...disabledBeaconAndAnimation,
    ...disabledScrolling,
  },
  {
    target: `${prependHash(StatisticsTourTarget.STEP_5)} div`,
    content: <Trans>You can filter by clicking directly in most widgets to explore your mentions further</Trans>,
    placement: 'right-start',
    ...disabledBeaconAndAnimation,
    ...disabledScrolling,
  },
]

const StatisticsTour = () => {
  const location = useLocation()
  const pathName = location.pathname
  const isStatisticsPage = pathName.includes('/statistics/')
  const documents = useAppSelector(getStatDocuments)
  const hasStatisticsData = documents?.length > 0

  const show = useDelayTour(isStatisticsPage && hasStatisticsData, 2000)

  if (!show) {
    return null
  }

  return <ProductTour tourKey={STATISTICS_TOUR_KEY} steps={steps} />
}

export default StatisticsTour
