import {
  MultiCombobox,
  MultiComboboxTrigger,
  MultiComboboxContent,
  MultiComboboxContentEmpty,
  MultiComboboxItem,
} from '@opoint/infomedia-storybook'

import classNames from 'classnames'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Tag } from '../../../types/tag'
import { CategorizationFolder } from '.'

type CategorizationMultiSelectProps = {
  onHandleTagArticle: ({ tag }: { tag: Tag | undefined }) => void
  onHandleUntagArticle: ({ tag }: { tag: Tag | undefined }) => void
  visibleListsOption: boolean
} & CategorizationFolder

const CategorizationMultiSelect = ({
  chosenTags,
  folderName,
  folderTags,
  isVisible,
  onHandleTagArticle,
  onHandleUntagArticle,
  visibleListsOption,
}: CategorizationMultiSelectProps): React.JSX.Element => {
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState('')

  const values = chosenTags.map((tag) => tag?.id.toString())

  const handleComboboxToggleTag = (changedTagIds: string[], chosenFolderTagsIds: string[], folderTags: Tag[]) => {
    const difference: Array<string> = []
    const isChosenTagsLonger = chosenFolderTagsIds.length > changedTagIds.length
    const tagsToIterate = isChosenTagsLonger ? chosenFolderTagsIds : changedTagIds

    if (changedTagIds.length === 0) {
      chosenFolderTagsIds.forEach((tagFolderId) => {
        const tagToToggle = folderTags.find((tag) => tag.id.toString() === tagFolderId)
        onHandleUntagArticle({ tag: tagToToggle })
      })

      return
    }

    tagsToIterate.forEach((key) => {
      if (!(isChosenTagsLonger ? changedTagIds : chosenFolderTagsIds).includes(key)) {
        difference.push(key)
      }
    })

    const tagToToggle = folderTags.find((tag) => tag.id.toString() === difference[0])

    if (isChosenTagsLonger) {
      // If the chosen tags are longer than the changed tags, it means that the tag was removed. (Untag)
      onHandleUntagArticle({ tag: tagToToggle })
    } else {
      // If the chosen tags are shorter than the changed tags, it means that the tag was added. (Tag)
      onHandleTagArticle({ tag: tagToToggle })
    }
  }

  const filteredFolderTags = folderTags.filter((tag) => tag.name.toLowerCase().includes(searchTerm.toLowerCase()))

  return (
    <MultiCombobox
      value={values}
      onChange={(changedTagIds) => handleComboboxToggleTag(changedTagIds, values, folderTags)}
    >
      <MultiComboboxTrigger
        id={`categorization_select_${folderName}`}
        displayValue={(value) => folderTags.find((tag) => tag.id.toString() === value)?.name || value}
        query={searchTerm}
        onQueryChange={(query) => setSearchTerm(query)}
        placeholder={t('Select tags')}
      />

      <MultiComboboxContent className={classNames({ relative: visibleListsOption })} static={isVisible}>
        {!filteredFolderTags.length ? (
          <MultiComboboxContentEmpty>
            <Trans>No results matching your search.</Trans>
          </MultiComboboxContentEmpty>
        ) : (
          filteredFolderTags.map((tag) => (
            <MultiComboboxItem key={tag.id} value={tag.id.toString()}>
              {tag.name}
            </MultiComboboxItem>
          ))
        )}
      </MultiComboboxContent>
    </MultiCombobox>
  )
}

export default CategorizationMultiSelect
