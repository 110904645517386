import { cn } from '@opoint/infomedia-storybook'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import { IMAGES } from '../../../../constants'
import { getHeaderUrls } from '../../../../opoint/articles'

type SocialAuthorProps = {
  author?: string
  content_protected?: number
  display_name: string
  hasHandle?: boolean
  isVerified?: boolean
  orig_url?: string
  showSmall?: boolean
  url?: string
  username: string
}

const SocialAuthor = ({
  author,
  content_protected,
  display_name,
  hasHandle,
  isVerified,
  orig_url,
  showSmall,
  url,
  username,
}: SocialAuthorProps) => {
  const { t } = useTranslation()

  const [urls, setUrls] = useState({ primary: '', secondary: '' })

  useEffect(() => {
    // @ts-expect-error: Muted so we could enable TS strict mode
    const newUrls: { primary: string; secondary: string } = getHeaderUrls({
      content_protected,
      orig_url,
      url,
    })

    setUrls(newUrls)
  }, [content_protected, orig_url, url])

  return (
    <div className={cn('flex flex-col space-y-0.5 text-label-l', { 'text-label-m': showSmall })}>
      <div className="flex items-center space-x-1">
        <a
          href={urls.primary || urls.secondary}
          target="_blank"
          className="font-medium text-black hover:text-black"
          rel="noreferrer"
        >
          {display_name ? display_name : author}
        </a>
        {isVerified && (
          <img
            className="mb-0 size-4"
            src={IMAGES.SoMe_verified}
            alt={t('Verified')}
            title={t('Verified')}
            loading="lazy"
          />
        )}
      </div>

      {hasHandle && display_name && (
        <a
          href={urls.primary || urls.secondary}
          target="_blank"
          className="text-sky.1 hover:text-sky.1"
          rel="noreferrer"
        >
          {username ?? author}
        </a>
      )}
    </div>
  )
}

export default SocialAuthor
