import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'

import { t } from "i18next";
import {
  AddTagFailureAction,
  AddTagSuccessAction,
  DeleteTagFailureAction,
  DeleteTagSuccessAction,
  EditTagFailureAction,
  EditTagSuccessAction,
  TagArticlesFailureAction,
  TagsActions,
  UntagArticlesFailureAction,
} from '../../../actions/tags'
import { ErrorEpic, ErrorPayload, handlerError } from '.'

const tagErrorTypes = (
  payload?: ErrorPayload,
): ErrorEpic<
  | AddTagSuccessAction['type']
  | DeleteTagSuccessAction['type']
  | EditTagSuccessAction['type']
  | TagArticlesFailureAction['type']
  | UntagArticlesFailureAction['type']
  | AddTagFailureAction['type']
  | EditTagFailureAction['type']
  | DeleteTagFailureAction['type']
> => {
  return {
    ['ADD_TAG_SUCCESS']: {
      isSuccess: true,
      message: t('We successfully added new tag'),
      toastId: 'ADD_TAG_SUCCESS',
    },
    ['DELETE_TAG_SUCCESS']: {
      isSuccess: true,
      message: t('We successfully deleted selected tag'),
      toastId: 'DELETE_TAG_SUCCESS',
    },
    ['EDIT_TAG_SUCCESS']: {
      isSuccess: true,
      message: t('We successfully edited selected tag'),
      toastId: 'EDIT_TAG_SUCCESS',
    },
    ['TAG_ARTICLES_FAILURE']: {
      isSuccess: false,
      message: t('We were not able to tag this article'),
      toastId: 'TAG_ARTICLES_FAILURE',
    },
    ['UNTAG_ARTICLES_FAILURE']: {
      isSuccess: false,
      message: t('We were not able to untag this article'),
      toastId: 'UNTAG_ARTICLES_FAILURE',
    },
    ['ADD_TAG_FAILURE']: {
      isSuccess: false,
      message: t('We were not able to add this tag'),
      toastId: 'ADD_TAG_FAILURE',
    },
    ['EDIT_TAG_FAILURE']: {
      isSuccess: false,
      message: t('We were not able to edit this tag'),
      toastId: 'EDIT_TAG_FAILURE',
    },
    ['DELETE_TAG_FAILURE']: {
      isSuccess: false,
      // @ts-expect-error: Muted so we could enable TS strict mode
      message: payload?.error,
      toastId: 'DELETE_TAG_FAILURE',
    },
  }
}

const tagErrorEpics: (action: ActionsObservable<TagsActions>) => void = (action$) =>
  action$.pipe(
    switchMap((action) =>
      'payload' in action
        ? handlerError(tagErrorTypes, action.type, action.payload as ErrorPayload)
        : handlerError(tagErrorTypes, action.type),
    ),
  )

export default tagErrorEpics
