import { handleErrors } from '../common'
import config from '../common/config'

export const fetchSentimentGroups: () => Promise<Response> = async () => {
  const request = new Request(config.url.api(`/sentiment/`), {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => result)
}

export const createSentimentGroup: (body: { name: string; ids: number[] }) => Promise<Response> = async (body) => {
  const request = new Request(config.url.api(`/sentiment/`), {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => result)
}

export const updateSentimentGroup: (groupId: number, ids: number[]) => Promise<Response> = async (groupId, ids) => {
  const request = new Request(config.url.api(`/sentiment/${groupId}/`), {
    ...(await config.request.getRequestHeaders()),
    method: 'PUT',
    body: JSON.stringify({ ids }),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => result)
}

export const overrideArticleCompanySentiment: (body: {
  id_group: number
  articles: Array<{ id_article: number; id_site: number; manual_sentiment: number }>
}) => Promise<Response> = async (body) => {
  const request = new Request(config.url.api(`/articles/tag_sentiment/`), {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response)
}
