import { getStatisticsList } from '../../../../../selectors/statisticsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useNavigationContext } from '../../NavigationContext'
import {
  NavigationSectionContent,
  NavigationSectionEmptySearchMessage,
  NavigationSectionSearchItem,
  NavigationSectionSearchItemLink,
} from '../../primitives/NavigationSection'

export const SavedStatisticsNavigationList = () => {
  const statistics = useAppSelector(getStatisticsList)

  const { searchQuery } = useNavigationContext()

  if (!searchQuery) {
    return null
  }

  const searchStatistics = statistics.filter(({ name }) => name.toLowerCase().includes(searchQuery.toLowerCase()))

  return (
    <NavigationSectionContent>
      {!searchStatistics.length ? (
        <NavigationSectionEmptySearchMessage />
      ) : (
        searchStatistics.map((statistics) => (
          <NavigationSectionSearchItem key={statistics.id}>
            <NavigationSectionSearchItemLink
              to={{
                pathname: `/statistics/${statistics.id}`,
              }}
            >
              {statistics.name}
            </NavigationSectionSearchItemLink>
          </NavigationSectionSearchItem>
        ))
      )}
    </NavigationSectionContent>
  )
}
