import React from 'react'
import { getArticleId, Loader } from '@opoint/infomedia-storybook'
import Preview from '../../components/articles/Article/Preview'
import { ExpansionMode } from '../../components/constants/common'
import { useAppSelector } from '../../components/hooks/useAppSelector'
import { M360Article } from '../../opoint/articles/types'
import { getArticles } from '../../selectors/articlesSelectors'
import { isSearchInProgress } from '../../selectors/searchSelectors'
import NoArticlesPage from '../../components/articles/Article/NoArticlesPage'

const SingleArticleView = (): React.JSX.Element => {
  const articles: M360Article[] = useAppSelector(getArticles)
  const searchInProgress = useAppSelector(isSearchInProgress)

  const article = articles[0]

  if (searchInProgress) {
    return (
      <div className="flex size-full items-center justify-center">
        <Loader />
      </div>
    )
  }

  if (!article) {
    return <NoArticlesPage isSearch />
  }

  return (
    <div className="relative m-auto w-full max-w-[40rem] overflow-auto">
      <Preview
        article={article}
        originalArticle={article}
        key={getArticleId(article)}
        isProfileEditor={false}
        index={0}
        expansion={ExpansionMode.FORCED}
      />
    </div>
  )
}

export default SingleArticleView
