import ModulePermissions from '../../common/ModulePermissions'
import { MODULES } from '../../constants/permissions'
import AddContentsTour from './AddContentsTour'
import AlertsTour from './AlertsTour'
import GeneralIntroProductTour from './GeneralIntroProductTour'
import ReportsTour from './ReportsTour'
import StatisticsTour from './StatisticsTour'
import TagsTour from './TagsTour'

export enum DisplayedTour {
  ALERTS = 'ALERTS',
  ADD_CONTENTS = 'ADD_CONTENTS',
  GENERAL_INTRO = 'GENERAL_INTRO',
  REPORTS = 'REPORTS',
  TAGS = 'TAGS',
  STATISTICS = 'STATISTICS',
}

type DisplayedTourOption = keyof typeof DisplayedTour

interface ProductToursProps {
  /**
   * Array of tours to be displayed.
   */
  displayedTours: Array<DisplayedTourOption>
}

const ProductTours = ({ displayedTours }: ProductToursProps) => (
  <ModulePermissions module="PRODUCT_TOUR" permissions={[MODULES.PRODUCT_TOUR.ON]}>
    <>
      {displayedTours.includes(DisplayedTour.ADD_CONTENTS) && <AddContentsTour />}
      {displayedTours.includes(DisplayedTour.ALERTS) && <AlertsTour />}
      {displayedTours.includes(DisplayedTour.GENERAL_INTRO) && <GeneralIntroProductTour />}
      {displayedTours.includes(DisplayedTour.REPORTS) && <ReportsTour />}
      {displayedTours.includes(DisplayedTour.TAGS) && <TagsTour />}
      {displayedTours.includes(DisplayedTour.STATISTICS) && <StatisticsTour />}
    </>
  </ModulePermissions>
)

export default ProductTours
