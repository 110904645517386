import { createSelector } from 'reselect'

import { getPreviewArticles } from './profilesSelectors'
import { isSearchExhausted } from './searchSelectors'

export const noPreviewArticlesFound = createSelector(
  getPreviewArticles,
  isSearchExhausted,
  (articles, isExhausted) => articles.length === 0 && isExhausted,
)
