import { ActionsObservable, StateObservable, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { AppActions } from '../actions'
import {
  AddArticleCommentAction,
  AddArticleCommentFailureAction,
  AddArticleCommentSuccessAction,
  DeleteArticleCommentAction,
  DeleteArticleCommentFailureAction,
  DeleteArticleCommentSuccessAction,
  EditArticleCommentAction,
  EditArticleCommentFailureAction,
  EditArticleCommentSuccessAction,
  FetchArticleCommentsFailureAction,
  FetchArticleCommentsSuccessAction,
  OpenArticleCommentModalAction,
} from '../actions/comments'
import {
  addArticleComment,
  deleteArticleComment,
  editArticleComment,
  fetchArticleComments,
} from '../opoint/common/comments'
import { RootState } from '../reducers'
import { getArticleDetails } from '../selectors/commentsSelectors'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const fetchCommentsEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<
      AppActions,
      | OpenArticleCommentModalAction
      | AddArticleCommentSuccessAction
      | DeleteArticleCommentSuccessAction
      | EditArticleCommentSuccessAction
    >(
      'OPEN_ARTICLE_COMMENTS_MODAL',
      'ADD_ARTICLE_COMMENT_SUCCESS',
      'DELETE_ARTICLE_COMMENT_SUCCESS',
      'EDIT_ARTICLE_COMMENT_SUCCESS',
    ),
    switchMap(() => {
      const state = state$.value
      // @ts-expect-error: Muted so we could enable TS strict mode
      const { id_site, id_article } = getArticleDetails(state)
      const articleId = `${id_site}_${id_article}`

      return from(fetchArticleComments(id_site, id_article)).pipe(
        map(
          (comments) =>
            ({
              type: 'FETCH_ARTICLE_COMMENTS_SUCCESS',
              payload: { comments, articleId },
            } as FetchArticleCommentsSuccessAction),
        ),
      )
    }),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<FetchArticleCommentsFailureAction>({ type: 'FETCH_ARTICLE_COMMENTS_FAILURE' })),
  )

const addCommentEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, AddArticleCommentAction>('ADD_ARTICLE_COMMENT'),
    switchMap(({ payload }) =>
      from(addArticleComment(payload)).pipe(
        map(() => ({ type: 'ADD_ARTICLE_COMMENT_SUCCESS' } as AddArticleCommentSuccessAction)),
      ),
    ),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<AddArticleCommentFailureAction>({ type: 'ADD_ARTICLE_COMMENT_FAILURE' })),
  )

const deleteCommentEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, DeleteArticleCommentAction>('DELETE_ARTICLE_COMMENT'),
    switchMap(({ payload }) =>
      from(deleteArticleComment(payload)).pipe(
        map(() => ({ type: 'DELETE_ARTICLE_COMMENT_SUCCESS' } as DeleteArticleCommentSuccessAction)),
      ),
    ),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<DeleteArticleCommentFailureAction>({ type: 'DELETE_ARTICLE_COMMENT_FAILURE' })),
  )

const editCommentEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, EditArticleCommentAction>('EDIT_ARTICLE_COMMENT'),
    switchMap(({ payload }) =>
      from(editArticleComment(payload)).pipe(
        map(() => ({ type: 'EDIT_ARTICLE_COMMENT_SUCCESS' } as EditArticleCommentSuccessAction)),
      ),
    ),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<EditArticleCommentFailureAction>({ type: 'EDIT_ARTICLE_COMMENT_FAILURE' })),
  )

export default [fetchCommentsEpic, addCommentEpic, deleteCommentEpic, editCommentEpic]
