import { createSelector } from 'reselect'

import { getWatchIndexes as getState } from './rootSelectors'

export const getProfilesMapping = createSelector(getState, (watchIndexState) => watchIndexState.profilesToWatchIds)

export const getWatchQueries = createSelector(getState, (watchIndexState) => watchIndexState.watchQueries)

export const getSearchWatchId = createSelector(getState, (watchIndexState) => watchIndexState.searchWatchId)

export const getFetchArticlesWithWatchIdInProgress = createSelector(
  getState,
  (watchIndexState) => watchIndexState.fetchArticlesWithWatchIdInProgress,
)

export const getNewArticlesCount = createSelector(getState, (watchIndexState) => {
  if (!watchIndexState.searchWatchId) {
    return undefined
  }

  return watchIndexState.watchQueries[watchIndexState.searchWatchId]?.cnt
})

export const getNewArticlesCountByWatchId = (watchId: number) =>
  createSelector(getState, (watchIndexState) => watchIndexState.watchQueries[watchId]?.cnt || 0)
