import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'
import { useAppSelector } from '../../hooks/useAppSelector'
import { getRecipientDeleteConfirmationModal } from '../../../selectors/uiSelectors'

const RecipientDeleteConfirmationModal = () => {
  const { open, onAccept, onDismiss } = useAppSelector(getRecipientDeleteConfirmationModal)

  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            <Trans>Are you sure you want to delete this recipient?</Trans>
          </AlertDialogTitle>
          <AlertDialogDescription>
            <Trans>{"The recipient will be permanently removed from the alert's recipient list."}</Trans>
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogAction onClick={onAccept}>
            <Trans>Accept</Trans>
          </AlertDialogAction>
          <AlertDialogCancel onClick={onDismiss}>
            <Trans>Cancel</Trans>
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default RecipientDeleteConfirmationModal
