import mergeAll from 'ramda/src/mergeAll'
import { formatISO } from 'date-fns'
import produce from 'immer'
import { AppActions } from '../actions'
import { FilterSuggestion } from '../api/opoint-search-suggest.schemas'
import { SearchFilterKey } from '../components/hooks/useSearchFilters'
import { SearchFilters } from '../components/types/search'

import type { Filter, MultipleSuggestionsOfType } from '../opoint/flow'
import {
  addOneOfKind,
  checkIfSuggestedFilterInverted,
  filterId,
  filterIsProfile,
  filterIsTag,
  filterIsTrashTag,
  invertFilter,
} from '../opoint/search'

export type SearchFilter = { [filterId: string]: Filter }

export type SearchState = {
  abandonedSearchLine: Array<number>
  searchFilters: SearchFilters
  trashTagIds: Array<number>
  profileTagIds: Array<number>
  selectedTagIds: Array<number>
  suggestions: Array<FilterSuggestion>
  suggestionsMultiple: MultipleSuggestionsOfType
  searchInProgress: boolean
  searchIsTakingTooLong: boolean
  loadMoreSearchInProgress: boolean
  meta: {
    foundDocumentsCount: number
    rangeStart?: number
    rangeEnd?: number
    hasInvalidRange?: boolean
    context?: string
    lastTimestamp?: number
    receivedDocumentsCount?: number
    firstTimestamp?: number
    rangeId?: string
    count?: number
  }
  searchDatepicker?: {
    startDate: string
    endDate: string
  } | null
  searchterm: string
  wikinames?: {
    [key in number]: string
  }
  filtersShowMore: Array<MultipleSuggestionsOfType>
  wikidescriptions?: {
    [key in number]: string
  }
  updatedSoMeMetadataResponse: any
}

export const initialState: SearchState = {
  abandonedSearchLine: [],
  searchFilters: {},
  filtersShowMore: [],
  // We store trash-tags on two places so that we don't need to re-render articles
  // each time filter is added. This is a performance optimisation.
  // PLEASE BE CAREFUL WHEN PLAYING AROUND WITH FILTERS AND ALWAYS CHECK A PERFORMANCE OF THE APP
  trashTagIds: [],
  profileTagIds: [],
  selectedTagIds: [],
  // END
  suggestions: [],
  suggestionsMultiple: {},
  searchInProgress: false,
  searchIsTakingTooLong: false,
  loadMoreSearchInProgress: false,
  meta: {
    foundDocumentsCount: 0,
    rangeStart: undefined,
    rangeEnd: undefined,
    hasInvalidRange: false,
    // @ts-expect-error: Muted so we could enable TS strict mode
    context: null,
    firstTimestamp: undefined,
    lastTimestamp: undefined,
    receivedDocumentsCount: undefined,
  },
  searchDatepicker: null,
  searchterm: '',
  wikinames: {},
  wikidescriptions: {},
  updatedSoMeMetadataResponse: {},
}

/**
 * This reducer controls how we retrieve suggestions from Opoint's backend.
 * It takes care of toggling filters, inverting them, setting search terms etc.
 *
 * NOTE: It doesn't contain information about complex handling of filters in a profile editor.
 * @see profileReducer for more information how profile editor actions are handled.
 */

const searchReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    /**
     * In case a location changed, we want to have the search field load the latest data.
     */
    case 'ROUTER_SEARCH_DATA_CHANGE': {
      const { parsedFilters, expression } = action.payload
      const getNumber = (value: number | string) => (typeof value === 'string' ? parseInt(value, 10) : value)

      // Check if the search term is a single article, and extract the profile id to be used as a filter.
      const singleArticleMatch = expression?.match(/\(profile:(\d+)\)/)
      if (singleArticleMatch) {
        const singleArticleFilter = {
          id: singleArticleMatch[1],
          type: SearchFilterKey.PROFILES,
        }

        parsedFilters.push(singleArticleFilter)
      }

      draftState.searchterm = expression ?? ''
      draftState.searchFilters = parsedFilters.reduce((acc, item) => {
        const key = filterId(item)
        acc[key] = item
        return acc
      }, {})
      draftState.trashTagIds = parsedFilters.filter(filterIsTrashTag)?.map((x) => getNumber(x.id))
      draftState.profileTagIds = parsedFilters.filter(filterIsProfile)?.map((x) => getNumber(x.id))
      draftState.selectedTagIds = parsedFilters.filter(filterIsTag)?.map((x) => getNumber(x.id))

      break
    }
    case 'CLEAR_FORM':
    case 'FEED_REMOVE_ACTIVE':
      draftState.abandonedSearchLine = []
      break

    /**
     * Clears the global search terms.
     */
    case 'SEARCHDATA_CLEAR': {
      const timePeriod = Object.values(draftState.searchFilters).filter(
        ({ type }) => type === SearchFilterKey.TIME_PERIOD,
      )[0]

      const searchFilters = timePeriod
        ? {
            [filterId(timePeriod)]: timePeriod,
          }
        : {}

      draftState.searchterm = ''
      draftState.searchFilters = searchFilters
      break
    }

    /**
     * Invert filter given in an payload.
     */
    case 'INVERT_FILTER': {
      const { filter } = action.payload

      const searchFilters = draftState.searchFilters
      delete searchFilters[filterId(filter)]

      draftState.searchFilters = {
        ...searchFilters,
        [filterId(invertFilter(filter))]: invertFilter(filter),
      }

      break
    }
    case 'UPDATE_SEARCHTERM_SUCCESS': {
      const { searchterm } = action.payload

      draftState.searchterm = searchterm
      break
    }

    /**
     * Assoc. filters once they are successfully retrieved.
     */
    case 'FILTERS_FETCH_SUCCESS':
      draftState.suggestions = action.payload
      break

    /**
     * Assoc. filters in a multiple mode.
     */
    case 'FILTERS_FETCH_MULTIPLE_SUCCESS':
      draftState.suggestionsMultiple = action.payload
      break

    /**
     * Filters - show more of filter type
     */
    case 'FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS':
      draftState.filtersShowMore = action.payload
      break

    /**
     * Remove filter received in an payload
     */
    case 'SEARCHFILTER_REMOVED': {
      const { id } = action.payload
      const isAlertContentTag = /^\d{10}$/.test(id as string)
      const newSearchFilters = { ...draftState.searchFilters }

      delete newSearchFilters[filterId(action.payload)]

      if (isAlertContentTag) {
        const alertFilter = Object.values(newSearchFilters).find(({ type }) => type === SearchFilterKey.ALERT_ID)
        if (alertFilter) {
          delete newSearchFilters[filterId(alertFilter)]
        }
      }

      draftState.searchFilters = newSearchFilters
      break
    }

    /**
     * Once a search filter is added to a search.
     */
    case 'SEARCHFILTER_ADDED': {
      const filterSuggestionToBeToggled = action.payload

      const filterToBeToggled = {
        id: filterSuggestionToBeToggled.id,
        type: filterSuggestionToBeToggled.type,
        name: filterSuggestionToBeToggled.name,
      }

      if (!draftState.searchFilters[filterId(filterToBeToggled)]) {
        draftState.searchFilters = addOneOfKind(
          filterId(filterToBeToggled),
          filterToBeToggled,
          draftState.searchFilters,
        )
      }

      break
    }

    /**
     * Add or remove given filter based on current filters state.
     */
    case 'SEARCHFILTER_TOGGLED': {
      // return state
      const filterSuggestionToBeToggled = action.payload
      const idFilter: string = checkIfSuggestedFilterInverted(
        filterId(filterSuggestionToBeToggled),
        draftState.searchFilters,
      )

      const filterToBeToggled = {
        id: filterSuggestionToBeToggled.id,
        type: filterSuggestionToBeToggled.type,
        name: filterSuggestionToBeToggled.name,
      }

      const searchFilters = draftState.searchFilters

      if (draftState.searchFilters[idFilter]) {
        delete searchFilters[idFilter]

        draftState.searchFilters = searchFilters
      } else {
        draftState.searchFilters = addOneOfKind(
          filterId(filterToBeToggled),
          filterToBeToggled,
          draftState.searchFilters,
        )
      }

      break
    }

    case 'FETCH_MORE_ARTICLES': {
      draftState.loadMoreSearchInProgress = true
      break
    }

    /**
     * Functions controlling the state of article's promise.
     */
    case 'FETCH_ARTICLES': {
      draftState.searchInProgress = true
      draftState.meta = initialState.meta
      break
    }

    case 'SEARCH_CANCELED':
    case 'SEARCH_IS_EMPTY': {
      draftState.searchInProgress = false
      draftState.meta = {
        ...initialState.meta,
        context: '',
      }

      break
    }

    case 'PROFILE_EDITOR_PREVIEW': {
      draftState.searchInProgress = true
      break
    }

    case 'PROFILE_EDITOR_PREVIEW_FAILURE': {
      draftState.searchInProgress = false
      break
    }

    case 'PROFILE_EDITOR_PREVIEW_SUCCESS': {
      const {
        documents: receivedDocumentsCount,
        range_count: foundDocumentsCount,
        context,
        rangeStart,
        rangeEnd,
        hasInvalidRange,
        lastTimestamp,
        firstTimestamp,
        wikinames,
        wikidescriptions,
        debug,
      } = action.payload.searchresult

      draftState.searchInProgress = false
      draftState.searchIsTakingTooLong = false
      draftState.loadMoreSearchInProgress = false
      draftState.meta = {
        receivedDocumentsCount,
        foundDocumentsCount, // (range count)
        // might be equal to number of documents, in which case all
        // requested documents were delivered
        // or it might be bigger number which means not all requested
        // were delivered and time range selector should be shown
        rangeStart, // requested range start
        rangeEnd, // requested range end - might be 0 which means now
        hasInvalidRange,
        lastTimestamp, // oldest - delivered range start
        firstTimestamp, // newest
        context,
      }
      draftState.searchterm = debug && debug.lines ? debug.lines[0].query ?? '' : draftState.searchterm
      draftState.wikinames = wikinames
      draftState.wikidescriptions = wikidescriptions

      break
    }

    case 'FETCH_MORE_ARTICLES_SUCCESS':
    case 'FETCH_MORE_PREVIEW_ARTICLES_SUCCESS':
    case 'FETCH_ARTICLES_SUCCESS':
    case 'FETCH_STATISTICS_SUCCESS': {
      const {
        documents: receivedDocumentsCount,
        range_count: foundDocumentsCount,
        context,
        rangeStart,
        rangeEnd,
        hasInvalidRange,
        lastTimestamp,
        firstTimestamp,
        wikinames,
        wikidescriptions,
        range_id,
        count,
      } = action.payload.response.searchresult

      // Merge new wiki values with the ones already in the state when fetching more
      const shouldMergeWikis =
        action.type === 'FETCH_MORE_ARTICLES_SUCCESS' || action.type === 'FETCH_MORE_PREVIEW_ARTICLES_SUCCESS'

      const allWikinames =
        shouldMergeWikis && draftState.wikinames ? mergeAll([draftState.wikinames, wikinames]) : wikinames

      const allWikidescriptions =
        shouldMergeWikis && draftState.wikidescriptions
          ? mergeAll([draftState.wikidescriptions, wikidescriptions])
          : wikidescriptions

      // TODO: Remove this temporary fix, when fixed in the backend.
      const now = +new Date()
      // In rare cases where no or just a single article have been fetched, the backend returns this Unix as rangeStart: "3791368940" and sometimes "2147483647"
      // If this is the case, then set the date to now.
      const newRangeStart: number = rangeStart > now ? now : rangeStart

      draftState.searchInProgress = false
      draftState.searchIsTakingTooLong = false
      draftState.loadMoreSearchInProgress = false
      draftState.meta = {
        receivedDocumentsCount,
        foundDocumentsCount, // (range count)
        // might be equal to number of documents, in which case all
        // requested documents were delivered
        // or it might be bigger number which means not all requested
        // were delivered and time range selector should be shown
        rangeStart: newRangeStart, // requested range start
        rangeEnd, // requested range end - might be 0 which means now
        hasInvalidRange,
        lastTimestamp, // oldest - delivered range start
        firstTimestamp, // newest
        rangeId: range_id,
        context,
        count,
      }

      draftState.wikinames = allWikinames
      draftState.wikidescriptions = allWikidescriptions

      break
    }
    case 'FETCH_MORE_ARTICLES_FAILURE':
    case 'FETCH_STATISTICS_FAILURE':
    case 'FETCH_ARTICLES_FAILURE':
    case 'FETCH_SUGGESTIONS_FAILURE':
      draftState.loadMoreSearchInProgress = false
      draftState.searchIsTakingTooLong = false
      draftState.searchInProgress = false

      break

    case 'DATEPICKER_MODAL_OPEN': {
      const {
        meta: { rangeEnd, rangeStart },
      } = draftState

      // Unix timestamp must be converted to iso string since datepicker
      // is returning iso string
      if (rangeEnd && rangeStart) {
        draftState.searchDatepicker = {
          endDate: formatISO(rangeEnd),
          startDate: formatISO(rangeStart),
        }
      }

      break
    }

    case 'SEARCH_IS_TAKING_TOO_LONG':
      draftState.searchIsTakingTooLong = true

      break

    case 'CANCEL_SEARCH':
      draftState.searchInProgress = false
      draftState.searchIsTakingTooLong = false

      break

    case 'STORE_CURRENT_SEARCHLINE': {
      const { searchLine } = action.payload
      draftState.abandonedSearchLine = searchLine

      break
    }
    case 'UPDATED_SOME_META_DATA_SUCCESS': {
      const { response } = action.payload
      draftState.updatedSoMeMetadataResponse = response

      break
    }

    case 'CLEAR_SUGGESTIONS': {
      draftState.suggestions = []
      break
    }

    default:
      return draftState
  }
}, initialState)

export default searchReducer
