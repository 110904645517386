import { Trans } from 'react-i18next'
import { useState, useEffect } from 'react'

import { abbreviateNumber } from '../../../helpers/common'
import type { SocialMetadata } from '../../../types/article'

const MATCH_META_PARAMS = /(?!_)[a-z]*(?=_count)/

const META_PRIORITY = [['Reaction', 'Favorite', 'Like'], ['Comment'], ['Share', 'Retweet'], ['Engagement']]

type Props = {
  articleId: number
  hasSentiment?: boolean
  meta_data: SocialMetadata
}
const SocialStats = (props: Props) => {
  const [hasMeta, setHasMeta] = useState(false)
  const [metaParams, setMetaParams] = useState([])

  useEffect(() => {
    const { meta_data } = props

    const hasMetaKeys = meta_data && Object.keys(meta_data).length > 0

    if (hasMetaKeys) {
      const newMetaParams = getMetaParams()
      setMetaParams(newMetaParams)
    } else {
      setMetaParams([])
    }
  }, [props.meta_data])

  useEffect(() => {
    setHasMeta(metaParams.length > 0)
  }, [metaParams])

  const getMetaParams = () => {
    const { meta_data } = props

    const newMetaParams = {}

    Object.keys(meta_data)?.forEach((key) => {
      const match = key.match(MATCH_META_PARAMS)

      if (match) {
        const newKey = match[0].charAt(0).toUpperCase() + match[0].slice(1)
        const newVal = parseInt(meta_data[key].value)

        newMetaParams[newKey] = newVal
      }
    })

    const sortedMetaParams = []

    META_PRIORITY?.forEach((cat) => {
      cat.some((p) => {
        if (newMetaParams[p] !== undefined) {
          const c = newMetaParams[p]

          // Avoid adding counts that are -1 (i.e. access blocked)
          if (c >= 0) {
            const newCount = abbreviateNumber(c)
            const newTitle = p === 'Engagement' ? p : p + 's'

            // @ts-expect-error: Muted so we could enable TS strict mode
            sortedMetaParams.push({
              title: newTitle,
              count: newCount,
            })

            return true
          }
        }
      })
    })

    return sortedMetaParams
  }

  // TODO: Actually handle sentiment
  const mockHasSentiment = hasMeta

  // {/* TODO: Reimplement sentiments when backend is ready */}
  //       {/* {mockHasSentiment && (
  //         <li>
  //           <div className={styles.heading}>
  //             <T _str="Sentiment" />
  //           </div>
  //           <div className={styles.sentiment}>
  //             <div className={classnames({ [styles.active]: positiveSentiment })}></div>
  //             <span className={styles.seperator} />
  //             <div className={classnames(styles.negative, { [styles.active]: !positiveSentiment })}></div>
  //           </div>
  //         </li>
  //       )} */}

  //If engagement score is 0 and undefined
  // @ts-expect-error: Muted so we could enable TS strict mode
  if (metaParams?.filter((meta) => meta.title == 'Engagement')[0]?.count === 0) {
    return null
  }

  return (
    (hasMeta || mockHasSentiment) && (
      <>
        {metaParams?.map((param, index) => (
          <li className="flex pr-3" key={`metaParam-${props.articleId}-${index}`}>
            <div className="m-2 flex-1 flex-col items-start">
              <div className="text-xs font-bold text-sky.cloudy">
                {/* @ts-expect-error idk man just let me work*/}
                <Trans>{param?.title}</Trans>
              </div>
              {/* @ts-expect-error: Muted so we could enable TS strict mode */}
              <div className="mt-2 text-xs font-normal text-sky.1">{param.count}</div>
            </div>
          </li>
        ))}
      </>
    )
  )
}

export default SocialStats
