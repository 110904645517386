/**
 * Intimacy unixtimestamp to regular unix timestamp convertor
 * @param intimacyUnixtimestamp
 * @returns unix timestamp {number}
 * @constructor
 */
export function OpointTimestampToTimestamp(intimacyUnixtimestamp = Math.round(+new Date() / 1000)) {
  const INT_MAX = 2147483647

  return INT_MAX - intimacyUnixtimestamp
}

export function IsoToUnixTimestamp(date: Date): number {
  return Math.floor(date.getTime() / 1000)
}

/**
 * Converts date to ISOFormat with local timezone
 * @param date
 * @returns {string}
 */
export const dateToISOWithLocalTimezone = (date) => {
  const dateObject = new Date(date)
  const tzoffset = dateObject.getTimezoneOffset() * 60000 //offset in milliseconds
  const localISOTime = new Date(dateObject.valueOf() - tzoffset).toISOString()

  return localISOTime
}
