import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { getAllFolders } from '../../selectors/foldersSelectors'
import { getProfiles } from '../../selectors/profilesSelectors'
import { getUISetting } from '../../selectors/settingsSelectors'
import { useNavigationContext } from '../layout/navigation/NavigationContext'
import { Folder, FolderType } from '../types/folder'
import { Profile, ProfileWithChildren } from '../types/profile'

import { useAppSelector } from './useAppSelector'

const buildProfileTree = (profiles: Profile[], parentId = 0): ProfileWithChildren[] => {
  return profiles
    .filter((profile) => profile.parent === parentId)
    .map((profile) => ({
      ...profile,
      children: buildProfileTree(profiles, profile.id),
    }))
}

export const useProfilesFolderTree = (): Folder<ProfileWithChildren>[] => {
  const { t } = useTranslation()

  const displayImportantProfiles = useAppSelector(getUISetting('DISPLAY_IMPORTANT_PROFILES'))
  const folders = useAppSelector(getAllFolders)
  const profiles = useAppSelector(getProfiles)

  const { searchQuery } = useNavigationContext()

  const displayProfiles = useMemo(() => {
    if (searchQuery) {
      return profiles.filter((profile) => profile.name.toLowerCase().includes(searchQuery.toLowerCase()))
    }

    return displayImportantProfiles ? [...profiles].sort((a, b) => a.score - b.score).slice(0, 10) : profiles
  }, [displayImportantProfiles, searchQuery, profiles])

  return useMemo(() => {
    // Return an artificial folder when only the most important profiles are displayed
    if (displayImportantProfiles || searchQuery) {
      return [
        // @ts-expect-error: Muted so we could enable TS strict mode
        {
          type: FolderType.PROFILES,
          category: 0,
          name: t('Mentions'),
          children: displayProfiles.map((item) => ({ ...item, children: [] })),
        } as Folder<ProfileWithChildren>,
      ]
    }

    return folders
      .filter((folder) => folder.type === FolderType.PROFILES)
      .reduce((acc, folder) => {
        const filteredItems = displayProfiles.filter((item) => item.folder === folder.id)

        if (!filteredItems.length) {
          return acc // Skip folders with no profiles
        }

        const tree = buildProfileTree(filteredItems)

        // @ts-expect-error: Muted so we could enable TS strict mode
        acc.push({
          ...folder,
          children: tree.length ? tree : filteredItems.map((item) => ({ ...item, children: [] })),
        })

        return acc
      }, [])
  }, [displayImportantProfiles, searchQuery, displayProfiles, folders, t])
}
