import { ActionsObservable, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'

import { AppActions } from '../actions'
import {
  EndImpersonationAction,
  FetchUsersSuccessAction,
  GoToCustomerViewAction,
  ImpersonateAction,
  ImpersonationOrganizationsFetchAction,
  ImpersonationOrganizationsFetchFailureAction,
  ImpersonationOrganizationsFetchSuccessAction,
  ImpersonationUsersFetchAction,
  ImpersonationUsersFetchFailureAction,
  ImpersonationUsersFetchSuccessAction,
} from '../actions/impersonation'
import { SettingsFetchSuccessAction } from '../actions/settings'
import { getImpersonationOrganizations, getImpersonationUsers } from '../opoint/impersonation'
import { queryClient } from '../queryClient'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const impersonationOrganizationsFetchEpic: (action$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, ImpersonationOrganizationsFetchAction>('IMPERSONATION_ORGANIZATIONS_FETCH'),
    switchMap(() =>
      from(getImpersonationOrganizations()).pipe(
        map(
          (organizations) =>
            ({
              type: 'IMPERSONATION_ORGANIZATIONS_FETCH_SUCCESS',
              payload: organizations,
            } as ImpersonationOrganizationsFetchSuccessAction),
        ),
      ),
    ),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() =>
      of<ImpersonationOrganizationsFetchFailureAction>({ type: 'IMPERSONATION_ORGANIZATIONS_FETCH_FAILURE' }),
    ),
  )

const impersonationUsersFetchEpic: (action$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, ImpersonationUsersFetchAction>('IMPERSONATION_USERS_FETCH'),
    debounceTime(250),
    switchMap(({ payload: { userSearch } }) =>
      from(getImpersonationUsers(userSearch)).pipe(
        map(
          (users) =>
            ({
              type: 'IMPERSONATION_USERS_FETCH_SUCCESS',
              payload: users,
            } as ImpersonationUsersFetchSuccessAction),
        ),
      ),
    ),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<ImpersonationUsersFetchFailureAction>({ type: 'IMPERSONATION_USERS_FETCH_FAILURE' })),
  )

// This fetches all impersonation users if the user only has 20 or below.
const impersonationUsersListFetchEpic: (action$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, GoToCustomerViewAction | SettingsFetchSuccessAction>(
      'GO_TO_CUSTOMER_VIEW',
      'SETTINGS_FETCH_SUCCESS',
    ),
    switchMap(() =>
      from(getImpersonationUsers('')).pipe(
        map((users) => ({ type: 'FETCH_USERS_SUCCESS', payload: users } as FetchUsersSuccessAction)),
      ),
    ),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<ImpersonationUsersFetchFailureAction>({ type: 'IMPERSONATION_USERS_FETCH_FAILURE' })),
  )

const impersonationCallbackEpic: (action$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, ImpersonateAction | EndImpersonationAction>('IMPERSONATE', 'END_IMPERSONATION'),
    switchMap(() => {
      queryClient.clear()

      return of()
    }),
  )

export default [
  impersonationOrganizationsFetchEpic,
  impersonationUsersFetchEpic,
  impersonationUsersListFetchEpic,
  impersonationCallbackEpic,
]
