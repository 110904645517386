import { SWITCHABLE_MAIN_GRAPH_KEY, WIDGET_SELECTION_KEY } from '../../constants/localStorage'
import { getLocalStorageItem, setLocalStorageItem } from '../../helpers/localStorage'

export const setWidgetSelection = (value: string) => setLocalStorageItem(WIDGET_SELECTION_KEY, value)

export const getWidgetSelection = () => getLocalStorageItem(WIDGET_SELECTION_KEY)

export const setSwitchableMainGraph = (value: 'lineChart' | 'barChart') =>
  setLocalStorageItem(SWITCHABLE_MAIN_GRAPH_KEY, value)

export const getSwitchableMainGraph = () =>
  getLocalStorageItem(SWITCHABLE_MAIN_GRAPH_KEY) as 'lineChart' | 'barChart' | null
