import { useLocation } from 'react-router-dom'

import { SetActiveArticle } from '../../opoint/flow'
import { getActive } from '../../selectors/articlesSelectors'

import { useAppSelector } from './useAppSelector'
import useArticleBaseProps from './useArticleBaseProps'
import { useAppDispatch } from './useAppDispatch'

const useSetActiveArticle = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const active = useAppSelector(getActive)

  const { isProfileEditor } = useArticleBaseProps()

  const getActionType = () => {
    if (location?.pathname?.includes('/alerts/')) {
      return 'SET_ACTIVE_ALERT_ARTICLE'
    }

    if (isProfileEditor) {
      return 'SET_ACTIVE_PROFILE_EDITOR_PREVIEW_ARTICLE'
    }

    return 'SET_ACTIVE_ARTICLE'
  }

  const setActiveArticle = (payload: SetActiveArticle) => {
    if (active.index !== payload.index) {
      dispatch({ type: getActionType(), payload })
    }
  }

  return setActiveArticle
}

export default useSetActiveArticle
