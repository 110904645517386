import { Trans } from 'react-i18next'

import {
  HighlightedMatchesAndEntities,
  type SocialSource,
  Textarea,
  ArticleQuote,
  ArticleMediaType,
} from '@opoint/infomedia-storybook'
import { ChangeEventHandler } from 'react'
import { stripHTML } from '../../../../../opoint/common'

import { DocumentMatchesItem } from '../../../../../api/opoint-search-suggest.schemas'

export type ArticleSummaryProps = {
  author?: string
  bodyText?: string
  editable?: boolean
  matches?: DocumentMatchesItem[]
  mediatype?: ArticleMediaType
  onContentChange?: (any) => void
  quotes?: ArticleQuote[]
  socialSource?: SocialSource
  summaryText?: string
  manualSummary?: { translated: string; original: string }
}

/**
 * Depending on searchD flags, it displays matchwords or perex
 */
const ArticleSummary = ({
  author,
  bodyText,
  editable,
  matches,
  quotes,
  socialSource,
  summaryText,
  manualSummary,
  onContentChange,
}: ArticleSummaryProps) => {
  const handleAuthorChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    onContentChange?.({ author: event.target.value })
  }

  const handleSummaryChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    onContentChange?.({ summary: { text: event.target.value, matches: false } })
  }

  const renderManualSummary = (translated, original) => (
    <>
      <div className="mb-12 border-y border-sky.1 px-0 py-3">
        <p className="m-0 mb-1.5 text-sm font-medium leading-4">
          <Trans>Summary</Trans>
        </p>
        <p className="m-0 leading-6">
          <HighlightedMatchesAndEntities socialSource={socialSource} text={translated} />
        </p>
      </div>
      <h2 className="leading-8">
        <HighlightedMatchesAndEntities socialSource={socialSource} text={original} />
      </h2>
    </>
  )

  return editable ? (
    <div className="mb-5">
      <label htmlFor="author">
        <Trans>Author</Trans>
      </label>
      <Textarea name="author" id="author" value={author} onChange={handleAuthorChange} className="mb-5 mt-1" />
      <label htmlFor="summary">
        <Trans>Summary</Trans>
      </label>
      <Textarea
        name="summary"
        id="summary"
        value={stripHTML(summaryText)}
        onChange={handleSummaryChange}
        className="mt-1"
      />
    </div>
  ) : (
    <div className="font-slab">
      {manualSummary ? (
        renderManualSummary(manualSummary.translated, manualSummary.original)
      ) : (
        <div className="mb-3 text-heading-2 font-bold">
          <HighlightedMatchesAndEntities socialSource={socialSource} text={summaryText} />
        </div>
      )}

      {!summaryText?.length && !bodyText?.length && (
        <div>
          {quotes ? (
            <div>
              {Object.values(quotes)?.map((quote: any, i) => (
                <p key={`quote-${i}`}>
                  <HighlightedMatchesAndEntities text={quote.text} />
                </p>
              ))}
            </div>
          ) : (
            <div>
              {matches?.map((match, i) => (
                <div key={`match-${i}`}>
                  <span className="bg-sky.6">{match.text}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ArticleSummary
