import { produce } from 'immer'

import { AppActions } from '../actions'
import { CreateFolderState } from '../components/folders/FolderEditor/FoldersCreateAndEdit/types'
import { Folder, ShareableUsers } from '../components/types/folder'
import { FolderType, folderTraits, getFolderIcon } from '../constants/folders.tsx'

export type FoldersState = {
  chosenFolder: Folder
  folders: Array<Folder>
  foldersTree: Array<Folder>
  formValues: CreateFolderState
  showFolderContent: boolean
  shareableUsers: Array<ShareableUsers>
  userSearchLoading: boolean
}

export const initialState: FoldersState = {
  // @ts-expect-error: Muted so we could enable TS strict mode
  chosenFolder: null,
  folders: [],
  foldersTree: [],
  // @ts-expect-error: Muted so we could enable TS strict mode
  formValues: { description: '', id: null, name: '', shares: [], traits: '0', type: '0', icon: '' },
  showFolderContent: false,
  shareableUsers: [],
  userSearchLoading: false,
}

export const getFolderPermission = (folder: Folder) => {
  const isRegularFolder = folderTraits[folder?.traits] === 'regular'

  // Default profiles folder
  if (folder.type === FolderType.PROFILES && isRegularFolder) {
    return 'PROFILE_MODULE'
  }

  // Default tags folders
  if ((folder.type === FolderType.TAGS || folder.type === FolderType.SENTIMENT_TAGS) && isRegularFolder) {
    return 'TAG_MODULE'
  }

  // Default statistics folder
  if (folder.type === FolderType.STATISTICS) {
    return 'STATISTICS_MODULE'
  }
}

const foldersReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'FETCH_FOLDERS_SUCCESS': {
      const { isECBUser, folders } = action.payload

      folders?.forEach((folder) => {
        folder.icon = getFolderIcon(folder, isECBUser)
        folder.module = getFolderPermission(folder)
      })

      draftState.folders = folders
      break
    }

    case 'CREATE_FOLDERS_TREE_SUCCESS': {
      draftState.foldersTree = action.payload
      break
    }

    case 'FETCH_SPECIFIC_FOLDER_SUCCESS': {
      draftState.chosenFolder = action.payload
      break
    }

    case 'RESET_FETCHED_SPECIFIC_FOLDER': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.chosenFolder = null
      break
    }

    case 'UPDATE_FOLDER_FORM_VALUES': {
      draftState.formValues = action.payload
      break
    }

    case 'FOLDER_DELETE_TRIGGER': {
      draftState.showFolderContent = action.payload
      break
    }

    case 'FETCH_FOLDER_SHARES': {
      draftState.userSearchLoading = true
      break
    }

    case 'FETCH_FOLDER_SHARES_SUCCESS': {
      draftState.shareableUsers = action.payload
      draftState.userSearchLoading = false
      break
    }

    case 'FETCH_FOLDER_SHARES_FAILURE': {
      draftState.userSearchLoading = false
      break
    }

    case 'CLEAR_FOLDER_SHARES': {
      draftState.shareableUsers = []
      break
    }
  }
}, initialState)

export default foldersReducer
