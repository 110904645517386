import { switchMap } from 'rxjs/operators'


import { ActionsObservable } from 'redux-observable'
import { t } from "i18next";
import {
  AlertDeleteFailureAction,
  AlertDeleteSuccessAction,
  AlertFetchContentFailureAction,
  AlertFetchFailureAction,
  AlertFetchHistoryFailureAction,
  AlertRemoveMarkedArticlesFailureAction,
  AlertRemoveMarkedArticlesSuccessAction,
  AlertSendFailureAction,
  AlertSendSuccessAction,
  AlertsActions,
  SaveAlertFailureAction,
  SaveAlertSuccessAction,
  SaveAlertValidFailureAction,
} from '../../../actions/alerts'
import { TemplateActions, TemplatePreviewFetchFailureAction } from '../../../actions/templates'
import { UiActions } from '../../../actions/ui'
import { ErrorEpic, ErrorPayload, handlerError } from '.'

const alertErrorTypes = (
  payload?: ErrorPayload,
): ErrorEpic<
  | AlertDeleteSuccessAction['type']
  | SaveAlertSuccessAction['type']
  | AlertSendSuccessAction['type']
  | AlertRemoveMarkedArticlesSuccessAction['type']
  | AlertRemoveMarkedArticlesFailureAction['type']
  | AlertDeleteFailureAction['type']
  | SaveAlertFailureAction['type']
  | AlertSendFailureAction['type']
  | AlertFetchFailureAction['type']
  | AlertFetchHistoryFailureAction['type']
  | AlertFetchContentFailureAction['type']
  | TemplatePreviewFetchFailureAction['type']
  | SaveAlertValidFailureAction['type']
> => {
  return {
    ['ALERT_DELETE_SUCCESS']: {
      isSuccess: true,
      message: t('Alert was successfully deleted'),
      toastId: 'ALERT_DELETE_SUCCESS',
    },
    ['SAVE_ALERT_SUCCESS']: {
      isSuccess: true,
      message: t('Alert was successfully saved'),
      toastId: 'SAVE_ALERT_SUCCESS',
    },
    ['ALERT_SEND_SUCCESS']: {
      isSuccess: true,
      message: t('Alert was successfully sent'),
      toastId: 'ALERT_SEND_SUCCESS',
    },
    ['ALERT_REMOVE_MARKED_ARTICLES_SUCCESS']: {
      isSuccess: true,
      message: t('Articles were successfully removed'),
      toastId: 'ALERT_REMOVE_MARKED_ARTICLES_SUCCESS',
    },
    ['ALERT_REMOVE_MARKED_ARTICLES_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to remove selected articles'),
      toastId: 'ALERT_REMOVE_MARKED_ARTICLES_FAILURE',
    },
    ['ALERT_DELETE_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to delete selected alert'),
      toastId: 'ALERT_DELETE_FAILURE',
    },
    ['SAVE_ALERT_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to save this alert'),
      toastId: 'SAVE_ALERT_FAILURES',
    },
    ['ALERT_SEND_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to send this alert'),
      toastId: 'ALERT_SEND_FAILURES',
    },
    ['ALERT_FETCH_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to fetch this alert'),
      toastId: 'ALERT_FETCH_FAILURE',
    },
    ['ALERT_FETCH_HISTORY_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to fetch alert history'),
      toastId: 'ALERT_FETCH_HISTORY_FAILURE',
    },
    ['ALERT_FETCH_CONTENT_FAILURE']: {
      isSuccess: false,
      message: payload?.error ?? t('We were unable to fetch articles for this alert'),
      toastId: 'ALERT_FETCH_CONTENT_FAILURE',
    },
    ['ALERT_TEMPLATE_FETCH_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to get this preview'),
      toastId: 'ALERT_TEMPLATE_FETCH_FAILURE',
    },
    ['SAVE_ALERT_VALID_FAILURE']: {
      isSuccess: false,
      // @ts-expect-error: Muted so we could enable TS strict mode
      message: payload?.error,
      toastId: 'SAVE_ALERT_VALID_FAILURE',
    },
  }
}

const alertErrorEpic: (action: ActionsObservable<AlertsActions | UiActions | TemplateActions>) => void = (action$) =>
  action$.pipe(
    switchMap((action) =>
      'payload' in action
        ? handlerError(alertErrorTypes, action.type, action.payload as ErrorPayload)
        : handlerError(alertErrorTypes, action.type),
    ),
  )

export default alertErrorEpic
