import { StorybookProvider as Provider } from '@opoint/infomedia-storybook'
import { ReactNode } from 'react'
import { useAppSelector } from '../../components/hooks/useAppSelector'
import { getWikidescriptions, getWikinames } from '../../selectors/searchSelectors'
import { getArticleListingStyle, getDateVariant, getOpointLocale } from '../../selectors/settingsSelectors'
import config from '../common/config'
import { getEditedArticlesState } from '../../selectors/articlesSelectors'

export const StorybookProvider = ({ children }: { children: ReactNode }) => {
  const wikinames = useAppSelector(getWikinames)
  const wikidescriptions = useAppSelector(getWikidescriptions)
  const articleListingStyle = useAppSelector(getArticleListingStyle)
  const locale = useAppSelector(getOpointLocale)
  const dateVariant = useAppSelector(getDateVariant)
  const editedArticles = useAppSelector(getEditedArticlesState)

  return (
    <Provider
      value={{
        imageProxyUrl: config.url.imageproxy(''),
        locale: locale,
        dateVariant,
        articleListingStyle,
        editedArticles,
        wikidescriptions,
        wikinames,
      }}
    >
      {children}
    </Provider>
  )
}
