import { Dialog, DialogBody, DialogContent, DialogHeader, DialogTitle } from '@opoint/infomedia-storybook'
import DOMPurify from 'dompurify'

type Props = {
  open: boolean
  onHide: () => void
  title: string
  src: string
  displayUrl?: string
}

const FullscreenIFrameDialog = ({ open, onHide, title, src, displayUrl }: Props) => {
  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onHide()
    }
  }

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent variant="fullscreen">
        <DialogHeader>
          <DialogTitle>{DOMPurify.sanitize(title)}</DialogTitle>
          {!!displayUrl && (
            <p>
              <a target="_blank" href={displayUrl} rel="noreferrer">
                {displayUrl}
              </a>
            </p>
          )}
        </DialogHeader>

        <DialogBody className="[&>div>div]:h-full">
          <iframe tabIndex={0} className="size-full" src={src} />
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default FullscreenIFrameDialog
