import { useLocation, useParams } from 'react-router-dom'

import { MODULES } from '../../../constants/permissions'
import useIsUserPermitted from '../../../hooks/useIsUserPermitted'
import ActionBar, { ActionBarLeft } from '../ActionBar'
import { ActionBarRight } from '../ActionBarRight'

import { AlertsTourTarget } from '../../../productTour/ProductTours/AlertsTour'
import AlertPeriodFilter from './filters/AlertPeriodFilter'
import { useAlertsActions } from './useAlertsActions'

const AlertActionBar = () => {
  const { pathname } = useLocation()
  const params = useParams()
  const actions = useAlertsActions()
  const hasPermission = useIsUserPermitted({
    module: 'ALERT_MODULE',
    permissions: [MODULES.ALERT_MODULE.ON, MODULES.ALERT_MODULE.READ_ONLY],
  })

  if (!hasPermission) {
    return null
  }

  const isCreateAlert = pathname.includes('/new')
  const isEditAlert = pathname.includes('/edit')
  const isViewingAlertInBrowser = pathname.includes('view')

  return (
    <ActionBar>
      <ActionBarLeft id={AlertsTourTarget.STEP_1}>
        {!isCreateAlert && !isEditAlert && !isViewingAlertInBrowser && params?.mailLogId && (
          <>
            <AlertPeriodFilter />
          </>
        )}
      </ActionBarLeft>
      <ActionBarRight items={actions} switcherVisible={false} />
    </ActionBar>
  )
}

export default AlertActionBar
