import { highlightSearched } from '../../../../opoint/common/highlight'
import { getContactTypeAndName } from '../../../../opoint/contacts'
import { CommonRecipientFilter, ContactFilter } from '../../../types/contact'

const ContactSuggestion = ({
  suggestion,
  onClickHandler,
  hint,
}: {
  suggestion: ContactFilter | CommonRecipientFilter
  onClickHandler: (filter: ContactFilter | CommonRecipientFilter) => void
  hint: string
}) => {
  const { type, name } = getContactTypeAndName(suggestion)

  const handleClick = () => onClickHandler(suggestion)

  return (
    <span className="auto-item" onClick={handleClick}>
      <span className="auto-item__type">
        {type}
        {': '}
      </span>
      <span
        className="auto-item__name"
        // @ts-expect-error: Muted so we could enable TS strict mode
        dangerouslySetInnerHTML={{ __html: highlightSearched(name, hint) }}
        data-cy="selectFirstRecipient"
      />
    </span>
  )
}

export default ContactSuggestion
