import { ActionsObservable, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { AppActions } from '../actions'
import {
  ReportHistoryFetchAction,
  ReportHistoryFetchFailureAction,
  ReportHistoryFetchSuccessAction,
} from '../actions/reportsHistory'
import { getReportsHistory } from '../opoint/reportsHistory'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const fetchReportsHistoryEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ReportHistoryFetchAction>('REPORTHISTORY_FETCH'),
    switchMap(({ payload: { page } }) =>
      from(getReportsHistory(page))?.pipe(
        map(
          ({ results, count }) =>
            ({
              type: 'REPORTHISTORY_FETCH_SUCCESS',
              payload: {
                reports: results,
                totalCount: count,
              },
            } as ReportHistoryFetchSuccessAction),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<ReportHistoryFetchFailureAction>({ type: 'REPORTHISTORY_FETCH_FAILURE' })),
      ),
    ),
  )

export default [fetchReportsHistoryEpic]
