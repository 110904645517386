import { NavLink } from 'react-router-dom'
import { ComponentPropsWithoutRef, ReactElement, forwardRef } from 'react'

type TableLinkCellProps = {
  children: ReactElement
  disabled?: boolean
} & ComponentPropsWithoutRef<typeof NavLink>

const TableLinkCell = forwardRef<React.ElementRef<typeof NavLink>, TableLinkCellProps>(
  ({ children, disabled, ...rest }, ref) => {
    if (disabled) {
      return (
        <span className="flex size-full flex-col rounded py-2 text-label-l font-medium text-grey.3">{children}</span>
      )
    }

    return (
      <NavLink
        {...rest}
        ref={ref}
        className="flex size-full cursor-pointer flex-col rounded py-2 text-label-l font-medium text-sky.1 focus:outline-none focus-visible:ring focus-visible:ring-inset focus-visible:ring-sky.6"
      >
        {children}
      </NavLink>
    )
  },
)

TableLinkCell.displayName = 'TableLinkCell'

export default TableLinkCell
