import { ReactNode, Suspense } from 'react'

import { Loader } from '@opoint/infomedia-storybook'
import ErrorBoundary from '../ErrorBoundary'

const SuspenseWithLoading = ({ children }: { children: ReactNode }) => (
  <ErrorBoundary reloadButton={false} errorImg={true} contactSupport={true} smallPicture={true}>
    <Suspense
      fallback={
        <div className="flex size-full items-center justify-center">
          <Loader />
        </div>
      }
    >
      {children}
    </Suspense>
  </ErrorBoundary>
)

export default SuspenseWithLoading
