import { t } from "i18next";
import { IconName } from '../components/types/theme'

export enum NotificationStatus {
  FAILED = -1,
  IN_PROGRESS = 0,
  DONE = 1,
}

export const notificationsTypes: Record<
  NotificationStatus,
  {
    class: 'mod-fail' | 'mod-in-progress' | 'mod-done'
    icon: IconName
    label: string
    status: 'failed' | 'loading' | 'done'
  }
> = {
  [NotificationStatus.FAILED]: {
    class: 'mod-fail',
    icon: 'close',
    label: t('Failed'),
    status: 'failed',
  },
  [NotificationStatus.IN_PROGRESS]: {
    class: 'mod-in-progress',
    icon: 'alert_alt',
    label: t('In progress'),
    status: 'loading',
  },
  [NotificationStatus.DONE]: {
    class: 'mod-done',
    icon: 'report_alt',
    label: t('Done'),
    status: 'done',
  },
}
