import config from '../../opoint/common/config'
import { ARTICLE_METADATA_CONFIG } from '../articles/Article/common/ArticleMetadata/ArticleMetadataBox/ArticleMetadataBox'
import { ArticleMetadataType } from '../types/article'
import { FilterType } from '../types/settings'

export enum MentionsNavigationKey {
  LEFT = 'left',
  RIGHT = 'right',
  LIST = 'list',
}

// Time offset for relative times in seconds
export const RELATIVE_TIME_LIMIT = 604800 // 1 week

export const IMAGE_URL_BASE = {
  old: 'http://pictures.opoint.com/',
  new: config.url.app('/pictures/'),
}

export const allMetadataOptions = (): Array<{
  name: string
  value: ArticleMetadataType
}> =>
  Object.entries(ARTICLE_METADATA_CONFIG)?.map(([value, { title }]) => ({
    value: value as ArticleMetadataType,
    name: title(),
  }))

// TODO same as with the autoTranslateLanguages below
export const allFilterOptions = [
  { value: FilterType.TimePeriod, name: 'Period' },
  { value: FilterType.Site, name: 'Source' },
  { value: FilterType.Rank, name: 'Rank' },
  { value: FilterType.Geo, name: 'From' },
  { value: FilterType.Lang, name: 'Language' },
  { value: FilterType.Cov, name: 'Coverage' },
  { value: FilterType.Media, name: 'Media Channel' },
  { value: FilterType.Content, name: 'Content' },
  { value: FilterType.Profiles, name: 'Profiles' },
  { value: FilterType.Tags, name: 'Tags' },
  { value: FilterType.Ent, name: 'Entity' },
  { value: FilterType.Topic, name: 'Topic' },
  { value: FilterType.ECBRank, name: 'ECB Rank' },
  { value: FilterType.ECBVoice, name: 'ECB Voices' },
  { value: FilterType.ECBTopic, name: 'ECB Topics' },
  { value: FilterType.ECBSentiment, name: 'ECB Sentiment' },
  { value: FilterType.Curated, name: 'Curated' },
  { value: FilterType.NonCurated, name: 'Non-Curated' },
  { value: FilterType.SocialMedia, name: 'Social Media' },
  { value: FilterType.FrontPages, name: 'Front Pages' },
]

export const FILTERS_ORDER_DEFAULT: Array<FilterType> = [
  FilterType.TimePeriod,
  FilterType.Geo,
  FilterType.Lang,
  FilterType.Media,
  FilterType.Content,
  FilterType.Site,
  FilterType.Rank,
  FilterType.Cov,
  FilterType.Profiles,
  FilterType.Tags,
  FilterType.Ent,
  FilterType.Topic,
]

// TODO - find a better way to handle translations
// TODO - https://stash.opoint.com/infomedia/m360/-/merge_requests/2340
// TODO: Wrapping all of these languages to `t()` does push the translations to transifex, however when using the languages
// TODO: in components, it seems they don't get translated on re-render.
// TODO: Rather than wrapping them in `t()` twice (once here, once on render),
// TODO: we removed transifex wrapper from this array and translate only when rendering.
export const autoTranslateLanguages = [
  { en: 'English' },
  { de: 'German' },
  { fr: 'French' },
  { es: 'Spanish' },
  { it: 'Italian' },
  { no: 'Norwegian' },
  { sv: 'Swedish' },
  { da: 'Danish' },
  { fi: 'Finnish' },
  { zh: 'Chinese' },
  { ru: 'Russian' },
  { tr: 'Turkish' },
  { ko: 'Korean' },
  { ar: 'Arabic' },
  { pt: 'Portuguese' },
  { el: 'Greek' },
  { ja: 'Japanese' },
  { vi: 'Vietnamese' },
  { id: 'Indonesian' },
  { pl: 'Polish' },
  { nl: 'Dutch' },
  { hi: 'Hindi' },
  { ro: 'Romanian' },
  { bs: 'Bosnian' },
  { fa: 'Persian' },
  { th: 'Thai' },
  { sq: 'Albanian' },
  { hr: 'Croatian' },
  { cs: 'Czech' },
  { hu: 'Hungarian' },
  { bg: 'Bulgarian' },
  { uk: 'Ukrainian' },
  { sk: 'Slovak' },
  { mk: 'Macedonian' },
  { sl: 'Slovene' },
  { bn: 'Bengali' },
  { te: 'Telugu' },
  { lt: 'Lithuanian' },
  { ms: 'Malay' },
  { ca: 'Catalan' },
  { ml: 'Malayalam' },
  { ta: 'Tamil' },
  { lv: 'Latvian' },
  { az: 'Azerbaijani' },
  { et: 'Estonian' },
  { pa: 'Punjabi' },
  { ur: 'Urdu' },
  { ka: 'Georgian' },
  { gu: 'Gujarati' },
  { kn: 'Kannada' },
  { am: 'Amharic' },
  { gl: 'Galician' },
  { hy: 'Armenian' },
  { sr: 'Serbian' },
  { is: 'Icelandic' },
  { kk: 'Kazakh' },
  { eu: 'Basque' },
  { af: 'Afrikaans' },
  { so: 'Somali' },
  { mt: 'Maltese' },
  { sw: 'Swahili' },
  { mr: 'Marathi' },
  { uz: 'Uzbek' },
  { si: 'Sinhalese' },
  { tg: 'Tajik' },
  { cy: 'Welsh' },
  { my: 'Burmese' },
  { km: 'Khmer' },
  { tl: 'Tagalog' },
  { fy: 'Western Frisian' },
  { sm: 'Samisk' },
  { eo: 'Esperanto' },
  { mn: 'Mongolian' },
  { ha: 'Hausa' },
  { ga: 'Irish' },
  { be: 'Belarusian' },
  { ny: 'Chichewa' },
  { ps: 'Pashto' },
  { st: 'Southern Sotho' },
  { ht: 'Haitian' },
  { ky: 'Kirghiz' },
  { lb: 'Luxembourgish' },
  { lo: 'Lao' },
]
