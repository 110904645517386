export const extractPageNumber = (nextUrl: string | null) => {
  if (!nextUrl) {
    return null
  }

  const url = new URL(nextUrl)
  const nextPage = url.searchParams.get('page')

  if (nextPage === null || isNaN(Number(nextPage))) {
    return null
  }

  return Number(nextPage)
}
