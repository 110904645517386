import { Table, TableHeader, TableRow, TableBody, TableCell, cn, formatDate } from '@opoint/infomedia-storybook'
import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Search48Regular } from '@fluentui/react-icons'
import { SingleUserInvitation } from '../../../../../api/opoint.schemas'
import { getOpointLocale, getDateVariant } from '../../../../../selectors/settingsSelectors'
import StatusTag from '../../../../alerts/RecipientsListModal/StatusTag'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { GeneralStatusType } from '../../../../alerts/RecipientsListModal/helpers'
import { InvitationSentStatus, InvitationsTableItem } from '../../../../types/userManagement'

import { TableColumnDefinition } from '../../../../types/common'
import TableLinkCell from '../../../../table/cells/TableLinkCell'
import { highlightItemsInTableSearched } from '../../../../../opoint/common/highlight'
import ActiveColumnsEditor from '../../../../table/cells/ActiveColumnsEditor'
import DefaultHeaderCell from '../../../../table/cells/DefaultHeaderCell'
import EmptyData from '../../../../../pages/empty/EmptyData'
import UserRevokeDialog from './UserRevokeDialog'

type AllUsersTableProps = {
  invitationItems: InvitationsTableItem[]
  allList?: boolean
}

enum ColumnKey {
  NAME = 'name',
  EMAIL = 'email',
  ROLE = 'role',
  ALERTS = 'alerts',
  STATUS = 'status',
  LAST_LOGIN = 'lastLogin',
}

const INVITATIONS_TABLE_COLUMNS: Record<ColumnKey, TableColumnDefinition> = {
  [ColumnKey.NAME]: {
    label: <Trans>Name</Trans>,
    forced: true,
  },
  [ColumnKey.EMAIL]: {
    label: <Trans>Email</Trans>,
  },
  [ColumnKey.ROLE]: {
    label: <Trans>Role</Trans>,
  },
  [ColumnKey.ALERTS]: {
    label: <Trans>Alerts</Trans>,
  },
  [ColumnKey.STATUS]: {
    label: <Trans>Status</Trans>,
  },
  [ColumnKey.LAST_LOGIN]: {
    label: <Trans>Last login</Trans>,
  },
} as const

const columnHelper = createColumnHelper<InvitationsTableItem>()

const UserInvitationsTable = ({ invitationItems, allList = false }: AllUsersTableProps): React.JSX.Element => {
  const { t } = useTranslation()
  const locale = useAppSelector(getOpointLocale)
  const dateVariant = useAppSelector(getDateVariant)
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeColumns, setActiveColumns] = useState<Record<string, boolean>>({})
  const searchTerm = searchParams.get('search') ?? ''
  const sortBy = searchParams.get('sortBy') ?? ''

  const handleStatusMessage = useCallback(
    ({ invitation, status }: { invitation?: SingleUserInvitation; status: GeneralStatusType }) => {
      switch (true) {
        case status === InvitationSentStatus.Failed:
          return t(
            "The invitation failed to reach the recipient. This can be because the email address doesn't exist, the inbox is full, server outages or several other reasons.",
          )

        case status === InvitationSentStatus.Pending && !!invitation?.expires:
          return t('Invitation expires {{date}}', {
            date: formatDate({ dateVariant, localeName: locale, unixTimestamp: invitation.expires }),
          })

        case status === InvitationSentStatus.Expired:
          return t('Invitations expire after one week')

        default:
          break
      }
    },
    [dateVariant, locale, t],
  )

  const hasAlerts = useMemo(() => {
    return invitationItems.some((item) => item.alerts && item.alerts.length > 0)
  }, [invitationItems])

  const sorting = useMemo(() => {
    const [left, right] = sortBy.split(':')

    if (left && right) {
      return [
        {
          id: left,
          desc: right === 'desc',
        },
      ]
    }

    return []
  }, [sortBy])

  const setSorting: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      const newSorting = typeof updater === 'function' ? updater(sorting) : updater

      if (!newSorting.length) {
        setSearchParams({
          ...(searchTerm && { search: searchTerm }),
        })
        return
      }

      const searchString = `${newSorting[0].id}:${newSorting[0].desc ? 'desc' : 'asc'}`

      setSearchParams({
        ...(searchTerm && { search: searchTerm }),
        sortBy: searchString,
      })

      return newSorting
    },
    [searchTerm, setSearchParams, sorting],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor(ColumnKey.NAME, {
        id: ColumnKey.NAME,
        header: () => INVITATIONS_TABLE_COLUMNS[ColumnKey.NAME].label,
        cell: ({ row, getValue }) => {
          const html = { __html: highlightItemsInTableSearched(t(getValue() ?? t('Invalid user')), searchTerm) }
          return (
            <TableLinkCell to={`/user-management/user/${row.original.id}`} disabled={!row.original.isUser}>
              <div dangerouslySetInnerHTML={html} />
            </TableLinkCell>
          )
        },
        sortingFn: 'basic',
      }),
      columnHelper.accessor(ColumnKey.EMAIL, {
        id: ColumnKey.EMAIL,
        header: () => INVITATIONS_TABLE_COLUMNS[ColumnKey.EMAIL].label,
        cell: ({ getValue }) => {
          const value = getValue()
          const html = { __html: highlightItemsInTableSearched(value, searchTerm) }

          return <div dangerouslySetInnerHTML={html} />
        },
        sortingFn: 'basic',
      }),
      columnHelper.accessor(ColumnKey.ROLE, {
        id: ColumnKey.ROLE,
        header: () => INVITATIONS_TABLE_COLUMNS[ColumnKey.ROLE].label,
        cell: ({ getValue }) => {
          const value = getValue() ?? ''
          const html = { __html: highlightItemsInTableSearched(t(value), searchTerm) }

          return <div dangerouslySetInnerHTML={html} />
        },
        sortingFn: 'basic',
      }),
      ...(hasAlerts
        ? [
            columnHelper.accessor(ColumnKey.ALERTS, {
              id: ColumnKey.ALERTS,
              header: () => INVITATIONS_TABLE_COLUMNS[ColumnKey.ALERTS].label,
              cell: ({ getValue }) => {
                const value = getValue()

                if (!value?.length) {
                  return null
                }

                return <div>{value}</div>
              },
              sortingFn: 'basic',
            }),
          ]
        : []),
      columnHelper.accessor(ColumnKey.STATUS, {
        id: ColumnKey.STATUS,
        header: () => INVITATIONS_TABLE_COLUMNS[ColumnKey.STATUS].label,
        cell: ({ row }) => {
          const { status, invitation } = row.original

          return <StatusTag status={status} message={handleStatusMessage({ invitation, status })} />
        },
        sortingFn: 'basic',
      }),
      ...(allList
        ? [
            columnHelper.accessor(ColumnKey.LAST_LOGIN, {
              id: ColumnKey.LAST_LOGIN,
              header: () => INVITATIONS_TABLE_COLUMNS[ColumnKey.LAST_LOGIN].label,
              cell: ({ getValue }) => {
                const value = getValue()

                if (!value) {
                  return null
                }

                return (
                  <div>{formatDate({ dateVariant: 'absolute', localeName: locale, unixTimestamp: value / 1000 })}</div>
                )
              },
              sortingFn: 'basic',
            }),
          ]
        : []),

      columnHelper.display({
        enableSorting: false,
        id: 'actions',
        header: () => {
          return (
            <ActiveColumnsEditor
              onActiveColumnChange={setActiveColumns}
              columns={INVITATIONS_TABLE_COLUMNS}
              storageKey="invitations"
            />
          )
        },
        cell: ({ row }) => {
          const invitation = row.original.invitation
          if (!invitation) {
            return null
          }

          return <UserRevokeDialog invitation={invitation} />
        },
      }),
    ],
    [allList, handleStatusMessage, hasAlerts, locale, searchTerm, t],
  )

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    sortDescFirst: false,
    data: invitationItems || [],
    columns: columns,
    state: {
      columnVisibility: activeColumns,
      sorting: sorting,
    },
    manualFiltering: true,
    onSortingChange: setSorting,
  })

  if (!invitationItems.length) {
    return (
      <div className="flex size-full items-center justify-center">
        <EmptyData
          text={t('No results found. Try adjusting your search to find what you’re looking for.')}
          icon={Search48Regular}
        />
      </div>
    )
  }

  return (
    <div className="max-h-full pb-4">
      <Table>
        <TableHeader className="sticky top-0 z-[1] border-b border-b-grey.6">
          <TableRow className="h-14 bg-grey.8 text-title text-sky.1">
            {table.getFlatHeaders().map((header) => (
              <DefaultHeaderCell<InvitationsTableItem>
                key={header.id}
                header={header}
                count={header.id === 'name' ? invitationItems.length : undefined}
                className="px-3 first:pl-6 last:pr-4"
              />
            ))}
          </TableRow>
        </TableHeader>

        <TableBody className="divide-grey.6 overflow-auto">
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} className={cn('h-14', { 'text-grey.3': !row.original.isUser })}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} className="px-3 first:pl-6 last:pr-4">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default UserInvitationsTable
