import { useMemo } from 'react'

import { FolderTraitName, folderTraits } from '../../constants/folders'
import { getAllFolders, getFoldersTree } from '../../selectors/foldersSelectors'
import { Folder } from '../types/folder'

import { Tag } from '../types/tag'
import { Profile } from '../types/profile'
import { StatisticsView } from '../../opoint/flow'
import { useAppSelector } from './useAppSelector'

type UseGetFoldersProps = {
  traitNames?: Array<FolderTraitName>
  excludeTraitNames?: boolean
  withChildren?: boolean
}

const useGetFoldersByTrait = <T = Profile | StatisticsView | Tag>({
  traitNames,
  excludeTraitNames = false,
  withChildren = false,
}: UseGetFoldersProps): Array<Folder<T>> => {
  const folders = useAppSelector(withChildren ? getFoldersTree : getAllFolders)

  const newFolders = useMemo(
    () =>
      folders?.filter((folder) =>
        excludeTraitNames
          ? !traitNames?.includes(folderTraits[folder.traits])
          : traitNames?.includes(folderTraits[folder.traits]),
      ),
    [traitNames, folders, excludeTraitNames],
  )

  if (!traitNames) {
    return folders
  }

  return newFolders
}

export default useGetFoldersByTrait
