import { createSelector } from 'reselect'

import { TAG_TYPES } from '../opoint/tags'

import { getTrashTags } from './rootSelectors'

export const getAllTrashTags = createSelector(getTrashTags, (trash) => trash.list)

export const getTrashTagById = (trashId: number) =>
  createSelector(getAllTrashTags, (trash) => trash.find(({ id }) => Math.abs(trashId) === Math.abs(id)))

export const getGlobalTrashTag = createSelector(getAllTrashTags, (trash) =>
  trash.find(({ type }) => type === TAG_TYPES.GLOBAL_TRASH),
)
