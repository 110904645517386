import { ArticleSourceAvatar, cn } from '@opoint/infomedia-storybook'

import { getHeaderUrls } from '../../../../opoint/articles'
import { alterSoMeSummary } from '../../../helpers/common'
import { SentimentType, type SocialMeta } from '../../../types/article'
import ArticleActionButtons from '../common/ArticleActionButtons'
import ArticleBody from '../common/ArticleBody'
import ArticleMetadata from '../common/ArticleMetadata/ArticleMetadata'
import ArticleSourceLine from '../common/ArticleSourceLine/ArticleSourceLine'
import ArticleTranslationInfo from '../common/ArticleTranslationInfo/ArticleTranslationInfo'
import SocialAuthor from '../SocialAuthor/SocialAuthor'
import { M360Article } from '../../../../opoint/articles/types'

type Props = {
  article: M360Article
  socialMeta: SocialMeta
  editMode: boolean
  editable?: boolean
  expanded?: boolean
  hideActionButtons?: boolean
  isEdited?: boolean
  onContentChange?: (any) => void
  openModal: () => void
  translation: any
  index?: number
  shouldBeExpanded?: boolean
  isAlert?: boolean
}

const PreviewSocial = ({
  article,
  editMode,
  editable = false,
  expanded = false,
  hideActionButtons = false,
  onContentChange,
  translation,
  isEdited = false,
  openModal,
  index,
  shouldBeExpanded,
  isAlert = false,
  socialMeta,
}: Props) => {
  const { content_protected, orig_url, url, first_source, topics_and_entities, shareable, edited, summary, meta_data } =
    article

  const renderSocialIcon = (profileImage?, profileUrl?) => {
    // @ts-expect-error: Muted so we could enable TS strict mode
    const newUrls: { primary: string; secondary: string } = getHeaderUrls({
      content_protected,
      orig_url,
      url,
    })

    return (
      <a
        href={profileUrl ? profileUrl : newUrls.primary || newUrls.secondary}
        target="_blank"
        className="mr-3 size-12"
        rel="noreferrer"
      >
        <ArticleSourceAvatar
          article={article}
          profileImageUrl={profileImage ? profileImage : socialMeta?.author?.profile_image}
          renderImage={true}
          showLogo
        />
      </a>
    )
  }

  const isTwitterContent = ['Twitter']?.includes(first_source.sitename)
  // @ts-expect-error: Muted so we could enable TS strict mode
  const summaryText = alterSoMeSummary(summary.text, socialMeta?.content_type, socialMeta?.attachments?.retweeted_user)
  const generalSentiment = topics_and_entities?.sentiment ?? SentimentType.Notset

  return (
    <>
      {!hideActionButtons && (
        <>
          <ArticleActionButtons
            article={article}
            sharingDisabled={shareable === false}
            translation={translation}
            isAlert={isAlert}
          />

          <ArticleSourceLine article={article} edited={edited || isEdited} articleIndex={index} />

          <ArticleMetadata
            article={article}
            meta_data={meta_data}
            sentiment={generalSentiment}
            showSentiment={!isAlert}
          />
        </>
      )}

      <ArticleTranslationInfo article={article} />

      <div
        className={cn({
          'max-h-[80rem] overflow-hidden': !expanded && !editMode,
          'border border-grey.6 rounded-md': !editMode,
          // @ts-expect-error: Muted so we could enable TS strict mode
          'pt-3': summary.text && !article?.author,
        })}
      >
        {!editMode && article?.author && (
          <div className="flex items-center p-3">
            {renderSocialIcon()}

            {article?.author !== 'InstagramUser' && (
              <SocialAuthor
                author={article?.author}
                content_protected={content_protected}
                hasHandle={!!socialMeta?.author}
                isVerified={socialMeta?.author?.verified}
                orig_url={orig_url}
                url={url}
                username={socialMeta?.author?.username}
                // @ts-expect-error: Muted so we could enable TS strict mode
                display_name={isTwitterContent ? socialMeta?.author?.display_name : null}
              />
            )}
          </div>
        )}

        <ArticleBody
          article={article}
          expanded={expanded}
          editable={editable}
          isTwitterContent={isTwitterContent}
          onClickArticleLinkPdf={openModal}
          onContentChange={onContentChange}
          socialContent_type={socialMeta?.content_type}
          renderSocialIcon={renderSocialIcon}
          summaryText={summaryText}
          shouldBeExpanded={shouldBeExpanded}
        />
      </div>
    </>
  )
}

export default PreviewSocial
