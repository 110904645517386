import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'

import { getContactFilterId, isEmailDomainAllowed } from '../../../helpers/contacts'
import { getContactFilterName } from '../../../opoint/contacts/index'
import { getAllowedDomains } from '../../../selectors/settingsSelectors'
import FilterPill from '../../common/FilterPill/FilterPill'
import { useAppSelector } from '../../hooks/useAppSelector'
import { CommonRecipientFilter, ContactFilter } from '../../types/contact'

type ContactFilterProps = {
  deleteHandler: (filter: ContactFilter | CommonRecipientFilter) => void
  filter: ContactFilter | CommonRecipientFilter
}

const ContactsFilter = (props: ContactFilterProps) => {
  const allowedDomains = useAppSelector(getAllowedDomains)
  const anyDomainRestrictions = !!allowedDomains

  const deleteHandler = () => {
    const { filter } = props
    props.deleteHandler(filter)
  }

  const { filter } = props
  const contactFilterName = getContactFilterName(filter)

  let name
  switch (filter.type) {
    case 'group':
      name = `Group: ${contactFilterName}`
      break
    case 'phoneNumber':
      name = `Phone number: ${contactFilterName}`
      break
    case 'email':
      name = `Email: ${contactFilterName}`
      break
    default:
      name = contactFilterName
  }

  const invalidValue =
    anyDomainRestrictions &&
    filter.type === 'email' &&
    !isEmailDomainAllowed(contactFilterName as string, allowedDomains)

  const tag = (
    <FilterPill
      invalid={invalidValue}
      deleteHandler={deleteHandler}
      filter={{
        id: getContactFilterId(filter),
        name,
        type: filter.type,
      }}
    />
  )

  if (invalidValue) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>{tag}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="top">
            <Trans>You are only allowed to send to recipients inside your organisation</Trans>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    )
  }

  return tag
}

export default ContactsFilter
