import { Location } from 'react-router-dom'

let currentLocation: Location = {
  pathname: '',
  search: '',
  hash: '',
  state: '',
  key: '',
}

export const setLocation = (location) => {
  currentLocation = location
}

export const getCurrentLocation = (): Location => {
  return currentLocation
}

export const getCurrentPath = () => {
  return currentLocation?.pathname
}
