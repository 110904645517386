import { useMemo } from 'react'

import { getUserPermissions } from '../../selectors/settingsSelectors'
import { Modules, PermissionOptions } from '../constants/permissions'

import { useAppSelector } from './useAppSelector'

const useIsUserPermitted = ({
  module,
  permissions,
}: {
  module: Modules
  permissions: PermissionOptions[]
}): boolean => {
  const userPermissions = useAppSelector(getUserPermissions(module))

  const isUserPermitted = useMemo(
    () => !!permissions.find((permission) => permission === userPermissions),
    [userPermissions, permissions],
  )

  return isUserPermitted
}

export default useIsUserPermitted
