import { equals } from 'ramda'
import { useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const useDeepCompareMemoize = (value: any): any => {
  const ref = useRef()

  if (!equals(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export default useDeepCompareMemoize
