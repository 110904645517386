import React from 'react'
import { ErrorCircle16Regular } from '@fluentui/react-icons'
import { Trans } from 'react-i18next'

export const ErrorBreadcrumb = (): React.JSX.Element => (
  <span className="flex items-center font-normal text-berry.2">
    <ErrorCircle16Regular className="mr-1 shrink-0" />
    <Trans>Unknown</Trans>
  </span>
)
