import { MailAdd24Regular } from '@fluentui/react-icons'
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@opoint/infomedia-storybook'

import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { ActionBarDropdownMenuItem } from '../../ActionBarDropdownMenuItem'
import { ActionBarActionsProps, ActionVariant } from '../../types'
import { UmamiEvent } from '../../../../../helpers/umami.ts'

const AlertCreateNewAction = ({ variant = ActionVariant.LONG, name }: ActionBarActionsProps) => {
  const dispatch = useAppDispatch()

  const onClickAction = () => dispatch({ type: 'CREATE_ALERT_ACTIONLINE' })

  if (variant === ActionVariant.MENU) {
    return (
      <ActionBarDropdownMenuItem
        onClick={onClickAction}
        data-cy="createAlert"
        data-umami-event={UmamiEvent.ALERTS_CREATE_BUTTON_CLICK}
      >
        <MailAdd24Regular />
        {name}
      </ActionBarDropdownMenuItem>
    )
  }

  if (variant === ActionVariant.LONG) {
    return (
      <Button
        variant="outline"
        onClick={onClickAction}
        data-cy="createAlert"
        data-umami-event={UmamiEvent.ALERTS_CREATE_BUTTON_CLICK}
      >
        <MailAdd24Regular />
        {name}
      </Button>
    )
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          onClick={onClickAction}
          data-cy="createAlert"
          data-umami-event={UmamiEvent.ALERTS_CREATE_BUTTON_CLICK}
        >
          <MailAdd24Regular />
          <span className="sr-only">{name}</span>
        </Button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{name}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}

export default AlertCreateNewAction
