import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'

import { t } from "i18next";
import {
  ProfileEditorFiltersFetchMultipleFailureAction,
  ProfileEditorFiltersFetchSimpleFailureAction,
  ProfileFetchFailureAction,
  ProfilesActions,
} from '../../../actions/profiles'
import { ErrorEpic, handlerError } from '.'

const profilesErrorTypes: ErrorEpic<
  | ProfileFetchFailureAction['type']
  | ProfileEditorFiltersFetchMultipleFailureAction['type']
  | ProfileEditorFiltersFetchSimpleFailureAction['type']
> = {
  ['PROFILES_FETCH_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to fetch profiles'),
    toastId: 'PROFILES_FETCH_FAILURE',
  },
  ['PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to provide suggestions'),
    toastId: 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE',
  },
  ['PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE']: {
    isSuccess: false,
    message: t('We were unable to provide suggestions'),
    toastId: 'PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE',
  },
}

const profilesErrorEpic: (action: ActionsObservable<ProfilesActions>) => void = (action$) =>
  action$.pipe(switchMap(({ type }) => handlerError(profilesErrorTypes, type)))

export default profilesErrorEpic
