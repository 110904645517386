import { produce } from 'immer'
import { getArticleId } from '@opoint/infomedia-storybook'
import { AppActions } from '../actions'
import { TranslatedAlertArticle } from '../components/types/alert'
import { AlertTag } from '../components/types/tag'
import { ALERT_TAGS_LOAD_STATE } from '../constants/alerts'
import { getRecipients, sortIdenticalArticlesInProfiles } from '../helpers/alerts'
import { processAlertTags } from '../opoint/alerts'
import { toDisplayTimeFormat } from '../opoint/alerts/apiTime'
import {
  ActiveAlert,
  AlertHistoryItem,
  AlertHistoryObject,
  AlertNextPreview,
  CurrentAlertMailType,
} from '../opoint/alerts/types'
import { AlertDetailed, SetActiveArticle } from '../opoint/flow'
import { Alert, AlertContactOverview } from '../api/opoint.schemas'
import { M360Article } from '../opoint/articles/types'
import { TAG_TYPES } from '../opoint/tags'

export type CurrentAlertRecipients = {
  emails: { email: string; mobile: string; name: string; status: number }[]
  phoneNumbers: string[]
}

export type AlertsState = {
  activeAlertInListing: SetActiveArticle
  activeAlertId?: number
  articlesList: Array<M360Article>
  //change this after new api schema is ready
  currentAlertMail?: CurrentAlertMailType[]
  currentAlertRecipients: CurrentAlertRecipients
  didFetchAlertTags: number
  details: Record<number, AlertDetailed>
  editedAlert: ActiveAlert
  history: AlertHistoryObject
  historyLoading: boolean
  nextAlertLoading: boolean
  fetchAlertDetailLoading: boolean
  currentHistoryItem?: AlertHistoryItem
  list: Alert[]
  nextPreview: Record<number, AlertNextPreview>
  requestPending: boolean
  articlesMarkedForRemoval: Record<string, M360Article>
  isAlertSavingInProgress: boolean
  alertsRecipients: Array<AlertContactOverview>
  translatedAlertArticles: Record<number, TranslatedAlertArticle>
  tags: Array<AlertTag>
  deleteInProgress: boolean
  wasInitialized: boolean
  template?: AlertNextPreview
  justSavedAlertId?: number
  activeIdenticalArticle: number
}

export const initialState: AlertsState = {
  activeAlertInListing: { index: 0, source: 'other' },
  activeAlertId: undefined,
  editedAlert: { mifluzIdLists: [], searchterm: '', schedule: { timeConfiguration: {} } },
  activeIdenticalArticle: 0,
  articlesList: [],
  alertsRecipients: [],
  currentAlertMail: undefined,
  currentHistoryItem: undefined,
  currentAlertRecipients: { emails: [], phoneNumbers: [] },
  deleteInProgress: false,
  details: {},
  didFetchAlertTags: ALERT_TAGS_LOAD_STATE.NONE,
  history: {},
  historyLoading: false,
  nextAlertLoading: false,
  fetchAlertDetailLoading: false,
  list: [],
  articlesMarkedForRemoval: {},
  requestPending: false,
  tags: [],
  isAlertSavingInProgress: false,
  translatedAlertArticles: {},
  wasInitialized: false,
  // @ts-expect-error: Muted so we could enable TS strict mode
  template: null,
  nextPreview: {},
}

const alertsReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'SET_ACTIVE_ALERT_ARTICLE_SUCCESS': {
      draftState.activeAlertInListing = action.payload
      break
    }

    case 'ALERT_FETCH_INFO': {
      draftState.fetchAlertDetailLoading = true
      break
    }

    case 'ALERTS_FETCH_SUCCESS': {
      const alertList = action.payload

      draftState.tags = processAlertTags(alertList.filter((alert) => alert.editable))
      // @ts-expect-error Error because baskets are readonly
      draftState.list = alertList.reverse()
      break
    }

    case 'ALERTS_FETCH_RECIPIENTS_SUCCESS': {
      draftState.alertsRecipients = action.payload.alertsRecipients
      break
    }

    case 'ALERT_FETCH': {
      draftState.requestPending = true
      draftState.activeAlertId = action.payload.alertId
      break
    }

    case 'ALERT_FETCH_SUCCESS': {
      const { alert, toBeEdited } = action.payload
      const currentRecipients = draftState.alertsRecipients.find((recipient) => recipient.id === alert.id)
      const mergedRecipients = currentRecipients
        ? getRecipients(currentRecipients, alert)
        : { emails: [], phoneNumbers: [] }

      draftState.details = {
        ...draftState.details,
        [alert.id]: alert,
      }
      draftState.currentAlertMail = undefined
      draftState.currentAlertRecipients = mergedRecipients
      if (toBeEdited) {
        draftState.editedAlert.schedule = {
          ...alert.schedule,
          timeConfiguration: toDisplayTimeFormat(alert.schedule.timeConfiguration),
        }
      }
      break
    }

    case 'ALERT_CURRENT_RECIPIENTS_UPDATE_SUCCESS': {
      draftState.currentAlertRecipients = action.payload.data
      break
    }

    case 'ALERT_TEMPLATE_FETCH_SUCCESS': {
      draftState.template = action.payload.preview
      break
    }

    case 'ALERT_CLEAR_TEMPLATE': {
      draftState.template = undefined
      break
    }

    case 'ALERT_PREVIEW_CLEAR': {
      delete draftState.nextPreview[action.payload.id]
      break
    }

    case 'ALERT_FETCH_HISTORY':
    case 'ALERT_FETCH_MORE_HISTORY': {
      draftState.historyLoading = true
      break
    }

    case 'ALERT_FETCH_HISTORY_SUCCESS': {
      const { id, data } = action.payload
      draftState.history[id] = data
      draftState.historyLoading = false
      break
    }

    case 'ALERT_FETCH_MORE_HISTORY_SUCCESS': {
      const { id, data } = action.payload
      draftState.history[id] = {
        ...draftState.history[id],
        next: data.next,
        results: [...(draftState.history[id]?.results ?? []), ...data.results],
      }
      draftState.historyLoading = false
      break
    }

    case 'ALERT_FETCH_CONTENT_SUCCESS': {
      const { alertId, data, historyId, historyTimestamp, id } = action.payload

      draftState.currentAlertMail = sortIdenticalArticlesInProfiles(data.content, true)
      draftState.didFetchAlertTags = ALERT_TAGS_LOAD_STATE.NONE
      draftState.currentHistoryItem = { alertId, historyId, historyTimestamp, id }
      draftState.translatedAlertArticles = {}
      draftState.activeAlertId = alertId
      draftState.fetchAlertDetailLoading = false
      break
    }

    case 'ALERT_FETCH_CONTENT_FAILURE': {
      draftState.fetchAlertDetailLoading = false
      break
    }

    case 'ALERT_FETCH_JSON_NEXT_PREVIEW': {
      draftState.nextAlertLoading = true
      break
    }

    case 'ALERT_FETCH_JSON_NEXT_PREVIEW_SUCCESS': {
      const { alertId, data } = action.payload

      draftState.currentAlertMail = sortIdenticalArticlesInProfiles(data)
      draftState.didFetchAlertTags = ALERT_TAGS_LOAD_STATE.NONE
      draftState.currentHistoryItem = {
        ...(draftState.currentHistoryItem ?? { historyId: 0, historyTimestamp: 0 }),
        alertId,
      }
      draftState.nextAlertLoading = false
      draftState.translatedAlertArticles = {}
      break
    }

    case 'ALERT_CLEAR_CONTENT': {
      draftState.activeIdenticalArticle = 0
      draftState.currentAlertMail = undefined
      draftState.didFetchAlertTags = ALERT_TAGS_LOAD_STATE.NONE
      draftState.history = {}
      draftState.translatedAlertArticles = {}
      break
    }

    case 'ALERT_FETCH_TAGS_SUCCESS': {
      const articlesWithBaskets = action.payload.data.filter((article) => article.baskets.length > 0)

      if (!draftState.currentAlertMail) {
        return
      }

      draftState.currentAlertMail = draftState.currentAlertMail.map((profile) => {
        if (!profile.content || !articlesWithBaskets.length) {
          return profile
        }

        return {
          ...profile,
          content: {
            searchresult: {
              ...profile.content.searchresult,
              document: profile.content.searchresult.document.map((article) => {
                const activeTags = {}

                articlesWithBaskets
                  .find((articleWithBasket) => article.id_article === articleWithBasket.id_article)
                  ?.baskets.forEach(({ basket, weight }) => {
                    activeTags[basket] = { basket, is_owner: 1, set: 0, weight }
                  })

                return {
                  ...article,
                  tags: activeTags,
                }
              }),
            },
          },
        }
      })

      draftState.didFetchAlertTags =
        draftState.didFetchAlertTags === ALERT_TAGS_LOAD_STATE.NONE
          ? ALERT_TAGS_LOAD_STATE.INITIAL
          : ALERT_TAGS_LOAD_STATE.ALL
      break
    }

    case 'ALERT_MARK_ARTICLE_FOR_REMOVAL': {
      draftState.articlesMarkedForRemoval[getArticleId(action.payload.article)] = action.payload.article
      break
    }

    case 'ALERT_UNMARK_ARTICLE_FOR_REMOVAL': {
      delete draftState.articlesMarkedForRemoval[getArticleId(action.payload.article)]
      break
    }

    case 'ALERT_REMOVE_ARTICLE_CLOSE': {
      draftState.articlesMarkedForRemoval = {}
      break
    }

    case 'ALERT_UPDATE_CURRENT_ALERT_MAIL_ARTICLES': {
      draftState.currentAlertMail = action.payload.data
      break
    }

    case 'FETCH_SINGLE_ALERT_ARTICLE_FOR_TRANSLATION_SUCCESS': {
      const translatedArticle = action.payload.response.searchresult.document[0]
      draftState.translatedAlertArticles[translatedArticle.id_article] = {
        author: translatedArticle.author,
        body: translatedArticle.body,
        caption: translatedArticle.caption,
        header: translatedArticle.header,
        language: translatedArticle.language,
        quotes: translatedArticle.quotes,
        short_header: translatedArticle.short_header,
        short_summary: translatedArticle.short_summary,
        summary: translatedArticle.summary,
      }
      break
    }

    case 'INIT_SCHEDULE_ENTRY': {
      draftState.editedAlert.schedule = {
        timeConfiguration: {
          ...draftState.editedAlert.schedule.timeConfiguration,
          [action.payload]: [],
        },
      }
      break
    }

    case 'DELETE_SCHEDULE_ENTRY': {
      delete draftState.editedAlert.schedule.timeConfiguration[action.payload]
      break
    }

    case 'SET_SCHEDULE_ENTRY': {
      const { day, intervalData } = action.payload
      draftState.editedAlert.schedule = {
        timeConfiguration: {
          ...draftState.editedAlert.schedule.timeConfiguration,
          [day]: intervalData,
        },
      }
      break
    }

    case 'ALERT_INIT_FORM': {
      if (draftState.wasInitialized) {
        return
      }

      draftState.wasInitialized = true
      draftState.editedAlert = {
        searchterm: '',
        mifluzIdLists: action.payload,
        schedule: { timeConfiguration: {} },
      }
      break
    }

    case 'TAG_ARTICLES': {
      const { currentAlertMail } = draftState

      if (!currentAlertMail || !action.payload.articles?.length) {
        return draftState
      }

      const { articles, tag, weight = tag?.type === TAG_TYPES.MENTOMETER ? 0 : 1 } = action.payload
      const firstArticleID = articles[0].id_article
      const profilesCopy = [...currentAlertMail]

      profilesCopy.forEach((group, groupIndex) => {
        if (group.content) {
          const articleIndex = group.content?.searchresult.document.findIndex(
            (article) => article?.id_article === firstArticleID,
          )

          if (profilesCopy[groupIndex].content && articleIndex > -1) {
            //@ts-expect-error: remove this after new api schema is ready
            profilesCopy[groupIndex].content.searchresult.document[articleIndex].tags[tag.id] = {
              id: tag.id,
              is_owner: 1,
              set: 0,
              weight,
            }
          }
        }
      })

      draftState.currentAlertMail = profilesCopy
      break
    }

    case 'UNTAG_ARTICLES': {
      const { currentAlertMail } = draftState

      if (!currentAlertMail || !action.payload.articles?.length) {
        return draftState
      }

      const { articles, tagId } = action.payload
      const firstArticleID = articles[0].id_article
      const profilesCopy = [...currentAlertMail]

      profilesCopy.forEach((group, groupIndex) => {
        if (group.content) {
          const articleIndex = group.content.searchresult.document.findIndex(
            (article) => article?.id_article === firstArticleID,
          )

          if (profilesCopy[groupIndex].content && articleIndex > -1) {
            //@ts-expect-error: remove this after new api schema is ready
            delete profilesCopy[groupIndex].content.searchresult.document[articleIndex]?.tags?.[tagId]
          }
        }
      })

      draftState.currentAlertMail = profilesCopy
      break
    }

    case 'CLEAR_FORM': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.editedAlert = null
      draftState.wasInitialized = false
      break
    }

    case 'ALERT_ADD_FILTER': {
      draftState.editedAlert.mifluzIdLists = [...(draftState.editedAlert?.mifluzIdLists ?? []), action.payload.id]
      draftState.editedAlert.searchterm = ''
      break
    }

    case 'ALERT_TOGGLE_FILTER': {
      const { id } = action.payload
      const toggleOff = !!draftState.editedAlert.mifluzIdLists?.includes(id)

      draftState.editedAlert.mifluzIdLists = toggleOff
        ? draftState.editedAlert.mifluzIdLists!.filter((listId) => listId !== id)
        : [...(draftState.editedAlert.mifluzIdLists ?? []), id]
      draftState.editedAlert.searchterm = toggleOff ? draftState.editedAlert.searchterm : ''
      break
    }

    case 'ALERT_REMOVE_FILTER': {
      draftState.editedAlert.mifluzIdLists = draftState.editedAlert.mifluzIdLists!.filter(
        (listId) => listId !== action.payload.id,
      )
      break
    }

    case 'ALERT_SEARCHTERM_CHANGED': {
      draftState.editedAlert.searchterm = action.payload
      break
    }

    case 'ALERT_CLEAR_ALL': {
      draftState.editedAlert.mifluzIdLists = []
      draftState.editedAlert.searchterm = ''
      break
    }

    case 'ALERT_REMOVE_ACTIVE': {
      draftState.editedAlert = { mifluzIdLists: [], searchterm: '', schedule: { timeConfiguration: {} } }
      break
    }

    case 'ALERT_INIT_MIFLUZ': {
      draftState.editedAlert.searchterm = ''
      draftState.editedAlert.mifluzIdLists = action.payload
      break
    }

    case 'ALERT_TAG_TOGGLE': {
      draftState.tags = draftState.tags.map((tag) =>
        tag.id === action.payload ? { ...tag, expanded: !tag.expanded } : tag,
      )
      break
    }

    case 'ALERT_TAG_EXPAND': {
      draftState.tags = draftState.tags.map((tag) => (tag.id === action.payload ? { ...tag, expanded: true } : tag))
      break
    }

    case 'DELETE_ALERT_CONFIRM': {
      draftState.deleteInProgress = true
      break
    }

    case 'ALERT_DELETE_SUCCESS':
    case 'ALERT_DELETE_FAILURE':
    case 'ALERT_DELETE_CANCEL': {
      draftState.deleteInProgress = false
      break
    }

    case 'SAVE_ALERT': {
      draftState.isAlertSavingInProgress = true
      break
    }

    case 'SAVE_ALERT_SUCCESS': {
      draftState.isAlertSavingInProgress = false
      draftState.justSavedAlertId = action.payload.alert.id
      break
    }

    case 'SAVE_ALERT_FAILURE':
    case 'SAVE_ALERT_VALID_FAILURE': {
      draftState.isAlertSavingInProgress = false
      break
    }

    default:
      return
  }
}, initialState)

export default alertsReducer
