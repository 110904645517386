import { compose } from 'ramda'
import { applyMiddleware, createStore } from 'redux'
import createLogger from 'redux-logger'
import { createEpicMiddleware } from 'redux-observable'

import rootReducer from './reducers'
import { rootEpic, routeChangedMiddleware } from './redux-middleware'

export const epicMiddleware = createEpicMiddleware()

const composeEnhancers =
  (import.meta.env.DEV &&
    // @ts-expect-error: Muted so we could enable TS strict mode
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const enhancers = [routeChangedMiddleware, epicMiddleware]

// If diff is set to true, we are running into performance issues with large objects
const logger = createLogger({
  collapsed: true,
  diff: false,
})

if (import.meta.env.DEV) {
  // @ts-expect-error: Muted so we could enable TS strict mode
  enhancers.push(logger)
}

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...enhancers)))

epicMiddleware.run(rootEpic)
export default store
