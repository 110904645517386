import { useTranslation } from 'react-i18next'
import { useMemo, useRef, useState } from 'react'

import { lensPath, view } from 'ramda'
import { filterSuggestions } from '../../../helpers/contacts'
import { getContactsAndGroups } from '../../../selectors/contactSelectors'
import { getContacts } from '../../../selectors/rootSelectors'
import AutocompleteField from '../../common/SearchInput/AutocompleteField'
import { useAppSelector } from '../../hooks/useAppSelector'
import { CommonRecipientFilter, ContactFilter, ContactsPath } from '../../types/contact'
import ContactsFilter from '../ContactsFilter'

import { useUsersSearchList } from '../../../api/users/users'
import '../../../opoint/search/AutocompleteItem.css'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import ContactSuggestion from './ContactSuggestion'

type ContactsInputProps = {
  id?: string //TODO: this doesn't get used
  contactsPath: ContactsPath
  error?: string
  onAddFilter?: (suggestion: ContactFilter | CommonRecipientFilter) => void
  onRemoveFilter?: (filter: ContactFilter | CommonRecipientFilter) => void
  onToggleFilter?: (suggestion: ContactFilter | CommonRecipientFilter) => void
  filters?: Array<ContactFilter | CommonRecipientFilter>
  onClearInput?: () => void
  variant?: 'contacts' | 'users'
  restrictedHeight?: boolean
}

const ContactsInput = ({
  id,
  contactsPath,
  error,
  onAddFilter,
  onRemoveFilter,
  onToggleFilter,
  onClearInput,
  filters,
  variant = 'contacts',
  restrictedHeight,
}: ContactsInputProps) => {
  const { t } = useTranslation()
  const contactsSuggestions = useAppSelector(getContactsAndGroups)
  const allContacts = useAppSelector(getContacts)
  const contacts: Array<ContactFilter> = view(lensPath(contactsPath.split('.')), allContacts)

  const { data } = useUsersSearchList({ q: '' })

  const users: ContactFilter[] = useMemo(() => {
    return (
      data?.map((user) => {
        return {
          type: 'user',
          entity: {
            id: user.id_user,
            firstName: user.display_name ? user.display_name : user.username,
            email: user.username, // Email is under username
          },
        } as ContactFilter
      }) ?? []
    )
  }, [data])

  const [expression, setExpression] = useState('')

  const handleExpressionChange = (value?: string) => {
    setExpression(value || '')
  }

  const searchInputRef = useRef<HTMLDivElement>(null)

  const dispatch = useAppDispatch()

  const handleToggleFilter = (suggestion: ContactFilter | CommonRecipientFilter) => {
    setExpression('')

    if (onToggleFilter) {
      onToggleFilter(suggestion)

      return
    }
    dispatch({ type: 'ON_CONTACT_FILTER_TOGGLED', payload: { filter: suggestion, contactsPath } })
  }

  const handleAddFilter = (suggestion: ContactFilter | CommonRecipientFilter) => {
    setExpression('')

    if (onAddFilter) {
      onAddFilter(suggestion)

      return
    }
    dispatch({ type: 'ON_CONTACT_FILTER_ADDED', payload: { filter: suggestion, contactsPath } })
  }

  const handleClearAll = () => {
    setExpression('')
    if (typeof onClearInput === 'function') {
      onClearInput()
    }
    dispatch({ type: 'ON_CONTACT_FILTER_EMPTIED', payload: { contactsPath } })
  }

  const handleRemoveFilter = (filter: ContactFilter | CommonRecipientFilter) => {
    if (onRemoveFilter) {
      onRemoveFilter(filter)

      return
    }

    dispatch({ type: 'ON_CONTACT_FILTER_REMOVED', payload: { filter, contactsPath } })
  }

  const suggestions = useMemo(
    () =>
      filterSuggestions({
        suggestions: contactsSuggestions,
        searchterm: expression,
        isEmailInput: contactsPath !== ContactsPath.EditedAlertSms,
      }),
    [contactsSuggestions, expression, contactsPath],
  )

  const ContactsFilterComponent = (props) => <ContactsFilter {...props} deleteHandler={handleRemoveFilter} />

  return (
    <AutocompleteField
      id={id}
      ref={searchInputRef}
      searchterm={expression}
      onSearchTermChanged={handleExpressionChange}
      placeholder={t('Enter recipients')}
      suggestions={variant === 'users' ? users : suggestions}
      filters={filters ?? contacts}
      tagSeparator={false}
      filterComponent={ContactsFilterComponent}
      suggestionComponent={ContactSuggestion}
      clearAllHandler={handleClearAll}
      onFilterToggled={handleToggleFilter}
      onFilterAdded={handleAddFilter}
      removeFilter={handleRemoveFilter}
      autoSuggestion
      error={error}
      data-cy="emailRecipientsInput"
      showHint
      maxHeight={restrictedHeight}
    />
  )
}

export default ContactsInput
