import { DOM } from 'rx-dom'

import { map, merge } from 'ramda'
import {
  Setting,
  SettingWithChoices,
  SettingsResponse,
  SettingsSaveRequestPayload,
} from '../../components/interfaces/settings'
import config from '../common/config'
import { SUPPORTED_LOCALES } from '../common/constants'

const normalizeSettingValues = map((setting: Setting | SettingWithChoices<any>) => {
  if (setting.name === 'LANGUAGE' && !SUPPORTED_LOCALES.includes(setting.value)) {
    return { ...setting, value: 'en-GB' }
  }

  // due to original mistake in Chinese locale
  if (setting.value === 'zh_CN') {
    return { ...setting, value: 'zh-CN' }
  }

  if (setting.name === 'MAX_GT_ARTICLE_LENGTH') {
    return { ...setting, value: Number(setting.value) }
  }

  return setting
})

export async function getSettings(): Promise<SettingsResponse> {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/settings/'),
  })

  // @ts-expect-error: Muted so we could enable TS strict mode
  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => normalizeSettingValues(response))
}

export async function saveSettings(settings: SettingsSaveRequestPayload): Promise<SettingsResponse> {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/settings/'),
    method: 'POST',
    body: JSON.stringify(normalizeSettingValues(settings)),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
