import { useOpointUser } from '@opoint/authjs-react'

import { isECBUser } from '../../helpers/auth'

const useIsECBUser = (): boolean => {
  const user = useOpointUser()

  return isECBUser(user)
}

export default useIsECBUser
