import { Auth, useOpointAuth } from '@opoint/authjs-react'
import Axios, { AxiosError, AxiosRequestConfig } from 'axios'

import { useAppSelector } from '../components/hooks/useAppSelector'
import { getLocale } from '../helpers/locale'

export const customAxiosInstance = Axios.create({
  baseURL: import.meta.env.VITE_RUNTIME_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// enhancing apiClient for better stack trace sent to sentry when error occurs
customAxiosInstance.interceptors.response.use(null, async (error) => {
  const originalStackTrace = error.config?.errorContext?.stack

  if (originalStackTrace) {
    error.stack = `${error.stack}\n${originalStackTrace}`
  }

  throw error
})

export const useCustomAxiosInstance = <T>(): ((config: AxiosRequestConfig) => Promise<T>) => {
  const auth: Auth | undefined = useOpointAuth()
  const locale = useAppSelector((state) => getLocale(state.settings))

  return async (config: AxiosRequestConfig) => {
    const source = Axios.CancelToken.source()
    const token = await auth?.getTokenString()
    const promise = customAxiosInstance({
      ...config,
      headers: {
        ...config.headers,
        'Accept-Language': locale,
        Authorization: token ? `JWT ${token}` : undefined,
      },
      cancelToken: source.token,
    }).then(({ data }) => data)

    // @ts-expect-error - picked from https://orval.dev/reference/configuration/output
    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query')
    }

    return promise
  }
}

export type ErrorType<Error> = AxiosError<Error>
