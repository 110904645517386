import { Trans } from 'react-i18next'
import { Step } from 'react-joyride'

import { ListingStyle } from '@opoint/infomedia-storybook'
import { GI_PRODUCT_TOUR_KEY } from '../../../constants/localStorage'
import { getArticleListingStyle } from '../../../selectors/settingsSelectors'

import { useAppSelector } from '../../hooks/useAppSelector'
import ProductTour from '../ProductTour'
import { disabledBeaconAndAnimation, disabledScrolling } from '../constants'
import { prependHash } from '../utility'

export enum GeneralIntroProductTourTarget {
  STEP_1 = 'body',
  STEP_2 = 'GIPT_STEP_2',
  STEP_3 = 'GIPT_STEP_3',
  STEP_4 = 'GIPT_STEP_4',
  STEP_5 = 'GIPT_STEP_5',
  STEP_6 = 'GIPT_STEP_6',
  STEP_7 = 'GIPT_STEP_7',
  STEP_8 = 'GIPT_STEP_8',
}

const GeneralIntroProductTour = () => {
  const articleListingStyle = useAppSelector(getArticleListingStyle)
  const isReversed =
    articleListingStyle === ListingStyle.EMAIL ||
    articleListingStyle === ListingStyle.ARCHIVE_LEFT ||
    articleListingStyle === ListingStyle.INTRO

  const steps: Step[] = [
    {
      target: GeneralIntroProductTourTarget.STEP_1,
      title: <Trans>Hi there.</Trans>,
      content: (
        <Trans>{"Welcome to Infomedia’s platform. Let's walk through the important features and a few tips to ease your work."}</Trans>
      ),
      placement: 'center',
      ...disabledBeaconAndAnimation,
    },
    {
      target: prependHash(GeneralIntroProductTourTarget.STEP_2),
      content: (
        <Trans>This is your overview of search profiles. Select one or multiple profiles you want to explore.</Trans>
      ),
      placement: 'right-start',
      ...disabledBeaconAndAnimation,
      ...disabledScrolling,
    },
    {
      target: prependHash(GeneralIntroProductTourTarget.STEP_3),
      content: (
        <Trans>Using Tags, articles of particular interest can be marked and saved in a separate folder for future reference, such as those related to a press release, a specific case, a campaign, etc.</Trans>
      ),
      placement: 'right',
      ...disabledBeaconAndAnimation,
      ...disabledScrolling,
    },
    {
      target: prependHash(GeneralIntroProductTourTarget.STEP_4),
      title: <Trans>In this list you’ll see a chronological overview of the articles from the selected period.</Trans>,
      content: (
        <Trans>Essential information is shown to make it easy to scan the list, and you can hover over the dots on the line to see how your keywords are mentioned.</Trans>
      ),
      placement: isReversed ? 'right-start' : 'left-start',
      ...disabledBeaconAndAnimation,
      ...disabledScrolling,
    },
    {
      target: prependHash(GeneralIntroProductTourTarget.STEP_5),
      content: <Trans>Read the original article by clicking on the article headline.</Trans>,
      placement: isReversed ? 'left-start' : 'right-start',
      ...disabledBeaconAndAnimation,
      ...disabledScrolling,
    },
    {
      target: prependHash(GeneralIntroProductTourTarget.STEP_6),
      title: <Trans>You’ll see results from the time period stated in Period.</Trans>,
      content: (
        <Trans>Here you can change the period and apply filters to better understand where your mentions are coming from.</Trans>
      ),
      placement: 'bottom-start',
      ...disabledBeaconAndAnimation,
    },
    {
      target: prependHash(GeneralIntroProductTourTarget.STEP_7),
      title: (
        <Trans>Search and use filters to explore and find specific articles. Type in your keywords in the search bar and use filters below the search bar to quickly add relevant filters.</Trans>
      ),
      content: <Trans>Tip: Under Help, you’ll find a guideline for creating advanced searches.</Trans>,
      ...disabledBeaconAndAnimation,
    },
    {
      target: prependHash(GeneralIntroProductTourTarget.STEP_8),
      content: (
        <Trans>Under your profile, you can adjust your preferred personal settings. This includes setting your preferred language, how to view your mentions, choosing filter options, and more.</Trans>
      ),
      placement: 'bottom',
      ...disabledBeaconAndAnimation,
    },
  ]

  return <ProductTour tourKey={GI_PRODUCT_TOUR_KEY} steps={steps} />
}

export default GeneralIntroProductTour
