import { useMemo } from 'react'

export const getCurrentDomain = () => {
  const urlParts = window?.location?.hostname?.split('.')

  const domain = urlParts?.slice(0).slice(-2).join('.')

  return domain
}

const useCurrentDomain = (): string => {
  const currentDomain = useMemo(() => {
    return getCurrentDomain()
  }, [])

  return currentDomain
}

export default useCurrentDomain
