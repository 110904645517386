import { getFeeds } from '../../../../../selectors/feedsSelector'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useNavigationContext } from '../../NavigationContext'
import {
  NavigationSectionContent,
  NavigationSectionEmptySearchMessage,
  NavigationSectionSearchItem,
  NavigationSectionSearchItemLink,
} from '../../primitives/NavigationSection'

export const FeedsNavigationList = () => {
  const feeds = useAppSelector(getFeeds)

  const { searchQuery } = useNavigationContext()

  if (!searchQuery) {
    return null
  }

  const searchFeeds = feeds.filter(({ name }) => name.toLowerCase().includes(searchQuery.toLowerCase()))

  return (
    <NavigationSectionContent>
      {!searchFeeds.length ? (
        <NavigationSectionEmptySearchMessage />
      ) : (
        searchFeeds.map((feed) => (
          <NavigationSectionSearchItem key={feed.id}>
            <NavigationSectionSearchItemLink to={`/feeds/${feed.id}`}>{feed.name}</NavigationSectionSearchItemLink>
          </NavigationSectionSearchItem>
        ))
      )}
    </NavigationSectionContent>
  )
}
