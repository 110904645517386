import { ReactElement } from 'react'

import isUserPermitted from './isUserPermitted'
import { ModulePermissionsProps } from './types'

const ModulePermissions = ({
  children,
  ...modulePermissionsProps
}: { children: ReactElement } & ModulePermissionsProps) => {
  const isPermitted = isUserPermitted(modulePermissionsProps)

  if (!isPermitted) {
    return null
  }

  return children
}

export default ModulePermissions
