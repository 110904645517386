import { cn } from '@opoint/infomedia-storybook'
import { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { getIsNavigationSectionActive, NavigationSectionKey } from '../../../../../helpers/navigation'
import { getSelectedProfilesIds } from '../../../../../selectors/searchSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { useProfilesFolderTree } from '../../../../hooks/useProfilesFolderTree'
import { SearchFilterKey } from '../../../../hooks/useSearchFilters'
import useSearchRouteBuilder from '../../../../hooks/useSearchRouteBuilder'
import { useNavigationContext } from '../../NavigationContext'
import { NavigationFolder, NavigationFolderContent, NavigationFolderTrigger } from '../../primitives/NavigationFolder'
import { NavigationItemMultiple } from '../../primitives/NavigationItemMultiple'
import { NavigationProfileSingle } from '../../primitives/NavigationProfileSingle'
import {
  NavigationSectionContent,
  NavigationSectionEmptySearchMessage,
  NavigationSectionSearchItem,
  NavigationSectionSearchItemLink,
} from '../../primitives/NavigationSection'

import { NAVIGATION_PROFILES_MULTIPLE_KEY } from '../../../../../constants/localStorage'
import { getDefaultFolderNameProfiles } from '../../../../../selectors/settingsSelectors.ts'
import { MostUsedProfilesToggle } from './MostUsedProfilesToggle'

export const ProfilesNavigationList = () => {
  const { t } = useTranslation()

  const selectedItemsIds = useAppSelector(getSelectedProfilesIds)
  const defaultFolderNameProfiles = useAppSelector(getDefaultFolderNameProfiles)

  const lastItem = useRef<HTMLLIElement>(null)
  const [showGradient, setShowGradient] = useState<boolean>(false)
  const [isMultiple] = useLocalStorage(NAVIGATION_PROFILES_MULTIPLE_KEY, false)
  const { getSearchRoute } = useSearchRouteBuilder()

  const { searchQuery } = useNavigationContext()
  const folderTree = useProfilesFolderTree()

  useEffect(() => {
    if (searchQuery) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) => {
        setShowGradient(!entries[0]?.isIntersecting)
      },
      { threshold: 0 },
    )

    if (lastItem.current) {
      observer.observe(lastItem.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [searchQuery, folderTree])

  if (!folderTree.length) {
    return null
  }

  if (searchQuery) {
    const firstFolder = folderTree[0]

    return (
      <NavigationSectionContent>
        {!firstFolder.children?.length ? (
          <NavigationSectionEmptySearchMessage />
        ) : (
          firstFolder.children.map((profile) =>
            isMultiple ? (
              <NavigationItemMultiple
                key={profile.id}
                item={profile}
                type={SearchFilterKey.PROFILES}
                selectedItemsIds={selectedItemsIds}
                searchMode
              />
            ) : (
              <NavigationSectionSearchItem key={profile.id}>
                <NavigationSectionSearchItemLink to={getSearchRoute(SearchFilterKey.PROFILES, profile.id)}>
                  {profile.name}
                </NavigationSectionSearchItemLink>
              </NavigationSectionSearchItem>
            ),
          )
        )}
      </NavigationSectionContent>
    )
  }

  return (
    <NavigationSectionContent
      className={cn('ml-0 max-h-[50vh] bg-white pt-2.5', {
        'bg-grey.8': getIsNavigationSectionActive(NavigationSectionKey.PROFILES),
      })}
    >
      <>
        {folderTree.length === 1
          ? folderTree[0].children?.map((profile) =>
              isMultiple ? (
                <NavigationItemMultiple
                  key={profile.id}
                  item={profile}
                  type={SearchFilterKey.PROFILES}
                  selectedItemsIds={selectedItemsIds}
                />
              ) : (
                <NavigationProfileSingle key={profile.id} profile={profile} selectedProfileIds={selectedItemsIds} />
              ),
            )
          : folderTree.map((folder) => (
              <NavigationFolder key={folder.id} defaultOpen={true}>
                <NavigationFolderTrigger>
                  {/* Only default folder names should be sent to Transifex for translation */}
                  {folder.name === defaultFolderNameProfiles ? t(folder.name) : folder.name}
                </NavigationFolderTrigger>
                <NavigationFolderContent isMultiple={isMultiple} folder={folder} selectedItemsIds={selectedItemsIds} />
              </NavigationFolder>
            ))}

        {/* Last item used to show and hide the gradient when in view */}
        <li className="mt-2.5" ref={lastItem} aria-hidden={true} />

        <span
          className={cn(
            'pointer-events-none absolute bottom-0 left-0 h-24 w-full bg-gradient-to-t from-grey.8 via-transparent to-transparent opacity-0 transition-opacity duration-300',
            {
              'opacity-100': showGradient,
            },
          )}
          aria-hidden={true}
        />
      </>

      {!searchQuery && <MostUsedProfilesToggle />}
    </NavigationSectionContent>
  )
}
