import {
  TextQuote16Filled,
  Link16Filled,
  Play16Filled,
  Camera16Filled,
  Calendar16Filled,
  ImageMultiple16Filled,
  Speaker016Filled,
  Note16Filled,
  FluentIcon,
  ArrowRepeatAll16Filled,
  Comment16Filled,
} from '@fluentui/react-icons'

export const KEYS = {
  backspace: 8,
  delete: 46,
  tab: 9,
  enter: 13,
  escape: 27,
  space: 32,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  comma: 188,
  ctrl: 17,
  alt: 18,
  leftCmd: 91,
  rightCmd: 93,
}

export const availablePostTypes: { [key: string]: FluentIcon } = {
  // TODO: Replace comment icon
  text: Comment16Filled,
  photo: Camera16Filled,
  quote: TextQuote16Filled,
  link: Link16Filled,
  retweet: ArrowRepeatAll16Filled,
  video: Play16Filled,
  reply: Comment16Filled,
  event: Calendar16Filled,
  album: ImageMultiple16Filled,
  music: Speaker016Filled,
  note: Note16Filled,
}

export const enum ExpansionMode {
  ALLOWED = 'allowed',
  DISALLOWED = 'disallowed',
  FORCED = 'forced',
}

export const enum LOCALES {
  SE = 'sv-SE',
  DK = 'da-DK',
  NO_NN = 'nn-NO',
  NO_NB = 'nb-NO',
  FI = 'fi-FI',
  EN = 'en-GB',
}

export enum ScheduleType {
  AsItHappens = 0,
  WeekDays8and2 = 1,
  WeekDays8 = 2,
  Custom = 3,
  Undefined = 4,
}
