import { ArticlePreviewTitle, HighlightedMatchesAndEntities, Textarea } from '@opoint/infomedia-storybook'
import { Trans, useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'

import { getHeaderUrls } from '../../../../../opoint/articles'
import { stripHTML } from '../../../../../opoint/common'
import { GeneralIntroProductTourTarget } from '../../../../productTour/ProductTours/GeneralIntroProductTour'

import ArticleLink from '../ArticleLink'

export type ArticleHeaderTextProps = {
  GTranslate_url?: any
  content_protected?: number
  editable?: boolean
  isMobile?: boolean
  onClickArticleLink?: (e?: any) => void
  onContentChange?: (values: { header: { text: string }; short_header: { text: string } }) => void
  orig_url?: string
  text?: string
  translated?: boolean
  url?: string
  translatedHeader?: { translated: string; original?: string }
}

const ArticleHeaderText = ({
  GTranslate_url,
  content_protected,
  editable,
  isMobile,
  onClickArticleLink,
  onContentChange,
  orig_url,
  text,
  translated,
  url,
  translatedHeader,
}: ArticleHeaderTextProps) => {
  const { t } = useTranslation()

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value
    onContentChange?.({ header: { text }, short_header: { text } })
  }

  // @ts-expect-error: Muted so we could enable TS strict mode
  const urls: { primary: string; secondary: string } = getHeaderUrls({
    GTranslate_url,
    content_protected,
    orig_url,
    translated,
    url,
  })

  if (editable) {
    return (
      <div className="mb-5">
        <label htmlFor="headline">
          <Trans>Headline</Trans>
        </label>
        <Textarea
          className="mt-1"
          id="headline"
          defaultValue={stripHTML(text)}
          onChange={onChange}
          aria-label={t('Headline')}
        />
      </div>
    )
  }

  if (translatedHeader) {
    return (
      <div id={GeneralIntroProductTourTarget.STEP_5} className="mb-3 w-full space-y-2">
        <ArticlePreviewTitle className="mt-0 pt-1.5">
          <ArticleLink url={urls.primary || urls.secondary} isMobile={isMobile} onClick={onClickArticleLink}>
            <HighlightedMatchesAndEntities text={translatedHeader.translated} />
          </ArticleLink>
        </ArticlePreviewTitle>

        {translatedHeader.original && (
          <p className="m-0 font-slab text-heading-3 text-sky.cloudy">
            <HighlightedMatchesAndEntities text={translatedHeader.original} />
          </p>
        )}
      </div>
    )
  }

  return (
    <div id={GeneralIntroProductTourTarget.STEP_5} className="mb-3 w-full">
      <ArticlePreviewTitle>
        <ArticleLink url={urls.primary || urls.secondary} isMobile={isMobile} onClick={onClickArticleLink}>
          <HighlightedMatchesAndEntities text={text} />
        </ArticleLink>
      </ArticlePreviewTitle>
    </div>
  )
}

export default ArticleHeaderText
