import { User } from '@opoint/authjs-react'

const ECB_CUSTOMER_ID = 50555

export const isUserAdmin = (user: User | undefined | null) => {
  return user?.privileges_index === 2
}

export const isECBUser = (user: User | undefined | null) => {
  return user?.customer_id === ECB_CUSTOMER_ID
}
