import { startsWith } from 'lodash-es'

const classNameKeys = [
  'appear',
  'appearActive',
  'appearDone',
  'enter',
  'enterActive',
  'enterDone',
  'exit',
  'exitActive',
  'exitDone',
]

const extractTransitionClasses = ({ styles, className, appearKey }) => {
  let capitalizedAppearKey
  if (appearKey) {
    capitalizedAppearKey = appearKey.charAt(0).toUpperCase() + appearKey.substring(1)
  }

  return classNameKeys.reduce((result, key) => {
    const capitalizedKey = key.charAt(0).toUpperCase() + key.substring(1)
    const classSpecificKey = `${className}${capitalizedKey}`
    const transitionClassName = styles[classSpecificKey]

    if (transitionClassName !== undefined) {
      // eslint-disable-next-line no-param-reassign
      result[key] = transitionClassName
    } else if (capitalizedAppearKey && startsWith(key, 'appear')) {
      const enterKey = classSpecificKey.replace('Appear', capitalizedAppearKey)

      // eslint-disable-next-line no-param-reassign
      result[key] = styles[enterKey]
    }

    return result
  }, {})
}

export default extractTransitionClasses
