import { useSelector } from 'react-redux'

import { getProfiles } from '../../selectors/profilesSelectors'
import { getDefaultHome } from '../../selectors/settingsSelectors'

import { SearchFilterKey } from './useSearchFilters'
import useSearchRouteBuilder from './useSearchRouteBuilder'

export const useDefaultHomeUrl = (): string => {
  const defaultHome = useSelector(getDefaultHome)
  const profiles = useSelector(getProfiles)
  const { getSearchRoute } = useSearchRouteBuilder()

  if (!defaultHome && profiles?.length > 0) {
    return getSearchRoute(SearchFilterKey.PROFILES, profiles[0].id)
  }

  if (defaultHome?.id) {
    const params = new URLSearchParams()
    params.append('filters', `${defaultHome.type}:${defaultHome.id}`)

    return `/search/?${params.toString()}`
  }

  return '/search'
}
