import classNames from 'classnames'
import { InputHTMLAttributes, forwardRef } from 'react'

import { FieldReduxInputType, FieldReduxMetaType } from '../field-types'

import styles from './index.module.scss'

export type RadioButtonProps = {
  label: string
  input?: FieldReduxInputType
  meta?: FieldReduxMetaType
  testid?: string
  className?: string
  error?: boolean
} & InputHTMLAttributes<HTMLInputElement>

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ input, testid, label, className, error, ...inputProps }, ref) => {
    // meta is not used, but we need to separate it, since we don't want it in the attributes of the element
    let reduxFormProps: any = {}
    if (input) {
      reduxFormProps = { ...input, defaultChecked: input.value, onChange: input.onChange }
    }

    return (
      <label className={classNames(styles.radio_button, className)} data-testid={testid}>
        {label}
        <input ref={ref} type="radio" {...inputProps} {...reduxFormProps} />
        <span className={classNames(styles.radio_checkmark, { [styles.error]: error })} />
      </label>
    )
  },
)

RadioButton.displayName = 'RadioButton'

export default RadioButton
