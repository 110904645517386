import { useState, useEffect, useMemo } from 'react'
import { Lightbox } from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/styles.css'

import { HighlightedMatchesAndEntities } from '@opoint/infomedia-storybook'
import { ArticleImageProps } from '../../../../types/article'

/**
 * Component for rendering a gallery of images
 *  @param {Array<ArticleImageProps>} images
 *  @param {number | null} initialImage
 *  @param {(index: number | null) => void} setInitialImage
 *  @return {JSXElement} ImageGallery
 */

export type Props = {
  images: Array<ArticleImageProps>
  initialImage: number | undefined
  setInitialImage: (index: number | null) => void
}

const ImageGallery = ({ images, initialImage, setInitialImage }: Props) => {
  const [open, setOpen] = useState(initialImage !== null)
  const [index, setIndex] = useState(initialImage ?? 0)

  useEffect(() => {
    setOpen(initialImage !== null)
    setIndex(initialImage ?? 0)
  }, [initialImage])

  useEffect(() => {
    if (open) {
      document.body.setAttribute('stop-propagation', 'true')
    }
    return () => {
      document.body.removeAttribute('stop-propagation')
    }
  }, [open])

  const slides = useMemo(
    () =>
      images.map((image) => ({
        src: image.url ?? '',
        alt: image.caption?.text ?? '',
      })),
    [images],
  )

  const showArrows = slides.length > 1

  return (
    <Lightbox
      open={open}
      close={() => {
        setOpen(false)
        setInitialImage(null)
      }}
      index={index}
      slides={slides}
      plugins={[Zoom]}
      render={{
        slideFooter: ({ slide }) => (
          <div className="absolute bottom-0 flex w-full items-center p-4 text-white">
            <HighlightedMatchesAndEntities text={slide.alt} />
          </div>
        ),
        buttonPrev: showArrows ? undefined : () => null,
        buttonNext: showArrows ? undefined : () => null,
      }}
      zoom={{
        maxZoomPixelRatio: 3,
        zoomInMultiplier: 2,
        doubleTapDelay: 300,
        doubleClickDelay: 500,
        doubleClickMaxStops: 2,
        keyboardMoveDistance: 50,
        wheelZoomDistanceFactor: 100,
        pinchZoomDistanceFactor: 100,
        scrollToZoom: false,
      }}
    />
  )
}

export default ImageGallery
