import { getAlertTags } from '../../../../../selectors/alertsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { SearchFilterKey } from '../../../../hooks/useSearchFilters'
import useSearchRouteBuilder from '../../../../hooks/useSearchRouteBuilder'
import { useNavigationContext } from '../../NavigationContext'
import {
  NavigationSectionContent,
  NavigationSectionEmptySearchMessage,
  NavigationSectionSearchItem,
  NavigationSectionSearchItemLink,
} from '../../primitives/NavigationSection'

export const EditAlertContentNavigationList = () => {
  const alertTags = useAppSelector(getAlertTags)

  const { getSearchRoute } = useSearchRouteBuilder()
  const { searchQuery } = useNavigationContext()

  if (!searchQuery) {
    return null
  }

  const searchAlertTags = alertTags.filter(({ subject }) => subject.toLowerCase().includes(searchQuery.toLowerCase()))

  return (
    <NavigationSectionContent>
      {!searchAlertTags.length ? (
        <NavigationSectionEmptySearchMessage />
      ) : (
        searchAlertTags.map((alertTag) => (
          <NavigationSectionSearchItem key={alertTag.id}>
            <NavigationSectionSearchItemLink to={getSearchRoute(SearchFilterKey.TAG, alertTag.id)}>
              {alertTag.subject}
            </NavigationSectionSearchItemLink>
          </NavigationSectionSearchItem>
        ))
      )}
    </NavigationSectionContent>
  )
}
