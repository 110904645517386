import React from 'react'
import { useRequiredParams } from '../../hooks/useRequiredParams.ts'

import { useAlertsRetrieve } from '../../../api/alerts/alerts.ts'
import { ErrorBreadcrumb } from '../../breadcrumbs/ErrorBreadcrumb.tsx'

export const AlertsDetailBreadcrumb = (): React.JSX.Element => {
  const params = useRequiredParams(['alertId'])
  const alertId = parseInt(params.alertId, 10)

  const { data, isError } = useAlertsRetrieve(alertId)

  if (isError) {
    return <ErrorBreadcrumb />
  }

  if (!data) {
    return <div className="h-4 w-24 animate-pulse rounded-sm bg-grey.6" />
  }

  return <>{data.subject}</>
}
