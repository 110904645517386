import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { combineLatest, concat, defer, EMPTY, from, of, timer } from 'rxjs'
import { catchError, filter, mergeMap, map as rxMap, switchMap, take, tap } from 'rxjs/operators'

import { isEmpty, map, mergeAll } from 'ramda'
import { t } from "i18next";
import { AppActions } from '../actions'
import {
  AlertDeleteConfirmAction,
  AlertDeleteFailureAction,
  AlertDeleteSuccessAction,
  AlertFetchAction,
  AlertFetchContentFailureAction,
  AlertFetchContentSuccessAction,
  AlertFetchFailureAction,
  AlertFetchHistoryAction,
  AlertFetchHistoryFailureAction,
  AlertFetchHistorySuccessAction,
  AlertFetchInfoAction,
  AlertFetchJSONextPreviewAction,
  AlertFetchJSONextPreviewSuccessAction,
  AlertFetchMoreHistoryAction,
  AlertFetchMoreHistorySuccessAction,
  AlertFetchSuccessAction,
  AlertFetchTagsAction,
  AlertFetchTagsFailureAction,
  AlertFetchTagsSuccessAction,
  AlertInitFormAction,
  AlertInitMifluzAction,
  AlertRemoveMarkedArticlesAction,
  AlertRemoveMarkedArticlesFailureAction,
  AlertRemoveMarkedArticlesSuccessAction,
  AlertSendFailureAction,
  AlertSendSuccessAction,
  AlertSetActiveArticleAction,
  AlertSetActiveArticleSuccessAction,
  AlertsFetchFailureAction,
  AlertsFetchRecipientsAction,
  AlertsFetchRecipientsFailureAction,
  AlertsFetchRecipientsSuccessAction,
  AlertsFetchSuccessAction,
  AlertUpdateCurrentAlertMailArticlesAction,
  CreateAlertAction,
  CreateAlertActionlineAction,
  EditAlertAction,
  SaveAlertAction,
  SaveAlertFailureAction,
  SaveAlertSuccessAction,
  SaveAlertValidFailureAction,
  SendAlertNowAction,
} from '../actions/alerts'
import { ImpersonateSuccessAction, LogInSuccessAction, LogoutAction } from '../actions/auth'
import {
  AlertInitRecipientsAction,
  ContactSaveSuccessAction,
  ContactsFetchSuccessAction,
  GroupsFetchSuccessAction,
} from '../actions/contacts'
import { scrollElementToTop } from '../helpers/common'
import { getFullName } from '../helpers/contacts'
import { getAlertId } from '../helpers/location'
import {
  deleteAlert,
  getAlert,
  getAlertHistory,
  getAlertInfo,
  getAlertJsonPreview,
  getAlerts,
  getAlertsRecipients,
  getAlertTags,
  removeArticlesFromAlert,
  saveAlert,
  sendNow,
} from '../opoint/alerts'
import { firstToUpperCase } from '../opoint/common'
import { AlertDetailed } from '../opoint/flow'
import { RootState } from '../reducers'
import {
  getAlertHistoryById,
  getArticlesMarkedForRemoval,
  getCurrentAlertMail,
  getEditedAlert,
  getNewAlert,
  getSaveFormData,
} from '../selectors/alertsSelectors'
import { getAlertEmail, getAlertSms } from '../selectors/contactSelectors'
import { getProfileById } from '../selectors/profilesSelectors'
import { getSelectedProfilesAndTagsIds } from '../selectors/searchSelectors'
import { getBaskets, getTagById } from '../selectors/tagsComposedSelectors'

import { extractErrorFromResponse } from '../helpers/extractErrorFromResponse'
import { AlertCreate } from '../api/opoint.schemas'
import { getCurrentLocation } from '../helpers/locationService'
import { router } from '../routes'
import { RouteChangeFailureAction } from '../actions/router'
import { M360Article } from '../opoint/articles/types'
import { queryClient } from '../queryClient'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

export const fetchAlerts = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, LogInSuccessAction | ImpersonateSuccessAction>('LOG_IN_SUCCESS', 'IMPERSONATE_SUCCESS'),
    switchMap(() =>
      from(getAlerts()).pipe(
        rxMap((alerts) => ({ type: 'ALERTS_FETCH_SUCCESS', payload: alerts } as AlertsFetchSuccessAction)),
        catchError((err) =>
          of<LogoutAction | AlertsFetchFailureAction>({
            type: err.status === 401 ? 'LOGOUT' : 'ALERTS_FETCH_FAILURE',
          }),
        ),
      ),
    ),
  )

export const SetActiveAlertArticle = (action$: ActionsObservable<AppActions>) => {
  let isLocked = false

  return action$.pipe(
    ofType<AppActions, AlertSetActiveArticleAction>('SET_ACTIVE_ALERT_ARTICLE'),
    filter((action) => !isLocked || !!action.payload.force),
    tap((action) => {
      if (action.payload.force) {
        isLocked = true
      }
    }),
    mergeMap(({ payload }) =>
      of({ payload }).pipe(
        tap(() => {
          if (payload.force) {
            timer(2000).subscribe(() => {
              isLocked = false
            })
          }
        }),
        switchMap(() => {
          return of<AlertSetActiveArticleSuccessAction>({
            type: 'SET_ACTIVE_ALERT_ARTICLE_SUCCESS',
            payload: payload,
          })
        }),
      ),
    ),
  )
}

export const fetchEpic = (action$: ActionsObservable<AppActions>) =>
  combineLatest([
    action$.pipe(ofType<AppActions, AlertFetchAction>('ALERT_FETCH')),
    action$.pipe(ofType<AppActions, ContactsFetchSuccessAction>('CONTACTS_FETCH_SUCCESS'), take(1)),
    action$.pipe(ofType<AppActions, GroupsFetchSuccessAction>('GROUPS_FETCH_SUCCESS'), take(1)),
  ]).pipe(
    switchMap(
      ([
        {
          payload: { alertId, toBeEdited },
        },
      ]) => {
        const alertFetchSuccess = (data: AlertDetailed, toBeEdited: boolean): AlertFetchSuccessAction => ({
          type: 'ALERT_FETCH_SUCCESS',
          payload: {
            alert: data,
            toBeEdited,
          },
        })
        const fetchHistory = (alertId: number): AlertFetchHistoryAction => ({
          type: 'ALERT_FETCH_HISTORY',
          payload: {
            alertId,
          },
        })
        const initMifluz = (data: AlertDetailed): AlertInitMifluzAction => ({
          type: 'ALERT_INIT_MIFLUZ',
          payload: data.profiles?.map((profile) => profile.id),
        })
        const initRecipients = (data: AlertDetailed): AlertInitRecipientsAction => ({
          type: 'ALERT_INIT_RECIPIENTS',
          payload: {
            recipients: data.recipients,
            smsRecipients: data.smsRecipients,
          },
        })

        return from(getAlert(alertId)).pipe(
          switchMap((data) =>
            of(alertFetchSuccess(data, toBeEdited), fetchHistory(alertId), initMifluz(data), initRecipients(data)),
          ),
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError(() => of<AlertFetchFailureAction>({ type: 'ALERT_FETCH_FAILURE' })),
        )
      },
    ),
  )

export const fetchHistoryEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, AlertFetchHistoryAction>('ALERT_FETCH_HISTORY'),
    switchMap(({ payload: { alertId } }) =>
      from(getAlertHistory(alertId)).pipe(
        rxMap(
          (data) =>
            ({
              type: 'ALERT_FETCH_HISTORY_SUCCESS',
              payload: {
                id: alertId,
                data,
              },
            } as AlertFetchHistorySuccessAction),
        ),
        catchError(() => of<AlertFetchHistoryFailureAction>({ type: 'ALERT_FETCH_HISTORY_FAILURE' })),
      ),
    ),
  )

export const fetchMoreHistoryEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, AlertFetchMoreHistoryAction>('ALERT_FETCH_MORE_HISTORY'),
    switchMap(({ payload: { alertId } }) => {
      const state = state$.value
      const alertHistory = getAlertHistoryById(alertId)(state)
      let page = ''
      if (alertHistory && alertHistory.next !== null) {
        const tmp = alertHistory.next.split('?page=')
        if (tmp.length === 2) {
          page = tmp[1]
        }
      }

      return from(getAlertHistory(alertId, page)).pipe(
        rxMap(
          (data) =>
            ({
              type: 'ALERT_FETCH_MORE_HISTORY_SUCCESS',
              payload: {
                id: alertId,
                data,
              },
            } as AlertFetchMoreHistorySuccessAction),
        ),
        catchError(() => of<AlertFetchHistoryFailureAction>({ type: 'ALERT_FETCH_HISTORY_FAILURE' })),
      )
    }),
  )

export const fetchAlertInfoEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, AlertFetchInfoAction>('ALERT_FETCH_INFO'),
    switchMap(({ payload: { alertId, historyId, maillog_id, historyTimestamp } }) => {
      return from(getAlertInfo(maillog_id)).pipe(
        rxMap(
          (data) =>
            ({
              type: 'ALERT_FETCH_CONTENT_SUCCESS',
              payload: {
                alertId,
                historyId,
                id: maillog_id,
                historyTimestamp,
                data,
              },
            } as AlertFetchContentSuccessAction),
        ),
        catchError(logOutOnExpiredToken),
        catchError((e) => {
          const errorMsg = e.status === 500 ? JSON.parse(e.xhr.response) : null
          return of<AlertFetchContentFailureAction>({
            type: 'ALERT_FETCH_CONTENT_FAILURE',
            payload: { error: errorMsg?.error },
          })
        }),
        catchError(serverIsDown),
      )
    }),
  )

export const fetchAlertJsonNextPreviewEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, AlertFetchJSONextPreviewAction>('ALERT_FETCH_JSON_NEXT_PREVIEW'),
    switchMap(({ payload: { alertId } }) => {
      const state = state$.value

      return from(getAlertJsonPreview(alertId)).pipe(
        rxMap((data) => {
          const dataWithProfileNames = data.map((item) => ({
            ...item,
            name: (getProfileById(item.id_list)(state) || getTagById(item.id_list)(state))?.name,
          }))

          return {
            type: 'ALERT_FETCH_JSON_NEXT_PREVIEW_SUCCESS',
            payload: {
              alertId,
              data: dataWithProfileNames,
            },
          } as AlertFetchJSONextPreviewSuccessAction
        }),
        catchError(logOutOnExpiredToken),
        catchError((e) => {
          const errorMsg = e.status === 500 ? JSON.parse(e.xhr.response) : null
          return of<AlertFetchContentFailureAction>({
            type: 'ALERT_FETCH_CONTENT_FAILURE',
            payload: { error: errorMsg?.error },
          })
        }),
        catchError(serverIsDown),
      )
    }),
  )

export const fetchAlertTagsEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, AlertFetchTagsAction>('ALERT_FETCH_TAGS'),
    switchMap(({ payload: { articleIds } }) => {
      const state = state$.value
      const baskets = getBaskets(state).split(',')

      return from(getAlertTags(articleIds, baskets)).pipe(
        rxMap((data) => ({ type: 'ALERT_FETCH_TAGS_SUCCESS', payload: { data } } as AlertFetchTagsSuccessAction)),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<AlertFetchTagsFailureAction>({ type: 'ALERT_FETCH_TAGS_FAILURE' })),
      )
    }),
  )

export const formSaveAlertEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, SaveAlertAction>('SAVE_ALERT'),
    switchMap(() => {
      const state = state$.value
      // Profiles data transformation to correct format
      const { mifluzIdLists } = getEditedAlert(state)
      const savedFormData = getSaveFormData(state)
      if (!savedFormData.template) {
        return of<SaveAlertValidFailureAction>({
          type: 'SAVE_ALERT_VALID_FAILURE',
          payload: {
            error: t('You have not selected an output type'),
          },
        })
      }
      const formData = getNewAlert(state)

      // Verification - checks whether subject was entered
      // (kind of, #TODO @oliver @honza do it better )
      if (!formData.subject || !formData.subject.length) {
        return of<SaveAlertValidFailureAction>({
          type: 'SAVE_ALERT_VALID_FAILURE',
          payload: {
            error: t('You have to enter subject of the alert'),
          },
        })
      }

      if (isEmpty(formData.schedule.timeConfiguration)) {
        return of<SaveAlertValidFailureAction>({
          type: 'SAVE_ALERT_VALID_FAILURE',
          payload: {
            error: t('Time period is required to save this alert'),
          },
        })
      }
      if (
        Object.values(formData.schedule.timeConfiguration).some(
          // @ts-expect-error: Muted so we could enable TS strict mode
          (intervalOptions: { start: number }[]) => !intervalOptions.length,
        )
      ) {
        return of<SaveAlertValidFailureAction>({
          type: 'SAVE_ALERT_VALID_FAILURE',
          payload: {
            error: t('Time of day is required in order to save this alert'),
          },
        })
      }
      const baskets = {
        baskets: mifluzIdLists
          .map((id) => {
            const item = getProfileById(id)(state) || getTagById(id)(state)
            if (!item) {
              return null
            }

            return { id: formData.id, name: item.name }
          })
          .filter((n) => n),
      }
      const profiles = {
        profiles: mifluzIdLists
          .map((id) => {
            const item = getProfileById(id)(state) || getTagById(id)(state)
            const type = getProfileById(id)(state) ? 'profile' : 'tag'
            if (!item) {
              return null
            }

            return { id: item.id, name: item.name, type }
          })
          .filter((n) => n),
      }
      // Emails / sms transformation to correct format
      const emails = getAlertEmail(state).filter(({ entity }) => !!entity)
      const sms = getAlertSms(state).filter(({ entity }) => !!entity)

      const recipients = {
        recipients: map((recipient) => {
          const label = firstToUpperCase(recipient.type)

          return recipient.type === 'person'
            ? {
                type: recipient.type,
                label,
                name: getFullName(recipient),
                ...recipient.entity,
              }
            : {
                type: recipient.type,
                label,
                ...recipient.entity,
              }
        }, emails),
      }
      const smsRecipients = {
        smsRecipients: map((recipient) => {
          const label = firstToUpperCase(recipient.type)

          return recipient.type === 'person'
            ? {
                type: recipient.type,
                label,
                name: getFullName(recipient),
                ...recipient.entity,
              }
            : {
                type: recipient.type,
                label,
                ...recipient.entity,
              }
        }, sms),
      }

      // Checks whether user chose atleast one recipient
      if (!recipients.recipients.length && !smsRecipients.smsRecipients.length) {
        return of<SaveAlertValidFailureAction>({
          type: 'SAVE_ALERT_VALID_FAILURE',
          payload: {
            error: t('You have to enter at least one valid recipient'),
          },
        })
      }

      // Checks whether user chose atleast one profile / tag
      if (!mifluzIdLists.length) {
        return of<SaveAlertValidFailureAction>({
          type: 'SAVE_ALERT_VALID_FAILURE',
          payload: {
            error: t('You have to enter at least one profile or tag'),
          },
        })
      }

      // Convert bools to ints
      formData.active = formData.active ? 1 : 0
      formData.editable = formData.editable ? 1 : 0
      formData.translate = formData.translate ? 1 : 0

      const editedAlert: AlertCreate = mergeAll([formData, baskets, profiles, recipients, smsRecipients])

      const saveAlert$ = from(saveAlert(editedAlert)).pipe(
        rxMap((alert) => ({ type: 'SAVE_ALERT_SUCCESS', payload: { alert } } as SaveAlertSuccessAction)),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError((e) =>
          of<SaveAlertValidFailureAction>({
            type: 'SAVE_ALERT_VALID_FAILURE',
            payload: { error: e.xhr.response },
          }),
        ),
        catchError(() => of<SaveAlertFailureAction>({ type: 'SAVE_ALERT_FAILURE' })),
      )

      const fetchAlerts$ = defer(getAlerts).pipe(
        rxMap((alerts) => ({ type: 'ALERTS_FETCH_SUCCESS', payload: alerts } as AlertsFetchSuccessAction)),
        catchError(() => of<AlertsFetchFailureAction>({ type: 'ALERTS_FETCH_FAILURE' })),
      )

      return saveAlert$.pipe(
        // @ts-expect-error: Muted so we could enable TS strict mode
        switchMap((saveAlertAction: SaveAlertSuccessAction | SaveAlertValidFailureAction) => {
          if (saveAlertAction.type === 'SAVE_ALERT_SUCCESS') {
            const {
              payload: {
                alert: { id },
              },
            } = saveAlertAction

            return concat(
              of(saveAlertAction),
              fetchAlerts$,
              of(`/alerts/${id}`).pipe(
                tap((path) => router.navigate(path)),
                rxMap(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
              ),
            )
          }

          return of<SaveAlertValidFailureAction>({
            type: 'SAVE_ALERT_VALID_FAILURE',
            payload: {
              error: extractErrorFromResponse(saveAlertAction.payload, t('We were unable to save this alert')),
            },
          })
        }),
      )
    }),
  )

export const fetchAlertsRecipients = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, AlertsFetchRecipientsAction | LogInSuccessAction | ImpersonateSuccessAction>(
      'ALERTS_FETCH_RECIPIENTS',
      'LOG_IN_SUCCESS',
      'IMPERSONATE_SUCCESS',
    ),
    switchMap(() =>
      defer(getAlertsRecipients).pipe(
        rxMap(
          (alertsRecipients) =>
            ({
              type: 'ALERTS_FETCH_RECIPIENTS_SUCCESS',
              payload: { alertsRecipients },
            } as AlertsFetchRecipientsSuccessAction),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        //TODO: This action is not set up, nothing happens
        //https://infomediacorp.atlassian.net/browse/FE-10315
        catchError(() => of<AlertsFetchRecipientsFailureAction>({ type: 'ALERTS_FETCH_RECIPIENTS_FAILURE' })),
      ),
    ),
  )

export const needFetchRecipientsAgain = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ContactSaveSuccessAction>('CONTACT_SAVE_SUCCESS'),
    switchMap(() => of<AlertsFetchRecipientsAction>({ type: 'ALERTS_FETCH_RECIPIENTS' })),
  )

export const scrollTopOnAlertCreated = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, SaveAlertSuccessAction>('SAVE_ALERT_SUCCESS'),
    switchMap(() => {
      scrollElementToTop('#content')

      return EMPTY
    }),
  )

export const deleteAlertEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, AlertDeleteConfirmAction>('DELETE_ALERT_CONFIRM'),
    switchMap(({ payload }) => {
      const deleteAlert$ = from(deleteAlert(payload.alertId.toString())).pipe(
        rxMap(() => ({ type: 'ALERT_DELETE_SUCCESS' } as AlertDeleteSuccessAction)),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<AlertDeleteFailureAction>({ type: 'ALERT_DELETE_FAILURE' })),
      )

      return deleteAlert$.pipe(
        // @ts-expect-error: Muted so we could enable TS strict mode
        switchMap((deleteAlertAction: AlertDeleteSuccessAction | AlertDeleteFailureAction) =>
          concat(
            of(deleteAlertAction),
            of('/alerts').pipe(
              tap((path) => router.navigate(path)),
              rxMap(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
            ),
          ),
        ),
        // we need this because we have alerts fetched by react query
        tap(() => {
          queryClient.refetchQueries(['/alerts/'])
        }),
      )
    }),
  )

export const goToEditAlertEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, EditAlertAction>('EDIT_ALERT'),
    switchMap(() => {
      const matchAlertPath = /(?<alertPath>\/alerts\/[0-9]+)/
      const location = getCurrentLocation()
      const path = location?.pathname?.match(matchAlertPath)

      if (path) {
        return of(path?.groups?.alertPath).pipe(
          tap((alertPath) => router.navigate(`${alertPath}/edit`)),
          rxMap(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
          catchError(logOutOnExpiredToken),
          catchError(serverIsDown),
          catchError(() => of<RouteChangeFailureAction>({ type: 'ROUTE_CHANGE_FAILURE' })),
        )
      }
      return of<RouteChangeFailureAction>({ type: 'ROUTE_CHANGE_FAILURE' })
    }),
  )

export const goToCreateAlertEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, CreateAlertAction>('CREATE_ALERT'),
    switchMap(() => {
      return of('/alerts/new').pipe(
        tap((path) => router.navigate(path)),
        rxMap(() => ({ type: 'ROUTER_LOCATION_CHANGE' })),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<RouteChangeFailureAction>({ type: 'ROUTE_CHANGE_FAILURE' })),
      )
    }),
  )

export const goToCreateAlertFromActionLine = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, CreateAlertActionlineAction>('CREATE_ALERT_ACTIONLINE'),
    switchMap(() => {
      const state = state$.value
      const searchLine = getSelectedProfilesAndTagsIds(state)

      return of<AlertInitFormAction, CreateAlertAction>(
        { type: 'ALERT_INIT_FORM', payload: searchLine },
        { type: 'CREATE_ALERT' },
      )
    }),
  )

export const sendAlertNowEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, SendAlertNowAction>('SEND_ALERT_NOW'),
    switchMap(() => {
      const alertId = getAlertId()
      // @ts-expect-error: Muted so we could enable TS strict mode
      const sendAlert$ = from(sendNow(alertId)).pipe(
        rxMap(() => ({ type: 'ALERT_SEND_SUCCESS' } as AlertSendSuccessAction)),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        catchError(() => of<AlertSendFailureAction>({ type: 'ALERT_SEND_FAILURE' })),
      )

      return sendAlert$.pipe(
        // @ts-expect-error: Muted so we could enable TS strict mode
        switchMap((sendAlertAction: AlertSendSuccessAction | AlertSendFailureAction) => of(sendAlertAction)),
      )
    }),
  )

export const removeArticesFromAlertEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, AlertRemoveMarkedArticlesAction>('ALERT_REMOVE_MARKED_ARTICLES'),
    switchMap(() => {
      const articlesMarkedForRemoval = getArticlesMarkedForRemoval(state$.value)
      const alertId = getAlertId()
      const currentAlertMail = getCurrentAlertMail(state$.value)

      // @ts-expect-error: Muted so we could enable TS strict mode
      const removeArticles$ = from(removeArticlesFromAlert(alertId, articlesMarkedForRemoval)).pipe(
        rxMap(() => ({ type: 'ALERT_REMOVE_MARKED_ARTICLES_SUCCESS' } as AlertRemoveMarkedArticlesSuccessAction)),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        // @ts-expect-error: Muted so we could enable TS strict mode
        catchError(() => of<AlertRemoveMarkedArticlesFailureAction>({ type: 'ALERT_REMOVE_MARKED_ARTICLES_FAILURE' })),
        switchMap(
          (deleteAlertAction: AlertRemoveMarkedArticlesSuccessAction | AlertRemoveMarkedArticlesFailureAction) => {
            const newCurrentAlertMail = currentAlertMail!.flatMap((group) =>
              group.content
                ? {
                    ...group,
                    content: {
                      ...group.content,
                      searchresult: {
                        ...group.content.searchresult,
                        document: group.content.searchresult.document.filter(
                          (doc) =>
                            !Object.values(articlesMarkedForRemoval).some(
                              (article: M360Article) =>
                                doc.id_site === article.id_site && doc.id_article === article.id_article,
                            ),
                        ),
                      },
                    },
                  }
                : [],
            )

            return concat(
              of(deleteAlertAction),
              of<AlertUpdateCurrentAlertMailArticlesAction>({
                type: 'ALERT_UPDATE_CURRENT_ALERT_MAIL_ARTICLES',
                payload: { data: newCurrentAlertMail },
              }),
            )
          },
        ),
      )

      return removeArticles$
    }),
  )

export default [
  deleteAlertEpic,
  fetchAlertInfoEpic,
  fetchAlertTagsEpic,
  fetchAlerts,
  fetchAlertsRecipients,
  fetchEpic,
  fetchHistoryEpic,
  fetchMoreHistoryEpic,
  formSaveAlertEpic,
  goToCreateAlertEpic,
  goToCreateAlertFromActionLine,
  goToEditAlertEpic,
  needFetchRecipientsAgain,
  scrollTopOnAlertCreated,
  removeArticesFromAlertEpic,
  sendAlertNowEpic,
  fetchAlertJsonNextPreviewEpic,
  SetActiveAlertArticle,
]
