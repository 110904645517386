import {
  AppsList20Regular,
  BookTemplate20Regular,
  CompassNorthwest20Regular,
  ContactCard20Regular,
  DocumentBulletList20Regular,
  Person24Regular,
  QuestionCircle20Regular,
  RibbonStar20Regular,
  Settings20Regular,
} from '@fluentui/react-icons'
import { useOpointUser } from '@opoint/authjs-react'
import { Label, Popover, PopoverContent, PopoverTrigger, RadioGroup, RadioGroupItem } from '@opoint/infomedia-storybook'
import { Trans, useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

import { useNavigate } from 'react-router-dom'
import { getImpersonationOrganizations } from '../../../selectors/impersonationSelectors'
import { getIsChangeLogOpen } from '../../../selectors/uiSelectors'
import { Changelog } from '../../changelog/Changelog'
import ModulePermissions from '../../common/ModulePermissions'
import { MODULES } from '../../constants/permissions'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../hooks/useDefaultHomeUrl'
import useIsInsiders, { useLeaveInsiders } from '../../hooks/useIsInsiders'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { GeneralIntroProductTourTarget } from '../../productTour/ProductTours/GeneralIntroProductTour'
import type { ImpersonationTarget } from '../../types/user'
import TopNavActionButton from '../Header/Actions/Button'

import { GI_PRODUCT_TOUR_KEY } from '../../../constants/localStorage'
import { UserPopoverItem } from './UserPopoverItem'

type Organization = {
  customer_id: number
  user_id: number
  email?: string
  username: string
}

const LeaveInsidersButton = () => {
  const isInsiders = useIsInsiders()
  const handleLeaveInsiders = useLeaveInsiders()
  const { t } = useTranslation()

  if (!isInsiders) {
    return null
  }

  return (
    <button type="button" onClick={handleLeaveInsiders} className="rounded text-sky.4 focus-visible:ring-offset-2">
      {t('Leave insiders')}
    </button>
  )
}

const UserPopover = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const user = useOpointUser()
  // @ts-expect-error: Muted so we could enable TS strict mode
  const impersonator = user.impersonator
  const impersonationOrganizations: ImpersonationTarget[] = useAppSelector(getImpersonationOrganizations)
  const isChangeLogOpen = useAppSelector(getIsChangeLogOpen)
  const canSelectOrganization = !!impersonationOrganizations?.length

  const [selectedOrg, setSelectedOrg] = useState<Organization>()

  const { customer_id, user_id, username } = impersonator || user || {}

  const newUsername = username?.includes('external:') ? t('SSO user') : username
  const defaultOrg = useMemo(() => {
    if (!impersonationOrganizations) {
      return undefined
    }

    return {
      customer_id,
      user_id,
      email: user?.email || '',
      username: newUsername,
    }
  }, [customer_id, impersonationOrganizations, newUsername, user?.email, user_id])

  const mappedImpersonationOrgs = useMemo(() => {
    const ids = {}
    // @ts-expect-error: Muted so we could enable TS strict mode
    ids[defaultOrg.customer_id] = 1

    // If there are multiple users from the same organization, increment the id.
    impersonationOrganizations?.forEach((org) => {
      const orgId = ids[org.customer.id_customer]
      ids[org.customer.id_customer] = orgId ? orgId + 1 : 1
    })

    const mappedOrgs = impersonationOrganizations?.map((org) => {
      let customerName = org.customer.name
      const orgUserName = org.username

      //If there are multiple users from the same organization, also display the username
      if (ids[org.customer.id_customer] > 1) {
        customerName += ` (${orgUserName.startsWith('IFM_') ? orgUserName.replace('IFM_', '') : orgUserName})`
      }

      return {
        customer_id: org.customer.id_customer,
        user_id: org.id_user,
        username: customerName,
      }
    })

    mappedOrgs.unshift({
      ...defaultOrg,
      // @ts-expect-error: Muted so we could enable TS strict mode
      username: ids[defaultOrg.customer_id] > 1 ? `${defaultOrg.username} (${defaultOrg.email})` : defaultOrg.username,
    })

    return mappedOrgs
  }, [defaultOrg, impersonationOrganizations])

  const handleOrganizationValueChange = (value: string) => {
    const org = mappedImpersonationOrgs.find(({ user_id }) => user_id.toString() === value)

    if (!org || !selectedOrg || org?.user_id === selectedOrg?.user_id) {
      return
    }
    // Wait until the dropdown menu is closed so the animation doesn't lag
    setTimeout(() => {
      org.user_id === user?.impersonator?.user_id
        ? dispatch({ type: 'END_IMPERSONATION', payload: { isEndingOrgImpersonation: true } })
        : dispatch({ type: 'IMPERSONATE', payload: { userId: org.user_id } })
      setSelectedOrg(org)
    }, 200)
  }

  useEffect(() => {
    if (!mappedImpersonationOrgs.length) {
      return
    }

    // @ts-expect-error: Muted so we could enable TS strict mode
    const isDefaultOrg = defaultOrg.user_id === user?.user_id

    // @ts-expect-error: Muted so we could enable TS strict mode
    const activeOrg = mappedImpersonationOrgs.find((org) => org.user_id === user.user_id)

    const newSelectedOrg = isDefaultOrg ? defaultOrg : activeOrg

    // @ts-expect-error: Muted so we could enable TS strict mode
    setSelectedOrg(newSelectedOrg)
  }, [defaultOrg, mappedImpersonationOrgs, user?.user_id])

  const defaultHomeUrl = useDefaultHomeUrl()
  const setRunTour = useLocalStorage(GI_PRODUCT_TOUR_KEY, undefined)[1]

  const handleClickRunTour = () => {
    // If the user is not on the default home page, redirect them to it
    if (`${location.pathname}${location.search}` !== defaultHomeUrl) {
      navigate(defaultHomeUrl)
    }

    // @ts-expect-error: Muted so we could enable TS strict mode
    setRunTour(true)
  }

  return (
    <li id={GeneralIntroProductTourTarget.STEP_8} data-cy="profileButton">
      <Popover>
        <PopoverTrigger asChild>
          <TopNavActionButton className="w-auto max-w-60">
            <Person24Regular className="shrink-0" />
            {canSelectOrganization && selectedOrg && (
              <span className="truncate" title={selectedOrg.username}>
                {selectedOrg.username}
              </span>
            )}
          </TopNavActionButton>
        </PopoverTrigger>

        <PopoverContent align="end" className="min-w-[13.75rem] max-w-64 divide-y divide-grey.6 p-0">
          {canSelectOrganization && (
            <RadioGroup
              orientation="vertical"
              value={selectedOrg?.user_id?.toString()}
              onValueChange={handleOrganizationValueChange}
              asChild
            >
              <ul className="flex flex-col gap-y-4 p-5">
                {mappedImpersonationOrgs?.map((org) => {
                  const id = `UserPopover-org-${org.user_id}`

                  return (
                    <div key={org.user_id} className="flex items-center space-x-1.5 overflow-x-hidden">
                      <RadioGroupItem id={id} value={org.user_id.toString()} />
                      <Label htmlFor={id} title={org.username} className="inline-block truncate">
                        {org.username}
                      </Label>
                    </div>
                  )
                })}
              </ul>
            </RadioGroup>
          )}

          <ul className="py-3">
            <ModulePermissions
              module="CONTACT_MODULE"
              permissions={[MODULES.CONTACT_MODULE.ON, MODULES.CONTACT_MODULE.READ_ONLY]}
            >
              <li>
                <UserPopoverItem onClick={() => dispatch({ type: 'CONTACTS_MODAL_OPEN' })} data-cy="contacts">
                  <ContactCard20Regular />
                  <Trans>Contacts</Trans>
                </UserPopoverItem>
              </li>
            </ModulePermissions>

            <ModulePermissions
              module="TEMPLATE_EDITOR_MODULE"
              permissions={[MODULES.TEMPLATE_EDITOR_MODULE.ON, MODULES.TEMPLATE_EDITOR_MODULE.READ_ONLY]}
            >
              <li>
                <UserPopoverItem
                  onClick={() => dispatch({ type: 'TEMPLATE_EDITOR_MODAL_OPEN' })}
                  data-cy="template-editor"
                >
                  <BookTemplate20Regular />
                  <Trans>Template editor</Trans>
                </UserPopoverItem>
              </li>
            </ModulePermissions>

            <ModulePermissions
              module="SETTING_MODULE"
              permissions={[MODULES.SETTING_MODULE.ON, MODULES.SETTING_MODULE.READ_ONLY]}
            >
              <li>
                <UserPopoverItem onClick={() => dispatch({ type: 'SETTINGS_MODAL_OPEN' })} data-cy="settings">
                  <Settings20Regular />
                  <Trans>Settings</Trans>
                </UserPopoverItem>
              </li>
            </ModulePermissions>

            <ModulePermissions module="REPORT_MODULE" permissions={[MODULES.REPORT_MODULE.ON]}>
              <li>
                <UserPopoverItem
                  onClick={() => dispatch({ type: 'REPORT_HISTORY_MODAL_OPEN' })}
                  data-cy="report-history"
                >
                  <DocumentBulletList20Regular />
                  <Trans>Report history</Trans>
                </UserPopoverItem>
              </li>
            </ModulePermissions>

            <ModulePermissions module="SOURCE_LIST_MODULE" permissions={[MODULES.SOURCE_LIST_MODULE.ON]}>
              <li>
                <UserPopoverItem onClick={() => dispatch({ type: 'SOURCE_LISTS_MODAL_OPEN' })} data-cy="source-lists">
                  <AppsList20Regular />
                  <Trans>Source lists</Trans>
                </UserPopoverItem>
              </li>
            </ModulePermissions>

            <ModulePermissions module="PRODUCT_TOUR" permissions={[MODULES.PRODUCT_TOUR.ON]}>
              <li>
                <UserPopoverItem onClick={handleClickRunTour} data-cy="Guided Tour">
                  <CompassNorthwest20Regular />
                  <Trans>Guided Tour</Trans>
                </UserPopoverItem>
              </li>
            </ModulePermissions>

            <li>
              <UserPopoverItem onClick={() => dispatch({ type: 'HELP_MODAL_OPEN' })} data-cy="help">
                <QuestionCircle20Regular />
                <Trans>Help</Trans>
              </UserPopoverItem>
            </li>

            <li>
              <UserPopoverItem
                onClick={() => dispatch({ type: 'SET_IS_CHANGELOG_OPEN', payload: true })}
                data-cy="changelog"
              >
                <RibbonStar20Regular />
                <Trans>Changelog</Trans>
              </UserPopoverItem>
            </li>
          </ul>

          <div className="flex flex-col items-start gap-y-2.5 py-6 pl-6 pr-3">
            {impersonator && selectedOrg && <div>{selectedOrg.username}</div>}
            <div className="text-label-l font-medium">{impersonator ? impersonator.username : username}</div>
            <LeaveInsidersButton />
            <button
              type="button"
              onClick={() => dispatch({ type: 'LOGOUT' })}
              className="rounded text-label-l text-sky.4 focus-visible:ring-offset-2"
            >
              <Trans>Log out</Trans>
            </button>
          </div>
        </PopoverContent>
      </Popover>

      <Changelog isOpen={isChangeLogOpen} onHide={() => dispatch({ type: 'SET_IS_CHANGELOG_OPEN', payload: false })} />
    </li>
  )
}

export default UserPopover
