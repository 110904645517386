import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'
import { t } from "i18next";
import {
  ContactDeleteErrorAction,
  ContactDeleteSuccessAction,
  ContactDomainNotAllowedErrorAction,
  ContactInactiveResetErrorAction,
  ContactInactiveResetSuccessAction,
  ContactSaveErrorAction,
  ContactSaveSuccessAction,
  ContactSetActiveFailureAction,
  ContactUsedDeleteErrorAction,
  ContactsActions,
  GroupDeleteErrorAction,
  GroupDeleteSuccessAction,
  GroupSaveErrorAction,
  GroupSaveSuccessAction,
  GroupSetActiveFailureAction,
} from '../../../actions/contacts'
import { ErrorEpic, handlerError } from '.'

const contactsErrorTypes: ErrorEpic<
  | ContactSaveSuccessAction['type']
  | ContactDeleteSuccessAction['type']
  | GroupDeleteSuccessAction['type']
  | GroupSaveSuccessAction['type']
  | ContactSaveErrorAction['type']
  | ContactDomainNotAllowedErrorAction['type']
  | GroupSaveErrorAction['type']
  | ContactInactiveResetSuccessAction['type']
  | ContactInactiveResetErrorAction['type']
  | ContactDeleteErrorAction['type']
  | ContactUsedDeleteErrorAction['type']
  | GroupDeleteErrorAction['type']
  | ContactSetActiveFailureAction['type']
  | GroupSetActiveFailureAction['type']
> = {
  ['CONTACT_SAVE_SUCCESS']: {
    isSuccess: true,
    message: t('Contact was successfully saved.'),
    toastId: 'CONTACT_SAVE_SUCCESS',
  },
  ['CONTACT_DELETE_SUCCESS']: {
    isSuccess: true,
    message: t('Contact was successfully deleted'),
    toastId: 'CONTACT_DELETE_SUCCESS',
  },
  ['GROUP_DELETE_SUCCESS']: {
    isSuccess: true,
    message: t('Group was successfully deleted'),
    toastId: 'GROUP_DELETE_SUCCESS',
  },
  ['GROUP_SAVE_SUCCESS']: {
    isSuccess: true,
    message: t('Group was successfully saved.'),
    toastId: 'GROUP_SAVE_SUCCESS',
  },
  ['CONTACT_SAVE_ERROR']: {
    isSuccess: false,
    message: t('We were unable to save this contact'),
    toastId: 'CONTACT_SAVE_ERROR',
  },
  ['CONTACT_DOMAIN_NOT_ALLOWED_ERROR']: {
    isSuccess: false,
    message: t('Given email domain is not allowed'),
    toastId: 'CONTACT_DOMAIN_NOT_ALLOWED_ERROR',
  },
  ['GROUP_SAVE_ERROR']: {
    isSuccess: false,
    message: t('We were unable to save this group'),
    toastId: 'GROUP_SAVE_ERROR',
  },
  ['CONTACT_INACTIVE_RESET_SUCCESS']: {
    isSuccess: true,
    message: t('Contact alerts pause is now not active.'),
    toastId: 'CONTACT_INACTIVE_RESET_SUCCESS',
  },
  ['CONTACT_INACTIVE_RESET_ERROR']: {
    isSuccess: false,
    message: t('We were unable to reset pause of alerts for this contact'),
    toastId: 'CONTACT_INACTIVE_RESET_ERROR',
  },
  ['CONTACT_DELETE_ERROR']: {
    isSuccess: false,
    message: t('We were unable to delete this contact'),
    toastId: 'CONTACT_DELETE_ERROR',
  },
  ['CONTACT_USED_DELETE_ERROR']: {
    isSuccess: false,
    message: t('We were unable to delete this contact'),
    toastId: 'CONTACT_USED_DELETE_ERROR',
  },
  ['GROUP_DELETE_ERROR']: {
    isSuccess: false,
    message: t('We were unable to delete this group'),
    toastId: 'GROUP_DELETE_ERROR',
  },
  ['CONTACT_SET_ACTIVE_FAILURE']: {
    isSuccess: false,
    message: t('We are currently not able to select this contact'),
    toastId: 'CONTACT_SET_ACTIVE_FAILURE',
  },
  ['GROUP_SET_ACTIVE_FAILURE']: {
    isSuccess: false,
    message: t('We are currently not able to select this group'),
    toastId: 'GROUP_SET_ACTIVE_FAILURE',
  },
}

const contactsErrorEpic: (action: ActionsObservable<ContactsActions>) => void = (action$) =>
  action$.pipe(switchMap(({ type }) => handlerError(contactsErrorTypes, type)))

export default contactsErrorEpic
