import { ChevronDown12Filled, ChevronUp12Filled } from '@fluentui/react-icons'
import { Button } from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'
import { useState } from 'react'

import ModulePermissions from '../../../../common/ModulePermissions'
import { MODULES } from '../../../../constants/permissions'
import Expand from '../../../../Transition/Expand'

const Disclaimer = () => {
  const [show, setShow] = useState(false)

  return (
    <ModulePermissions module="SHOW_COPYRIGHT_DISCLAIMER" permissions={[MODULES.SHOW_COPYRIGHT_DISCLAIMER.ON]}>
      <div className="py-6 font-sans">
        <Button variant="text" onClick={() => setShow(!show)} className="p-0 text-sky.cloudy no-underline">
          <Trans>All Infomedia media material is protected by copyright.</Trans>
          {show ? <ChevronUp12Filled /> : <ChevronDown12Filled />}
        </Button>
        <Expand in={show}>
          <div className="flex flex-col text-xs leading-4">
            <span>
              <Trans>You may not sell, transmit, distribute, reproduce or multiply Infomedia media material without the specific written agreement of the publishers who hold the copyright to the material. You are not allowed to store media material locally, for example on your own PC. All material must be accessed via Infomedia`s systems. A violation of the above is a breach of copyright and will be reported to the publishers who hold the copyright. Infomedia reserves the right to claim compensation for misuse that violates the terms of your agreement or applicable Danish law.</Trans>
            </span>
            <span className="mt-3 font-bold">
              <Trans>Copying</Trans>
            </span>
            <span>
              <Trans values={{link: <a href="http://www.tekstognode.dk">http://www.tekstognode.dk</a>}}>{'An agreement with Infomedia does not give the right to copy media material. This right can be obtained through an agreement with Copydan Tekst & Node, which covers copying in the text area. Read more about the possibilities for analogue and digital copying for companies and education respectively at {{link}}'}</Trans>
            </span>
          </div>
        </Expand>
      </div>
    </ModulePermissions>
  )
}

export default Disclaimer
