/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Infomedia API
 * OpenAPI spec version: 1.0.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  AuthInviteResendEmailCreateParams,
  AuthInviteSearchRetrieveParams,
  IFMLogin,
  RemainingInvitesResponse,
  RemainingInvitesResponseOld,
  ResendEmailResponse,
  Response,
  UserInvitationSearch,
} from '../opoint.schemas'
import { useCustomAxiosInstance } from '.././custom-client'
import type { ErrorType } from '.././custom-client'

/**
 * Authenticates Infomedia users, creating them if missing.

This endpoint is here temporarily, as part of the integration.

The user logs into Infomedias portal, and upon successful authentication,
Infomedia passes user data to Opoint. If the user is already in the system,
we pass them a token. If the user is not in the system, we create the user.

It also create the necessary customer and customer users.
 */
export const useAuthGetTokenInfomediaCreateHook = () => {
  const authGetTokenInfomediaCreate = useCustomAxiosInstance<Response>()

  return useCallback(
    (iFMLogin: IFMLogin) => {
      return authGetTokenInfomediaCreate({
        url: `/auth/get-token-infomedia/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: iFMLogin,
      })
    },
    [authGetTokenInfomediaCreate],
  )
}

export const useAuthGetTokenInfomediaCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthGetTokenInfomediaCreateHook>>>,
    TError,
    { data: IFMLogin },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthGetTokenInfomediaCreateHook>>>,
  TError,
  { data: IFMLogin },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const authGetTokenInfomediaCreate = useAuthGetTokenInfomediaCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthGetTokenInfomediaCreateHook>>>,
    { data: IFMLogin }
  > = (props) => {
    const { data } = props ?? {}

    return authGetTokenInfomediaCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuthGetTokenInfomediaCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthGetTokenInfomediaCreateHook>>>
>
export type AuthGetTokenInfomediaCreateMutationBody = IFMLogin
export type AuthGetTokenInfomediaCreateMutationError = ErrorType<unknown>

export const useAuthGetTokenInfomediaCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthGetTokenInfomediaCreateHook>>>,
    TError,
    { data: IFMLogin },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthGetTokenInfomediaCreateHook>>>,
  TError,
  { data: IFMLogin },
  TContext
> => {
  const mutationOptions = useAuthGetTokenInfomediaCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Returns the amount of remining invites for authenticated user
 */
export const useAuthInviteRemainingInvitesRetrieveHook = () => {
  const authInviteRemainingInvitesRetrieve = useCustomAxiosInstance<RemainingInvitesResponseOld>()

  return useCallback(
    (signal?: AbortSignal) => {
      return authInviteRemainingInvitesRetrieve({ url: `/auth/invite/remaining-invites/`, method: 'GET', signal })
    },
    [authInviteRemainingInvitesRetrieve],
  )
}

export const getAuthInviteRemainingInvitesRetrieveQueryKey = () => {
  return [`/auth/invite/remaining-invites/`] as const
}

export const useAuthInviteRemainingInvitesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesRetrieveHook>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesRetrieveHook>>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAuthInviteRemainingInvitesRetrieveQueryKey()

  const authInviteRemainingInvitesRetrieve = useAuthInviteRemainingInvitesRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesRetrieveHook>>>> = ({
    signal,
  }) => authInviteRemainingInvitesRetrieve(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuthInviteRemainingInvitesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesRetrieveHook>>>
>
export type AuthInviteRemainingInvitesRetrieveQueryError = ErrorType<void>

export const useAuthInviteRemainingInvitesRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesRetrieveHook>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesRetrieveHook>>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAuthInviteRemainingInvitesRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Returns the amount of remining invites for authenticated user
 */
export const useAuthInviteRemainingInvitesNewRetrieveHook = () => {
  const authInviteRemainingInvitesNewRetrieve = useCustomAxiosInstance<RemainingInvitesResponse[]>()

  return useCallback(
    (signal?: AbortSignal) => {
      return authInviteRemainingInvitesNewRetrieve({
        url: `/auth/invite/remaining-invites-new/`,
        method: 'GET',
        signal,
      })
    },
    [authInviteRemainingInvitesNewRetrieve],
  )
}

export const getAuthInviteRemainingInvitesNewRetrieveQueryKey = () => {
  return [`/auth/invite/remaining-invites-new/`] as const
}

export const useAuthInviteRemainingInvitesNewRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesNewRetrieveHook>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesNewRetrieveHook>>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAuthInviteRemainingInvitesNewRetrieveQueryKey()

  const authInviteRemainingInvitesNewRetrieve = useAuthInviteRemainingInvitesNewRetrieveHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesNewRetrieveHook>>>
  > = ({ signal }) => authInviteRemainingInvitesNewRetrieve(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesNewRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuthInviteRemainingInvitesNewRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesNewRetrieveHook>>>
>
export type AuthInviteRemainingInvitesNewRetrieveQueryError = ErrorType<void>

export const useAuthInviteRemainingInvitesNewRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesNewRetrieveHook>>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteRemainingInvitesNewRetrieveHook>>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAuthInviteRemainingInvitesNewRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Resend an email to the email address associated with the given token.
This also resets sent and expired timestamps.
 */
export const useAuthInviteResendEmailCreateHook = () => {
  const authInviteResendEmailCreate = useCustomAxiosInstance<ResendEmailResponse>()

  return useCallback(
    (params?: AuthInviteResendEmailCreateParams) => {
      return authInviteResendEmailCreate({ url: `/auth/invite/resend-email/`, method: 'POST', params })
    },
    [authInviteResendEmailCreate],
  )
}

export const useAuthInviteResendEmailCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteResendEmailCreateHook>>>,
    TError,
    { params?: AuthInviteResendEmailCreateParams },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthInviteResendEmailCreateHook>>>,
  TError,
  { params?: AuthInviteResendEmailCreateParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const authInviteResendEmailCreate = useAuthInviteResendEmailCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteResendEmailCreateHook>>>,
    { params?: AuthInviteResendEmailCreateParams }
  > = (props) => {
    const { params } = props ?? {}

    return authInviteResendEmailCreate(params)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuthInviteResendEmailCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthInviteResendEmailCreateHook>>>
>

export type AuthInviteResendEmailCreateMutationError = ErrorType<void>

export const useAuthInviteResendEmailCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteResendEmailCreateHook>>>,
    TError,
    { params?: AuthInviteResendEmailCreateParams },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthInviteResendEmailCreateHook>>>,
  TError,
  { params?: AuthInviteResendEmailCreateParams },
  TContext
> => {
  const mutationOptions = useAuthInviteResendEmailCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
const useAuthInviteSearchRetrieveHook = () => {
  const authInviteSearchRetrieve = useCustomAxiosInstance<UserInvitationSearch>()

  return useCallback(
    (params?: AuthInviteSearchRetrieveParams) => {
      return authInviteSearchRetrieve({ url: `/auth/invite/search/`, method: 'GET', params })
    },
    [authInviteSearchRetrieve],
  )
}

export const getAuthInviteSearchRetrieveQueryKey = (params?: AuthInviteSearchRetrieveParams) => {
  return [`/auth/invite/search/`, ...(params ? [params] : [])] as const
}

export const useAuthInviteSearchRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: AuthInviteSearchRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>,
      TError,
      TData
    >
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAuthInviteSearchRetrieveQueryKey(params)

  const authInviteSearchRetrieve = useAuthInviteSearchRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>> = ({
    pageParam,
  }) => authInviteSearchRetrieve({ ...params, page: pageParam || params?.['page'] })

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuthInviteSearchRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>
>
export type AuthInviteSearchRetrieveInfiniteQueryError = ErrorType<unknown>

export const useAuthInviteSearchRetrieveInfinite = <
  TData = Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: AuthInviteSearchRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>,
      TError,
      TData
    >
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAuthInviteSearchRetrieveInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useAuthInviteSearchRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: AuthInviteSearchRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAuthInviteSearchRetrieveQueryKey(params)

  const authInviteSearchRetrieve = useAuthInviteSearchRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>> = () =>
    authInviteSearchRetrieve(params)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuthInviteSearchRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>
>
export type AuthInviteSearchRetrieveQueryError = ErrorType<unknown>

export const useAuthInviteSearchRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: AuthInviteSearchRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAuthInviteSearchRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAuthInviteSearchRetrieveQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
