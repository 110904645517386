import { produce } from 'immer'

import { AppActions } from '../actions'
import { TabType } from '../components/settings/navigation/SettingsNavigation'

import { DeleteModalVariantKey } from '../components/types/profile'
import { GraphControlsMenu } from '../components/types/statistics'
import { M360Article } from '../opoint/articles/types'

export type StatisticsSideBarData = {
  chartType: 'table' | 'chart' | 'customDataTable' | 'customBubbleChart' | 'barChart' | 'lineChart' | 'rowChart' | ''
  graph: {
    chartType: string
    seriesType?: 'stacked' | 'overlapped'
    saveFile: (title: string, format: 'svg' | 'png' | 'csv', chart: any) => void
    mode?: string
  }
  menuList: GraphControlsMenu | null
  count: string
  isOpen: boolean
  contextType: 'edit_widgets' | 'edit_dashboard' | 'edit_dashboard_widget' | 'widget_settings'
}

export interface UIState {
  addArticleModalOpen: boolean
  dashboardModalState: {
    url: string | null
    name: string | null
    isOpen: boolean
  }
  deleteProfilesModeEnabled: boolean
  editArticleModalOpen: boolean
  filterDetailModalOpen: boolean
  isEditorFormModalOpen: boolean
  isEditorModalOpen: boolean
  sortableModuleModalOpen: boolean
  alertRecipientsListOpen: {
    alertId: number | null
    lastSent: number | null
    alertName: string | null
    open: boolean
  }
  manageProfilesModalOpen: boolean
  manageTagsModalOpen: boolean
  settingsModalOpen: boolean
  settingsModalDefaultSection: TabType | null
  shareArticleModalOpen: boolean
  deleteProfileConfirmationOpen: DeleteModalVariantKey | boolean
  recipientDeleteConfirmationModal: { open: boolean; onAccept: () => void; onDismiss: () => void }
  infoCalcModalOpen: boolean
  supportIssueModalOpen: { id_site: number; id_article: number } | null
  supportRequestModalOpen: { id_site: number; id_article: number } | null
  datepickerModalOpen: boolean
  helpModalOpen: boolean
  clickedArticle: M360Article | null | undefined
  statisticsSidebar: StatisticsSideBarData
  correctStatsArticleNumbers: null | { filtered: number }
  statsInfoModalOpen: boolean
  articleModalStatus: null | {
    status: boolean
    article: M360Article | null
    index: number
  }
  alertRemoveArticleOpen: boolean
  alertChangeRecipientsOpen: boolean
  isStatisticsExportModalOpen: boolean
  isChangeLogOpen: boolean
  reportsModalOpen: boolean
  reportHistoryModalOpen: boolean
  sourceListsModalOpen: boolean
  contactsModalOpen: boolean
  isUserInvitationsModalOpen: boolean
}

export const initialState: UIState = {
  addArticleModalOpen: false,
  dashboardModalState: { url: null, name: null, isOpen: false },
  deleteProfilesModeEnabled: false,
  editArticleModalOpen: false,
  filterDetailModalOpen: false,
  isEditorFormModalOpen: false,
  isEditorModalOpen: false,
  sortableModuleModalOpen: false,
  alertRecipientsListOpen: {
    open: false,
    alertId: null,
    lastSent: null,
    alertName: null,
  },
  manageProfilesModalOpen: false,
  manageTagsModalOpen: false,
  settingsModalOpen: false,
  settingsModalDefaultSection: null,
  shareArticleModalOpen: false,
  deleteProfileConfirmationOpen: false,
  recipientDeleteConfirmationModal: { open: false, onAccept: () => {}, onDismiss: () => {} },
  infoCalcModalOpen: false,
  supportIssueModalOpen: null,
  supportRequestModalOpen: null,
  datepickerModalOpen: false,
  helpModalOpen: false,
  statisticsSidebar: {
    isOpen: false,
    chartType: '',
    contextType: 'edit_widgets',
    // @ts-expect-error: Muted so we could enable TS strict mode
    graph: null,
    menuList: null,
    count: '',
  },
  correctStatsArticleNumbers: null,
  statsInfoModalOpen: false,
  articleModalStatus: null,
  clickedArticle: null,
  alertRemoveArticleOpen: false,
  alertChangeRecipientsOpen: false,
  isStatisticsExportModalOpen: false,
  isChangeLogOpen: false,
  reportsModalOpen: false,
  reportHistoryModalOpen: false,
  sourceListsModalOpen: false,
  contactsModalOpen: false,
  isUserInvitationsModalOpen: false,
}

const uiReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'LOGOUT': {
      Object.assign(draftState, initialState)
      break
    }

    case 'FILTER_METAS_SHOW_DETAIL': {
      draftState.filterDetailModalOpen = true
      break
    }

    case 'FILTER_METAS_HIDE_DETAIL': {
      draftState.filterDetailModalOpen = false
      break
    }

    case 'SETTINGS_MODAL_OPEN': {
      draftState.settingsModalOpen = true
      draftState.settingsModalDefaultSection = action.payload ?? null
      break
    }

    case 'SETTINGS_MODAL_CLOSE': {
      draftState.settingsModalOpen = false
      draftState.settingsModalDefaultSection = null
      break
    }

    case 'MANAGE_PROFILES_MODAL_OPEN': {
      draftState.manageProfilesModalOpen = true
      break
    }

    case 'MANAGE_PROFILES_MODAL_CLOSE': {
      draftState.manageProfilesModalOpen = false
      break
    }

    case 'EDIT_ARTICLE_MODAL_OPEN': {
      draftState.editArticleModalOpen = true
      break
    }

    case 'MANAGE_TAGS_MODAL_CLOSE': {
      draftState.manageTagsModalOpen = false
      break
    }

    case 'MANAGE_TAGS_MODAL_OPEN': {
      draftState.manageTagsModalOpen = true
      break
    }

    case 'EDIT_ARTICLE_MODAL_CLOSE':
    case 'EDIT_ECB_ARTICLE_SUCCESS': {
      draftState.editArticleModalOpen = false
      break
    }

    case 'SHARE_ARTICLE_MODAL_OPEN': {
      draftState.shareArticleModalOpen = true
      draftState.clickedArticle = action.payload
      break
    }

    case 'SHARE_ARTICLE_MODAL_CLOSE': {
      draftState.shareArticleModalOpen = false
      draftState.clickedArticle = null
      break
    }

    case 'REPORT_HISTORY_MODAL_OPEN': {
      draftState.reportHistoryModalOpen = true
      break
    }

    case 'REPORT_HISTORY_MODAL_CLOSE': {
      draftState.reportHistoryModalOpen = false
      break
    }

    case 'SOURCE_LISTS_MODAL_OPEN': {
      draftState.sourceListsModalOpen = true
      break
    }

    case 'SOURCE_LISTS_MODAL_CLOSE': {
      draftState.sourceListsModalOpen = false
      break
    }

    case 'CONTACTS_MODAL_OPEN': {
      draftState.contactsModalOpen = true
      break
    }

    case 'CONTACTS_MODAL_CLOSE': {
      draftState.contactsModalOpen = false
      break
    }

    case 'TEMPLATE_EDITOR_MODAL_OPEN': {
      draftState.isEditorModalOpen = true
      break
    }

    case 'TEMPLATE_EDITOR_MODAL_CLOSE': {
      draftState.isEditorModalOpen = false
      break
    }

    case 'TEMPLATE_EDITOR_FORM_MODAL_OPEN': {
      draftState.isEditorFormModalOpen = true
      break
    }

    case 'TEMPLATE_EDITOR_FORM_MODAL_CLOSE': {
      draftState.isEditorFormModalOpen = false
      break
    }

    case 'TEMPLATE_EDITOR_MODULE_SORT_MODAL_OPEN': {
      draftState.sortableModuleModalOpen = true
      break
    }

    case 'TEMPLATE_EDITOR_MODULE_SORT_MODAL_CLOSE': {
      draftState.sortableModuleModalOpen = false
      break
    }

    case 'ALERT_REMOVE_ARTICLE_OPEN': {
      draftState.alertRemoveArticleOpen = true
      break
    }

    case 'ALERT_REMOVE_ARTICLE_CLOSE': {
      draftState.alertRemoveArticleOpen = false
      break
    }

    case 'ALERT_CHANGE_RECIPIENTS_OPEN': {
      draftState.alertChangeRecipientsOpen = true
      break
    }

    case 'ALERT_CHANGE_RECIPIENTS_CLOSE': {
      draftState.alertChangeRecipientsOpen = false
      break
    }

    case 'ALERT_RECIPIENT_LIST_OPEN': {
      draftState.alertRecipientsListOpen = {
        open: true,
        ...action.payload,
      }
      break
    }

    case 'ALERT_RECIPIENT_LIST_CLOSE': {
      draftState.alertRecipientsListOpen = {
        open: false,
        alertId: null,
        lastSent: null,
        alertName: null,
      }
      break
    }

    case 'HELP_MODAL_OPEN': {
      draftState.helpModalOpen = true
      break
    }

    case 'HELP_MODAL_CLOSE': {
      draftState.helpModalOpen = false
      break
    }

    case 'DELETE_PROFILES_MODE_DISABLE': {
      draftState.deleteProfilesModeEnabled = false
      break
    }

    case 'DELETE_PROFILES_MODE_TOGGLE': {
      draftState.deleteProfilesModeEnabled = !draftState.deleteProfilesModeEnabled
      break
    }

    case 'ADD_ARTICLE_MODAL_OPEN': {
      draftState.addArticleModalOpen = true
      break
    }

    case 'ADD_ARTICLE_SUCCESS':
    case 'ADD_ARTICLE_MODAL_CLOSE': {
      draftState.addArticleModalOpen = false
      break
    }

    case 'REPORTS_MODAL_OPEN': {
      draftState.reportsModalOpen = true
      break
    }

    case 'REPORTS_MODAL_CLOSE': {
      draftState.reportsModalOpen = false
      break
    }

    case 'DATEPICKER_MODAL_OPEN': {
      draftState.datepickerModalOpen = true
      break
    }

    case 'DATEPICKER_MODAL_CLOSE': {
      draftState.datepickerModalOpen = false
      break
    }

    case 'PROFILE_DELETE_CONFIRMATION_OPEN': {
      draftState.deleteProfileConfirmationOpen = DeleteModalVariantKey.Manage
      break
    }

    case 'RECIPIENT_DELETE_CONFIRMATION_OPEN': {
      draftState.recipientDeleteConfirmationModal = { open: true, ...action.payload }
      break
    }

    case 'RECIPIENT_DELETE_CONFIRMATION_CLOSE': {
      draftState.recipientDeleteConfirmationModal = { open: false, onAccept: () => {}, onDismiss: () => {} }
      break
    }

    case 'PROFILE_EDITOR_DELETE_CONFIRMATION_OPEN': {
      draftState.deleteProfileConfirmationOpen = DeleteModalVariantKey.Editor
      break
    }

    case 'PROFILE_DELETE_CONFIRMATION_CLOSE': {
      draftState.deleteProfileConfirmationOpen = false
      break
    }

    case 'STATISTICS_VIEW_EXPORT_OPEN_MODAL': {
      draftState.isStatisticsExportModalOpen = true
      break
    }

    case 'STATISTICS_VIEW_EXPORT_CLOSE_MODAL':
    case 'STATISTICS_VIEW_EXPORT_XLSX_FAILURE':
    case 'STATISTICS_VIEW_EXPORT_PPTX_FAILURE':
    case 'STATISTICS_VIEW_EXPORT_PDF_FAILURE': {
      draftState.isStatisticsExportModalOpen = false
      break
    }

    case 'INFO_CALC_MODAL_OPEN': {
      draftState.infoCalcModalOpen = true
      break
    }

    case 'INFO_CALC_MODAL_CLOSE': {
      draftState.infoCalcModalOpen = false
      break
    }

    case 'SUPPORT_REPORT_MODAL_OPEN': {
      draftState.supportIssueModalOpen = action.payload
      break
    }

    case 'SUPPORT_REPORT_MODAL_CLOSE': {
      draftState.supportIssueModalOpen = null
      break
    }

    case 'SUPPORT_REQUEST_MODAL_OPEN': {
      draftState.supportRequestModalOpen = action.payload
      break
    }

    case 'SUPPORT_REQUEST_MODAL_CLOSE': {
      draftState.supportRequestModalOpen = null
      break
    }

    case 'CLOSE_STATISTICS_SIDEBAR': {
      draftState.statisticsSidebar.isOpen = false
      break
    }

    case 'OPEN_EDIT_WIDGETS_STATISTICS_SIDEBAR': {
      draftState.statisticsSidebar = {
        isOpen: true,
        chartType: '',
        contextType: 'edit_widgets',
        // @ts-expect-error: Muted so we could enable TS strict mode
        graph: null,
        menuList: null,
        count: '',
      }
      break
    }

    case 'OPEN_WIDGET_SETTINGS_STATISTICS_SIDEBAR': {
      const { chartType, graph, menuList, count } = action.payload

      draftState.statisticsSidebar = {
        isOpen: true,
        contextType: 'widget_settings',
        graph,
        chartType,
        menuList,
        count,
      }
      break
    }

    case 'STATISTICS_CORRECT_ARTICLE_NUMBERS': {
      draftState.correctStatsArticleNumbers = action.payload
      break
    }

    case 'STATS_INFO_MODAL_OPEN': {
      draftState.statsInfoModalOpen = true
      break
    }

    case 'STATS_INFO_MODAL_CLOSE': {
      draftState.statsInfoModalOpen = false
      break
    }

    case 'TOGGLE_ARTICLE_MODAL': {
      draftState.articleModalStatus = action.payload
      break
    }

    case 'TOGGLE_DASHBOARD_MODAL': {
      draftState.dashboardModalState = action.payload
      break
    }

    case 'SET_IS_CHANGELOG_OPEN': {
      draftState.isChangeLogOpen = action.payload
      break
    }

    case 'USER_INVITATIONS_MODAL_OPEN': {
      draftState.isUserInvitationsModalOpen = true
      break
    }

    case 'USER_INVITATIONS_MODAL_CLOSE': {
      draftState.isUserInvitationsModalOpen = false
      break
    }

    default:
      break
  }
}, initialState)

export default uiReducer
