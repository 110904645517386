import { omit } from 'ramda'

export const DAY_CONST = {
  monday: {
    displayName: 'Mondays',
    indexName: 'monday',
    key: '0',
  },
  tuesday: {
    displayName: 'Tuesdays',
    indexName: 'tuesday',
    key: '1',
  },
  wednesday: {
    displayName: 'Wednesdays',
    indexName: 'wednesday',
    key: '2',
  },
  thursday: {
    displayName: 'Thursdays',
    indexName: 'thursday',
    key: '3',
  },
  friday: {
    displayName: 'Fridays',
    indexName: 'friday',
    key: '4',
  },
  saturday: {
    displayName: 'Saturdays',
    indexName: 'saturday',
    key: '5',
  },
  sunday: {
    displayName: 'Sundays',
    indexName: 'sunday',
    key: '6',
  },
  holidays: {
    displayName: 'Holidays',
    indexName: 'holidays',
    key: '7',
  },
  weekends: {
    displayName: 'Weekends',
    indexName: 'weekends',
    key: '-1',
  },
  weekdays: {
    displayName: 'Weekdays',
    indexName: 'weekdays',
    key: '-2',
  },
  // TODO rename
  alldays: {
    displayName: 'Every day',
    indexName: 'alldays',
    key: '-3',
  },
}

export const DAY_CONST_TIME_SCHEDULE: Object = omit(['weekends', 'weekdays', 'alldays'], DAY_CONST)
