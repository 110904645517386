import { Auth } from '@opoint/authjs-react'

type Params = {
  headers: {
    accept: string
    'accept-language': string
    'content-type': string
    authorization?: string
  }
  responseType: 'json'
}

export const LOAD_WATCH_INDEX_ARTICLES_REFRESH_LIMIT = 20

export const urls = {
  auth: {
    infomedia: (tld: string) => ({
      dev: `https://id.dev.infomedia.${tld}`,
      prod: `https://id.infomedia.${tld}`,
    }),
    opoint: { dev: 'https://auth.staging.opoint.com', prod: 'https://auth.opoint.com' },
  },
  callback: `https://${location.host}/callback`,
}

const api = (path: string) => `${import.meta.env.VITE_RUNTIME_API_URL}${path}`

const app = (path: string) => `${import.meta.env.VITE_RUNTIME_APP_URL}${path}`

const imageproxy = (path: string) => app(`/imageproxy${path}`)

const socketio = (path: string) =>
  import.meta.env.VITE_RUNTIME_SOCKETIO_URL ? `${import.meta.env.VITE_RUNTIME_SOCKETIO_URL}${path}` : api(path)

const buildAuthUrl = () => {
  // This env var is only for overriding behaviour, not for normal config!
  if (import.meta.env.VITE_RUNTIME_AUTH_URL) {
    return import.meta.env.VITE_RUNTIME_AUTH_URL
  }
  if (window.location.hostname.includes('infomedia')) {
    const [tld] = window.location.hostname.split('.').splice(-1)
    const { dev, prod } = urls.auth.infomedia(tld)

    return import.meta.env.DEV ? dev : prod
  }

  return import.meta.env.DEV ? urls.auth.opoint.dev : urls.auth.opoint.prod
}

const buildCallbackUrl = (redirect = '') =>
  `https://${location.host}/callback${redirect ? `?redirect=${redirect}` : ''}`

export const getRequestHeaders = async (requireAuth = true) => {
  const params: Params = {
    headers: {
      accept: 'application/json',
      'accept-language': 'en-GB',
      'content-type': 'application/json',
    },
    responseType: 'json',
  }
  if (requireAuth) {
    // NEW AUTH
    params.headers.authorization = `JWT ${await config.auth?.getTokenString()}`
  }

  return params
}

const config: {
  auth?: Auth
  request: {
    getRequestHeaders: (requireAuth?: boolean) => Promise<Params>
  }
  url: {
    api: (path: string) => string
    imageproxy: (path: string) => string
    app: (path: string) => string
    auth: () => string
    callback: (redirect?: string) => string
    socketio: (path: string) => string
  }
} = {
  // This value is initialised at the root node of the app,
  // so no code that needs it should actually hit this undefined
  auth: undefined,
  request: {
    getRequestHeaders,
  },
  url: {
    api,
    imageproxy,
    app,
    auth: () => buildAuthUrl(),
    callback: buildCallbackUrl,
    socketio: socketio,
  },
}

export default config
