import { CommentGroup } from '../../opoint/flow'
import {
  AttributeOptions,
  CategorizationOptions,
  LicenseModules,
  LicenseOptions,
  ModuleOptions,
} from '../constants/permissions'
import { SearchFilterKey } from '../hooks/useSearchFilters'
import { SupportAccess } from '../types/article'
import { LocaleKey } from '../types/locale'

/*
  IMPORTANT INFO:
  The plan is to eventually replace these interfaces with a schema for orval
  These interfaces are not completely exhaustive, as there are many more settings a user may receive from the BE
  These at least include all the ones we use in the app
*/

export interface Setting<T = string> {
  name: T
  value: any
}

export type SettingChoices<T> = {
  name: string
  value: T
}[]

export interface SettingWithChoices<T extends string, P = any> extends Setting<T> {
  choices: SettingChoices<P>
}

export interface NewPortalAutoLoadSetting extends Setting<'NEW_PORTAL_AUTOLOAD'> {
  name: 'NEW_PORTAL_AUTOLOAD'
  value: boolean | 0 | 1
}

export interface NewPortalListingLeftSetting extends Setting<'NEW_PORTAL_LISTING_LEFT'> {
  name: 'NEW_PORTAL_LISTING_LEFT'
  value: number
}

export interface NewPortalAutoshowSuggestionSetting extends Setting<'NEW_PORTAL_AUTOSHOW_SUGGESTION'> {
  name: 'NEW_PORTAL_AUTOSHOW_SUGGESTION'
  value: boolean | 0 | 1
}

export interface NewPortalArticleListingSetting extends Setting<'NEW_PORTAL_ARTICLE_LISTING'> {
  name: 'NEW_PORTAL_ARTICLE_LISTING'
  value: string
}

export interface NewPortalKPIBarVisibleSetting extends SettingWithChoices<'NEW_PORTAL_KPI_BAR_VISIBLE', boolean> {
  name: 'NEW_PORTAL_KPI_BAR_VISIBLE'
  value: boolean
  choices: SettingChoices<boolean>
}

export interface NewPortalLayoutSetting extends SettingWithChoices<'NEW_PORTAL_LAYOUT', number> {
  name: 'NEW_PORTAL_LAYOUT'
  value: number
  choices: SettingChoices<number>
}

export interface EntitiesSelectedSetting extends SettingWithChoices<'ENTITIES_SELECTED', number> {
  name: 'ENTITIES_SELECTED'
  value: number
  choices: SettingChoices<number>
}

export interface NewPortalRelativeDateFormat extends SettingWithChoices<'NEW_PORTAL_RELATIVE_DATE_FORMAT', number> {
  name: 'NEW_PORTAL_RELATIVE_DATE_FORMAT'
  value: number
  choices: SettingChoices<number>
}

export interface NewPortalRelativeDateLimit extends Setting<'NEW_PORTAL_RELATIVE_DATE_LIMIT'> {
  name: 'NEW_PORTAL_RELATIVE_DATE_LIMIT'
  value: number | string
}

export interface AppDefaultHomeSetting extends Setting<'APP_DEFAULT_HOME'> {
  name: 'APP_DEFAULT_HOME'
  value:
    | {
        id: number
        type: SearchFilterKey
      }
    | {
        //for new users
        id: undefined
        type: 'search'
      }
}

export interface AppUISettingsSetting extends Setting<'APP_UI_SETTINGS'> {
  name: 'APP_UI_SETTINGS'
  value: {
    // THESE ARE ONLY THE ONES WE STILL USE
    // There are many more legacy ui settings in the response
    AUTO_EXPAND_PROFILE_EDITOR_SEARCH_LINES?: boolean
    DISPLAY_IMPORTANT_PROFILES?: boolean
    FOLDERS_CATEGORY?: {
      [key: string]: boolean
    }
    HIDE_ALL_TAGS?: boolean
    NEW_PORTAL_FILTERS: string
    NEW_PORTAL_FILTER_SUGGEST: number
    NEW_PORTAL_SEARCH_FILTERS: string
    NEW_PORTAL_SEARCH_SUGGEST: number
    NEW_PORTAL_SHOW_METADATA: string
    NEW_PORTAL_SITE_DRILLDOWN_OPTION: string
    SHOW_COMPANY_SENTIMENT?: boolean
    SHOW_GENERAL_SENTIMENT?: boolean
    SHOW_METRICS?: boolean
    SIDEBAR_SHOWN?: boolean
    TRASH_TAGS_VISIBLE?: Array<number>
  }
}

export interface FilterDrilldownSetting extends SettingWithChoices<'FILTER_DRILLDOWN', boolean> {
  name: 'FILTER_DRILLDOWN'
  value: boolean | 0 | 1
  choices: SettingChoices<boolean>
}

export interface EmailSenderSetting extends Setting<'EMAIL_SENDER'> {
  name: 'EMAIL_SENDER'
  value: string
}

export interface PapermillAccessSetting extends Setting<'PAPERMILL_ACCESS'> {
  name: 'PAPERMILL_ACCESS'
  value: number
}

export interface TranslateCfgSetting extends Setting<'TRANSLATE_CFG'> {
  name: 'TRANSLATE_CFG'
  value: string
}

export interface TranslateToSetting extends Setting<'TRANSLATE_TO'> {
  name: 'TRANSLATE_TO'
  value: string
}

export interface TranslateTypeSetting extends Setting<'TRANSLATE_TYPE'> {
  name: 'TRANSLATE_TYPE'
  value: number
}

export interface TranslateAutoSetting extends Setting<'TRANSLATE_AUTO'> {
  name: 'TRANSLATE_AUTO'
  value: boolean
}

export interface MaxGtArticleLengthSetting extends Setting<'MAX_GT_ARTICLE_LENGTH'> {
  name: 'MAX_GT_ARTICLE_LENGTH'
  value: number //! The response gives us a string, that we parse with normalizeSettingValues
}

export interface LanguageSetting extends Setting<'LANGUAGE'> {
  name: 'LANGUAGE'
  value: LocaleKey
}

export interface ControlPanelSetting extends Setting<'CONTROL_PANEL'> {
  name: 'CONTROL_PANEL'
  value: number
}

export interface IntroModeSetting extends Setting<'INTRO_MODE'> {
  name: 'INTRO_MODE'
  value: number
}

export interface IdenticalSetting extends Setting<'IDENTICAL'> {
  name: 'IDENTICAL'
  value: boolean | 0 | 1
}

export interface ColorbarColorsSetting extends Setting<'COLORBAR_COLORS'> {
  name: 'COLORBAR_COLORS'
  value: number
}

export interface NewPortalReportLogoSetting extends Setting<'NEW_PORTAL_report_logo'> {
  name: 'NEW_PORTAL_report_logo'
  value: string
}

export interface DefaultSearchSetting extends Setting<'DEFAULT_SEARCH'> {
  name: 'DEFAULT_SEARCH'
  value: any[]
}

export interface NewPortalLogoSetting extends Setting<'NEW_PORTAL_LOGO'> {
  name: 'NEW_PORTAL_LOGO'
  value: string
}

export interface GtQuotaSetting extends Setting<'GT_QUOTA'> {
  name: 'GT_QUOTA'
  value: {
    quota: number
    google_translated: number
    total_translated: number
  }
}

export interface HideTagsSetting extends Setting<'HIDE_ALL_TAGS'> {
  name: 'HIDE_ALL_TAGS'
  value: AttributeOptions
}

export interface ProfileModuleSetting extends Setting<'PROFILE_MODULE'> {
  name: 'PROFILE_MODULE'
  value: ModuleOptions
}

export interface AlertModuleSetting extends Setting<'ALERT_MODULE'> {
  name: 'ALERT_MODULE'
  value: ModuleOptions
}

export interface TagModuleSetting extends Setting<'TAG_MODULE'> {
  name: 'TAG_MODULE'
  value: ModuleOptions
}

export interface TrashModuleSetting extends Setting<'TRASH_MODULE'> {
  name: 'TRASH_MODULE'
  value: ModuleOptions
}

export interface FeedModuleSetting extends Setting<'FEED_MODULE'> {
  name: 'FEED_MODULE'
  value: ModuleOptions
}

export interface ReportModuleSetting extends Setting<'REPORT_MODULE'> {
  name: 'REPORT_MODULE'
  value: ModuleOptions
}

export interface StatisticsModuleSetting extends Setting<'STATISTICS_MODULE'> {
  name: 'STATISTICS_MODULE'
  value: ModuleOptions
}

export interface CreateArticleModuleSetting extends Setting<'CREATE_ARTICLE_MODULE'> {
  name: 'CREATE_ARTICLE_MODULE'
  value: ModuleOptions
}

export interface ContactModuleSetting extends Setting<'CONTACT_MODULE'> {
  name: 'CONTACT_MODULE'
  value: ModuleOptions
}

export interface SettingModuleSetting extends Setting<'SETTING_MODULE'> {
  name: 'SETTING_MODULE'
  value: ModuleOptions
}

export interface NewsletterEditorModuleSetting extends Setting<'NEWSLETTER_EDITOR_MODULE'> {
  name: 'NEWSLETTER_EDITOR_MODULE'
  value: string
}

export interface SourceListModuleSetting extends Setting<'SOURCE_LIST_MODULE'> {
  name: 'SOURCE_LIST_MODULE'
  value: ModuleOptions
}

export interface TemplateEditorModuleSetting extends Setting<'TEMPLATE_EDITOR_MODULE'> {
  name: 'TEMPLATE_EDITOR_MODULE'
  value: string
}

export interface UserInviteTargetsSetting extends Setting<'USER_INVITE_TARGETS'> {
  name: 'USER_INVITE_TARGETS'
  value: {
    id: number
    name: string
  }[]
}

export interface AllowedDomainsSetting extends Setting<'ALLOWED_DOMAINS'> {
  name: 'ALLOWED_DOMAINS'
  value: string
}

export interface CanAddToAlertBasketSetting extends Setting<'CAN_ADD_TO_ALERT_BASKET'> {
  name: 'CAN_ADD_TO_ALERT_BASKET'
  value: number
}

export interface DefaultFolderNameProfilesSetting extends Setting<'DEFAULT_FOLDER_NAME_PROFILES'> {
  name: 'DEFAULT_FOLDER_NAME_PROFILES'
  value: string
}

export interface DefaultFolderNameTagsSetting extends Setting<'DEFAULT_FOLDER_NAME_TAGS'> {
  name: 'DEFAULT_FOLDER_NAME_TAGS'
  value: string
}

export interface DefaultFolderNameSentimentTagsSetting extends Setting<'DEFAULT_FOLDER_NAME_SENTIMENT_TAGS'> {
  name: 'DEFAULT_FOLDER_NAME_SENTIMENT_TAGS'
  value: string
}

export interface DefaultFolderNameStatisticsSetting extends Setting<'DEFAULT_FOLDER_NAME_STATISTICS'> {
  name: 'DEFAULT_FOLDER_NAME_STATISTICS'
  value: string
}

export interface DisplayPrintPdfLinkSetting extends Setting<'DISPLAY_PRINT_PDF_LINK'> {
  name: 'DISPLAY_PRINT_PDF_LINK'
  value: AttributeOptions
}

export interface EditOrgAlertsSetting extends Setting<'EDIT_ORG_ALERTS'> {
  name: 'EDIT_ORG_ALERTS'
  value: number
}

export interface EnableCategorizationSetting extends Setting<'ENABLE_CATEGORIZATION'> {
  name: 'ENABLE_CATEGORIZATION'
  value: CategorizationOptions
}

export interface EnableEditArticlesSetting extends Setting<'ENABLE_EDIT_ARTICLES'> {
  name: 'ENABLE_EDIT_ARTICLES'
  value: AttributeOptions
}

export interface EnableReachOnArticlesSetting extends Setting<'ENABLE_REACH_ON_ARTICLES'> {
  name: 'ENABLE_REACH_ON_ARTICLES'
  value: AttributeOptions
}

export interface EnableSingleArticleModeSetting extends Setting<'ENABLE_SINGLE_ARTICLE_MODE'> {
  name: 'ENABLE_SINGLE_ARTICLE_MODE'
  value: AttributeOptions
}

export interface EnableTextSmsAlertsSetting extends Setting<'ENABLE_TEXT_SMS_ALERTS'> {
  name: 'ENABLE_TEXT_SMS_ALERTS'
  value: AttributeOptions
}

export interface FolderAdminSetting extends Setting<'FOLDER_ADMIN'> {
  name: 'FOLDER_ADMIN'
  value: AttributeOptions
}

export interface InternalOrmSetting extends Setting<'INTERNAL_ORM'> {
  name: 'INTERNAL_ORM'
  value: number
}

export interface LimitedAddContentSetting extends Setting<'LIMITED_ADD_CONTENT'> {
  name: 'LIMITED_ADD_CONTENT'
  value: AttributeOptions
}

export interface LimitedCreateProfilesSetting extends Setting<'LIMITED_CREATE_PROFILES'> {
  name: 'LIMITED_CREATE_PROFILES'
  value: AttributeOptions
}

export interface NewPortalCommentGroupsSetting extends Setting<'NEW_PORTAL_COMMENT_GROUPS'> {
  name: 'NEW_PORTAL_COMMENT_GROUPS'
  value: CommentGroup[]
}

export interface NewPortalDashboardNameSetting extends Setting<'NEW_PORTAL_DASHBOARD_NAME'> {
  name: 'NEW_PORTAL_DASHBOARD_NAME'
  value: string
}

export interface NewPortalDashboardUrlSetting extends Setting<'NEW_PORTAL_DASHBOARD_URL'> {
  name: 'NEW_PORTAL_DASHBOARD_URL'
  value: string
}

export interface NewPortalDefaultTemplateSetting extends Setting<'NEW_PORTAL_DEFAULT_TEMPLATE'> {
  name: 'NEW_PORTAL_DEFAULT_TEMPLATE'
  value: number
}

export interface NewPortalMainDomainSetting extends Setting<'NEW_PORTAL_MAIN_DOMAIN'> {
  name: 'NEW_PORTAL_MAIN_DOMAIN'
  value: number
}

export interface NewPortalMaxAlertsSetting extends Setting<'NEW_PORTAL_MAX_ALERTS'> {
  name: 'NEW_PORTAL_MAX_ALERTS'
  value: number
}

export interface NewPortalMaxAlertsRecipientsSetting extends Setting<'NEW_PORTAL_MAX_ALERTS_RECIPIENTS'> {
  name: 'NEW_PORTAL_MAX_ALERTS_RECIPIENTS'
  value: number
}

export interface NewPortalMaxProfilesSetting extends Setting<'NEW_PORTAL_MAX_PROFILES'> {
  name: 'NEW_PORTAL_MAX_PROFILES'
  value: number
}

export interface NewPortalSearchBarSetting extends Setting<'NEW_PORTAL_SEARCH_BAR'> {
  name: 'NEW_PORTAL_SEARCH_BAR'
  value: number
}

export interface NewPortalShowSentimentSetting extends Setting<'NEW_PORTAL_SHOW_SENTIMENT'> {
  name: 'NEW_PORTAL_SHOW_SENTIMENT'
  value: number
}

export interface NonPdfReportAccessSetting extends Setting<'NON_PDF_REPORT_ACCESS'> {
  name: 'NON_PDF_REPORT_ACCESS'
  value: AttributeOptions
}

export interface NumUserInvitesSetting extends Setting<'NUM_USER_INVITES'> {
  name: 'NUM_USER_INVITES'
  value: number
}

export interface OcgAccessGroupSetting extends Setting<'OCG_ACCESS_GROUP'> {
  name: 'OCG_ACCESS_GROUP'
  value: string
}

export interface PersistentProfileAccessSetting extends Setting<'PERSISTENT_PROFILE_ACCESS'> {
  name: 'PERSISTENT_PROFILE_ACCESS'
  value: number
}

export interface ProductTourSetting extends Setting<'PRODUCT_TOUR'> {
  name: 'PRODUCT_TOUR'
  value: AttributeOptions
}

export interface RestrictedReportHistorySetting extends Setting<'RESTRICTED_REPORT_HISTORY'> {
  name: 'RESTRICTED_REPORT_HISTORY'
  value: AttributeOptions
}

export interface ShowCopyrightDisclaimerSetting extends Setting<'SHOW_COPYRIGHT_DISCLAIMER'> {
  name: 'SHOW_COPYRIGHT_DISCLAIMER'
  value: AttributeOptions
}

export interface SimilarSettingVisibleSetting extends Setting<'SIMILAR_SETTING_VISIBLE'> {
  name: 'SIMILAR_SETTING_VISIBLE'
  value: AttributeOptions
}

export interface StatisticsEnableEacAndCirculationSetting extends Setting<'STATISTICS_ENABLE_EAC_AND_CIRCULATION'> {
  name: 'STATISTICS_ENABLE_EAC_AND_CIRCULATION'
  value: AttributeOptions
}

export interface SuggestserverSortingSetting extends Setting<'SUGGESTSERVER_SORTING'> {
  name: 'SUGGESTSERVER_SORTING'
  value: number
}

export interface UploadArticlesAsCustomerSetting extends Setting<'UPLOAD_ARTICLES_AS_CUSTOMER'> {
  name: 'UPLOAD_ARTICLES_AS_CUSTOMER'
  value: number
}

export interface OcgRlLicenseSetting extends Setting<'OCG_RL_license'> {
  name: 'OCG_RL_license'
  value: {
    name: LicenseModules | string // we only use the ones in LicenseModules, but the response can have others
    label: string
    value: LicenseOptions
  }[]
}

export interface LastArtTimestampSetting extends Setting<'LAST_ART_TIMESTAMP'> {
  name: 'LAST_ART_TIMESTAMP'
  value: number | null
}

export interface ArticleAgeLimitSetting extends Setting<'ARTICLE_AGE_LIMIT'> {
  name: 'ARTICLE_AGE_LIMIT'
  value: {
    from: number
    to: number
  }
}

export interface LimitedSearchSetting extends Setting<'LIMITED_SEARCH'> {
  name: 'LIMITED_SEARCH'
  value: AttributeOptions
}

export interface LimitedSharingSetting extends Setting<'LIMITED_SHARING'> {
  name: 'LIMITED_SHARING'
  value: number
}

export interface ArticleEditAccessSetting extends Setting<'ARTICLE_EDIT_ACCESS'> {
  name: 'ARTICLE_EDIT_ACCESS'
  value: {
    translated_header: boolean
    manual_summary: boolean
  }
}

export interface NewPortalSupportSetting extends Setting<'NEW_PORTAL_SUPPORT'> {
  name: 'NEW_PORTAL_SUPPORT'
  value: SupportAccess
}

export interface TemplateAccessSetting extends Setting<'TEMPLATE_ACCESS'> {
  name: 'TEMPLATE_ACCESS'
  value: {
    pdf: boolean
    html: boolean
    excel: boolean
    xml: boolean
    word: boolean
    ppt: boolean
    rss: boolean
    json: boolean
  }
}

export interface NewPortalStatisticsExportFormatsSetting extends Setting<'NEW_PORTAL_STATISTICS_EXPORT_FORMATS'> {
  name: 'NEW_PORTAL_STATISTICS_EXPORT_FORMATS'
  value: {
    pdf: boolean
    ppt: boolean
    excel_static: boolean
    excel_dynamic: boolean
    raw_data: boolean
  }
}

export interface ShareHtmlOnlySetting extends Setting<'SHARE_HTML_ONLY'> {
  name: 'SHARE_HTML_ONLY'
  value: 0 | 1
}

export interface AllowedQueryLanguageSetting extends Setting<'ALLOWED_QUERY_LANGUAGE'> {
  name: 'ALLOWED_QUERY_LANGUAGE'
  value: AllowedQueryLanguageValues | 4 | 5 | 6 | 7 | 8 | 9 // 4-9 are possible values, but unused
}

export interface ProfileEditorAccessSetting extends Setting<'PROFILE_EDITOR_ACCESS'> {
  name: 'PROFILE_EDITOR_ACCESS'
  value: ProfileEditorAccessValues | 3 | 4 | 5 | 6 | 7 | 8 | 9 // 3-9 are possible values, but unused
}

// Settings that are applicable to all users
type UniversalSettings =
  | NewPortalAutoLoadSetting
  | NewPortalListingLeftSetting
  | NewPortalAutoshowSuggestionSetting
  | NewPortalArticleListingSetting
  | NewPortalKPIBarVisibleSetting
  | NewPortalLayoutSetting
  | EntitiesSelectedSetting
  | NewPortalRelativeDateFormat
  | NewPortalRelativeDateLimit
  | AppDefaultHomeSetting
  | AppUISettingsSetting
  | FilterDrilldownSetting
  | EmailSenderSetting
  | PapermillAccessSetting
  | TranslateCfgSetting
  | TranslateToSetting
  | TranslateTypeSetting
  | TranslateAutoSetting
  | MaxGtArticleLengthSetting
  | LanguageSetting
  | ControlPanelSetting
  | IntroModeSetting
  | IdenticalSetting
  | ColorbarColorsSetting
  | NewPortalReportLogoSetting
  | DefaultSearchSetting
  | NewPortalLogoSetting
  | ProfileModuleSetting
  | AlertModuleSetting
  | TagModuleSetting
  | TrashModuleSetting
  | FeedModuleSetting
  | ReportModuleSetting
  | StatisticsModuleSetting
  | CreateArticleModuleSetting
  | ContactModuleSetting
  | SettingModuleSetting
  | NewsletterEditorModuleSetting
  | SourceListModuleSetting
  | TemplateEditorModuleSetting
  | UserInviteTargetsSetting
  | EnableCategorizationSetting
  | NewPortalCommentGroupsSetting
  | OcgRlLicenseSetting
  | LastArtTimestampSetting
  | ArticleAgeLimitSetting
  | LimitedSearchSetting
  | LimitedSharingSetting
  | ArticleEditAccessSetting
  | NewPortalSupportSetting
  | TemplateAccessSetting
  | NewPortalStatisticsExportFormatsSetting
  | ShareHtmlOnlySetting
  | AllowedQueryLanguageSetting
  | ProfileEditorAccessSetting

// Settings that only return for specific users
// ! Many of these I could not determine if they are applicable to all users, so I am keeping them optional to be safe
type OptionalSettings =
  | AllowedDomainsSetting
  | CanAddToAlertBasketSetting
  | DefaultFolderNameProfilesSetting
  | DefaultFolderNameTagsSetting
  | DefaultFolderNameSentimentTagsSetting
  | DefaultFolderNameStatisticsSetting
  | DisplayPrintPdfLinkSetting
  | EditOrgAlertsSetting
  | EnableEditArticlesSetting
  | EnableReachOnArticlesSetting
  | EnableSingleArticleModeSetting
  | EnableTextSmsAlertsSetting
  | FolderAdminSetting
  | InternalOrmSetting
  | LimitedAddContentSetting
  | LimitedCreateProfilesSetting
  | NewPortalDashboardNameSetting
  | NewPortalDashboardUrlSetting
  | NewPortalDefaultTemplateSetting
  | NewPortalMainDomainSetting
  | NewPortalMaxAlertsSetting
  | NewPortalMaxAlertsRecipientsSetting
  | NewPortalMaxProfilesSetting
  | NewPortalSearchBarSetting
  | NewPortalShowSentimentSetting
  | NonPdfReportAccessSetting
  | NumUserInvitesSetting
  | OcgAccessGroupSetting
  | PersistentProfileAccessSetting
  | ProductTourSetting
  | RestrictedReportHistorySetting
  | ShowCopyrightDisclaimerSetting
  | SimilarSettingVisibleSetting
  | StatisticsEnableEacAndCirculationSetting
  | SuggestserverSortingSetting
  | UploadArticlesAsCustomerSetting
  | GtQuotaSetting
  | HideTagsSetting

export type SettingsInterfaces = UniversalSettings | OptionalSettings

export type SettingNames = SettingsInterfaces['name']
type SettingsThatHaveChoices = Extract<SettingsInterfaces, SettingWithChoices<any>>

export type SettingsResponse = SettingsInterfaces[]
// Omit choices from save request payload
export type SettingsSaveRequestPayload = Omit<SettingsResponse[number], 'choices'>[]

export type SettingsStateList = {
  [K in UniversalSettings['name']]: Extract<UniversalSettings, { name: K }>['value']
} & {
  [K in OptionalSettings['name']]?: Extract<OptionalSettings, { name: K }>['value']
}

export type SettingsStateChoices = {
  [K in SettingsThatHaveChoices['name']]: Extract<SettingsThatHaveChoices, { name: K }>['choices']
}

export type AppUISettings = AppUISettingsSetting['value']

// Enum values for settings
export enum AllowedQueryLanguageValues {
  OnlyOQL = 0,
  OnlyIQL = 1,
  DefaultOQL = 2,
  DefaultIQL = 3,
}

export enum ProfileEditorAccessValues {
  OnlyLegacy = 0,
  OnlyNew = 1,
  Both = 2,
}
