import { produce } from 'immer'

import { AppActions } from '../actions'
import { Filter, FilterExtended } from '../opoint/flow'
import { filterId } from '../opoint/search/index'

export type EntityRepositoryState = {
  filters: Record<string, Filter | FilterExtended>
}

export const initialState: EntityRepositoryState = {
  filters: {},
}
const entityRepositoryReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'SEARCHFILTER_ADDED':
    case 'SEARCHFILTER_TOGGLED': {
      const filter = action.payload
      const filterKey = filterId(filter)
      draftState.filters[filterKey] = filter

      break
    }

    case 'FETCH_FILTER_DETAIL_SUCCESS': {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((filter) => {
          const filterKey = filterId(filter)
          draftState.filters[filterKey] = filter
        })
      }

      break
    }

    case 'REFRESH_FILTERS_NAME': {
      draftState.filters = {}

      break
    }

    default:
      break
  }
}, initialState)

export default entityRepositoryReducer
