import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, switchMap, takeUntil } from 'rxjs/operators'

import { append } from 'ramda'
import { AppActions } from '../actions'
import { ClearArticlesAction, FetchArticlesSuccessAction } from '../actions/articles'
import {
  UpdateCurrentWatchIdAction,
  UpdateWatchIdIndexesAction,
  UpdateWatchIndexesFailureAction,
  UpdateWatchIndexesMappingAction,
  UpdateWatchIndexesSuccessAction,
} from '../actions/watchIndex'
import { PROFILES_TO_WATCH_IDS_KEY } from '../constants/localStorage'
import { isProfileSearch } from '../opoint/search'
import { updateWatchIndexes } from '../opoint/watchIndex'
import { RootState } from '../reducers'
import { getWatchQueries } from '../selectors/watchIndexSelectors'

import config from '../opoint/common/config'
import { getLocalStorageItem, setLocalStorageItem } from '../helpers/localStorage'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

export const updateWatchIndexesEpic = (
  action$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) =>
  action$.pipe(
    ofType<AppActions, UpdateWatchIdIndexesAction>('UPDATE_WATCH_INDEXES'),
    switchMap(({ payload: { watchId, userId } }) => {
      const state = state$.value

      // @ts-expect-error: Muted so we could enable TS strict mode
      const profilesToWatchIdsByUser = JSON.parse(getLocalStorageItem(PROFILES_TO_WATCH_IDS_KEY)) ?? {}

      const watchQueries = Object.keys(getWatchQueries(state))
      const profilesToWatchIds = profilesToWatchIdsByUser[userId] ?? {}
      const profilesWatchIds = Object.values(profilesToWatchIds)

      const allWatchedItems = [...new Set([...watchQueries, ...profilesWatchIds])] as string[]

      const watchIds =
        watchId && !allWatchedItems.includes(watchId) ? append(watchId, allWatchedItems) : allWatchedItems

      if (!watchIds.length) {
        return of()
      }

      return from(updateWatchIndexes(watchIds)).pipe(
        switchMap((watchQueries) =>
          of<UpdateWatchIndexesSuccessAction>({
            type: 'UPDATE_WATCH_INDEXES_SUCCESS',
            payload: { watchQueries },
          }),
        ),
        catchError(logOutOnExpiredToken),
        catchError(serverIsDown),
        //TODO:this is unused action https://infomediacorp.atlassian.net/browse/FE-10354
        catchError(() => of<UpdateWatchIndexesFailureAction>({ type: 'UPDATE_WATCH_INDEXES_FAILURE' })),
        takeUntil(action$.pipe(ofType<AppActions, ClearArticlesAction>('CLEAR_ARTICLES'))),
      )
    }),
  )

const addWatchIndexesEpic = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, FetchArticlesSuccessAction>('FETCH_ARTICLES_SUCCESS'),
    switchMap(
      ({
        payload: {
          response: {
            searchresult: { watch_id: watchId },
          },
          searchItem,
        },
      }) => {
        // If search for some reason didn't return watch ID, don't update the store
        if (!watchId) {
          return of()
        }

        // @ts-expect-error: Muted so we could enable TS strict mode
        return from(config.auth?.getUser()).pipe(
          switchMap((user) => {
            // @ts-expect-error: Muted so we could enable TS strict mode
            const profileId = isProfileSearch([searchItem])
            // We update watch ID as a string so we have consistent data types
            // for watch ids throughout the store
            const watchIdString = watchId.toString()

            if (profileId && user) {
              // @ts-expect-error: Muted so we could enable TS strict mode
              const profilesToWatchIdsByUserId = JSON.parse(getLocalStorageItem(PROFILES_TO_WATCH_IDS_KEY))
              const profileWithWatchId = profilesToWatchIdsByUserId?.[user.user_id]?.[profileId]

              if (!profileWithWatchId || profileWithWatchId !== watchIdString) {
                setLocalStorageItem(
                  PROFILES_TO_WATCH_IDS_KEY,
                  JSON.stringify({
                    ...profilesToWatchIdsByUserId,
                    [user.user_id]: {
                      ...profilesToWatchIdsByUserId?.[user.user_id],
                      [profileId]: watchIdString,
                    },
                  }),
                )
              }
            }

            return profileId
              ? of<UpdateCurrentWatchIdAction | UpdateWatchIndexesMappingAction>(
                  { type: 'UPDATE_CURRENT_WATCH_ID', payload: { watchId: watchIdString } },
                  { type: 'UPDATE_WATCH_INDEXES_MAPPING', payload: { profileId, watchId: watchIdString } },
                )
              : of<UpdateCurrentWatchIdAction>({
                  type: 'UPDATE_CURRENT_WATCH_ID',
                  payload: { watchId: watchIdString },
                })
          }),
        )
      },
    ),
  )

export default [addWatchIndexesEpic, updateWatchIndexesEpic]
