import { cn } from '@opoint/infomedia-storybook'
import { ButtonHTMLAttributes, forwardRef } from 'react'

export const UserPopoverItem = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
  ({ type = 'button', className, ...props }, ref) => (
    <button
      ref={ref}
      type={type}
      className={cn(
        'flex h-auto w-full min-w-40 max-w-[80vw] cursor-pointer items-center justify-start gap-3 px-5 py-2 text-left text-sm text-sky.1 no-underline hover:bg-grey.7 focus:outline-none focus:ring-0 focus-visible:bg-grey.7',
        className,
      )}
      {...props}
    />
  ),
)
UserPopoverItem.displayName = 'UserPopoverItem'
