import { NAVIGATION_PROFILES_MULTIPLE_KEY } from '../../../../../constants/localStorage'
import { getSelectedTagIds } from '../../../../../selectors/searchSelectors'
import { getTags } from '../../../../../selectors/tagsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { SearchFilterKey } from '../../../../hooks/useSearchFilters'
import useSearchRouteBuilder from '../../../../hooks/useSearchRouteBuilder'
import { useNavigationContext } from '../../NavigationContext'
import { NavigationItemMultiple } from '../../primitives/NavigationItemMultiple'
import {
  NavigationSectionContent,
  NavigationSectionEmptySearchMessage,
  NavigationSectionSearchItem,
  NavigationSectionSearchItemLink,
} from '../../primitives/NavigationSection'

export const TagsNavigationList = () => {
  const tags = useAppSelector(getTags)
  const selectedItemsIds = useAppSelector(getSelectedTagIds)
  const [isMultiple] = useLocalStorage(NAVIGATION_PROFILES_MULTIPLE_KEY, false)

  const { getSearchRoute } = useSearchRouteBuilder()
  const { searchQuery } = useNavigationContext()

  if (!searchQuery) {
    return null
  }

  const searchTags = tags.filter(({ name }) => name.toLowerCase().includes(searchQuery.toLowerCase()))

  return (
    <NavigationSectionContent>
      {!searchTags.length ? (
        <NavigationSectionEmptySearchMessage />
      ) : (
        searchTags.map((tag) => (
          <>
            {isMultiple ? (
              <NavigationItemMultiple
                key={tag.id}
                item={tag}
                type={SearchFilterKey.TAG}
                selectedItemsIds={selectedItemsIds}
                searchMode
              />
            ) : (
              <NavigationSectionSearchItem key={tag.id}>
                <NavigationSectionSearchItemLink to={getSearchRoute(SearchFilterKey.TAG, tag.id)}>
                  {tag.name}
                </NavigationSectionSearchItemLink>
              </NavigationSectionSearchItem>
            )}
          </>
        ))
      )}
    </NavigationSectionContent>
  )
}
