import { Dismiss24Regular } from '@fluentui/react-icons'
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@opoint/infomedia-storybook'

import { useNavigate } from 'react-router-dom'
import { ActionBarDropdownMenuItem } from '../../ActionBarDropdownMenuItem'
import { ActionBarActionsProps, ActionVariant } from '../../types'

const AlertCancelAction = ({ variant = ActionVariant.LONG, name }: ActionBarActionsProps) => {
  const navigate = useNavigate()
  const onClickAction = () => navigate(-1)

  if (variant === ActionVariant.MENU) {
    return (
      <ActionBarDropdownMenuItem onClick={onClickAction} data-cy="alertCancelBtn">
        <Dismiss24Regular />
        {name}
      </ActionBarDropdownMenuItem>
    )
  }

  if (variant === ActionVariant.LONG) {
    return (
      <Button variant="outline" onClick={onClickAction} data-cy="alertCancelBtn">
        <Dismiss24Regular />
        {name}
      </Button>
    )
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="outline" size="icon" onClick={onClickAction} data-cy="alertCancelBtn">
          <Dismiss24Regular />
          <span className="sr-only">{name}</span>
        </Button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{name}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}

export default AlertCancelAction
