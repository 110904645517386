import { CommonRecipientFilter, ContactFilter } from '../components/types/contact'
import { isContact } from '../guards/isContact'
import { getContactFilterName } from '../opoint/contacts'
import { AlertShortRecipientAPI } from '../opoint/flow'

/**
 * Checks if the domain of the provided email is in the list of allowed domains.
 *
 * @param email The email address to be checked.
 * @param allowedDomains A string representing a regular expression of allowed domains.
 * @returns A boolean indicating whether the domain of the email is in the allowed list.
 * If the email does not contain a domain (i.e., does not contain an '@' symbol), the function returns false.
 */
export const isEmailDomainAllowed = (email: string, allowedDomains: string): boolean => {
  const [, domain] = email.split('@')

  if (!domain) {
    return false
  }

  return new RegExp(allowedDomains).test(domain)
}

/**
 * Checks if the given recipients list contains any entries from disallowed email domains.
 *
 * @param recipients The array of recipients to check.
 * @param allowedDomains A string representation of a regular expression that defines allowed email domains.
 * @returns A boolean value that is 'true' if any recipient in the list is from a disallowed email domain,
 *          and 'false' otherwise.
 */
export const includesDisallowedEmailRecipients = (
  recipients: (Pick<AlertShortRecipientAPI, 'type'> & {
    value?: string
  })[],
  allowedDomains: string,
): boolean => {
  // Filter email type recipients
  const emailRecipients = recipients.filter(({ type }) => type === 'email')

  // @ts-expect-error: Muted so we could enable TS strict mode
  return emailRecipients.some(({ value }) => !isEmailDomainAllowed(value, allowedDomains))
}

export const getFullName = ({ entity }: ContactFilter) => {
  if ('firstName' in entity) {
    return `${entity.firstName} ${entity.lastName}`
  } else {
    // Handle the case when entity is of type Group (no firstName)
    return entity.name
  }
}

// Email may start with plus and be separated by ", " (comma, space) or " " (space)
const emailRegExp =
  /^((([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})(,? )?)+$/i
// Phone number must be minimum 7 digit long
const phoneNumberContactRegExp = /^(\+?\d{7,} ?)+$/

type FilterSuggestionsArgs = {
  suggestions: Array<ContactFilter>
  searchterm: string
  isEmailInput: boolean
}

const isEmailValid = (email: string) => emailRegExp.test(email)
const isPhoneNumberValid = (number: string) => phoneNumberContactRegExp.test(number)
/**
 * Filters an array of contact filters based on the provided search term and contactsPath.
 *
 * @function
 * @param {FilterSuggestionsArgs} args - An object containing the arguments for filtering the contact suggestions.
 * @param {Array<ContactFilter>} args.suggestions - An array of contact filters that need to be filtered based on the search term.
 * @param {string | undefined} args.searchterm - The search term to filter the contact suggestions.
 * @param {ContactsPath} args.contactsPath - The path indicating the type of contact path to filter.
 * @returns {Array<CommonRecipientFilter | ContactFilter>} - An array of filtered custom suggestions (if any) and contact filters.
 */
export const filterSuggestions = ({
  suggestions,
  searchterm,
  isEmailInput,
}: FilterSuggestionsArgs): Array<CommonRecipientFilter | ContactFilter> => {
  const searchWord = searchterm?.trim()

  // Filter contact suggestions based on the search term and suggestion type
  const contactSuggestions = suggestions?.filter((contactFilter) => {
    const loverCaseSearchWord = searchWord?.toLowerCase()

    // Check if the contact filter name contains the search term in a case-insensitive manner
    // @ts-expect-error: Muted so we could enable TS strict mode
    const isContactName = getContactFilterName(contactFilter).toString().toLowerCase().includes(loverCaseSearchWord)

    if (contactFilter.type === 'person') {
      if (isEmailInput) {
        // Check if the contact has a valid email address
        // @ts-expect-error: Muted so we could enable TS strict mode
        if (isContact(contactFilter.entity) && contactFilter.entity.email) {
          // Check if the email address contains the search term in a case-insensitive manner
          const containsEmail = contactFilter.entity.email.toLowerCase().includes(loverCaseSearchWord)

          return isContactName || containsEmail
        }

        return false
      }

      // @ts-expect-error: Muted so we could enable TS strict mode
      if (isContact(contactFilter.entity) && contactFilter.entity.mobile) {
        const containsNumber = contactFilter.entity.mobile.toString().includes(loverCaseSearchWord)

        return isContactName || containsNumber
      }

      return false
    }

    return isContactName
  })

  // Append custom email/sms suggestion if search term is a valid email/sms
  if (isEmailInput ? isEmailValid(searchWord) : isPhoneNumberValid(searchWord)) {
    return [
      { type: isEmailInput ? 'email' : 'phoneNumber', entity: { value: searchWord, id: searchWord } },
      ...contactSuggestions,
    ]
  }

  return contactSuggestions
}

export const getContactFilterId = (filter: ContactFilter | CommonRecipientFilter) =>
  `${filter.type}_${filter.entity?.id || 0}`
