import { EditedFolderType, Folder, ShareableUsers } from '../../components/types/folder'
import { handleErrors } from '../common'
import config from '../common/config'

/**
 * Retrieves folders from API and transforms them into a Promise.
 * @returns {*}
 */
export const getFolders: () => Promise<Array<Folder>> = async () => {
  // Query on how to fetch specific type and if the folders are owned or not
  // /folders/?type=0&owner=true

  const url = config.url.api(`/folders/`)

  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const createNewFolder: (body: {
  description: string
  id: number | null
  name: string
  traits: string | number
  type: string | number
}) => Promise<Folder> = async (body) => {
  const method = 'POST'
  const url = config.url.api('/folders/')

  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method,
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const reorderFolderPosition: (body: { id: number; position: number }) => Promise<Response> = async (body) => {
  const url = config.url.api(`/folders/sort/`)

  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(body),
  })

  return fetch(request).then(handleErrors)
}

export const deleteFolder: (id: number) => Promise<Response> = async (id) => {
  const url = config.url.api(`/folders/${id}/`)

  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'DELETE',
  })

  return fetch(request).then(handleErrors)
}

export const getSpecificFolder: (folderId: number) => Promise<Folder> = async (folderId) => {
  const url = config.url.api(`/folders/${folderId}/`)

  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const updateFolder: (payload: { body: EditedFolderType; id: number }) => Promise<Folder> = async ({
  body,
  id,
}) => {
  const url = config.url.api(`/folders/${id}/`)

  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'PATCH',
    body: JSON.stringify(body),
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}

export const getFolderShares: (searchTerm: string) => Promise<ShareableUsers> = async (searchTerm) => {
  const url = config.url.api(`/folders/shares/targets/search/?q=${searchTerm}`)

  const request = new Request(url, {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
}
