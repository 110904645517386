import { Tag24Regular } from '@fluentui/react-icons'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import { t } from "i18next";
import { NavigationComponentProps, NavigationSectionKey } from '../../../../../helpers/navigation'
import { getDefaultTag } from '../../../../../selectors/tagsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../../hooks/useDefaultHomeUrl'
import { SearchFilterKey } from '../../../../hooks/useSearchFilters'
import useSearchRouteBuilder from '../../../../hooks/useSearchRouteBuilder'
import { GeneralIntroProductTourTarget } from '../../../../productTour/ProductTours/GeneralIntroProductTour'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'
import { TagsNavigationList } from './TagsNavigationList'

export const TagsNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => {
    const defaultTag = useAppSelector(getDefaultTag)
    const defaultHomeUrl = useDefaultHomeUrl()
    const { getSearchRoute } = useSearchRouteBuilder()

    return (
      <NavigationSection id={GeneralIntroProductTourTarget.STEP_3}>
        <NavigationSectionHeaderLink
          label={t('Tags')}
          isExpanded={Boolean(isExpanded)}
          ref={ref}
          to={defaultTag?.id ? getSearchRoute(SearchFilterKey.TAG, defaultTag.id) : defaultHomeUrl}
          section={NavigationSectionKey.TAGS}
          data-cy="tagsBtn"
          {...props}
        >
          <Tag24Regular className="w-7 shrink-0 text-sand.4" />
        </NavigationSectionHeaderLink>

        <TagsNavigationList />
      </NavigationSection>
    )
  },
)
TagsNavigationSection.displayName = 'TagsNavigationSection'
