import { toast } from '@opoint/infomedia-storybook'
import { format, isSameDay } from 'date-fns'
import { uniq, values } from 'ramda'

import { t } from "i18next";
import { LinemodeEnum } from '../api/opoint.schemas'
import { CreateFolderState } from '../components/folders/FolderEditor/FoldersCreateAndEdit/types'
import { ProfileItem } from '../components/profile/builder/types'
import { EditedFolderType, Folder } from '../components/types/folder'
import { Profile } from '../components/types/profile'
import { Tag } from '../components/types/tag'
import { User } from '../components/types/user'
import { LOGOS } from '../constants'
import { ECB_DOMAINS } from '../constants/ecbDomains'
import config from '../opoint/common/config'
import { SearchItem } from '../opoint/flow'

// TODO: Investigate whether this logic is necessary. If possible, simplify or remove it entirely.
// https://infomediacorp.atlassian.net/browse/FE-10085
export const getLogoUrl = (host: string, user: User, portalLogo: string) => {
  let returnLogo = LOGOS.fallback

  // portalLogo has absolute path
  if (user && portalLogo) {
    returnLogo = portalLogo
  }

  // return relative path from LOGOS or fallback logo in case of undefined
  if (!user || !portalLogo) {
    returnLogo = LOGOS[host] ? config.url.api(LOGOS[host]) : LOGOS.fallback
  }

  if (returnLogo.endsWith('m360.svg')) {
    return LOGOS.fallback
  }

  return returnLogo
}

export const isEcbDomain = () => ECB_DOMAINS.includes(window?.location?.hostname)

/**
 * Sorts an array of object by a single criteria
 * @param arr
 * @param sortBy
 * @param toLowerCase
 * @returns Array<any>
 */
export const simpleSort = (arr: any[], sortBy: string, toLowerCase?: boolean) => {
  return arr.sort((a, b) => {
    let aVal = a[sortBy]
    let bVal = b[sortBy]

    if (toLowerCase) {
      aVal = aVal.toLowerCase()
      bVal = bVal.toLowerCase()
    }

    if (aVal < bVal) {
      return -1
    }
    if (aVal > bVal) {
      return 1
    }

    return 0
  })
}

/**
 * Utility function for finding all children in a given tree for the specified node
 * @typedef NodeWithChildrenType
 * @param node - tree node (must have children and id)
 * @returns Array<number>
 */

/** @type {NodeWithChildrenType} */
export type NodeWithChildrenType = {
  children: Array<NodeWithChildrenType>
  id: number
}

export const findAllChildren = (node: NodeWithChildrenType) => {
  let res = []
  if (node.children.length === 0) {
    return [node.id]
  }
  node.children.forEach((child) => {
    // @ts-expect-error: Muted so we could enable TS strict mode
    res = res.concat(findAllChildren(child))
  })

  // @ts-expect-error: Muted so we could enable TS strict mode
  return res.concat([node.id])
}

export function scrollElementToTop(className: string) {
  const scrollElement = document.querySelector(className)
  if (scrollElement !== null) {
    scrollElement.scroll({ top: 0, behavior: 'smooth' })
  }
}

/**
 * Converts a large number to a shorter tuple, e.g. 23548 will be converted into ['24', 'k.'] and 1343872 into ['1.34', 'mill.']
 * @param {number} num - Large number to get converted.
 * @returns Return a tuple of the formatted number and it's suffix
 */
export const formatLargeNumber = (num: number): [string, 'mill.' | 'k.' | ''] => {
  if (num > 1e6) {
    return [(num / 1e6).toFixed(num > 1e7 ? 1 : 2), 'mill.']
  } else if (num > 1e4) {
    return [(num / 1e3).toFixed(1), 'k.']
  } else {
    return [num?.toString(), '']
  }
}

export const mergedPrevAndCurrentPeriod = (currentPeriod, prevPeriod) => {
  const documentsCurrent = documentsAddPeriod(currentPeriod, 'current')
  const documentsPrevious = documentsAddPeriod(prevPeriod, 'previous')

  const mergedPeriods = [documentsCurrent, documentsPrevious]

  return mergedPeriods.flat()
}

const documentsAddPeriod = (period, periodName) => {
  period?.map((element) => {
    return (element['period'] = periodName)
  })

  return period
}

/**
 * Debounce delays triggering of the given function, using a setTimeout.
 * @param func The function that needs to be delayed
 * @param timeout Milliseconds, the function is delayed by
 */
export const debounce = (func, timeout) => {
  let timer

  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func?.apply(this, args)
    }, timeout)
  }
}

/**
 * Offer the function a unix time stamp, and it will tell you if it's within this year or not.
 * @param unix Unix time stamp
 * @returns {boolean} True or false.
 */
export const isCurrentYear = (unix: number) => {
  const currentYear = new Date().getFullYear()
  const yearFromUnix = new Date(unix).getFullYear()

  return yearFromUnix === currentYear
}

export const isSameDate: (startDate: Date | number, endDate: Date | number) => boolean = (startDate, endDate) =>
  isSameDay(startDate, endDate)

/**
 * Changing the timePeriod filter to only show time, if a specific time has been chosen by the user.
 * @param filter
 * @returns {String} Returns a string with or without time, depending on period chosen.
 */
export const alterTimeperiod = (filter) => {
  const startOfDay = '00:00'
  const endOfDay = '23:59'
  const splittedFilter = filter && filter?.split('-')
  let startDate = splittedFilter[0]?.split(' ').filter(Boolean) // Splits the string and removes empty ones from array.
  let endDate = splittedFilter[1]?.split(' ').filter(Boolean) // Splits the string and removes empty ones from array.

  // Reduced into one function, because endDate and startDate are being handled the exact same way.
  const displayDate = (date: any[], timeOfDay: string) => {
    const displayYear = !isCurrentYear(+new Date(date?.join(' ')))

    if (date && date.includes(timeOfDay)) {
      // If startOfDay is included in the string then show the date without time.
      if (date[0] === timeOfDay) {
        // If only the time is shown, display only that.
        return date[0]
      } else {
        return `${date[0]} ${date[1]} ${date.length > 3 && displayYear ? date[2] : ''}`
      }
    } else {
      // In cases where dates are spans across multiple years
      if (displayYear) {
        // Join the array of strings and show the full date (With time, and year if it's the case).
        return date && date.join(' ')
      } else {
        // Otherwise remove the index, where year appears, and then join and display the date.
        date.splice(2, 1).join(' ') // Removes year from index 2.

        return date.join(' ') // Join date without year.
      }
    }
  }

  startDate = displayDate(startDate, startOfDay)
  endDate = displayDate(endDate, endOfDay)

  if (startDate === endDate) {
    return startDate
  } else {
    return `${startDate ? startDate : ''} ${endDate ? '- ' + endDate : ''}`
  }
}

/**
 * Getting the timePeriod from searchFilter and converting it into values that can be used to decide, which data should be displayed in the listingBar.
 * @param timeFilter
 * @returns {Object} Object which holds data for comparison of startDate and endDate
 */
export const getTimeperiodAndConvert = (timeFilter) => {
  // Get timePeriod filter
  const chosenPeriod = values(timeFilter).filter((filter) => filter.type === 'timePeriod')
  const exist = chosenPeriod.length > 0
  const splitPeriods = exist && chosenPeriod[0].id.split('-')
  const startDateIsToday = format(new Date(splitPeriods[0] * 1000), 'dd-MM-yy') === format(new Date(), 'dd-MM-yy')

  const convertedIntoHoursAndMinutes =
    exist &&
    chosenPeriod[0].id.split('-')?.map((period) => {
      const date = new Date(+period * 1000)

      return `${date.getHours()}:${date.getMinutes()}`
    })

  return {
    start: { time: convertedIntoHoursAndMinutes[0], today: startDateIsToday },
    end: { time: convertedIntoHoursAndMinutes[1] },
  }
}

/**
 * If more than one tag is selected, run this function to convert the filters into a search query.
 *
 * The backend does not support multiple tags, in the form of a filter.
 * @param filters Array of filters
 * @returns {string} Returns a search query
 */
const convertToSearchQuery = (filters: any[]) => {
  const convertToQuery = filters
    ?.map((filter) => {
      return `${filter.type}:${filter.id}`
    })
    .join(' OR ')

  return convertToQuery
}

/**
 * If multiple tags are chosen, we will handle it here, and convert the filters into a search query.
 * @param searchline
 * @returns {string} Returns a search query
 */
export const multipleTagsHandling = (searchline) => {
  let tempSearchLine
  const tagsQuery = convertToSearchQuery(searchline.filters)

  tempSearchLine = { filters: [] }

  if (searchline.searchterm) {
    tempSearchLine = { searchterm: tagsQuery + ' AND ' + searchline.searchterm }
  } else {
    tempSearchLine = { searchterm: tagsQuery }
  }

  return tempSearchLine
}

/**
 * Reducing duplicate snippets into one object with multiple matches.
 * @returns Array of objects containing snippets, multiple matches and seconds.
 */
export const reduceDuplicateSnippetOccurrence = (mediaMatches: any[]) => {
  // Finding duplicate snippets by mapping and filtering
  // This will generate duplicate objects, that we'll be removing below this map.
  const duplicateSnippets = mediaMatches?.map((match) => {
    const seconds = match.play_offset
    const snippet = match?.quote?.text

    const matchArr = []
    const temp = mediaMatches.filter((m) => m.quote?.text === snippet)

    // There might be matches without snippets, but with an play_offset that varys from seconds to minutes.
    // In those cases we would like to show each match with it's different offset, to show the user when it's mentioned.
    if (snippet) {
      temp?.map((m) => {
        // @ts-expect-error: Muted so we could enable TS strict mode
        matchArr.push(m.text)
      })
    } else {
      // @ts-expect-error: Muted so we could enable TS strict mode
      matchArr.push(match.text)
    }

    return { matches: matchArr, seconds, snippet, video_id: match.video_id }
  })

  // Reducing the duplicate objects by comparing snippets.
  const uniqueArray = duplicateSnippets.filter((v, i, a) => {
    // This check is due to what's mentioned above, with the other snippet check.
    // If no snippet, just return the match.
    if (v.snippet) {
      return a.findIndex((t) => t.snippet === v.snippet) === i
    } else {
      return v
    }
  })

  return uniqueArray
}

/**
 * Deeply flattens an array by recursively extracting the children.
 * @param items Items containing children.
 * @returns Returns a single array containing nested childrens.
 */
export const flatten = (items) => {
  const flat = []

  items?.forEach((item) => {
    if (Array.isArray(item.children) && item.children.length > 0) {
      flat.push(...flatten(item.children))
    }
    // @ts-expect-error: Muted so we could enable TS strict mode
    flat.push(item)
  })

  return flat
}

/**
 * Orders the suggestions so that we're able to select them in correct order.
 * @param folders Folders with children
 * @param suggestions
 * @returns Returns an ordered suggesion array based on the folders children
 */
export const orderedSuggestions = (folders, suggestions: (Profile | Tag)[]): (Profile | Tag)[] => {
  const folderChildren = folders
    ?.map((folder) => folder.children)
    .flat()
    ?.map((context) => context.id)

  const sortedSuggestions = suggestions.sort((a: any, b: any) => {
    return folderChildren.indexOf(a.id) - folderChildren.indexOf(b.id)
  })

  return sortedSuggestions
}

/**
 * Groups array by the giving key
 * @param key String to define which prop it should be grouped by
 */
export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)

    return rv
  }, {})
}

/* Checks if a tag or profile has been chosen in the "Required search" and "Include search" fields, that are active.
 * @param items Active search fields
 * @returns {boolean} true/false
 */
export const tagOrProfileChosen = (items: ProfileItem[] | SearchItem[] | undefined) => {
  const includesTagOrProfile = (items: ProfileItem[] | SearchItem[] | undefined, linemode: LinemodeEnum) => {
    const searchMode = items?.filter((item) => [linemode].includes(item.linemode))
    const boolArr =
      searchMode?.map((item) => {
        switch (true) {
          case item.searchline.filters.some((filter) => ['tag', 'profile'].includes(filter.type)):
          case item.searchline.filters.some((filter) => filter.type.includes('basket')):
          case item.searchline.searchterm?.includes('basket:'):
            return true
          default:
            return false
        }
      }) ?? []

    const uniqBoolArr = uniq(boolArr)

    if (uniqBoolArr.length === 1 && uniqBoolArr[0] === true) {
      return true
    } else {
      return false
    }
  }

  const requiredTagOrProfileChosen: boolean = includesTagOrProfile(items, 'R')

  const includeTagOrProfileChosen: boolean = includesTagOrProfile(items, 'O')

  return requiredTagOrProfileChosen || includeTagOrProfileChosen
}

export const copiedToClipBoardNotification = () =>
  // @ts-expect-error: Muted so we could enable TS strict mode
  toast({ title: t('Content copied to clipboard'), variant: 'success', toastId: 'COPY_TO_CLIPBOARD' })

export const capitalizeFirstLetter = (string) => string[0].toUpperCase() + string.slice(1)

export const isCustomerViewOpen: () => boolean = () => window.location.pathname === '/customer_view/'

/**
 * Creates an object where the keys are determined by a specific property
 * of the objects in the input array.
 *
 * @param collection - The input array of objects to be indexed.
 * @param iteratee - A function that extracts a key from each object in the array.
 * @returns An object where the keys are the values returned by `callback` for each item,
 * and the values are the corresponding items from the array.
 */
export function keyBy<T, K extends string | number>(collection: T[], iteratee: (item: T) => K): Record<K, T> {
  return collection.reduce((result, item) => {
    const key = iteratee(item)
    result[key] = item

    return result
  }, {} as Record<K, T>)
}

/**
 * Retrieves keys from a dictionary based on the matching values in the search array.
 *
 * @param searchArray - An array of values to search for in the dictionary.
 * @param dictionary - A dictionary where keys are numbers and values are corresponding items to search for.
 * @returns An array of keys from the 'dictionary' that match the provided values in the search array.
 */
export const getKeysByMatchingValues = (searchArray: string[], dictionary: Record<number, string>): number[] => {
  const keys: number[] = []
  for (const key in dictionary) {
    if (searchArray.includes(dictionary[key])) {
      keys.push(Number(key))
    }
  }

  return keys
}

const arraysEqual = (a: Array<any>, b: Array<any>): boolean => {
  if (a.length !== b.length) {
    return false
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false
    }
  }

  return true
}

export const handleEditChanges: (payload: {
  oldValues: CreateFolderState | Folder
  newValues: CreateFolderState
}) => EditedFolderType = ({ oldValues, newValues }) => {
  const changes = Object.entries(newValues).reduce((acc, [key, value]) => {
    if (value.constructor === Array) {
      if (!arraysEqual(oldValues[key], value)) {
        acc[key] = value
      }
    } else if (oldValues[key] !== value) {
      acc[key] = value
    }

    return acc
  }, {})

  return changes
}

export const isEmptyObject = (obj?: Record<string, unknown> | null) => {
  if (!obj) {
    return true
  }
  return obj.constructor === Object && Object.keys(obj).length === 0
}
