import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export enum SearchFilterKey {
  PROFILES = 'profile',
  TAG = 'tag',
  BASKET = 'basket',
  CHART = 'chart',
  TRASH = 'trash',
  TIME_PERIOD = 'timePeriod',
  SITE = 'site',
  RANK = 'rank',
  GEO = 'geo',
  LANG = 'lang',
  COV = 'cov',
  MEDIA = 'media',
  CONTENT = 'content',
  ENT = 'ent',
  TOPIC = 'topic',
  ALERT_ID = 'alertId',
  ECB_SENTIMENT = 'basket212',
  ECB_RANK = 'basket213',
  ECB_TOPIC = 'basket214',
  ECB_VOICE = 'basket215',
}

export type UseSearchFiltersResult = {
  type: SearchFilterKey
  inverted: boolean
  id: string
}[]

export const useSearchFilters = (filterKey?: SearchFilterKey): UseSearchFiltersResult => {
  const location = useLocation()
  const filtersString = new URLSearchParams(location.search).get('filters')
  return useMemo(() => {
    if (!filtersString) {
      return []
    }

    const filters = filtersString
      .split(';')
      .map((filterString) => {
        const [type, id] = filterString.split(':')

        const inverted = type.startsWith('-')

        return {
          type: (inverted ? type.replace('-', '') : type) as SearchFilterKey,
          inverted,
          id,
        }
      })
      .filter((filter) => !!filter.id && Object.values(SearchFilterKey).includes(filter.type))

    return filterKey ? filters.filter((filter) => filter.type === filterKey) : filters
  }, [filtersString, filterKey])
}

export const useSearchFiltersWithoutPeriod = () => {
  const filters = useSearchFilters()
  return filters.filter(({ type }) => type !== SearchFilterKey.TIME_PERIOD).filter(Boolean)
}
