import { useMemo } from 'react'

import { ArticleSpecialStatsKey } from '../types/article'
import { Folder } from '../types/folder'
import { Tag } from '../types/tag'

const useSpecialTags = (
  allTags,
  articleTags,
  foldersTree: Array<Folder>,
  folderName?: ArticleSpecialStatsKey,
  multipleFoldersTrait?: number | null,
) => {
  const specialTags = useMemo(() => {
    const getTaggedTags = (folderChildren) => {
      return allTags?.filter(
        (tag) => articleTags?.includes(`${tag.id}`) && folderChildren?.some((t: Tag) => t.id === tag.id),
      )
    }

    if (multipleFoldersTrait) {
      const specialFolders = foldersTree.filter((folder: Folder) => folder?.traits === multipleFoldersTrait)
      const specialFoldersChildrens = specialFolders.flatMap(({ children }) => children)

      return getTaggedTags(specialFoldersChildrens)
    } else {
      // TODO: use folder traits when available
      const specialFolder = foldersTree.find((folder: Folder) => folder.name === folderName)

      return getTaggedTags(specialFolder?.children)
    }
  }, [allTags, articleTags, folderName, foldersTree, multipleFoldersTrait])

  return specialTags
}

export default useSpecialTags
