import { createSelector } from 'reselect'

import { getFeeds as getState } from './rootSelectors'

export const getFeeds = createSelector(getState, (feeds) => feeds.list)

export const isLoading = createSelector(getState, (feeds) => feeds.loading)

export const feedsFailed = createSelector(getState, (feeds) => feeds.failed)

export const getFirstFeed = createSelector(getFeeds, (feeds) => feeds?.[0])

export const getEditedFeed = createSelector(getState, (feeds) => feeds.activeFeed)

export const getFeedById = (feedId: string) =>
  createSelector(getFeeds, (feeds) => feeds.find(({ id }) => id === feedId))

export const getFeedUrlById = (feedId: string) => createSelector(getFeedById(feedId), (feed) => feed && feed.url)
