import {
  CollectionsAdd24Regular,
  DocumentBulletList24Regular,
  Edit24Regular,
  Flag24Regular,
  Link24Regular,
  MoreHorizontal24Filled,
  Share24Regular,
  StackStar24Regular,
  Tag24Regular,
  Translate24Regular,
} from '@fluentui/react-icons'
import {
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  isSocialArticle,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@opoint/infomedia-storybook'
import { Trans, useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash-es'
import { MouseEvent, useRef, useState } from 'react'

import { copiedToClipBoardNotification } from '../../../../../helpers/common'
import { shouldShowTag } from '../../../../../helpers/tags'
import { getAllTagLikeEntities } from '../../../../../selectors/tagsComposedSelectors'
import { MODULES } from '../../../../constants/permissions'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import useGetFoldersByTrait from '../../../../hooks/useGetFoldersByTrait'
import useIsECBUser from '../../../../hooks/useIsECBUser'
import useIsMobile from '../../../../hooks/useIsMobile'
import useIsUserPermitted from '../../../../hooks/useIsUserPermitted'
import { TabType } from '../../../../settings/navigation/SettingsNavigation'
import OverflowTagsPopover from '../../../../tags/OverflowTagsPopover'

import { categorizationFolderTraitsToShow } from '../../Categorization'
import CategorizationModal from '../../Categorization/CategorizationModal'

import { getSelectedProfilesIds } from '../../../../../selectors/searchSelectors'
import { Tag } from '../../../../types/tag'

import { getArticleEditAccess, getNewPortalSupport } from '../../../../../selectors/settingsSelectors'
import { M360Article } from '../../../../../opoint/articles/types'

export interface ArticleActionButtonsProps {
  article: M360Article
  isListLayout?: boolean
  sharingDisabled?: boolean
  simpleView?: boolean
  translation?: {
    onTranslateClick: () => void
    isTranslated: boolean
    language: string
  }
  shouldShowRequestSummary?: boolean
  stickyMode?: boolean
  isAlert?: boolean
}

const ArticleActionButtons = ({
  article,
  isListLayout,
  sharingDisabled,
  simpleView,
  translation,
  shouldShowRequestSummary = false,
  stickyMode = false,
  isAlert,
}: ArticleActionButtonsProps) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const tagsFn = useAppSelector(getAllTagLikeEntities)
  const tags = tagsFn(article)?.filter((tag) => shouldShowTag(tag))

  const isMobile = useIsMobile()
  const isECBUser = useIsECBUser()

  const popContainer = useRef<HTMLDivElement>()

  // TDOD: We need an attribute. Until we have that one this is temporary.
  const [showCategorizationModal, setShowCategorizationModal] = useState(false)

  const handleCategorizationClick = () => setShowCategorizationModal(true)

  const handleChangeMetrics = (settingsSection: TabType) =>
    dispatch({ type: 'SETTINGS_MODAL_OPEN', payload: settingsSection })
  const handleShareClick = () => dispatch({ type: 'SHARE_ARTICLE_MODAL_OPEN', payload: article })
  const handleEditClick = () => dispatch({ type: 'EDIT_ARTICLE_MODAL_OPEN', payload: { article } })

  const isPermittedToCategorize = useIsUserPermitted({
    module: 'ENABLE_CATEGORIZATION',
    permissions: [MODULES.ENABLE_CATEGORIZATION.IN_MENU],
  })

  const showCategorizationAsIcon = useIsUserPermitted({
    module: 'ENABLE_CATEGORIZATION',
    permissions: [MODULES.ENABLE_CATEGORIZATION.AS_ICON],
  })

  const editArticleEnabled = useIsUserPermitted({
    module: 'ENABLE_EDIT_ARTICLES',
    permissions: [MODULES.ENABLE_EDIT_ARTICLES.ON],
  })

  const handleOpenSupportReportModal = () => {
    const { id_site, id_article } = article

    dispatch({
      type: 'SUPPORT_REPORT_MODAL_OPEN',
      payload: { id_site, id_article },
    })
  }

  const handleOpenSupportRequestModal = () => {
    const { id_site, id_article } = article

    dispatch({
      type: 'SUPPORT_REQUEST_MODAL_OPEN',
      payload: { id_site, id_article },
    })
  }

  const supportAccess = useAppSelector(getNewPortalSupport)
  const manualFieldsAccess = useAppSelector(getArticleEditAccess)

  const specialTagFolders = useGetFoldersByTrait<Tag>({
    traitNames: categorizationFolderTraitsToShow,
    withChildren: true,
  })

  const specialFoldersChildren = specialTagFolders.flatMap((folder) => folder.children)
  const isTaggedWithSpecialTag = Object.keys(article.tags || {}).some((tag) => {
    return specialFoldersChildren.some((folder) => folder && folder.id === Number(tag))
  })

  const selectedProfileIds = useAppSelector(getSelectedProfilesIds)
  const curatedAndNonCuratedFolders = useGetFoldersByTrait({
    traitNames: ['curated', 'nonCurated'],
    withChildren: true,
  })
  const hasSelectedCuratedOrNonCuratedProfile = selectedProfileIds.some((id) => {
    return curatedAndNonCuratedFolders.some((folder) => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      return folder.children.some((child) => child.id === id)
    })
  })

  const isAbleToReport = isTaggedWithSpecialTag || hasSelectedCuratedOrNonCuratedProfile

  const hasCategorizationFolders = !isEmpty(specialTagFolders)

  const isSocial = isSocialArticle(article)

  const showEditFunction = editArticleEnabled
    ? true
    : manualFieldsAccess?.manual_summary || manualFieldsAccess?.translated_header

  if (
    (!handleChangeMetrics && !sharingDisabled && !translation && !isPermittedToCategorize) ||
    (simpleView && !isPermittedToCategorize && !showEditFunction)
  ) {
    return null
  }

  const handleClick = (e: MouseEvent, callback) => {
    e.preventDefault()
    callback()
  }

  const handleCopyArticleLink = () => {
    // push
    const articleLink = `https://ecb.infomedia.eu/search/?expression=article:${article.id_site}-${article.id_article}`

    void navigator.clipboard.writeText(articleLink)
    copiedToClipBoardNotification()
  }

  // We only want the translation action button to be accessible on the mobile
  if (isMobile) {
    if (translation) {
      return (
        <DropdownMenu>
          <Tooltip>
            <TooltipTrigger asChild>
              <DropdownMenuTrigger type="button" onClick={(e) => e.stopPropagation()}>
                <MoreHorizontal24Filled />
              </DropdownMenuTrigger>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent side="top">
                <Trans>More</Trans>
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>

          <DropdownMenuContent>
            <DropdownMenuItem onClick={(e) => handleClick(e, translation.onTranslateClick)}>
              <Translate24Regular />
              {translation.isTranslated ? (
                <Trans>{"Don't translate"}</Trans>
              ) : (
                <Trans values={{language: t(translation?.language)}}>{'Translate to {{language}}'}</Trans>
              )}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    }

    return null
  }

  return (
    <>
      <div
        className={cn(
          'relative z-30 flex justify-end gap-2.5',
          stickyMode &&
            'sticky top-9 z-[1] mr-3 [&>div]:rounded-sm [&>div]:border [&>div]:border-grey.5 [&>div]:bg-white [&>div]:shadow-[0.7px_0.19rem_8px_rgba(0,0,0,0.22)]',
        )}
        // @ts-expect-error: Muted so we could enable TS strict mode
        ref={popContainer}
        // The following event handlers on the div element prevent propagation of click and keyboard (tab key) events.
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {hasCategorizationFolders && showCategorizationAsIcon && (
          <Tooltip>
            <TooltipTrigger
              onClick={(e) => {
                e.stopPropagation()
                handleCategorizationClick()
              }}
              className="cursor-pointer"
              type="button"
            >
              <CollectionsAdd24Regular />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent side="top">
                <Trans>Categorization</Trans>
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )}

        {isListLayout && (
          <Tooltip>
            <TooltipTrigger type="button" onClick={(e) => e.stopPropagation()}>
              <OverflowTagsPopover article={article} tags={tags} side="bottom">
                <Tag24Regular />
              </OverflowTagsPopover>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent side="top">
                <Trans>Add tag</Trans>
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )}

        {isAbleToReport && !isSocial && (
          <>
            {shouldShowRequestSummary && supportAccess?.request_data && (
              <Tooltip>
                <TooltipTrigger
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleOpenSupportRequestModal()
                  }}
                >
                  <DocumentBulletList24Regular />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent side="top">
                    <Trans>Request</Trans>
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )}

            {supportAccess?.report_issue && (
              <Tooltip>
                <TooltipTrigger
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleOpenSupportReportModal()
                  }}
                >
                  <Flag24Regular />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent side="top">
                    <Trans>Report an issue</Trans>
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )}
          </>
        )}

        <DropdownMenu modal={false}>
          <Tooltip>
            <TooltipTrigger asChild>
              <DropdownMenuTrigger type="button" onClick={(e) => e.stopPropagation()}>
                <MoreHorizontal24Filled />
              </DropdownMenuTrigger>
            </TooltipTrigger>

            <TooltipPortal>
              <TooltipContent side="top">
                <Trans>More</Trans>
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>

          <DropdownMenuContent align={isListLayout ? 'center' : 'end'}>
            <>
              {isAbleToReport && isListLayout && (
                <>
                  {shouldShowRequestSummary && supportAccess?.request_data && (
                    <DropdownMenuItem onClick={handleOpenSupportRequestModal}>
                      <DocumentBulletList24Regular />
                      <Trans>Request</Trans>
                    </DropdownMenuItem>
                  )}
                  {supportAccess?.report_issue && (
                    <DropdownMenuItem onClick={handleOpenSupportReportModal}>
                      <Flag24Regular />
                      <Trans>Report an issue</Trans>
                    </DropdownMenuItem>
                  )}
                </>
              )}
              {!sharingDisabled && handleShareClick && (
                <DropdownMenuItem onClick={handleShareClick}>
                  <Share24Regular />
                  <Trans>Share</Trans>
                </DropdownMenuItem>
              )}
              {translation && (
                <DropdownMenuItem onClick={translation.onTranslateClick}>
                  <Translate24Regular />
                  {translation.isTranslated ? (
                    <Trans>{"Don't translate"}</Trans>
                  ) : (
                    <Trans values={{language: t(translation?.language)}}>{'Translate to {{language}}'}</Trans>
                  )}
                </DropdownMenuItem>
              )}
              {/* 
                Editing an article in alerts won't reflect the changes, as the alert_info endpoint fetches a JSON from the time the alert was processed. 
                So we don't want to show the edit button in alerts.
              */}
              {showEditFunction && !isAlert && (
                <DropdownMenuItem onClick={handleEditClick}>
                  <Edit24Regular />
                  <Trans>Edit</Trans>
                </DropdownMenuItem>
              )}
              {hasCategorizationFolders && !isSocial && isPermittedToCategorize && (
                <DropdownMenuItem onClick={handleCategorizationClick}>
                  <CollectionsAdd24Regular />
                  <Trans>Add to collection</Trans>
                </DropdownMenuItem>
              )}
              {handleChangeMetrics && (
                <DropdownMenuItem onClick={() => handleChangeMetrics('metrics')}>
                  <StackStar24Regular />
                  <Trans>Change metrics</Trans>
                </DropdownMenuItem>
              )}
              {isECBUser && (
                <DropdownMenuItem onClick={handleCopyArticleLink}>
                  <Link24Regular />
                  <Trans>Copy article link</Trans>
                </DropdownMenuItem>
              )}
            </>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <CategorizationModal
        article={article}
        isOpen={showCategorizationModal}
        onCloseModal={() => setShowCategorizationModal(false)}
      />
    </>
  )
}

export default ArticleActionButtons
