import { Delete24Regular } from '@fluentui/react-icons'
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@opoint/infomedia-storybook'

import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { ActionBarDropdownMenuItem } from '../../ActionBarDropdownMenuItem'
import { ActionBarActionsProps, ActionVariant } from '../../types'

const AlertRemoveArticlesAction = ({ variant = ActionVariant.LONG, name }: ActionBarActionsProps) => {
  const dispatch = useAppDispatch()
  const onClickAction = () => dispatch({ type: 'ALERT_REMOVE_ARTICLE_OPEN' })

  if (variant === ActionVariant.MENU) {
    return (
      <ActionBarDropdownMenuItem onClick={onClickAction} data-cy="alertRemoveArticlesBtn">
        <Delete24Regular />
        {name}
      </ActionBarDropdownMenuItem>
    )
  }

  if (variant === ActionVariant.LONG) {
    return (
      <Button variant="outline" onClick={onClickAction} data-cy="alertRemoveArticlesBtn">
        <Delete24Regular />
        {name}
      </Button>
    )
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="outline" size="icon" onClick={onClickAction} data-cy="alertRemoveArticlesBtn">
          <Delete24Regular />
          <span className="sr-only">{name}</span>
        </Button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{name}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}

export default AlertRemoveArticlesAction
