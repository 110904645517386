export enum FilterType {
  TimePeriod = 'timeperiod',
  Site = 'site',
  Rank = 'rank',
  Geo = 'geo',
  Lang = 'lang',
  Cov = 'cov',
  Media = 'media',
  Content = 'content',
  Profiles = 'profiles',
  Tags = 'tags',
  Ent = 'ent',
  Topic = 'topic',
  ECBRank = 'ecb_rank',
  ECBVoice = 'ecb_voice',
  ECBTopic = 'ecb_topic',
  ECBSentiment = 'ecb_sentiment',
  Curated = 'curated',
  NonCurated = 'non-curated',
  SocialMedia = 'social media',
  FrontPages = 'front pages',
}

export interface Option {
  name: string
  value: string
}

export type KeyValue = { key: string; value: string }

export type UserInviteTarget = {
  id: number
  name: string
}

export type LanguageOption = {
  id: string
  name: string
  type: string
}
