import { handleErrors } from '..'
import { CommentRequestTypes, CommentType } from '../../flow'
import config from '../config'

/**
 * Retrieves Comments from API and transforms them into a Promise.
 * @returns {*}
 */
export const fetchArticleComments: (site_id: number, article_id: number) => Promise<CommentType[]> = async (
  site_id,
  article_id,
) => {
  const request = new Request(config.url.api(`/articles/${site_id}/${article_id}/comments/`), {
    ...(await config.request.getRequestHeaders()),
    method: 'GET',
  })

  return fetch(request)
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => result)
}

export const addArticleComment: (payload: CommentRequestTypes) => Promise<Response> = async ({
  group,
  id_site,
  id_article,
  body,
}) => {
  const request = new Request(config.url.api(`/articles/${id_site}/${id_article}/comments/${group}/`), {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify(body),
  })

  return fetch(request).then(handleErrors)
}

export const deleteArticleComment: (payload: CommentRequestTypes) => Promise<Response> = async ({
  group,
  id_article,
  id_site,
  id_user,
  timestamp,
}) =>
  fetch(config.url.api(`/articles/${id_site}/${id_article}/comments/${group}/${id_user}/${timestamp}/`), {
    ...(await config.request.getRequestHeaders()),
    method: 'DELETE',
  }).then(handleErrors)

export const editArticleComment: (payload: CommentRequestTypes) => Promise<Response> = async ({
  group,
  id_article,
  id_site,
  id_user,
  timestamp,
  body,
}) =>
  fetch(config.url.api(`/articles/${id_site}/${id_article}/comments/${group}/${id_user}/${timestamp}/`), {
    ...(await config.request.getRequestHeaders()),
    method: 'PATCH',
    body: JSON.stringify(body),
  }).then(handleErrors)
