import { ActionsObservable } from 'redux-observable'
import { switchMap } from 'rxjs/operators'

import { t } from "i18next";
import {
  ReportsActions,
  ReportsArticlesFailureAction,
  ReportsCreateFailureAction,
  ReportsHistoryFetchFailureAction,
  ReportsShareFailureAction,
  ReportsShareSuccessAction,
  ReportsValidShareFailureAction,
  UpdatedSoMeMetaDataSuccessAction,
  UpdatedSomeMetaDataFailureAction,
} from '../../../actions/reports'
import {
  DeleteReportFailureAction,
  DeleteReportSuccessAction,
  ReportHistoryFetchFailureAction,
  ReportsHistoryActions,
} from '../../../actions/reportsHistory'
import { ErrorEpic, handlerError } from '.'

const reportErrorTypes: ErrorEpic<
  | ReportsShareSuccessAction['type']
  | DeleteReportSuccessAction['type']
  | ReportsShareFailureAction['type']
  | DeleteReportFailureAction['type']
  | ReportsArticlesFailureAction['type']
  | ReportsHistoryFetchFailureAction['type']
  | ReportHistoryFetchFailureAction['type']
  | ReportsValidShareFailureAction['type']
  | ReportsCreateFailureAction['type']
  | UpdatedSoMeMetaDataSuccessAction['type']
  | UpdatedSomeMetaDataFailureAction['type']
> = {
  ['REPORTS_SHARE_SUCCESS']: {
    isSuccess: true,
    message: t('We successfully shared this report'),
    toastId: 'REPORTS_SHARE_SUCCESS',
  },
  ['DELETE_REPORT_SUCCESS']: {
    isSuccess: true,
    message: t('We successfully deleted selected report'),
    toastId: 'DELETE_REPORT_SUCCESS',
  },
  ['REPORTS_SHARE_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to share this report'),
    toastId: 'REPORTS_SHARE_FAILURE',
  },
  ['DELETE_REPORT_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to delete selected report'),
    toastId: 'DELETE_REPORT_FAILURE',
  },
  ['REPORTS_ARTICLES_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to fetch articles for report'),
    toastId: 'REPORTS_ARTICLES_FAILURE',
  },
  ['REPORTS_HISTORY_FETCH_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to fetch report history'),
    toastId: 'REPORTS_HISTORY_FETCH_FAILURE',
  },
  ['REPORTHISTORY_FETCH_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to fetch report history'),
    toastId: 'REPORTHISTORY_FETCH_FAILURE',
  },
  ['REPORTS_VALID_SHARE_FAILURE']: {
    isSuccess: false,
    message: t('You have to enter at least one valid recipient'),
    toastId: 'REPORTS_VALID_SHARE_FAILURE',
  },
  ['REPORTS_CREATE_FAILURE']: {
    isSuccess: false,
    message: t('It was not possible to create the report'),
    toastId: 'REPORTS_CREATE_FAILURE',
  },
  ['UPDATED_SOME_META_DATA_SUCCESS']: {
    isSuccess: true,
    message: t('We successfully updated Social Media metadata posts'),
    toastId: 'UPDATED_SOME_META_DATA_SUCCESS',
  },
  ['UPDATED_SOME_META_DATA_FAILURE']: {
    isSuccess: false,
    message: t('We were not able to update Social Media metadata posts'),
    toastId: 'UPDATED_SOME_META_DATA_FAILURE',
  },
}

const reportErrorEpic: (action: ActionsObservable<ReportsActions | ReportsHistoryActions>) => void = (action$) =>
  action$.pipe(switchMap(({ type }) => handlerError(reportErrorTypes, type)))

export default reportErrorEpic
