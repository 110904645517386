import { createSelector } from 'reselect'

import { t } from "i18next";
import { HIDDEN_TAGS } from '../opoint/common/constants'

import { TAG_TYPES } from '../opoint/tags'
import { getTags as getTagsState } from './rootSelectors'

export const getTags = createSelector(getTagsState, (tagsState) => {
  return tagsState.list
    .filter((tag) => !HIDDEN_TAGS.includes(tag.name))
    .map((tag) =>
      // If the default mobile tag is present, replace its name with a localized "Saved"
      tag.name === 'DEFAULT_TAG_MOBILE_APP' ? { ...tag, name: t('Saved') } : tag,
    )
})

export const getDefaultTag = createSelector(
  getTags,
  (tags) => tags.find((tag) => tag.category === 0 && tag.type === TAG_TYPES.KEYWORD) || tags[0],
)

export const getLastUsedTagId = createSelector(getTagsState, (tagsState) => tagsState.lastUsedTagId)

export const getTagFormLoading = createSelector(getTagsState, (tagsState) => tagsState.tagFormLoading)
