import { createSelector } from 'reselect'

import { getArticles } from './articlesSelectors'
import { isSearchExhausted } from './searchSelectors'

export const noArticlesFound = createSelector(
  getArticles,
  isSearchExhausted,
  (articles, isExhausted) => articles.length === 0 && isExhausted,
)
