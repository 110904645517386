import { produce } from 'immer'

import { AppActions } from '../actions'
import { CommonRecipientFilter, ContactDetail, ContactFilter, Group } from '../components/types/contact'
import { contactRecipientDataTransformToFilter, eqContactFilters } from '../opoint/contacts'
import type { ContactOrGroupDeleteWarningType } from '../opoint/flow'

export type ContactState = {
  activeContactId: number
  activeGroup: Group | null
  details: { [key: number]: ContactDetail }
  contactOrGroupDeleteWarning: ContactOrGroupDeleteWarningType | null
  editedAlertEmail: Array<ContactFilter | CommonRecipientFilter>
  editedAlertSms: Array<ContactFilter | CommonRecipientFilter>
  expandContacts: boolean
  expandGroups: boolean
  list: Array<ContactDetail>
  listGroups: Group[]
  shareArticlesContacts: Array<ContactFilter | CommonRecipientFilter>
  shareReportContacts: Array<ContactFilter | CommonRecipientFilter>
  errors: { [key: string]: string[] }
  loading: boolean
}

export const initialState: ContactState = {
  // @ts-expect-error: Muted so we could enable TS strict mode
  activeContactId: null,
  activeGroup: null,
  contactOrGroupDeleteWarning: null,
  details: {},
  editedAlertEmail: [],
  editedAlertSms: [],
  expandContacts: true,
  expandGroups: false,
  list: [],
  listGroups: [],
  shareArticlesContacts: [],
  shareReportContacts: [],
  errors: {},
  loading: false,
}

const contactsReducer = produce((draftState, action: AppActions) => {
  switch (action.type) {
    case 'LOGOUT':
      return initialState

    case 'CONTACTS_FETCH_SUCCESS': {
      //sort by first name
      draftState.list = action.payload.sort((a, b) =>
        a.firstName.localeCompare(b.firstName, undefined, { sensitivity: 'base' }),
      )
      break
    }
    case 'CONTACT_SET_ACTIVE_SUCCESS': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.activeContactId = action.payload.id
      draftState.contactOrGroupDeleteWarning = null
      break
    }
    case 'CONTACT_REMOVE_ACTIVE':
    case 'CONTACT_INIT_NEW': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.activeContactId = null
      draftState.contactOrGroupDeleteWarning = null
      break
    }
    case 'CONTACTS_ACTIVE': {
      draftState.expandContacts = true
      draftState.expandGroups = false
      break
    }
    case 'CONTACTS_GROUP_ACTIVE': {
      draftState.expandContacts = false
      draftState.expandGroups = true
      break
    }
    case 'CONTACT_DETAIL_FETCH_SUCCESS': {
      const contact = action.payload

      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.details[contact.id] = contact
      break
    }

    case 'GROUP_DELETE_ERROR':
    case 'CONTACT_DELETE_ERROR':
    case 'CONTACT_DOMAIN_NOT_ALLOWED_ERROR': {
      draftState.loading = false
      break
    }

    case 'CONTACT_SAVE':
    case 'CONTACT_DELETE_CONFIRM':
    case 'GROUP_SAVE':
    case 'GROUP_DELETE_CONFIRM': {
      draftState.loading = true
      break
    }

    case 'CONTACT_SAVE_SUCCESS': {
      const contactIndex = draftState.list.findIndex((contact) => contact.id === action.payload.id)

      if (contactIndex !== -1) {
        draftState.list = draftState.list.map((contact) =>
          contact.id === action.payload.id ? action.payload : contact,
        )
      } else {
        draftState.list.push(action.payload)
      }

      draftState.errors = {}
      draftState.loading = false
      break
    }
    case 'CONTACT_DELETE_SUCCESS': {
      draftState.list = draftState.list.filter((contact) => contact.id !== action.payload)
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.activeContactId = null
      draftState.contactOrGroupDeleteWarning = null
      draftState.loading = false
      break
    }

    case 'GROUP_USED_DELETE_ERROR':
    case 'CONTACT_USED_DELETE_ERROR': {
      const {
        message,
        data: { alerts },
      } = action.payload

      draftState.loading = false
      draftState.contactOrGroupDeleteWarning = {
        message,
        // @ts-expect-error: Muted so we could enable TS strict mode
        alerts: alerts.map((alert) => alert.name).join(', '),
      }
      break
    }

    case 'CONTACT_OR_GROUP_USED_DELETE_ERROR_CLEAR': {
      draftState.contactOrGroupDeleteWarning = null
      break
    }

    case 'GROUPS_FETCH_SUCCESS': {
      draftState.listGroups = action.payload.sort((a, b) => a.id - b.id)
      break
    }

    case 'GROUP_SET_ACTIVE_SUCCESS': {
      draftState.activeGroup = action.payload
      draftState.contactOrGroupDeleteWarning = null
      break
    }

    case 'GROUP_REMOVE_ACTIVE': {
      draftState.activeGroup = null
      break
    }

    case 'GROUP_SAVE_SUCCESS': {
      const { group } = action.payload

      draftState.errors = {}
      draftState.loading = false
      draftState.activeGroup = group
      draftState.listGroups = draftState.listGroups.map((savedGroup) =>
        savedGroup.id === group.id ? group : savedGroup,
      )
      break
    }

    case 'GROUP_INIT_NEW': {
      draftState.activeGroup = null
      draftState.contactOrGroupDeleteWarning = null
      draftState.errors = {}
      break
    }

    case 'GROUP_DELETE_SUCCESS': {
      draftState.listGroups = draftState.listGroups.filter((group) => group.id !== action.payload)
      draftState.activeGroup = null
      draftState.loading = false
      break
    }

    case 'ON_CONTACT_FILTER_ADDED': {
      const { filter, contactsPath } = action.payload

      if (contactsPath in draftState) {
        const currentFilters = draftState[contactsPath]

        const alreadyExists = currentFilters.some((existingFilter) => eqContactFilters(existingFilter, filter))

        if (!alreadyExists) {
          draftState[contactsPath].push(filter)
        }
      }
      break
    }

    case 'ON_CONTACT_FILTER_REMOVED': {
      const { filter, contactsPath } = action.payload

      if (contactsPath in draftState) {
        draftState[contactsPath] = draftState[contactsPath].filter(
          (existingFilter) => !eqContactFilters(existingFilter, filter),
        )
      }
      break
    }

    case 'ON_CONTACT_FILTER_EMPTIED': {
      const { contactsPath } = action.payload

      if (contactsPath in draftState) {
        draftState[action.payload.contactsPath] = []
      }
      break
    }

    case 'ON_CONTACT_FILTER_TOGGLED': {
      const { filter, contactsPath } = action.payload

      if (contactsPath in draftState) {
        const currentFilters = draftState[contactsPath]

        const alreadyExists = currentFilters.some((existingFilter) => eqContactFilters(existingFilter, filter))

        if (alreadyExists) {
          draftState[contactsPath] = draftState[contactsPath].filter(
            (existingFilter) => !eqContactFilters(existingFilter, filter),
          )
        } else {
          draftState[contactsPath].push(filter)
        }
      }
      break
    }

    case 'ALERT_CLEAR_RECIPIENTS': {
      draftState.editedAlertEmail = []
      draftState.editedAlertSms = []
      break
    }

    case 'ALERT_INIT_RECIPIENTS': {
      const { smsRecipients, recipients } = action.payload
      const listByType = {
        person: draftState.list,
        group: draftState.listGroups,
      }

      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.editedAlertEmail = contactRecipientDataTransformToFilter(recipients, listByType)
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.editedAlertSms = contactRecipientDataTransformToFilter(smsRecipients, listByType)
      break
    }

    case 'SHARE_ARTICLE_MODAL_CLOSE': {
      draftState.shareArticlesContacts = []
      break
    }

    case 'CLEAR_SHARE_REPORT_RECIPIENTS': {
      draftState.shareReportContacts = []
      break
    }

    case 'CONTACTS_AND_GROUPS_CLEAR_ERRORS': {
      draftState.errors = {}
      break
    }
    case 'GROUP_SAVE_ERROR':
    case 'CONTACT_SAVE_ERROR': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.errors = action.payload.responseErrors
      draftState.loading = false
      break
    }

    default:
      return draftState
  }
}, initialState)

export default contactsReducer
