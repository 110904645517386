import { Eye24Regular } from '@fluentui/react-icons'
import { useOpointImpersonationState } from '@opoint/authjs-react'
import { cn } from '@opoint/infomedia-storybook'
import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'
import { getIsAllowedToImpersonate } from '../../../../selectors/impersonationSelectors'
import { useAppSelector } from '../../../hooks/useAppSelector'
import NotificationsPopover from '../../NotificationsPopover/NotificationPopover'
import ProductsDialog from '../../ProductDialog'
import UserPopover from '../../UserPopover/UserPopover'
import TopNavActionButton from './Button'

const TopNavActions = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const impersonationActive = !!useOpointImpersonationState()
  const allowedToImpersonate = useAppSelector(getIsAllowedToImpersonate)
  const openCustomerView = () => navigate('/customer_view')

  // mr-2.5 aligns with the padding of the action bar below
  return (
    <ul className="ml-2 mr-2.5 flex h-full list-none items-center gap-x-1">
      <ProductsDialog />
      <NotificationsPopover />

      {allowedToImpersonate && (
        <li>
          <TopNavActionButton
            title={t('Customer view')}
            onClick={openCustomerView}
            className={cn({ 'bg-sky.1 hover:bg-sky.1': impersonationActive })}
          >
            <Eye24Regular
              className={cn({
                'text-white': impersonationActive,
              })}
            />
          </TopNavActionButton>
        </li>
      )}

      <UserPopover />
    </ul>
  )
}

export default TopNavActions
