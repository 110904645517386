import { ReactNode, useEffect } from 'react'

import useInView from '../../hooks/useInView'
import useSetActiveArticle from '../../hooks/useSetActiveArticle'

type ArticleInnerWrapperProps = {
  articleIndex: number
  children: ReactNode
  onActiveArticleChange?: (index: number) => void
}

const ArticleInnerWrapper = ({ children, articleIndex, onActiveArticleChange }: ArticleInnerWrapperProps) => {
  const [ref, isVisible] = useInView({ threshold: 0.5 })
  const setActiveArticle = useSetActiveArticle()

  useEffect(() => {
    if (!isVisible) {
      return
    }

    if (articleIndex === null || articleIndex === undefined) {
      return
    }

    setActiveArticle({ index: articleIndex, source: 'scroll' })
    onActiveArticleChange?.(articleIndex)
  }, [isVisible])

  return <div ref={ref}>{children}</div>
}

export default ArticleInnerWrapper
