import i18n from 'i18next';
import translationEN from './locales/en/translation.json';
import translationNN from './locales/nn/translation.json';
import translationNB from './locales/nb/translation.json';
import translationET from './locales/et/translation.json';
import translationFI from './locales/fi/translation.json';
import translationZH from './locales/zh/translation.json';
import translationSV from './locales/sv/translation.json';
import translationDA from './locales/da/translation.json';

i18n.init({
  resources: {
    en: { translation: translationEN },
    nn: { translation: translationNN },
    nb: { translation: translationNB },
    et: { translation: translationET },
    fi: { translation: translationFI },
    zh: { translation: translationZH },
    sv: { translation: translationSV },
    da: { translation: translationDA },
  },
  lng: 'en', // default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for React
  },
});

export default i18n;
