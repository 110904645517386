import { createSelector } from 'reselect'

import { FiltersMap } from '../opoint/flow'

import { getSearchFilters } from './searchSelectors'
import { hasLimitedSearch } from './settingsSelectors'

export const isLimitedSearch = createSelector(hasLimitedSearch, getSearchFilters, (hasLimitedSearch, searchFilters) => {
  const hasFilters = !!Object.values(searchFilters as FiltersMap).filter(
    (x) => x.type === 'tag' || x.type === 'trash' || x.type === 'profile',
  ).length

  return hasLimitedSearch && !hasFilters
})
