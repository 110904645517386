import { SingleUserInvitation, SingleUserInvitationStatusEnum } from '../../api/opoint.schemas'
import { GeneralStatusType } from '../alerts/RecipientsListModal/helpers'

export enum UserManagementTab {
  ALL = 'all',
  INVITATIONS = 'invitations',
}

export interface UserManagementMatchParams {
  tab: UserManagementTab
}

export interface UserDetailMatchParams {
  userId: string
}

export const InvitationSentStatus = {
  Accepted: 'Accepted',
  Active: 'Active',
  Expired: 'Expired',
  Failed: 'Failed',
  Inactive: 'Inactive',
  Pending: 'Pending',
} as const

export const InvitationSentStatusMapping = {
  [SingleUserInvitationStatusEnum.NUMBER_0]: InvitationSentStatus.Failed,
  [SingleUserInvitationStatusEnum.NUMBER_1]: InvitationSentStatus.Pending,
  [SingleUserInvitationStatusEnum.NUMBER_2]: InvitationSentStatus.Accepted,
  [SingleUserInvitationStatusEnum.NUMBER_3]: InvitationSentStatus.Expired,
} as const

export type InvitationsTableItem = {
  alerts?: string[]
  email: string
  id: number
  invitation?: SingleUserInvitation
  isUser: boolean
  lastLogin?: number | undefined
  name?: string
  role: string | undefined
  status: GeneralStatusType
}
