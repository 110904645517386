import { Trans } from 'react-i18next'

import { Step } from 'react-joyride'

import { useMatch } from 'react-router-dom'
import { ALERTS_TOUR_KEY } from '../../../constants/localStorage'
import { NavigationSectionKey } from '../../../helpers/navigation'
import { disabledBeaconAndAnimation, disabledScrolling } from '../constants'
import ProductTour from '../ProductTour'
import useDelayTour from '../useDelayTour'
import { prependHash } from '../utility'

export enum AlertsTourTarget {
  STEP_1 = 'AT_STEP_1',
}

const steps: Step[] = [
  {
    target: prependHash(AlertsTourTarget.STEP_1),
    content: <Trans>Using the Date selector you can browse your current and historical alerts.</Trans>,
    placement: 'right-start',
    showSkipButton: false,
    ...disabledBeaconAndAnimation,
    ...disabledScrolling,
  },
]

const AlertsTour = () => {
  const match = useMatch(`/${NavigationSectionKey.ALERTS}/:alertId/:mailLogId`)

  const show = useDelayTour(!!match && match.params.mailLogId !== 'edit', 1000)

  if (!show) {
    return null
  }

  return <ProductTour tourKey={ALERTS_TOUR_KEY} steps={steps} />
}

export default AlertsTour
