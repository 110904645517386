import { WarningRegular } from '@fluentui/react-icons'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '@opoint/infomedia-storybook'
import { useNavigate } from 'react-router-dom'
import ErrorPage from '../../components/error/ErrorPage.tsx'

export const AccessDeniedErrorPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="flex size-full grow flex-col items-center justify-center">
      <ErrorPage
        primaryText={t('Access denied')}
        secondaryText={t(
          "You don't have permission to view this page. If you think this is a mistake, please contact support for help.",
        )}
        statusCode={403}
        icon={WarningRegular}
      >
        <Button onClick={() => navigate('/')}>
          <Trans>Back to homepage</Trans>
        </Button>
      </ErrorPage>
    </div>
  )
}
