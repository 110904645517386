import { useOpointUser } from '@opoint/authjs-react'

import { isUserAdmin } from '../../helpers/auth'

const useIsUserAdmin = (): boolean => {
  const user = useOpointUser()

  return isUserAdmin(user)
}

export default useIsUserAdmin
