import { MoreHorizontal24Regular } from '@fluentui/react-icons'
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'
import { ReactNode } from 'react'

type MoreActionsMenuProps = {
  children: ReactNode[]
}

const MoreActionsMenu = ({ children }: MoreActionsMenuProps) => (
  <DropdownMenu modal={false}>
    <DropdownMenuTrigger asChild>
      <Button variant="outline" size="icon">
        <MoreHorizontal24Regular />
        <span className="sr-only" data-cy="more-options-button">
          <Trans>More options...</Trans>
        </span>
      </Button>
    </DropdownMenuTrigger>

    <DropdownMenuContent align="end">{children}</DropdownMenuContent>
  </DropdownMenu>
)

export default MoreActionsMenu
