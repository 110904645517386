import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'

import { t } from "i18next";
import {
  ProfileDeleteFailureAction,
  ProfileDeleteSuccessAction,
  DeletedProfilesFetchFailureAction,
  ProfileEditorInvalidSearchlineAction,
  ProfileEditorSaveProfileFailureAction,
  ProfileEditorSaveProfileSuccessAction,
  ProfileHistoryFetchFailureAction,
  LoadEditedProfileFailureAction,
  SaveAsProfileSuccessAction,
  ProfilesActions,
} from '../../../actions/profiles'
import { ErrorEpic, ErrorPayload, handlerError } from '.'

const profileEditorErrorTypes = (
  payload?: ErrorPayload,
): ErrorEpic<
  | SaveAsProfileSuccessAction['type']
  | ProfileEditorSaveProfileSuccessAction['type']
  | ProfileDeleteSuccessAction['type']
  | ProfileEditorSaveProfileFailureAction['type']
  | LoadEditedProfileFailureAction['type']
  | ProfileDeleteFailureAction['type']
  | ProfileHistoryFetchFailureAction['type']
  | DeletedProfilesFetchFailureAction['type']
  | ProfileEditorInvalidSearchlineAction['type']
> => {
  return {
    ['SAVE_AS_PROFILE_SUCCESS']: {
      isSuccess: true,
      message: t('Profile was successfully saved'),
      toastId: 'PROFILE_EDITOR_SAVE_PROFILE_SUCCESS',
    },
    ['PROFILE_EDITOR_SAVE_PROFILE_SUCCESS']: {
      isSuccess: true,
      message: t('Profile was successfully saved'),
      toastId: 'PROFILE_EDITOR_SAVE_PROFILE_SUCCESS',
    },
    ['PROFILE_DELETE_SUCCESS']: {
      isSuccess: true,
      message: t('Profile was successfully deleted'),
      toastId: 'PROFILE_DELETE_SUCCESS',
    },
    ['PROFILE_EDITOR_SAVE_PROFILE_FAILURE']: {
      isSuccess: false,
      message: payload?.error ?? t('We were unable to save this profile'),
      toastId: 'PROFILE_EDITOR_SAVE_PROFILE_FAILURE',
    },
    ['LOAD_EDITED_PROFILE_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to load this profile'),
      toastId: 'LOAD_EDITED_PROFILE_FAILURE',
    },
    ['PROFILE_DELETE_FAILURE']: {
      isSuccess: false,
      // @ts-expect-error: Muted so we could enable TS strict mode
      message: payload?.error,
      toastId: 'PROFILE_DELETE_FAILURE',
    },
    ['PROFILE_HISTORY_FETCH_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to fetch this profile history'),
      toastId: 'PROFILE_HISTORY_FETCH_FAILURE',
    },
    ['DELETED_PROFILES_HISTORY_FETCH_FAILURE']: {
      isSuccess: false,
      message: t('We were unable to fetch deleted profiles history'),
      toastId: 'DELETED_PROFILES_HISTORY_FETCH_FAILURE',
    },
    ['PROFILE_EDITOR_INVALID_SEARCHLINE']: {
      isSuccess: false,
      message: t('We were unable to save this profile - search line is in wrong format'),
      toastId: 'PROFILE_EDITOR_INVALID_SEARCHLINE',
    },
  }
}

const profileEditorErrorEpic: (action: ActionsObservable<ProfilesActions>) => void = (action$) =>
  action$.pipe(
    switchMap((action) =>
      'payload' in action
        ? handlerError(profileEditorErrorTypes, action.type, action.payload as ErrorPayload)
        : handlerError(profileEditorErrorTypes, action.type),
    ),
  )
export default profileEditorErrorEpic
