import { DOM } from 'rx-dom'

import { merge } from 'ramda'
import config from '../common/config'

export async function getTags() {
  const requestHeaders = merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/analysis-tags/'),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
