import { Fragment, MutableRefObject, useMemo } from 'react'

import { DropdownMenuLabel } from '@opoint/infomedia-storybook'
import { People16Filled } from '@fluentui/react-icons'
import { useTranslation } from 'react-i18next'
import { AlertTag as AlertTagType, GeneralTag, Tag } from '../types/tag'

import { M360Article } from '../../opoint/articles/types'
import { useAppSelector } from '../hooks/useAppSelector'
import { getFoldersTree } from '../../selectors/foldersSelectors'
import useIsUserPermitted from '../hooks/useIsUserPermitted'
import { MODULES } from '../constants/permissions'
import TagSwitch from './TagSwitch'

export type OverflownTagsDropdownProps = {
  article: M360Article
  tags: GeneralTag[]
  tagRefs?: MutableRefObject<(HTMLDivElement | null)[]>
  setOverflowDrilldown?: (arg: AlertTagType) => void
  setIdenticalArticleTag?: () => void
  handleUpdateTagWeight?: (increment: boolean) => void
}

const OverflownTagsDropdown = ({ tags, ...tagProps }: OverflownTagsDropdownProps) => {
  const { t } = useTranslation()
  const folders = useAppSelector(getFoldersTree)

  const isPermittedToCategorize = useIsUserPermitted({
    module: 'ENABLE_CATEGORIZATION',
    permissions: [MODULES.ENABLE_CATEGORIZATION.AS_LIST],
  })

  const ids = [...new Set(tags.map((tag) => (tag as Tag).folder).filter(Boolean))]

  const filteredFolders = folders.filter((f) => ids.some((id) => id === f.id))

  const tagsToDisplay = useMemo(() => {
    const data = filteredFolders.map((folder) => {
      return {
        ...folder,
        children: folder.children?.filter((tag: Tag) => tags.some((t) => t.id === tag.id)),
      }
    })

    return data.filter((f) => f.children?.length)
  }, [filteredFolders, tags])

  return (
    <>
      {isPermittedToCategorize
        ? tagsToDisplay.map((folder, index) => (
            <Fragment key={index}>
              <DropdownMenuLabel className="mb-0.5 ml-4 flex items-center gap-1">
                {folder.category > 0 && <People16Filled title={t('Shared')} />}
                {folder.name}
              </DropdownMenuLabel>

              <div className="ml-4">
                {folder.children?.map((tag, index) => (
                  <TagSwitch key={tag.id} tag={tag} index={index} isOverflow {...tagProps} />
                ))}
              </div>
            </Fragment>
          ))
        : tags.map((tag, index) => <TagSwitch key={tag.id} tag={tag} index={index} isOverflow {...tagProps} />)}
    </>
  )
}

export default OverflownTagsDropdown
