import { range, unnest } from 'ramda'

export function highlightWithSuggestionServerHint(expression: string, hint?: string) {
  if (!hint) {
    return expression
  }

  const matchedIndexes = unnest(
    hint.split(',')?.map((x) => {
      const [start, end] = x.split('-')

      return range(+start, +end + 1)
    }),
  )

  const expressionSplitted = expression.split('')

  const r = expressionSplitted.reduce(
    ([res, hlActive], val, index) => {
      if (!hlActive && matchedIndexes.includes(index)) {
        return [`${res}<strong class="text-highlight">${val}`, true]
      }
      if (hlActive && !matchedIndexes.includes(index)) {
        return [`${res}</strong>${val}`, false]
      }

      return [res + val, hlActive]
    },
    ['', false],
  )

  return r[0] + (r[1] ? '</strong>' : '')
}

export function highlightSearched(text: string, search: string) {
  /* eslint no-useless-escape: "off" */

  const searchWords = search?.trim().split(' ')
  let unsafeHTML = text

  searchWords?.forEach((searchWord) => {
    if (searchWord.length) {
      const tempHTML = text.replace(
        new RegExp(searchWord.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&'), 'gi'),
        '<strong class="text-highlight">$&</strong>',
      )

      unsafeHTML = tempHTML
    }
  })

  return unsafeHTML
}

export function highlightItemsInTableSearched(text: string, search: string) {
  let unsafeHTML = text

  if (search.length) {
    const tempHTML = text.replace(
      new RegExp(search.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&'), 'gi'),
      '<span class="px-0.5 bg-sky.7 rounded-sm">$&</span>',
    )

    unsafeHTML = tempHTML
  }

  return unsafeHTML
}
