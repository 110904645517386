import { Alert24Regular } from '@fluentui/react-icons'
import { Popover, PopoverContent, PopoverTrigger } from '@opoint/infomedia-storybook'
import { useTranslation } from 'react-i18next'

import { useNotificationsAlertCountRetrieve } from '../../../api/notifications/notifications'
import TopNavActionButton from '../Header/Actions/Button'

import useNotificationSocket from './hooks/useNotificationSocket'
import { NotificationsList } from './NotificationsList'

const NotificationsPopover = () => {
  const { t } = useTranslation()

  useNotificationSocket()

  const { data } = useNotificationsAlertCountRetrieve({ query: { keepPreviousData: true } })

  return (
    <li>
      <Popover>
        <PopoverTrigger title={t('Notifications')} asChild>
          <TopNavActionButton>
            {!!data?.count && (
              <span className="pointer-events-none absolute -top-0 right-0 inline-flex min-h-5 min-w-5 items-center justify-center rounded-xl bg-sky.1 px-1 text-xs text-white">
                {data.count}
              </span>
            )}

            <Alert24Regular />
          </TopNavActionButton>
        </PopoverTrigger>

        <PopoverContent align="end" className="flex w-60 flex-col overflow-hidden px-0 py-3">
          <NotificationsList />
        </PopoverContent>
      </Popover>
    </li>
  )
}

export default NotificationsPopover
