import { escapeRegExp } from 'lodash-es'
import { Alert, AlertBasket, AlertContactOverview } from '../../api/opoint.schemas'
import { highlightSearched } from '../../opoint/common/highlight'

const testAlertContent = (baskets: readonly AlertBasket[], regex: RegExp) => {
  const filtered = baskets.filter(({ name }) => regex.test(name))

  return !!filtered.length
}

const testContacts = ({ contacts, direct, groups }, regex: RegExp) => {
  const filtered = [...contacts, ...direct, ...groups.flatMap((group) => group?.contacts || [])].filter(
    ({ email, mobile, name }) => regex.test(email) || regex.test(mobile) || regex.test(name),
  )

  return !!filtered.length
}

const getSearchedAlerts = (alerts: Alert[], alertContacts: AlertContactOverview[], searchTerm: string) => {
  const regex = new RegExp(escapeRegExp(searchTerm), 'i')

  return alerts.filter((alert) => {
    if (alert.subject && regex.test(alert.subject)) {
      return true
    }

    if (testAlertContent(alert.baskets, regex)) {
      return true
    }

    const recipients = alertContacts.find((alertContact) => alertContact.id === alert.id)?.recipients

    if (!recipients) {
      return false
    }

    return testContacts(recipients, regex)
  })
}

const highlightSearchAlerts = (alerts: Alert[], searchTerm: string) => {
  return alerts.map(({ subject, baskets, ...rest }) => ({
    ...rest,
    subject: subject ? highlightSearched(subject, searchTerm) : undefined,
    baskets: baskets.map(({ name, ...contentRest }) => ({ ...contentRest, name: highlightSearched(name, searchTerm) })),
  }))
}

export { getSearchedAlerts, testContacts, testAlertContent, highlightSearchAlerts }
