import { Outlet } from 'react-router-dom'
import Header from '../../components/topNav/Header'
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'

const ProfilesPage = () => (
  <>
    <Header>
      <Breadcrumbs />
    </Header>
    <Outlet />
  </>
)

export default ProfilesPage
