import { getCurrentLocation, getCurrentPath } from './locationService'

// TODO: Refactor getSearchObj & getCurrentSearchObj into the hook using 'qs' library
// ! Both functions returns the same data, the only difference is that one take parameter and another does not, against DRY method
/**
 * Converts URL query string (search) into object
 * @param search The query string to convert, typically found in `window.location.search`. E.g. `?some=value&other=thing`
 * @returns An object with the keys and values of the query string
 */
export function getSearchObj(search: string): { [key: string]: string } {
  return Object.fromEntries(new URLSearchParams(search ?? ''))
}

/**
 * Converts the current URL query string (search) into object
 * @param search The query string to convert, typically found in `window.location.search`. E.g. `?some=value&other=thing`
 * @returns An object with the keys and values of the query string
 */
export function getCurrentSearchObj(): { [key: string]: string } {
  const location = getCurrentLocation()
  return Object.fromEntries(new URLSearchParams(location?.search ?? ''))
}

export const getAlertId = () => {
  const pathname = getCurrentPath()
  const data = pathname.split('/')
  const type = data[1]
  const id = data[2]

  return type === 'alerts' ? parseInt(id, 10) : undefined
}
