export enum ContactTypes {
  Person = 'person',
  User = 'user',
  Group = 'group',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
}

export enum FilterTypeNumeric {
  lang = 4,
  site = 64,
  geo = 128,
  ecb_rank = 2048,
  ecb_voice = 4096,
  ecb_topic = 8192,
  ecb_sentiment = 16384,
  timeperiod = 1024,
  ent = 65536,
  topic = 131072,
  media = -32,
  content = 67108864,
  rank = 2147483648,
}

export const MAX_SUGGESTION_RESULTS = 20

export const SHOW_ALL_SUGGESTIONS_ACCESS_GROUP = 2147483647
export const SUGGESTIONS_OFFSET_START = '0'
