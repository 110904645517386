/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Infomedia API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  AlertSubscription,
  AlertSubscriptionDetail,
  Currency,
  DanishUser,
  ImpersonationTarget,
  OpointUser,
  PatchedDanishUser,
  UsersCurrencyListParams,
  UsersImpersonationTargetsListParams,
  UsersSearchListParams,
} from '../opoint.schemas'
import { useCustomAxiosInstance } from '.././custom-client'
import type { ErrorType } from '.././custom-client'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

export const useUsersCurrencyListHook = () => {
  const usersCurrencyList = useCustomAxiosInstance<Currency[]>()

  return useCallback(
    (params?: UsersCurrencyListParams, signal?: AbortSignal) => {
      return usersCurrencyList({ url: `/users/currency/`, method: 'GET', params, signal })
    },
    [usersCurrencyList],
  )
}

export const getUsersCurrencyListQueryKey = (params?: UsersCurrencyListParams) => {
  return [`/users/currency/`, ...(params ? [params] : [])] as const
}

export const useUsersCurrencyListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useUsersCurrencyListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersCurrencyListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUsersCurrencyListHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersCurrencyListQueryKey(params)

  const usersCurrencyList = useUsersCurrencyListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useUsersCurrencyListHook>>>> = ({ signal }) =>
    usersCurrencyList(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUsersCurrencyListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersCurrencyListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUsersCurrencyListHook>>>>
export type UsersCurrencyListQueryError = ErrorType<unknown>

export const useUsersCurrencyList = <
  TData = Awaited<ReturnType<ReturnType<typeof useUsersCurrencyListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersCurrencyListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUsersCurrencyListHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUsersCurrencyListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Search impersonation targets.

The search syntax is simple, any spaces in the search expression, that are
not at the start or the end are counted as AND operators, while the terms
has to be a substring:

    tech admin

Translates to something like

    substring(text, tech) AND substring(text, admin)

By default, the search is run against the username, email and customer
names concatenated.
 * @summary Search impersonation targets.
 */
export const useUsersImpersonationTargetsListHook = () => {
  const usersImpersonationTargetsList = useCustomAxiosInstance<ImpersonationTarget[]>()

  return useCallback(
    (params?: UsersImpersonationTargetsListParams, signal?: AbortSignal) => {
      return usersImpersonationTargetsList({ url: `/users/impersonation_targets/`, method: 'GET', params, signal })
    },
    [usersImpersonationTargetsList],
  )
}

export const getUsersImpersonationTargetsListQueryKey = (params?: UsersImpersonationTargetsListParams) => {
  return [`/users/impersonation_targets/`, ...(params ? [params] : [])] as const
}

export const useUsersImpersonationTargetsListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useUsersImpersonationTargetsListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersImpersonationTargetsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUsersImpersonationTargetsListHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersImpersonationTargetsListQueryKey(params)

  const usersImpersonationTargetsList = useUsersImpersonationTargetsListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useUsersImpersonationTargetsListHook>>>> = ({
    signal,
  }) => usersImpersonationTargetsList(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUsersImpersonationTargetsListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersImpersonationTargetsListQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUsersImpersonationTargetsListHook>>>
>
export type UsersImpersonationTargetsListQueryError = ErrorType<unknown>

/**
 * @summary Search impersonation targets.
 */
export const useUsersImpersonationTargetsList = <
  TData = Awaited<ReturnType<ReturnType<typeof useUsersImpersonationTargetsListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersImpersonationTargetsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUsersImpersonationTargetsListHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUsersImpersonationTargetsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useUsersManagementRetrieveHook = () => {
  const usersManagementRetrieve = useCustomAxiosInstance<DanishUser>()

  return useCallback(
    (idUser: number, signal?: AbortSignal) => {
      return usersManagementRetrieve({ url: `/users/management/${idUser}/`, method: 'GET', signal })
    },
    [usersManagementRetrieve],
  )
}

export const getUsersManagementRetrieveQueryKey = (idUser: number) => {
  return [`/users/management/${idUser}/`] as const
}

export const useUsersManagementRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useUsersManagementRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  idUser: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUsersManagementRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersManagementRetrieveQueryKey(idUser)

  const usersManagementRetrieve = useUsersManagementRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useUsersManagementRetrieveHook>>>> = ({ signal }) =>
    usersManagementRetrieve(idUser, signal)

  return { queryKey, queryFn, enabled: !!idUser, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUsersManagementRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersManagementRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUsersManagementRetrieveHook>>>
>
export type UsersManagementRetrieveQueryError = ErrorType<unknown>

export const useUsersManagementRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useUsersManagementRetrieveHook>>>,
  TError = ErrorType<unknown>,
>(
  idUser: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUsersManagementRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUsersManagementRetrieveQueryOptions(idUser, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useUsersManagementPartialUpdateHook = () => {
  const usersManagementPartialUpdate = useCustomAxiosInstance<DanishUser>()

  return useCallback(
    (idUser: number, patchedDanishUser: NonReadonly<PatchedDanishUser>) => {
      return usersManagementPartialUpdate({
        url: `/users/management/${idUser}/`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: patchedDanishUser,
      })
    },
    [usersManagementPartialUpdate],
  )
}

export const useUsersManagementPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUsersManagementPartialUpdateHook>>>,
    TError,
    { idUser: number; data: NonReadonly<PatchedDanishUser> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUsersManagementPartialUpdateHook>>>,
  TError,
  { idUser: number; data: NonReadonly<PatchedDanishUser> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const usersManagementPartialUpdate = useUsersManagementPartialUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUsersManagementPartialUpdateHook>>>,
    { idUser: number; data: NonReadonly<PatchedDanishUser> }
  > = (props) => {
    const { idUser, data } = props ?? {}

    return usersManagementPartialUpdate(idUser, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersManagementPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUsersManagementPartialUpdateHook>>>
>
export type UsersManagementPartialUpdateMutationBody = NonReadonly<PatchedDanishUser>
export type UsersManagementPartialUpdateMutationError = ErrorType<unknown>

export const useUsersManagementPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUsersManagementPartialUpdateHook>>>,
    TError,
    { idUser: number; data: NonReadonly<PatchedDanishUser> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUsersManagementPartialUpdateHook>>>,
  TError,
  { idUser: number; data: NonReadonly<PatchedDanishUser> },
  TContext
> => {
  const mutationOptions = useUsersManagementPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Get alert subscription status for user
 */
export const useUsersManagementAlertsListHook = () => {
  const usersManagementAlertsList = useCustomAxiosInstance<AlertSubscription[]>()

  return useCallback(
    (idUser: number, signal?: AbortSignal) => {
      return usersManagementAlertsList({ url: `/users/management/${idUser}/alerts/`, method: 'GET', signal })
    },
    [usersManagementAlertsList],
  )
}

export const getUsersManagementAlertsListQueryKey = (idUser: number) => {
  return [`/users/management/${idUser}/alerts/`] as const
}

export const useUsersManagementAlertsListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsListHook>>>,
  TError = ErrorType<unknown>,
>(
  idUser: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsListHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersManagementAlertsListQueryKey(idUser)

  const usersManagementAlertsList = useUsersManagementAlertsListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsListHook>>>> = ({
    signal,
  }) => usersManagementAlertsList(idUser, signal)

  return { queryKey, queryFn, enabled: !!idUser, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersManagementAlertsListQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsListHook>>>
>
export type UsersManagementAlertsListQueryError = ErrorType<unknown>

/**
 * @summary Get alert subscription status for user
 */
export const useUsersManagementAlertsList = <
  TData = Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsListHook>>>,
  TError = ErrorType<unknown>,
>(
  idUser: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsListHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUsersManagementAlertsListQueryOptions(idUser, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Subscribe and unsubscribe user from alerts
 */
export const useUsersManagementAlertsCreateHook = () => {
  const usersManagementAlertsCreate = useCustomAxiosInstance<void>()

  return useCallback(
    (idUser: number, alertSubscriptionDetail: AlertSubscriptionDetail[]) => {
      return usersManagementAlertsCreate({
        url: `/users/management/${idUser}/alerts/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: alertSubscriptionDetail,
      })
    },
    [usersManagementAlertsCreate],
  )
}

export const useUsersManagementAlertsCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsCreateHook>>>,
    TError,
    { idUser: number; data: AlertSubscriptionDetail[] },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsCreateHook>>>,
  TError,
  { idUser: number; data: AlertSubscriptionDetail[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const usersManagementAlertsCreate = useUsersManagementAlertsCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsCreateHook>>>,
    { idUser: number; data: AlertSubscriptionDetail[] }
  > = (props) => {
    const { idUser, data } = props ?? {}

    return usersManagementAlertsCreate(idUser, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersManagementAlertsCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsCreateHook>>>
>
export type UsersManagementAlertsCreateMutationBody = AlertSubscriptionDetail[]
export type UsersManagementAlertsCreateMutationError = ErrorType<void>

/**
 * @summary Subscribe and unsubscribe user from alerts
 */
export const useUsersManagementAlertsCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsCreateHook>>>,
    TError,
    { idUser: number; data: AlertSubscriptionDetail[] },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUsersManagementAlertsCreateHook>>>,
  TError,
  { idUser: number; data: AlertSubscriptionDetail[] },
  TContext
> => {
  const mutationOptions = useUsersManagementAlertsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Search for users using either a user id, salesforce id, or match some query string to part of a username, organisation name, or email. Data should be provided in the query params.
 * @summary Search for users
 */
export const useUsersSearchListHook = () => {
  const usersSearchList = useCustomAxiosInstance<OpointUser[]>()

  return useCallback(
    (params?: UsersSearchListParams, signal?: AbortSignal) => {
      return usersSearchList({ url: `/users/search/`, method: 'GET', params, signal })
    },
    [usersSearchList],
  )
}

export const getUsersSearchListQueryKey = (params?: UsersSearchListParams) => {
  return [`/users/search/`, ...(params ? [params] : [])] as const
}

export const useUsersSearchListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useUsersSearchListHook>>>,
  TError = ErrorType<void>,
>(
  params?: UsersSearchListParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUsersSearchListHook>>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersSearchListQueryKey(params)

  const usersSearchList = useUsersSearchListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useUsersSearchListHook>>>> = ({ signal }) =>
    usersSearchList(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUsersSearchListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersSearchListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUsersSearchListHook>>>>
export type UsersSearchListQueryError = ErrorType<void>

/**
 * @summary Search for users
 */
export const useUsersSearchList = <
  TData = Awaited<ReturnType<ReturnType<typeof useUsersSearchListHook>>>,
  TError = ErrorType<void>,
>(
  params?: UsersSearchListParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUsersSearchListHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUsersSearchListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
