import { Save24Regular } from '@fluentui/react-icons'
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@opoint/infomedia-storybook'

import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { ActionBarDropdownMenuItem } from '../../ActionBarDropdownMenuItem'
import { ActionBarActionsProps, ActionVariant } from '../../types'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { getIsAlertSavingInProgress } from '../../../../../selectors/alertsSelectors'

const AlertSaveAction = ({ variant = ActionVariant.LONG, name }: ActionBarActionsProps) => {
  const dispatch = useAppDispatch()
  const onClickAction = () => dispatch({ type: 'SAVE_ALERT' })
  const isAlertSavingInProgress = useAppSelector(getIsAlertSavingInProgress)

  if (variant === ActionVariant.MENU) {
    return (
      <ActionBarDropdownMenuItem onClick={onClickAction} disabled={isAlertSavingInProgress} data-cy="alertSaveBtn">
        <Save24Regular />
        {name}
      </ActionBarDropdownMenuItem>
    )
  }

  if (variant === ActionVariant.LONG) {
    return (
      <Button variant="success" onClick={onClickAction} loading={isAlertSavingInProgress} data-cy="alertSaveBtn">
        <Save24Regular />
        {name}
      </Button>
    )
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="success"
          size="icon"
          onClick={onClickAction}
          data-cy="alertSaveBtn"
          loading={isAlertSavingInProgress}
        >
          <Save24Regular />
          <span className="sr-only">{name}</span>
        </Button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{name}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}

export default AlertSaveAction
