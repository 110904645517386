import { useTranslation } from 'react-i18next'

import ActionBar from '../ActionBar'
import { ActionBarRight } from '../ActionBarRight'

import InviteUserAction from './actions/InviteUserAction'

export const UserManagementActionBar = () => {
  const { t } = useTranslation()

  const actions = [
    {
      name: t('Invite user'),
      Action: InviteUserAction,
    },
  ]

  return (
    <ActionBar>
      <ActionBarRight items={actions} switcherVisible={false} />
    </ActionBar>
  )
}
