import { Trans, useTranslation } from 'react-i18next'

import { getHeaderUrls, getLangCodes } from '../../../../../opoint/articles'
import { translatedFromLanguages } from '../../../../../opoint/common/constants'
import { M360Article } from '../../../../../opoint/articles/types'

export type ArticleTranslationInfoProps = {
  article: M360Article
}

const ArticleTranslationInfo = ({ article }: ArticleTranslationInfoProps) => {
  const { t } = useTranslation()
  const { GTranslate_url, content_protected, language, orig_language, orig_url, translated, translated_count, url } =
    article

  if (!translated && !GTranslate_url) {
    return null
  }

  // @ts-expect-error: Muted so we could enable TS strict mode
  const urls: { primary: string; secondary: string } = getHeaderUrls({
    GTranslate_url,
    content_protected,
    orig_url,
    translated,
    url,
  })
  // @ts-expect-error: Muted so we could enable TS strict mode
  const langCodes: { orig: string; dest: string } = getLangCodes({ orig_language, language, GTranslate_url })
  const otherLanguage = translatedFromLanguages[translated ? langCodes.orig : langCodes.dest]

  if (!translated && !GTranslate_url) {
    return null
  }

  // TODO this essentially means that we are wrapping the language twice in transifex
  // It is possible (very rarely) that article is missing the orig_language prop, in that case don't attempt to translate
  const lang = orig_language && t(translatedFromLanguages[orig_language.text])

  return (
    <div className="mb-5 flex flex-col space-y-1">
      {translated && !!lang && (
        <p className="mb-0 text-label-s text-sky.cloudy">
          {translated_count ? (
            <Trans values={{lang, numberOfCharacters: translated_count}}>{'First {{numberOfCharacters}} characters were machine-translated from {{lang}}'}</Trans>
          ) : (
            <Trans>Machine-translated from {lang}</Trans>
          )}
        </p>
      )}

      {GTranslate_url && (
        <a
          className="text-label-s text-sky.4 hover:text-sky.4"
          href={urls.primary}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Trans values={{lang: t(otherLanguage)}}>{'Open original article in {{lang}}'}</Trans>
        </a>
      )}
    </div>
  )
}

export default ArticleTranslationInfo
