import { Outlet } from 'react-router-dom'
import Header from '../../components/topNav/Header'
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs'
import { ModulePermissionCheck } from '../permissions/ModulePermissionCheck'

const FeedRootPage = () => {
  return (
    <ModulePermissionCheck module="FEED_MODULE">
      <Header>
        <Breadcrumbs />
      </Header>

      <Outlet />
    </ModulePermissionCheck>
  )
}

export default FeedRootPage
