import { ReactNode } from 'react'

type ArticleLinkProps = {
  children: ReactNode
  isMobile?: boolean
  onClick?: (e?: any) => void
  url: string
}

const ArticleLink = ({ isMobile, onClick, children, url }: ArticleLinkProps) => {
  const isPdfSource = url.includes('paper.opoint.com')

  const onClickAnchor = (e) => {
    if (isMobile) {
      onClick?.(e)
    } else if (isPdfSource) {
      e.preventDefault()
      onClick?.()
    }
  }

  return (
    <a
      href={isPdfSource ? '#' : url}
      target={isPdfSource ? '' : '_blank'}
      rel="noopener noreferrer"
      onClick={onClickAnchor}
      className="text-sky.1 transition-colors hover:text-sky.4"
    >
      {children}
    </a>
  )
}

export default ArticleLink
