import { ActionsObservable, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import { AppActions } from '../../actions'
import { ScrollToTopAction } from '../../actions/ui'
import { SetActiveArticleAction } from '../../actions/articles'

const scrollToTop = (action$: ActionsObservable<AppActions>) =>
  action$.pipe(
    ofType<AppActions, ScrollToTopAction>('SCROLL_TO_TOP'),
    switchMap(() => {
      const articlePreviewsRef = document.getElementById('article-previews')

      if (articlePreviewsRef) {
        articlePreviewsRef.scrollTop = 0
      }

      return of<SetActiveArticleAction>({ type: 'SET_ACTIVE_ARTICLE', payload: { index: 0, source: 'scroll' } })
    }),
  )

export default [scrollToTop]
