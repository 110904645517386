import { BROADCAST_MEDIA_TYPES } from '../../../../opoint/common/constants'
import { getArticleModalStatus } from '../../../../selectors/uiSelectors'
import { MODULES } from '../../../constants/permissions'
import { useAppSelector } from '../../../hooks/useAppSelector'
import useArticleBaseProps from '../../../hooks/useArticleBaseProps'
import useIsUserPermitted from '../../../hooks/useIsUserPermitted'
import { SentimentType } from '../../../types/article'
import ArticleActionButtons from '../common/ArticleActionButtons'
import ArticleBody from '../common/ArticleBody'
import ArticleHeaderText from '../common/ArticleHeaderText/ArticleHeaderText'
import ArticleMetadata from '../common/ArticleMetadata/ArticleMetadata'
import ArticleSourceLine from '../common/ArticleSourceLine/ArticleSourceLine'
import ArticleTranslationInfo from '../common/ArticleTranslationInfo/ArticleTranslationInfo'
import { getEditedArticlesState } from '../../../../selectors/articlesSelectors'
import { articleIdFromIds } from '../../../../opoint/articles'
import { M360Article } from '../../../../opoint/articles/types'

type Props = {
  article: M360Article
  editable?: boolean
  editMode?: boolean
  expanded?: boolean
  hideActionButtons?: boolean
  index?: number
  isAlert?: boolean
  isEdited?: boolean
  onContentChange?: (any) => void
  openModal?: () => void
  shouldBeExpanded?: boolean
  translation: any
}

const PreviewArticle = ({
  article,
  editable = false,
  editMode = false,
  expanded = false,
  hideActionButtons = false,
  index,
  isAlert = false,
  isEdited = false,
  onContentChange,
  openModal,
  shouldBeExpanded,
  translation,
}: Props) => {
  const generalSentiment = article.topics_and_entities?.sentiment ?? SentimentType.Notset
  const { status: articleModalStatus } = useAppSelector(getArticleModalStatus) || {}
  const editedArticleState = useAppSelector(getEditedArticlesState)
  const { articleListingStyle } = useArticleBaseProps()
  const showCategorizationAsIcon = useIsUserPermitted({
    module: 'ENABLE_CATEGORIZATION',
    permissions: [MODULES.ENABLE_CATEGORIZATION.AS_ICON],
  })
  const isEditedArticle = editedArticleState.includes(articleIdFromIds(article.id_site, article.id_article))
  const isSticky = articleModalStatus && articleListingStyle && showCategorizationAsIcon
  // @ts-expect-error: Muted so we could enable TS strict mode
  const headerText = isEditedArticle ? article.header.text : article.short_header?.text ?? article.header.text

  return (
    <>
      {!hideActionButtons && (
        <ArticleActionButtons
          article={article}
          sharingDisabled={article.shareable === false}
          translation={translation}
          shouldShowRequestSummary={!article.manual_summary}
          // @ts-expect-error: Muted so we could enable TS strict mode
          stickyMode={isSticky}
          isAlert={isAlert}
        />
      )}
      <ArticleSourceLine
        article={article}
        edited={article.edited || isEdited}
        editMode={editMode}
        articleIndex={index}
      />

      <ArticleMetadata article={article} sentiment={generalSentiment} showSentiment={!isAlert} />

      <ArticleTranslationInfo article={article} />

      <ArticleHeaderText
        content_protected={article.content_protected}
        editable={editable}
        onContentChange={onContentChange}
        onClickArticleLink={openModal}
        orig_url={article.orig_url}
        text={headerText}
        url={article.url}
        // @ts-expect-error: Muted so we could enable TS strict mode
        translatedHeader={
          article.translated_header && {
            translated: article.translated_header?.text,
            // @ts-expect-error: Muted so we could enable TS strict mode
            original: !BROADCAST_MEDIA_TYPES?.includes(article.mediatype?.text) && article.header.text,
          }
        }
      />

      <ArticleBody
        article={article}
        expanded={expanded}
        editable={editable}
        onClickArticleLinkPdf={() => openModal?.()}
        onContentChange={onContentChange}
        // @ts-expect-error: Muted so we could enable TS strict mode
        summaryText={article.summary.text}
        shouldBeExpanded={shouldBeExpanded}
      />
    </>
  )
}

export default PreviewArticle
