import { ActionsObservable, StateObservable, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { AppActions } from '../actions'
import {
  SentimentCreateGroupAction,
  SentimentCreateGroupFailureAction,
  SentimentFetchEntitiesAction,
  SentimentFetchGroupAction,
  SentimentFetchGroupsFailureAction,
  SentimentOverrideCompanyAction,
  SentimentOverrideCompanySentimentFailureAction,
  SentimentUpdateGroupAction,
  SentimentUpdateGroupFailureAction,
} from '../actions/sentiment'
import { getMultipleSuggestions } from '../opoint/search'
import {
  createSentimentGroup,
  fetchSentimentGroups,
  overrideArticleCompanySentiment,
  updateSentimentGroup,
} from '../opoint/sentiment'
import { RootState } from '../reducers'
import { getSentimentGroups } from '../selectors/sentimentSelectors'
import { getSuggestionLocale } from '../selectors/settingsSelectors'

import { SettingsFetchSuccessAction } from '../actions/settings'
import { FilterSuggestion } from '../api/opoint-search-suggest.schemas'
import { NewPortalShowSentimentSetting } from '../components/interfaces/settings'
import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'

const sentimentFetchEpic: (actions$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, SettingsFetchSuccessAction>('SETTINGS_FETCH_SUCCESS'),
    switchMap(({ payload }) => {
      const showSentimentSettings = payload.find(
        (item) => item.name === 'NEW_PORTAL_SHOW_SENTIMENT',
      ) as NewPortalShowSentimentSetting

      //The value is in a bitstring
      const sentimentPermission = showSentimentSettings.value

      //if value & 1 !== 0 you have access to the endpoint
      if (sentimentPermission && (sentimentPermission & 1) !== 0) {
        return of<SentimentFetchGroupAction>({ type: 'SENTIMENT_FETCH_GROUPS' })
      }

      return of()
    }),
  )

const sentimentFetchGroupsEpic: (actions$: ActionsObservable<AppActions>) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, SentimentFetchGroupAction>('SENTIMENT_FETCH_GROUPS'),
    switchMap(() =>
      from(fetchSentimentGroups()).pipe(
        map((sentimentGroups) => ({ type: 'SENTIMENT_FETCH_GROUPS_SUCCESS', payload: sentimentGroups })),
      ),
    ),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<SentimentFetchGroupsFailureAction>({ type: 'SENTIMENT_FETCH_GROUPS_FAILURE' })),
  )

const overrideCompanySentimentEpic: (
  actions$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$, { state$ }) =>
  action$.pipe(
    ofType<AppActions, SentimentOverrideCompanyAction>('OVERRIDE_COMPANY_SENTIMENT'),
    switchMap(({ payload }) => {
      const state = state$.value
      const { article, id_group } = payload || {}
      const sentimentGroups = getSentimentGroups(state)
      const { id_group: id } = sentimentGroups[0] || {}

      // endpoint accepts article array, but we only send one article at a time
      const body = { id_group: id_group || id, articles: [article] }

      return from(overrideArticleCompanySentiment(body)).pipe(
        map(() => ({ type: 'OVERRIDE_COMPANY_SENTIMENT_SUCCESS', payload: { article } })),
      )
    }),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() =>
      of<SentimentOverrideCompanySentimentFailureAction>({ type: 'OVERRIDE_COMPANY_SENTIMENT_FAILURE' }),
    ),
  )

const sentimentFetchEntitiesEpic: (
  actions$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$, { state$ }) =>
  action$.pipe(
    ofType<AppActions, SentimentFetchEntitiesAction>('SENTIMENT_FETCH_ENTITIES'),
    switchMap(({ payload: { searchterm } }) => {
      const state = state$.value
      const suggestionsLocale = getSuggestionLocale(state)
      const accessParameterWithEntitiesOnly = 65536

      return from(
        getMultipleSuggestions(searchterm, [], suggestionsLocale, [], undefined, accessParameterWithEntitiesOnly),
      ).pipe(
        // @ts-expect-error: Muted so we could enable TS strict mode
        map((response: { ent: { suggest: FilterSuggestion[] } }) => ({
          type: 'SENTIMENT_FETCH_ENTITIES_SUCCESS',
          payload: response?.ent?.suggest || [],
        })),
      )
    }),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
  )

const sentimentCreateGroupEpic: (
  actions$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, SentimentCreateGroupAction>('SENTIMENT_CREATE_GROUP'),
    switchMap(({ payload: { groupName, ids } }) => {
      return from(createSentimentGroup({ name: groupName, ids })).pipe(
        map((response) => ({ type: 'SENTIMENT_CREATE_GROUP_SUCCESS', payload: response })),
      )
    }),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<SentimentCreateGroupFailureAction>({ type: 'SENTIMENT_CREATE_GROUP_FAILURE' })),
  )

const sentimentUpdateGroupEpic: (
  actions$: ActionsObservable<AppActions>,
  { state$ }: { state$: StateObservable<RootState> },
) => void = (action$) =>
  action$.pipe(
    ofType<AppActions, SentimentUpdateGroupAction>('SENTIMENT_UPDATE_GROUP'),
    switchMap(({ payload: { groupId, ids } }) => {
      return from(updateSentimentGroup(groupId, ids)).pipe(
        map((response) => ({ type: 'SENTIMENT_UPDATE_GROUP_SUCCESS', payload: response })),
      )
    }),
    catchError(logOutOnExpiredToken),
    catchError(serverIsDown),
    catchError(() => of<SentimentUpdateGroupFailureAction>({ type: 'SENTIMENT_UPDATE_GROUP_FAILURE' })),
  )

export default [
  sentimentFetchEpic,
  overrideCompanySentimentEpic,
  sentimentFetchEntitiesEpic,
  sentimentCreateGroupEpic,
  sentimentUpdateGroupEpic,
  sentimentFetchGroupsEpic,
]
