import { RootState } from '../reducers'

export const getRootState = (appState: RootState) => appState
export const getAlerts = (appState: RootState) => appState.alerts
export const getArticles = (appState: RootState) => appState.articles
export const getProfiles = (appState: RootState) => appState.profiles
export const getSearch = (appState: RootState) => appState.search
export const getSettings = (appState: RootState) => appState.settings
export const getTags = (appState: RootState) => appState.tags
export const getUI = (appState: RootState) => appState.ui
export const getFeeds = (appState: RootState) => appState.feeds
export const getForm = (appState) => appState.form
export const getContacts = (appState: RootState) => appState.contacts
export const getStatistics = (appState: RootState) => appState.statistics
export const getTrashTags = (appState: RootState) => appState.trash
export const getEntities = (appState: RootState) => appState.entities
export const getReports = (appState: RootState) => appState.reports
export const getReportsHistory = (appState: RootState) => appState.reportsHistory
export const getTemplates = (appState: RootState) => appState.templates
export const getAnalytics = (appState: RootState) => appState.analytics
export const getWatchIndexes = (appState: RootState) => appState.watchIndex
export const getFolders = (appState: RootState) => appState.folders
export const getSentiment = (appState: RootState) => appState.sentiment
export const getImpersonationTargets = (appState: RootState) => appState.impersonation
export const getComments = (appState: RootState) => appState.comments
