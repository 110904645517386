import {
  Button,
  Pagination,
  PaginationControl,
  PaginationNext,
  PaginationPrevious,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  ScrollAreaViewPort,
} from '@opoint/infomedia-storybook'
import { Trans, useTranslation } from 'react-i18next'

import { getSimilarArticlesVisible } from '../../../../../selectors/settingsSelectors'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import NonInteractiveIdenticalArticles from '../../../NonInteractiveIdenticalArticles'

import { M360Article } from '../../../../../opoint/articles/types'
import IdenticalArticlesItem from './IdenticalArticlesItem'

type IdenticalArticlesProps = {
  article: M360Article
  activeArticle: M360Article
  identicalCount: number
  isProfileEditor: boolean
  isAlert: boolean
}

const IdenticalArticles = ({
  article,
  activeArticle,
  identicalCount,
  isProfileEditor,
  isAlert,
}: IdenticalArticlesProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const showSimilarArticles = useAppSelector(getSimilarArticlesVisible)

  const articles = article?.identical_documents?.document || []

  const handlePreviousClick = () => {
    if (isProfileEditor) {
      dispatch({
        type: 'PREVIOUS_PROFILE_EDITOR_PREVIEW_IDENTICAL',
        payload: article,
      })

      return
    }
    dispatch({
      type: 'PREVIOUS_IDENTICAL',
      payload: {
        article,
        isAlert,
      },
    })
  }

  const handleNextClick = () => {
    if (isProfileEditor) {
      dispatch({
        type: 'NEXT_PROFILE_EDITOR_PREVIEW_IDENTICAL',
        payload: article,
      })

      return
    }
    dispatch({
      type: 'NEXT_IDENTICAL',
      payload: {
        article,
        isAlert,
      },
    })
  }

  return showSimilarArticles ? (
    <Popover>
      <Pagination>
        <PaginationPrevious onClick={handlePreviousClick} aria-label={t('Previous')} />

        <PopoverTrigger asChild>
          <PaginationControl>
            <Trans values={{ identicalCount: identicalCount + 1, identicalDocumentsCount: articles.length }}>
              {'Similar {{identicalCount}} of {{identicalDocumentsCount}}'}
            </Trans>
          </PaginationControl>
        </PopoverTrigger>

        <PopoverContent className="px-0 py-4" side="top">
          <ScrollArea>
            <ScrollAreaViewPort className="max-h-80">
              <ul>
                {articles.map((identicalArticle, index) => (
                  <IdenticalArticlesItem
                    key={identicalArticle.id}
                    article={article}
                    activeArticle={activeArticle}
                    identicalArticle={identicalArticle}
                    isProfileEditor={isProfileEditor}
                    isAlert={isAlert}
                    index={index}
                  />
                ))}
              </ul>
            </ScrollAreaViewPort>
          </ScrollArea>
        </PopoverContent>

        <PaginationNext onClick={handleNextClick} aria-label={t('Next')} />
      </Pagination>
    </Popover>
  ) : (
    <NonInteractiveIdenticalArticles
      defaultShow={false}
      // @ts-expect-error: Muted so we could enable TS strict mode
      similarArticles={article.identical_documents.document}
      activeArticle={article}
      trigger={
        <Button>
          <Trans>Similar articles</Trans>
        </Button>
      }
    />
  )
}

export default IdenticalArticles
