import { TagColor } from '@opoint/infomedia-storybook'

import { TAG_TYPES } from '../../opoint/tags'

import { Trash } from './trash'

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}
export enum SortType {
  Date = 'date',
  Priority = 'priority',
}

export enum TagVisibilityType {
  Always = 2,
  WhenSet = 1,
}

export type Tag = {
  color: TagColor
  combo: string
  category: number
  id: number
  index: number
  lastSort: string
  last_set: string
  name: string
  score: number
  statistics: string
  // TODO: Change the type to TAG_TYPES.KEYWORD and TAG_TYPES.MENTOMETER
  type: TAG_TYPES
  url: string
  visibility: TagVisibilityType
  subject: string
  children?: Tag[]
  folder: number
}

export type GeneralTag = Tag | Trash | AlertTag | ChildAlertTag

export type AlertTag = {
  children: Array<ChildAlertTag>
  expanded: boolean
  id: number
  name: string
  subject: string
  type: TAG_TYPES.ALERT
  visibility: number
}

export type ChildAlertTag = {
  id: number
  name: string
  type: TAG_TYPES.ALERT
}

export type TagCreate = {
  visibility: number
  combo?: string
  name: string
  type: TAG_TYPES
  folder: number
  color: TagColor
}
