import {
  ArrowSortRegular,
  BriefcaseRegular,
  ChatRegular,
  ContentViewRegular,
  DataTrendingRegular,
  DocumentTextRegular,
  DocumentToolboxRegular,
  NumberSymbolSquareRegular,
  StarRegular,
  TagRegular,
} from '@fluentui/react-icons'
import { isEmpty } from 'ramda'
import { MIN_CUSTOM_FOLDER_ID } from '../components/helpers/common'
import { Folder } from '../components/types/folder'

const traits = [-3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 10] as const

export const folderTraits = {
  [-3]: 'filtersOnly',
  [-2]: 'categorizationOnly',
  [-1]: 'categorizationAndFilters',
  0: 'regular',
  1: 'curated',
  2: 'nonCurated',
  3: 'socialMedia',
  4: 'frontPages',
  5: 'curatedMenuAndFilters',
  6: 'nonCuratedMenuAndFilters',
  7: 'socialMediaMenuAndFilters',
  10: 'special',
} as const

export type FolderTrait = (typeof traits)[number]
export type FolderTraitName = (typeof folderTraits)[FolderTrait]

export const folderTypes = {
  0: 'profiles',
  1: 'tags',
  2: 'sentimentTags',
  4: 'alerts',
  6: 'statistics',
}

export enum FolderType {
  PROFILES = 0,
  TAGS = 1,
  SENTIMENT_TAGS = 2,
  ALERTS = 4,
  STATISTICS = 6,
}

// Mapping the backend icon names to Fluent Icon elements
export const folderIcons: { [index: string]: JSX.Element } = {
  star: <StarRegular />,
  briefcase: <BriefcaseRegular />,
  tag: <TagRegular />,
  up_and_down_arrows: <ArrowSortRegular />,
  statistic: <DataTrendingRegular />,
  curated_profiles: <DocumentToolboxRegular />,
  non_curated_profiles: <DocumentTextRegular />,
  speech_bubble: <ChatRegular />,
  front_pages: <ContentViewRegular />,
  hashtag_small: <NumberSymbolSquareRegular />,
}

export type FolderIcon = keyof typeof folderIcons

export const defaultFolderIcons = {
  0: <StarRegular />,
  1: <TagRegular />,
  2: <ArrowSortRegular />,
  6: <DataTrendingRegular />,
}

export const getFolderIcon = (folder: Folder, isECBUser: boolean) => {
  const groupAndIconNames: {
    typeIcons: { [index: string]: JSX.Element }
    categoryIcons: { [index: string]: JSX.Element }
  } = {
    typeIcons: {
      0: isECBUser ? <BriefcaseRegular /> : <StarRegular />,
      1: <TagRegular />,
      2: <ArrowSortRegular />,
      6: <DataTrendingRegular />,
    },
    categoryIcons: {
      0: <StarRegular />,
      1: <DocumentToolboxRegular />,
      2: <DocumentTextRegular />,
      3: <ChatRegular />,
      4: <ContentViewRegular />,
      5: <DocumentToolboxRegular />,
      6: <DocumentTextRegular />,
      7: <ChatRegular />,
      10: <NumberSymbolSquareRegular />,
    },
  }

  const { icon, id, traits, type } = folder || {}

  if (!isEmpty(icon) && !isECBUser) {
    // @ts-expect-error: Muted so we could enable TS strict mode
    return folderIcons[icon]
  }

  const isDefaultFolder = id < MIN_CUSTOM_FOLDER_ID

  return isDefaultFolder || folderTraits[traits] === 'regular'
    ? groupAndIconNames.typeIcons[type]
    : groupAndIconNames.categoryIcons[traits]
}
