import { createSelector } from 'reselect'

import { flattenDeep } from 'lodash-es'
import { Modules } from '../components/templates/TemplateEditor/types'
import { EXCLUDED_TEMPLATES, objectDiff, TEMPLATE_TYPES } from '../opoint/templates'

import { groupBy } from '../helpers/common'
import { TemplateModule, Widget } from '../opoint/flow'
import { getForm, getTemplates as getState } from './rootSelectors'

export const getTemplates = createSelector(getState, (templatesState) => templatesState.list)

export const isPreviewModalOpen = createSelector(getState, (templatesState) => templatesState.previewModalIsOpen)

export const getTemplatePreview = createSelector(
  getState,
  (templatesState) => templatesState.lastFetchedTemplate && templatesState.lastFetchedTemplate.preview,
)

export const getGroupedTemplates = createSelector(getTemplates, (templates) => groupBy(templates, 'type'))

export const getGroupedTemplatesWithoutExcluded = createSelector(getTemplates, (templates) => {
  const filteredTemplates = templates.filter((template) => !EXCLUDED_TEMPLATES.includes(template.id))
  return groupBy(filteredTemplates, 'type')
})

export const getDefaultTemplate = createSelector(getTemplates, (templates) => templates[0])

export const getTemplateModules = (templateId?: number) =>
  createSelector(getState, (templateState) => {
    if (!templateId) {
      return null
    }
    const templateDetail = templateState.templateDetails[templateId]

    if (templateDetail) {
      return templateDetail.modules
    }

    return null
  })

export const getTemplateMaxLevel = (templateId?: number) =>
  createSelector(getState, (templateState) => {
    const templateDetails = templateId && templateState.templateDetails[templateId]

    return templateDetails ? templateDetails.maxLevel : 0
  })

const childBitPairs = (childrenCount, value) => {
  const pairs = {}
  let tmp = value

  for (let i = 0; i < childrenCount; i++) {
    pairs[Math.pow(2, i)] = tmp % 2
    tmp = Math.floor(tmp / 2)
  }

  return pairs
}

export const updateChildren = (children, value) => {
  const pairs = childBitPairs(children.length, value)

  return children.map((child) => ({
    ...child,
    value: pairs[child.dataValue],
  }))
}

const mapInitialValues = (widgets: Widget[], groupName: string) =>
  widgets.map((widget) => {
    if (widget) {
      const { name, value, type } = widget
      switch (type) {
        case 'WIDGET_INPUT_BIG':
          return { [name]: value }
        case 'WIDGET_VALUE_RADIO':
        case 'WIDGET_DROPDOWN': {
          let selectedOption = {}
          value?.forEach?.((option: any) => {
            if (option.selected) {
              selectedOption = { [name]: option.value }
            }
          })

          return selectedOption
        }
        case 'WIDGET_GROUP': {
          const { parent, children, childSetting, name: groupName } = widget
          const intValue = parseInt(value, 10)
          // If there is value greater than 0 and childSetting is false,
          // than update children values according to group value
          const kids = intValue && !childSetting ? updateChildren(children, intValue) : children
          if (parent) {
            return [{ [parent.name]: !!parseInt(parent.value, 10) }, ...mapInitialValues(kids, groupName)]
          }

          return [...mapInitialValues(kids, groupName)]
        }
        case 'WIDGET_RADIO': {
          if (parseInt(value, 10)) {
            return { [groupName]: name }
          }

          return null
        }
        case 'WIDGET_SLIDER':
          return { [name]: parseInt(value.selected, 10) }
        case 'WIDGET_TEXTAREA_SMALL':
          return { [name]: value }
        case 'WIDGET_CHECKBOX':
          return { [name]: !!parseInt(value, 10) }
        default:
          return { [name]: value }
      }
    }
  })

const getWidgets = (modules: TemplateModule[]): Record<string, boolean | string | number> => {
  const widgets = modules.flatMap((module) => module.widgets)
  const initialValues = mapInitialValues(widgets, '') // Provide an empty string as the second argument
  const unnestedValues = flattenDeep(initialValues)
  return Object.assign({}, ...unnestedValues)
}

export const getTemplateValues = ({ modules }) => getWidgets(Object.values(modules))

export const getActiveTemplate = createSelector(getState, (templatesState) => templatesState.activeTemplate)

export const getTemplateSortableModules = createSelector(getState, (templatesState) => templatesState.sortableModules)

export const getSortableModules = (sortableModules: Modules) =>
  Object.values(sortableModules)
    .flatMap((module) => module)
    .reduce((acc, widget) => {
      const name = widget.name
      if (!acc[name]) {
        acc[name] = widget.sortIndex
      }

      return acc
    }, {})

export const getPreparedSubmittedValuesForSend = (
  values: Record<string, unknown>,
  isNewTemplate: boolean,
  sortableModules: Modules,
  initialValues: Record<string, unknown>,
) => {
  const validValues = []
  const sortableWidgetsSortIndexes = getSortableModules(sortableModules)

  const settings = isNewTemplate ? values : objectDiff(initialValues, values, sortableWidgetsSortIndexes)
  let includeQrValue = 0

  Object.keys(settings).forEach((key) => {
    let value = settings[key]
    switch (key) {
      case 'REPORT_LIST_HEADER':
        // @ts-expect-error: Muted so we could enable TS strict mode
        value ? validValues.push({ name: key, value }) : validValues.push({ name: key, value: '' })
        break
      case 'RT_INC_QR_0':
        break
      case 'RT_INC_QR_1':
        value && (includeQrValue += 1)
        break
      case 'RT_INC_QR_2':
        value && (includeQrValue += 2)
        break
      case 'RT_INC_IDENTICAL':
        if (value !== initialValues[key]) {
          if (value === 'RT_INC_IDENTICAL_0') {
            // @ts-expect-error: Muted so we could enable TS strict mode
            validValues.push({ name: 'RT_INC_IDENTICAL', value: 0 })
          }
          if (value === 'RT_INC_IDENTICAL_1') {
            // @ts-expect-error: Muted so we could enable TS strict mode
            validValues.push({ name: 'RT_INC_IDENTICAL', value: 1 })
          }
          if (value === 'RT_INC_IDENTICAL_2') {
            // @ts-expect-error: Muted so we could enable TS strict mode
            validValues.push({ name: 'RT_INC_IDENTICAL', value: 2 })
          }
        }
        break
      case 'RT_INC_PDF_GROUP':
      case 'RT_INC_TOC': {
        let boolValue
        // RT_INC_TOC checked but there are no any selected radio
        if (value === true) {
          // We always have to send `RT_INC_TOC` state
          // @ts-expect-error: Muted so we could enable TS strict mode
          validValues.push({ name: key, value: +value })
          boolValue = +value
          // Set RT_INC_TOC_ORIG_LINK as a default if no other radio was selected
          /* eslint-disable-next-line no-param-reassign */
          value = 'RT_INC_TOC_ORIG_LINK'
          // Unchecking RT_INC_TOC unchecks itself and radios
        } else if (value === false) {
          boolValue = +value
          /* eslint-disable-next-line no-param-reassign */
          value = key
          // Turn some radio on
        } else {
          // Always here send RT_INC_TOC: 1
          // @ts-expect-error: Muted so we could enable TS strict mode
          validValues.push({ name: key, value: 1 })
          boolValue = 1
        }

        // Setting which changes
        // @ts-expect-error: Muted so we could enable TS strict mode
        validValues.push({ name: value, value: boolValue })

        if (initialValues[key]) {
          // @ts-expect-error: Muted so we could enable TS strict mode
          validValues.push({ name: initialValues[key], value: 0 }) // Turn off selected settings
        }
        break
      }
      default: {
        const sortIndex = sortableWidgetsSortIndexes[key]
        if (typeof values[key] === 'boolean') {
          value = Number(values[key])
        }

        sortIndex !== undefined
          ? // @ts-expect-error: Muted so we could enable TS strict mode
            validValues.push({ name: key, value, sortIndex })
          : // @ts-expect-error: Muted so we could enable TS strict mode
            validValues.push({ name: key, value })
      }
    }
  })

  if (
    initialValues.RT_INC_QR_0 !== undefined &&
    (initialValues.RT_INC_QR_1 !== values.RT_INC_QR_1 || initialValues.RT_INC_QR_2 !== values.RT_INC_QR_2)
  ) {
    // @ts-expect-error: Muted so we could enable TS strict mode
    validValues.push({ name: 'RT_INC_QR', value: includeQrValue })
  }

  return validValues
}

// In the end this cannot be memoized
// because if we save new template old values are persisted until page is refreshed
export const getTemplateInitialValues = (templateId?: number) =>
  createSelector(getTemplateModules(templateId), (modules) => getTemplateValues({ modules }))

export const getXlsTemplates = createSelector(getTemplates, (templatesList) =>
  templatesList.filter((template) => template.type === TEMPLATE_TYPES.XLS),
)

export const getTemplateEditName = createSelector(
  getForm,
  (formState) => formState?.templateEditor?.initial?.REPORT_LIST_NAME,
)

export const getActiveModuleName = createSelector(getState, (templatesState) => templatesState.activeSortableModule)

export const getTemplateActiveModuleWidgets = createSelector(
  getActiveModuleName,
  getTemplateSortableModules,
  (moduleName, modules) => modules[moduleName],
)
