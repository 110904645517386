import {
  TimeConfigurationItem,
  TimeConfigurationStored,
  TimeConfigurationStoredWithoutMeta,
  TimeConfigurationItemStored,
  TimeConfiguration,
} from '../flow'
import { DAY_CONST } from './weekdays'
import { ALL_DAYS, WEEKEND_DAYS, WORKING_DAYS } from './index'

export function toAPITimeFormat(data: TimeConfigurationStored) {
  return internalDataToAPITimeFormat(toInternalData(data))
}

export function internalDataToAPITimeFormat(data: TimeConfigurationStoredWithoutMeta) {
  return Object.entries(data).reduce((prev, [key, value]) => {
    const dayId = parseInt(DAY_CONST[key].key, 10)
    return { ...prev, [key]: calculateAPITime(value, dayId) }
  }, {})
}

function calculateAPITime(dayIntervals: TimeConfigurationItemStored[], dayId: number) {
  return dayIntervals.map((day) => {
    const start = day.start ?? 0
    const end = day.end ?? 0

    const ret: TimeConfigurationItem = { start: day.interval[0] * 60 * 60 + 24 * 60 * 60 * dayId + start * 60 }

    if (day.interval.length > 1) {
      ret.end = day.interval[day.interval.length - 1] * 60 * 60 + 24 * 60 * 60 * dayId + end * 60
      ret.interval = 0
    }
    if (day.sendInterval) {
      ret.interval = day.sendInterval
    }

    return ret
  })
}

export function toInternalData(data: TimeConfigurationStored): TimeConfigurationStoredWithoutMeta {
  const internals = Object.entries(data).reduce((prev, [key, value]) => {
    const transformed = { ...prev }
    if (key === 'alldays') {
      ALL_DAYS.forEach((day) => (transformed[day] = value))
    }
    if (key === 'weekend') {
      WEEKEND_DAYS.forEach((day) => (transformed[day] = value))
    }
    if (key === 'weekdays') {
      WORKING_DAYS.forEach((day) => (transformed[day] = value))
    }

    if (ALL_DAYS.includes(key)) {
      transformed[key] = value
    }

    return transformed
  }, {})
  return internals
}

export function toTimeConfigInternal(data: TimeConfiguration) {
  return Object.entries(data).reduce((prev, [key, value]) => {
    const dayId = parseInt(DAY_CONST[key].key, 10)
    return { ...prev, [key]: toTimeConfigTransform(value, dayId) }
  }, {})
}

export function toTimeConfigTransform(intervals, day: number) {
  return intervals.map((interval) => {
    const startInterval = Math.floor(interval.start / (60 * 60) - day * 24)
    const ret: TimeConfigurationItemStored = {
      interval: [],
      start: Math.round((interval.start / (60 * 60) - day * 24 - startInterval) * 60),
    }

    if (interval.end) {
      const endInterval = Math.floor(interval.end / (60 * 60) - day * 24)
      ret.end = Math.round((interval.end / (60 * 60) - day * 24 - endInterval) * 60)
      // ret.interval = R.range(startInterval, endInterval + 1)
      ret.interval = Array.from({ length: endInterval - startInterval + 1 }, (_, i) => startInterval + i)
    } else {
      ret.interval.push(startInterval)
    }
    if (interval.interval) {
      ret.sendInterval = interval.interval
    }

    if (ret.interval.length > 24) {
      ret.interval = ret.interval.slice(0, 25)
    }

    return ret
  })
}

export function toDisplayTimeFormat(data: TimeConfiguration) {
  return unifySameTimes(toTimeConfigInternal(data))
}

export function unifySameTimes(data) {
  const reversed: Record<string, Set<string>> = Object.entries(data).reduce((prev, [key, value]) => {
    const stringifiedSchedule = JSON.stringify(value)
    const daysSet = stringifiedSchedule in prev ? prev[stringifiedSchedule] : new Set()
    daysSet.add(key)
    return { ...prev, [stringifiedSchedule]: daysSet }
  }, {})

  const unified = Object.entries(reversed).reduce((prev, [key, value]) => {
    const schedule = JSON.parse(key)
    if (ALL_DAYS.every((day) => value.has(day))) {
      return { ...prev, alldays: schedule }
    }

    if (WORKING_DAYS.every((day) => value.has(day))) {
      return { ...prev, weekdays: schedule }
    }

    const reversedBack = Array.from(value).reduce(
      (newprev, day) => ({
        ...newprev,
        [day]: schedule,
      }),
      {},
    )
    return { ...prev, ...reversedBack }
  }, {})
  return unified
}
