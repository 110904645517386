import { switchMap } from 'rxjs/operators'

import { ActionsObservable } from 'redux-observable'

import { t } from "i18next";
import { AuthActions } from '../../../actions/auth'
import { SettingsFetchFailureAction } from '../../../actions/settings'
import { ErrorEpic, handlerError } from '.'

const settingsErrorTypes: ErrorEpic<SettingsFetchFailureAction['type']> = {
  ['SETTINGS_FETCH_FAILURE']: {
    isSuccess: false,
    message: t('Settings fetch failed'),
    toastId: 'SETTINGS_FETCH_FAILED',
  },
}

const settingsErrorEpic: (action: ActionsObservable<AuthActions>) => void = (action$) =>
  action$.pipe(switchMap(({ type }) => handlerError(settingsErrorTypes, type)))

export default settingsErrorEpic
