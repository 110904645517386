/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Infomedia API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  ArticleBasket,
  ArticleBasketDelete,
  Articles,
  DeleteTagResponse,
  LastSortedResponse,
  PatchedTagDetail,
  SortTagContent,
  TagDetail,
  TagReport,
  TagSort,
  TaggedArticle,
  TaggedArticleDetection,
  TaggedArticleDetectionResponse,
  TagsListParams,
  TagsReportsListParams,
  Updated,
} from '../opoint.schemas'
import { useCustomAxiosInstance } from '.././custom-client'
import type { ErrorType } from '.././custom-client'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

/**
 * Get a list of tags.
 * @summary List
 */
export const useTagsListHook = () => {
  const tagsList = useCustomAxiosInstance<TagDetail[]>()

  return useCallback(
    (params?: TagsListParams, signal?: AbortSignal) => {
      return tagsList({ url: `/tags/`, method: 'GET', params, signal })
    },
    [tagsList],
  )
}

export const getTagsListQueryKey = (params?: TagsListParams) => {
  return [`/tags/`, ...(params ? [params] : [])] as const
}

export const useTagsListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useTagsListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: TagsListParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useTagsListHook>>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getTagsListQueryKey(params)

  const tagsList = useTagsListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useTagsListHook>>>> = ({ signal }) =>
    tagsList(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type TagsListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useTagsListHook>>>>
export type TagsListQueryError = ErrorType<unknown>

/**
 * @summary List
 */
export const useTagsList = <
  TData = Awaited<ReturnType<ReturnType<typeof useTagsListHook>>>,
  TError = ErrorType<unknown>,
>(
  params?: TagsListParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useTagsListHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTagsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create a new tag.
 * @summary Add
 */
export const useTagsCreateHook = () => {
  const tagsCreate = useCustomAxiosInstance<TagDetail>()

  return useCallback(
    (tagDetail: NonReadonly<TagDetail>) => {
      return tagsCreate({
        url: `/tags/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: tagDetail,
      })
    },
    [tagsCreate],
  )
}

export const useTagsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsCreateHook>>>,
    TError,
    { data: NonReadonly<TagDetail> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTagsCreateHook>>>,
  TError,
  { data: NonReadonly<TagDetail> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const tagsCreate = useTagsCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTagsCreateHook>>>,
    { data: NonReadonly<TagDetail> }
  > = (props) => {
    const { data } = props ?? {}

    return tagsCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TagsCreateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useTagsCreateHook>>>>
export type TagsCreateMutationBody = NonReadonly<TagDetail>
export type TagsCreateMutationError = ErrorType<unknown>

/**
 * @summary Add
 */
export const useTagsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsCreateHook>>>,
    TError,
    { data: NonReadonly<TagDetail> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTagsCreateHook>>>,
  TError,
  { data: NonReadonly<TagDetail> },
  TContext
> => {
  const mutationOptions = useTagsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Add an article to the tag.
 * @summary Tag article
 */
export const useTagsArticlesTagCreateHook = () => {
  const tagsArticlesTagCreate = useCustomAxiosInstance<TaggedArticle>()

  return useCallback(
    (idBasket: number, articleBasket: ArticleBasket) => {
      return tagsArticlesTagCreate({
        url: `/tags/${idBasket}/articles/tag/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: articleBasket,
      })
    },
    [tagsArticlesTagCreate],
  )
}

export const useTagsArticlesTagCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsArticlesTagCreateHook>>>,
    TError,
    { idBasket: number; data: ArticleBasket },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTagsArticlesTagCreateHook>>>,
  TError,
  { idBasket: number; data: ArticleBasket },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const tagsArticlesTagCreate = useTagsArticlesTagCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTagsArticlesTagCreateHook>>>,
    { idBasket: number; data: ArticleBasket }
  > = (props) => {
    const { idBasket, data } = props ?? {}

    return tagsArticlesTagCreate(idBasket, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TagsArticlesTagCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTagsArticlesTagCreateHook>>>
>
export type TagsArticlesTagCreateMutationBody = ArticleBasket
export type TagsArticlesTagCreateMutationError = ErrorType<void>

/**
 * @summary Tag article
 */
export const useTagsArticlesTagCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsArticlesTagCreateHook>>>,
    TError,
    { idBasket: number; data: ArticleBasket },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTagsArticlesTagCreateHook>>>,
  TError,
  { idBasket: number; data: ArticleBasket },
  TContext
> => {
  const mutationOptions = useTagsArticlesTagCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Remove an article from the tag.
 * @summary Untag article
 */
export const useTagsArticlesUntagCreateHook = () => {
  const tagsArticlesUntagCreate = useCustomAxiosInstance<TaggedArticle>()

  return useCallback(
    (idBasket: number, articleBasketDelete: ArticleBasketDelete) => {
      return tagsArticlesUntagCreate({
        url: `/tags/${idBasket}/articles/untag/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: articleBasketDelete,
      })
    },
    [tagsArticlesUntagCreate],
  )
}

export const useTagsArticlesUntagCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsArticlesUntagCreateHook>>>,
    TError,
    { idBasket: number; data: ArticleBasketDelete },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTagsArticlesUntagCreateHook>>>,
  TError,
  { idBasket: number; data: ArticleBasketDelete },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const tagsArticlesUntagCreate = useTagsArticlesUntagCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTagsArticlesUntagCreateHook>>>,
    { idBasket: number; data: ArticleBasketDelete }
  > = (props) => {
    const { idBasket, data } = props ?? {}

    return tagsArticlesUntagCreate(idBasket, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TagsArticlesUntagCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTagsArticlesUntagCreateHook>>>
>
export type TagsArticlesUntagCreateMutationBody = ArticleBasketDelete
export type TagsArticlesUntagCreateMutationError = ErrorType<void>

/**
 * @summary Untag article
 */
export const useTagsArticlesUntagCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsArticlesUntagCreateHook>>>,
    TError,
    { idBasket: number; data: ArticleBasketDelete },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTagsArticlesUntagCreateHook>>>,
  TError,
  { idBasket: number; data: ArticleBasketDelete },
  TContext
> => {
  const mutationOptions = useTagsArticlesUntagCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * List reports that can be made based on tag.
 * @summary List reports
 */
export const useTagsReportsListHook = () => {
  const tagsReportsList = useCustomAxiosInstance<TagReport[]>()

  return useCallback(
    (idBasket: number, params?: TagsReportsListParams, signal?: AbortSignal) => {
      return tagsReportsList({ url: `/tags/${idBasket}/reports/`, method: 'GET', params, signal })
    },
    [tagsReportsList],
  )
}

export const getTagsReportsListQueryKey = (idBasket: number, params?: TagsReportsListParams) => {
  return [`/tags/${idBasket}/reports/`, ...(params ? [params] : [])] as const
}

export const useTagsReportsListQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useTagsReportsListHook>>>,
  TError = ErrorType<unknown>,
>(
  idBasket: number,
  params?: TagsReportsListParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useTagsReportsListHook>>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getTagsReportsListQueryKey(idBasket, params)

  const tagsReportsList = useTagsReportsListHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useTagsReportsListHook>>>> = ({ signal }) =>
    tagsReportsList(idBasket, params, signal)

  return { queryKey, queryFn, enabled: !!idBasket, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsReportsListHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type TagsReportsListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useTagsReportsListHook>>>>
export type TagsReportsListQueryError = ErrorType<unknown>

/**
 * @summary List reports
 */
export const useTagsReportsList = <
  TData = Awaited<ReturnType<ReturnType<typeof useTagsReportsListHook>>>,
  TError = ErrorType<unknown>,
>(
  idBasket: number,
  params?: TagsReportsListParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useTagsReportsListHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTagsReportsListQueryOptions(idBasket, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Sort articles based on parameters.
 * @summary Sort articles
 */
export const useSortCreateIdHook = () => {
  const sortCreateId = useCustomAxiosInstance<void>()

  return useCallback(
    (idBasket: number, sortTagContent: SortTagContent) => {
      return sortCreateId({
        url: `/tags/${idBasket}/sort/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: sortTagContent,
      })
    },
    [sortCreateId],
  )
}

export const useSortCreateIdMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSortCreateIdHook>>>,
    TError,
    { idBasket: number; data: SortTagContent },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSortCreateIdHook>>>,
  TError,
  { idBasket: number; data: SortTagContent },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const sortCreateId = useSortCreateIdHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSortCreateIdHook>>>,
    { idBasket: number; data: SortTagContent }
  > = (props) => {
    const { idBasket, data } = props ?? {}

    return sortCreateId(idBasket, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SortCreateIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSortCreateIdHook>>>>
export type SortCreateIdMutationBody = SortTagContent
export type SortCreateIdMutationError = ErrorType<void>

/**
 * @summary Sort articles
 */
export const useSortCreateId = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSortCreateIdHook>>>,
    TError,
    { idBasket: number; data: SortTagContent },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useSortCreateIdHook>>>,
  TError,
  { idBasket: number; data: SortTagContent },
  TContext
> => {
  const mutationOptions = useSortCreateIdMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Called when a tagged article is moved to a different position (e.g. using drag & drop in a portal for instance)
 * @summary Change order of articles.
 */
export const useTagsSortCustomCreateHook = () => {
  const tagsSortCustomCreate = useCustomAxiosInstance<Updated>()

  return useCallback(
    (idBasket: number, articles: Articles) => {
      return tagsSortCustomCreate({
        url: `/tags/${idBasket}/sort/custom/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: articles,
      })
    },
    [tagsSortCustomCreate],
  )
}

export const useTagsSortCustomCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsSortCustomCreateHook>>>,
    TError,
    { idBasket: number; data: Articles },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTagsSortCustomCreateHook>>>,
  TError,
  { idBasket: number; data: Articles },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const tagsSortCustomCreate = useTagsSortCustomCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTagsSortCustomCreateHook>>>,
    { idBasket: number; data: Articles }
  > = (props) => {
    const { idBasket, data } = props ?? {}

    return tagsSortCustomCreate(idBasket, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TagsSortCustomCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTagsSortCustomCreateHook>>>
>
export type TagsSortCustomCreateMutationBody = Articles
export type TagsSortCustomCreateMutationError = ErrorType<void>

/**
 * @summary Change order of articles.
 */
export const useTagsSortCustomCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsSortCustomCreateHook>>>,
    TError,
    { idBasket: number; data: Articles },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTagsSortCustomCreateHook>>>,
  TError,
  { idBasket: number; data: Articles },
  TContext
> => {
  const mutationOptions = useTagsSortCustomCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Get a tag by id_list.
 * @summary Retrieve
 */
export const useTagsRetrieveHook = () => {
  const tagsRetrieve = useCustomAxiosInstance<TagDetail>()

  return useCallback(
    (idList: number, signal?: AbortSignal) => {
      return tagsRetrieve({ url: `/tags/${idList}/`, method: 'GET', signal })
    },
    [tagsRetrieve],
  )
}

export const getTagsRetrieveQueryKey = (idList: number) => {
  return [`/tags/${idList}/`] as const
}

export const useTagsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useTagsRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  idList: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useTagsRetrieveHook>>>, TError, TData> },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getTagsRetrieveQueryKey(idList)

  const tagsRetrieve = useTagsRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useTagsRetrieveHook>>>> = ({ signal }) =>
    tagsRetrieve(idList, signal)

  return { queryKey, queryFn, enabled: !!idList, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type TagsRetrieveQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useTagsRetrieveHook>>>>
export type TagsRetrieveQueryError = ErrorType<void>

/**
 * @summary Retrieve
 */
export const useTagsRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useTagsRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  idList: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useTagsRetrieveHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTagsRetrieveQueryOptions(idList, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a tag.
 * @summary Update
 */
export const useTagsUpdateHook = () => {
  const tagsUpdate = useCustomAxiosInstance<TagDetail>()

  return useCallback(
    (idList: number, tagDetail: NonReadonly<TagDetail>) => {
      return tagsUpdate({
        url: `/tags/${idList}/`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: tagDetail,
      })
    },
    [tagsUpdate],
  )
}

export const useTagsUpdateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsUpdateHook>>>,
    TError,
    { idList: number; data: NonReadonly<TagDetail> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTagsUpdateHook>>>,
  TError,
  { idList: number; data: NonReadonly<TagDetail> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const tagsUpdate = useTagsUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTagsUpdateHook>>>,
    { idList: number; data: NonReadonly<TagDetail> }
  > = (props) => {
    const { idList, data } = props ?? {}

    return tagsUpdate(idList, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TagsUpdateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useTagsUpdateHook>>>>
export type TagsUpdateMutationBody = NonReadonly<TagDetail>
export type TagsUpdateMutationError = ErrorType<void>

/**
 * @summary Update
 */
export const useTagsUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsUpdateHook>>>,
    TError,
    { idList: number; data: NonReadonly<TagDetail> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTagsUpdateHook>>>,
  TError,
  { idList: number; data: NonReadonly<TagDetail> },
  TContext
> => {
  const mutationOptions = useTagsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Partially update a tag.
 * @summary Partial update
 */
export const useTagsPartialUpdateHook = () => {
  const tagsPartialUpdate = useCustomAxiosInstance<TagDetail>()

  return useCallback(
    (idList: number, patchedTagDetail: NonReadonly<PatchedTagDetail>) => {
      return tagsPartialUpdate({
        url: `/tags/${idList}/`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: patchedTagDetail,
      })
    },
    [tagsPartialUpdate],
  )
}

export const useTagsPartialUpdateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsPartialUpdateHook>>>,
    TError,
    { idList: number; data: NonReadonly<PatchedTagDetail> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTagsPartialUpdateHook>>>,
  TError,
  { idList: number; data: NonReadonly<PatchedTagDetail> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const tagsPartialUpdate = useTagsPartialUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTagsPartialUpdateHook>>>,
    { idList: number; data: NonReadonly<PatchedTagDetail> }
  > = (props) => {
    const { idList, data } = props ?? {}

    return tagsPartialUpdate(idList, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TagsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTagsPartialUpdateHook>>>
>
export type TagsPartialUpdateMutationBody = NonReadonly<PatchedTagDetail>
export type TagsPartialUpdateMutationError = ErrorType<void>

/**
 * @summary Partial update
 */
export const useTagsPartialUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsPartialUpdateHook>>>,
    TError,
    { idList: number; data: NonReadonly<PatchedTagDetail> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTagsPartialUpdateHook>>>,
  TError,
  { idList: number; data: NonReadonly<PatchedTagDetail> },
  TContext
> => {
  const mutationOptions = useTagsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Delete a tag.
 * @summary Delete
 */
export const useTagsDestroyHook = () => {
  const tagsDestroy = useCustomAxiosInstance<void>()

  return useCallback(
    (idList: number) => {
      return tagsDestroy({ url: `/tags/${idList}/`, method: 'DELETE' })
    },
    [tagsDestroy],
  )
}

export const useTagsDestroyMutationOptions = <
  TError = ErrorType<DeleteTagResponse | void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsDestroyHook>>>,
    TError,
    { idList: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTagsDestroyHook>>>,
  TError,
  { idList: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const tagsDestroy = useTagsDestroyHook()

  const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useTagsDestroyHook>>>, { idList: number }> = (
    props,
  ) => {
    const { idList } = props ?? {}

    return tagsDestroy(idList)
  }

  return { mutationFn, ...mutationOptions }
}

export type TagsDestroyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useTagsDestroyHook>>>>

export type TagsDestroyMutationError = ErrorType<DeleteTagResponse | void>

/**
 * @summary Delete
 */
export const useTagsDestroy = <TError = ErrorType<DeleteTagResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsDestroyHook>>>,
    TError,
    { idList: number },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTagsDestroyHook>>>,
  TError,
  { idList: number },
  TContext
> => {
  const mutationOptions = useTagsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Get when tag was last sorted
 * @summary Tag last sorted at
 */
export const useTagsLastSortedRetrieveHook = () => {
  const tagsLastSortedRetrieve = useCustomAxiosInstance<LastSortedResponse>()

  return useCallback(
    (idList: number, signal?: AbortSignal) => {
      return tagsLastSortedRetrieve({ url: `/tags/${idList}/last-sorted/`, method: 'GET', signal })
    },
    [tagsLastSortedRetrieve],
  )
}

export const getTagsLastSortedRetrieveQueryKey = (idList: number) => {
  return [`/tags/${idList}/last-sorted/`] as const
}

export const useTagsLastSortedRetrieveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useTagsLastSortedRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  idList: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useTagsLastSortedRetrieveHook>>>, TError, TData>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getTagsLastSortedRetrieveQueryKey(idList)

  const tagsLastSortedRetrieve = useTagsLastSortedRetrieveHook()

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useTagsLastSortedRetrieveHook>>>> = ({ signal }) =>
    tagsLastSortedRetrieve(idList, signal)

  return { queryKey, queryFn, enabled: !!idList, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsLastSortedRetrieveHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type TagsLastSortedRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTagsLastSortedRetrieveHook>>>
>
export type TagsLastSortedRetrieveQueryError = ErrorType<void>

/**
 * @summary Tag last sorted at
 */
export const useTagsLastSortedRetrieve = <
  TData = Awaited<ReturnType<ReturnType<typeof useTagsLastSortedRetrieveHook>>>,
  TError = ErrorType<void>,
>(
  idList: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useTagsLastSortedRetrieveHook>>>, TError, TData>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTagsLastSortedRetrieveQueryOptions(idList, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Detect tagged articles
 */
export const useTagsDetectTaggedCreateHook = () => {
  const tagsDetectTaggedCreate = useCustomAxiosInstance<TaggedArticleDetectionResponse[]>()

  return useCallback(
    (taggedArticleDetection: TaggedArticleDetection) => {
      return tagsDetectTaggedCreate({
        url: `/tags/detect_tagged/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: taggedArticleDetection,
      })
    },
    [tagsDetectTaggedCreate],
  )
}

export const useTagsDetectTaggedCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsDetectTaggedCreateHook>>>,
    TError,
    { data: TaggedArticleDetection },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTagsDetectTaggedCreateHook>>>,
  TError,
  { data: TaggedArticleDetection },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const tagsDetectTaggedCreate = useTagsDetectTaggedCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTagsDetectTaggedCreateHook>>>,
    { data: TaggedArticleDetection }
  > = (props) => {
    const { data } = props ?? {}

    return tagsDetectTaggedCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TagsDetectTaggedCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTagsDetectTaggedCreateHook>>>
>
export type TagsDetectTaggedCreateMutationBody = TaggedArticleDetection
export type TagsDetectTaggedCreateMutationError = ErrorType<void>

/**
 * @summary Detect tagged articles
 */
export const useTagsDetectTaggedCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsDetectTaggedCreateHook>>>,
    TError,
    { data: TaggedArticleDetection },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTagsDetectTaggedCreateHook>>>,
  TError,
  { data: TaggedArticleDetection },
  TContext
> => {
  const mutationOptions = useTagsDetectTaggedCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Sort tags
 */
export const useTagsSortCreateHook = () => {
  const tagsSortCreate = useCustomAxiosInstance<void>()

  return useCallback(
    (tagSort: TagSort) => {
      return tagsSortCreate({
        url: `/tags/sort/`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: tagSort,
      })
    },
    [tagsSortCreate],
  )
}

export const useTagsSortCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsSortCreateHook>>>,
    TError,
    { data: TagSort },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTagsSortCreateHook>>>,
  TError,
  { data: TagSort },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const tagsSortCreate = useTagsSortCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTagsSortCreateHook>>>,
    { data: TagSort }
  > = (props) => {
    const { data } = props ?? {}

    return tagsSortCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TagsSortCreateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useTagsSortCreateHook>>>>
export type TagsSortCreateMutationBody = TagSort
export type TagsSortCreateMutationError = ErrorType<void>

/**
 * @summary Sort tags
 */
export const useTagsSortCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTagsSortCreateHook>>>,
    TError,
    { data: TagSort },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTagsSortCreateHook>>>,
  TError,
  { data: TagSort },
  TContext
> => {
  const mutationOptions = useTagsSortCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
