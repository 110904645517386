import { cn } from '@opoint/infomedia-storybook'
import { HTMLAttributes, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { TagsTourTarget } from '../../productTour/ProductTours/TagsTour'

import MoreActionsMenu from './MoreActionsMenu'
import StatisticsViewSwitch from './search/StatisticsViewSwitch'
import { ActionBarRightItem, ActionVariant, AvailablePosition } from './types'

type ActionBarRightProps = HTMLAttributes<HTMLDivElement> & { items: ActionBarRightItem[]; switcherVisible: boolean }

const INSIDE_BUTTON_GAP = 12
const GAP_BETWEEN_BUTTONS = 4
const ICON_W = 24
const FONT = 16
const BUTTON_PADDING = 16
const LIMIT = 800
const ACTION_BUTTON_FALLBACK_WIDTH = 40
const MAX_ITEMS = 5

export const ActionBarRight = ({ items, className, switcherVisible, ...props }: ActionBarRightProps) => {
  const container = useRef<HTMLDivElement>(null)
  const [actualButtonsVariant, setActualButtonVariant] = useState(ActionVariant.LONG)
  const [cursor, setCursor] = useState(0)

  const actionBarItemsWithoutMenu = items.filter((item) => item.allowedPosition !== AvailablePosition.Menu)
  const actionBarItems = actionBarItemsWithoutMenu.sort(
    ({ allowedPosition: availablePositionA = 2 }, { allowedPosition: availablePositionB = 2 }) =>
      availablePositionA > availablePositionB ? 1 : availablePositionA < availablePositionB ? -1 : 0,
  )
  const onlyBarItems = items.filter((item) => item.allowedPosition === AvailablePosition.Bar)
  const onlyMenuItems = items.filter((item) => item.allowedPosition === AvailablePosition.Menu)

  const sliceValue = cursor > onlyBarItems.length ? cursor : onlyBarItems.length

  const actionMenuItems = actionBarItems.slice(sliceValue, actionBarItems.length)

  const moreButtonVisible = actionMenuItems.length + onlyMenuItems.length > 0

  const actionButtonsWidths = useMemo(() => {
    const AVERAGE_CHAR_WIDTH = 0.7

    const calculateButtonBoundingBox = (buttonSetting: ActionBarRightItem) => {
      return (
        buttonSetting.name.length * (FONT * AVERAGE_CHAR_WIDTH) +
        2 * BUTTON_PADDING +
        ICON_W +
        INSIDE_BUTTON_GAP +
        GAP_BETWEEN_BUTTONS
      )
    }

    return actionBarItems.map((item) => calculateButtonBoundingBox(item))
  }, [actionBarItems])

  const computeMaxItems = useCallback(
    (availableWidth: number, variant = ActionVariant.LONG) => {
      let remainingSize = availableWidth

      return actionButtonsWidths.reduce((count, item) => {
        const itemWidth = variant === ActionVariant.LONG ? item : ACTION_BUTTON_FALLBACK_WIDTH + GAP_BETWEEN_BUTTONS

        remainingSize = remainingSize - itemWidth
        if (remainingSize >= 0) {
          return count + 1
        }

        return count
      }, 0)
    },
    [actionButtonsWidths],
  )

  const updateCursor = useCallback(() => {
    if (!container.current) {
      return
    }

    const SAFETY_MARGIN = 10
    const containerWidth = container.current.offsetWidth
    let availableWidth = containerWidth - SAFETY_MARGIN

    if (switcherVisible) {
      availableWidth -= 3 * ACTION_BUTTON_FALLBACK_WIDTH
    }

    if (moreButtonVisible) {
      availableWidth -= ACTION_BUTTON_FALLBACK_WIDTH
    }

    const isShortVariant = containerWidth < LIMIT
    const variant = isShortVariant ? ActionVariant.SHORT : ActionVariant.LONG
    const maxItems = computeMaxItems(availableWidth, variant)

    setActualButtonVariant(variant)
    setCursor(maxItems > MAX_ITEMS ? MAX_ITEMS : maxItems)
  }, [computeMaxItems, moreButtonVisible, switcherVisible])

  useLayoutEffect(() => {
    if (!container.current) {
      return
    }

    const resizeObserver = new ResizeObserver(updateCursor)
    resizeObserver.observe(container.current)

    window.addEventListener('resize', updateCursor)

    return () => {
      resizeObserver.disconnect()
      window.removeEventListener('resize', updateCursor)
    }
  }, [updateCursor])

  return (
    <div
      id={TagsTourTarget.STEP_2}
      className={cn('flex h-11 w-full flex-nowrap items-center justify-end gap-1', className)}
      ref={container}
      {...props}
    >
      {switcherVisible && <StatisticsViewSwitch variant={actualButtonsVariant} className="mr-9 bg-white" />}
      {actionBarItems.slice(0, sliceValue).map(({ Action, name }, index) => {
        if (actualButtonsVariant === ActionVariant.LONG) {
          return <Action key={index} name={name} />
        }

        return <Action key={index} variant={ActionVariant.SHORT} name={name} />
      })}

      {moreButtonVisible && (
        <MoreActionsMenu>
          {[...actionMenuItems, ...onlyMenuItems].map(({ Action, name }, index) => (
            <Action key={index} variant={ActionVariant.MENU} name={name} />
          ))}
        </MoreActionsMenu>
      )}
    </div>
  )
}
