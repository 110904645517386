import * as R from 'ramda'
import { DOM } from 'rx-dom'

import { AlertTag, ChildAlertTag, GeneralTag, Tag } from '../../components/types/tag'
import { getAllIdenticalArticles } from '../articles'
import config from '../common/config'
import { OpointTimestampToTimestamp } from '../common/time'

import { Trash } from '../../components/types/trash'
import { M360Article } from '../articles/types'
import { TAG_TYPES } from './index'

/**
 * Functions
 */
// Fetch trash tags from server
export async function getTrashTags(): Promise<Array<Trash>> {
  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/trash/'),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

// Delete article via trash tag
export async function deleteArticles(articles: Array<M360Article>, tag: Trash): Promise<any> {
  const stimestamp = OpointTimestampToTimestamp()
  const allArticles = articles.flatMap(getAllIdenticalArticles)
  const articlesIds = allArticles.flatMap(({ id_site, id_article }) => ({
    id_site,
    id_article,
    stimestamp,
  }))

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/articles/delete/'),
    method: 'post',
    body: JSON.stringify({
      articles: articlesIds,
      profile: tag.type === TAG_TYPES.GLOBAL_TRASH ? undefined : tag.profileId,
    }),
  })

  return (
    DOM.ajax(requestHeaders)
      .toPromise()
      .then(({ response }) => response)
      // @ts-expect-error: Bad typings
      .catch(({ status }) => {
        // @ts-expect-error Muted so we could enable TS strict mode
        return Promise.reject(new Error({ articles, status }))
      })
  )
}

// Undelete article via trash tags
export async function undeleteArticles(articles: Array<M360Article>, { id, type }: GeneralTag): Promise<any> {
  const stimestamp = OpointTimestampToTimestamp()
  const articlesIds = articles.map(({ id_site, id_article }) => ({ id_site, id_article, stimestamp })).flat()
  const profile = type === TAG_TYPES.GLOBAL_TRASH ? null : { profile: Math.abs(id) }

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api('/articles/undelete/'),
    method: 'post',
    body: JSON.stringify({
      articles: articlesIds,
      ...profile,
    }),
  })

  return (
    DOM.ajax(requestHeaders)
      .toPromise()
      .then(({ response }) => response)
      // @ts-expect-error: Bad typings
      .catch(({ status }) => {
        // @ts-expect-error Muted so we could enable TS strict mode
        return Promise.reject(new Error({ articles, status }))
      })
  )
}

// Returns array of visible trash tags in entity listing based on active profiles
// or on selected trash tag (in search line).
// Array is sorted in a way that global trash tags are always first
export function getVisibleTrashTags(
  trashTags: Array<Trash>,
  profileIds: Array<number>,
  trashTagFilterIds: Array<number>,
): Array<Trash> {
  return trashTags.filter(
    // @ts-expect-error: Muted so we could enable TS strict mode
    (tag) => profileIds.includes(tag?.profileId) || tag.globalTrash || trashTagFilterIds.includes(tag.id),
  )
}

// Return trash tags displayed in article footer
export function getTrashTagsVisibleOnArticle(
  trashTags: Array<Trash>,
  trashInListing: Array<Trash>,
  visibleTrashTags: Array<number> = [],
  trashTagFilterIds: Array<number> = [],
  articleTrashTags: Array<number> = [],
): Array<Trash> {
  return R.uniq([
    ...trashInListing.filter((tag) => visibleTrashTags.includes(tag.id) || trashTagFilterIds.includes(tag.id)),
    ...trashTags.filter((tag) => articleTrashTags.includes(tag.id)),
  ])
}

// Return ids with correct symbol as a string for search
// in order to get all relevant tag data and articles
export function generateBaskets(
  tags: Array<Tag>,
  trashTags: Array<Trash>,
  alertTags: Array<AlertTag | ChildAlertTag>,
): string {
  return R.uniq(
    [...tags, ...trashTags, ...alertTags]
      .filter(({ id }) => id)
      .map((tag) => ({ ...tag, id: Math.abs(tag.id) }))
      ?.map(({ id }) => +id),
  ).join(',')
}
