import React, { useState, useEffect, useRef } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export type EditableTextProps = {
  bodyText?: string
  onContentChange?: ({ body }) => void
}

const EditableText: React.FC<EditableTextProps> = ({ bodyText = '', onContentChange }) => {
  const editorRef = useRef<any>(null)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (isReady && editorRef.current && bodyText !== editorRef.current.getData()) {
      const selection = editorRef.current.model.document.selection
      const range = selection.getFirstRange()
      const viewPosition = editorRef.current.editing.mapper.toViewPosition(range.end)
      const domPosition = editorRef.current.editing.view.domConverter.viewPositionToDom(viewPosition)

      editorRef.current.setData(bodyText)

      if (domPosition && domPosition.parent) {
        const range = document.createRange()
        range.setStart(domPosition.parent, domPosition.offset)
        range.collapse(true)

        const selection = window.getSelection()
        selection?.removeAllRanges()
        selection?.addRange(range)
      }
    }
  }, [bodyText, isReady])

  const handleEditorReady = (editor: any) => {
    editorRef.current = editor
    setIsReady(true)
  }

  const handleChange = (_: any, editor: any) => {
    const data = editor.getData()
    onContentChange?.({
      body: {
        text: data,
      },
    })
  }

  return (
    <CKEditor
      editor={ClassicEditor}
      data={bodyText}
      config={{
        toolbar: [],
      }}
      onReady={handleEditorReady}
      onChange={handleChange}
    />
  )
}

export default EditableText
