const SCRIPT_ID = 'intercom'

const init = (userId: string) => {
  const script = document.createElement('script')
  script.setAttribute('id', SCRIPT_ID)
  script.setAttribute('type', 'text/javascript')
  script.text = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/bz7n19z7';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`
  document.body.appendChild(script)

  window.Intercom?.('boot', {
    api_base: 'https://api-iam.eu.intercom.io',
    app_id: 'bz7n19z7',
    user_id: userId,
  })
}

const destroy = () => {
  window.Intercom?.('shutdown')
  const script = document.getElementById(SCRIPT_ID)
  if (script) {
    document.body.removeChild(script)
  }
}

export { init, destroy }
