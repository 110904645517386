import { useEffect } from 'react'

export const useResizeObserverErrorDisabled = () => {
  const errorHandler = (e: ErrorEvent) => {
    if (
      e.message === 'ResizeObserver loop limit exceeded' ||
      e.message === 'ResizeObserver loop completed with undelivered notifications.'
    ) {
      const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
      const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none')
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none')
      }
      e.stopImmediatePropagation()
      e.preventDefault()
    }
  }

  useEffect(() => {
    window.addEventListener('error', errorHandler)

    return () => window.removeEventListener('error', errorHandler)
  }, [])
}
