import { Loader, TabsTrigger } from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'

import { useSearchParams } from 'react-router-dom'
import { useAuthInviteSearchRetrieve } from '../../../../../api/auth/auth'
import { UserManagementTab } from '../../../../types/userManagement'

const AllTabTrigger = () => {
  const [searchParams] = useSearchParams()
  const searchTerm = searchParams.get('search') ?? ''

  const { data, isLoading, isFetching } = useAuthInviteSearchRetrieve(
    { page_size: 1, q: searchTerm },
    { query: { staleTime: 5000 } },
  )

  return (
    <TabsTrigger className="gap-x-2" value={UserManagementTab.ALL}>
      <Trans>All</Trans>
      {isFetching || isLoading ? <Loader size="tiny" /> : <span>({data?.count})</span>}
    </TabsTrigger>
  )
}

export default AllTabTrigger
