import { createSelector } from 'reselect'

import { getComments as getState } from './rootSelectors'

export const getArticleComments = createSelector(getState, (commentsState) => commentsState.comments)

export const getAlteredArticleComments = createSelector(
  getState,
  (commentsState) => commentsState.alteredArticleComments,
)

export const getCommentsModalState = createSelector(getState, (commentsState) => commentsState.commentsModalOpen)

export const getArticleDetails = createSelector(getState, (commentsState) => commentsState.articleDetails)
