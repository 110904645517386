import { t } from "i18next";

/**
 * Extracts error message from the API error response.
 * @param {{error: string}} response  Error response body from the API.
 * @param {string} message Fallback error message.
 * @return {String} Error message from the API.
 */
export function extractErrorFromResponse(
  response: { error: string },
  message: string | undefined = t('Unknown error, please contact support.'),
): string {
  try {
    return JSON.parse(response.error).error
  } catch (SyntaxError) {
    return message
  }
}
