import { Row } from '@tanstack/react-table'
import {
  ContactRecipient,
  DirectRecipient,
  GroupRecipient,
  GroupRecipientChild,
  MailSentStatus,
  Recipient,
  UserRecipient,
} from '../../../api/opoint.schemas'
import { InvitationSentStatus } from '../../types/userManagement'
import { RecipientNameCellData, GroupRecipientWithStatus, RecipientWithGroupStatus } from './types'

export const isDirectRecipient = (recipient: Recipient | GroupRecipientChild): recipient is DirectRecipient => {
  return 'type' in recipient && (recipient.type === 'email' || recipient.type === 'mobile')
}

export const isContactRecipient = (recipient: Recipient | GroupRecipientChild): recipient is ContactRecipient => {
  return 'type' in recipient && recipient.type === 'contact'
}

export const isUserRecipient = (recipient: Recipient | GroupRecipientChild): recipient is UserRecipient => {
  return 'type' in recipient && recipient.type === 'user'
}

export const isGroupRecipient = (recipient: Recipient | GroupRecipientChild): recipient is GroupRecipient => {
  return 'type' in recipient && recipient.type === 'group'
}

export const isGroupRecipientChild = (recipient: Recipient | GroupRecipientChild): recipient is GroupRecipientChild => {
  return !('type' in recipient)
}

export const getMailSentStatusSeverity = (status: MailSentStatus) => {
  switch (status) {
    case MailSentStatus.Failed:
    case MailSentStatus.Filtered:
      return 3
    case MailSentStatus.Processing:
      return 2
    case MailSentStatus.Delivered:
      return 1
    case MailSentStatus.Unknown:
    default:
      return 0
  }
}

export const getMailSentStatusSortingOrder = (status: MailSentStatus, isMixed?: boolean) => {
  switch (status) {
    case MailSentStatus.Delivered:
      return 1
    case MailSentStatus.Processing:
      return 2
    case MailSentStatus.Failed:
      return isMixed ? 5 : 3
    case MailSentStatus.Filtered:
      return isMixed ? 5 : 4
    case MailSentStatus.Unknown:
    default:
      return 6
  }
}

export const getGroupRecipientWithStatus = (recipient: GroupRecipient): GroupRecipientWithStatus => {
  const status = recipient.contacts.reduce<MailSentStatus>((previousValue, { status }) => {
    return getMailSentStatusSeverity(status) > getMailSentStatusSeverity(previousValue) ? status : previousValue
  }, MailSentStatus.Unknown)

  const mixed = recipient.contacts.some((contact) => contact.status !== status)

  return { ...recipient, status, isMixed: mixed }
}

export const getMailSentStatusLabel = (value: GeneralStatusType) => {
  if (value === 'Failed') {
    return 'Not delivered'
  }
  if (value === 'Filtered') {
    return 'Not sent'
  }
  return value
}

export const GeneralStatus = {
  ...InvitationSentStatus,
  ...MailSentStatus,
} as const

export type GeneralStatusType = (typeof GeneralStatus)[keyof typeof GeneralStatus]

export const handleSearchFilter = (recipient: Recipient | GroupRecipientChild, searchTerm: string) => {
  if (isDirectRecipient(recipient)) {
    return recipient.value.toLowerCase().includes(searchTerm)
  }
  if (isGroupRecipient(recipient)) {
    return (
      recipient.name.toLowerCase().includes(searchTerm) ||
      recipient.contacts.some(
        (item) =>
          item.name?.toLowerCase().includes(searchTerm) ||
          item.email?.toLowerCase().includes(searchTerm) ||
          item.mobile?.toLowerCase().includes(searchTerm),
      )
    )
  }
  return !!(
    recipient.name?.toLowerCase().includes(searchTerm) ||
    recipient.email?.toLowerCase().includes(searchTerm) ||
    recipient.mobile?.toLowerCase().includes(searchTerm)
  )
}

export const getRecipientCount = (recipients: Recipient[]) => {
  return recipients.reduce(
    (count, recipient) => (isGroupRecipient(recipient) ? count + recipient.contacts.length : count + 1),
    0,
  )
}

export const getRecipientEmail = (recipient: RecipientWithGroupStatus | GroupRecipientChild): RecipientNameCellData => {
  if (isDirectRecipient(recipient)) {
    return { email: recipient.value, inPreviousSending: recipient.in_previous_sending }
  }
  if (isGroupRecipient(recipient)) {
    return { email: recipient.name, inPreviousSending: true }
  }
  return {
    email: recipient.email ?? '',
    name: recipient.name ?? '',
    inPreviousSending: recipient.in_previous_sending,
  }
}

export const statusSortingFunction = (
  rowA: Row<RecipientWithGroupStatus | GroupRecipientChild>,
  rowB: Row<RecipientWithGroupStatus | GroupRecipientChild>,
  columnId: string,
) => {
  const rowAvalue = rowA.getValue<{ status: MailSentStatus; isMixed: boolean }>(columnId)
  const rowBvalue = rowB.getValue<{ status: MailSentStatus; isMixed: boolean }>(columnId)
  const rowAseverity = getMailSentStatusSortingOrder(rowAvalue.status)
  const rowBseverity = getMailSentStatusSortingOrder(rowBvalue.status)

  if (rowAseverity === rowBseverity) {
    return 0
  }
  return rowAseverity - rowBseverity
}
