import { Dismiss24Regular } from '@fluentui/react-icons'
import { Button, cn } from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'
import { TooltipRenderProps } from 'react-joyride'

const ProductTourTooltip = ({
  backProps,
  index,
  isLastStep,
  primaryProps,
  size,
  skipProps,
  step: { content, title, showSkipButton, showProgress },
  tooltipProps,
}: TooltipRenderProps) => (
  <div
    className="pointer-events-auto flex max-w-sm flex-col justify-between gap-6 rounded bg-white p-6"
    {...tooltipProps}
  >
    {showSkipButton && (
      <div className="flex justify-end">
        <Button
          variant="outline"
          className="h-8 cursor-pointer border-0 p-0 hover:bg-grey.6"
          name="default"
          {...skipProps}
        >
          <Dismiss24Regular className="size-6 fill-sky.1" />
          <span className="sr-only">
            <Trans>Skip product tour</Trans>
          </span>
        </Button>
      </div>
    )}

    {title && <span className="text-lg">{title}</span>}

    <span className="text-lg">{content}</span>

    {showProgress && (
      <div className="flex justify-center gap-x-1">
        {Array.from({ length: size }, (_, i) => (
          <div key={i} className={cn('size-2 rounded-full', i === index ? 'bg-sky.4' : 'bg-sky.7')} />
        ))}
      </div>
    )}

    <div className={cn('grid gap-4', index > 0 ? 'grid-cols-2' : 'grid-cols-1')}>
      {index > 0 && (
        <Button variant="outline" {...backProps}>
          <Trans>Back</Trans>
        </Button>
      )}

      <Button {...primaryProps}>{isLastStep ? <Trans>Close</Trans> : <Trans>Continue</Trans>}</Button>
    </div>
  </div>
)

export default ProductTourTooltip
