import { ArrowLeft24Regular } from '@fluentui/react-icons'
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@opoint/infomedia-storybook'

import { useNavigate } from 'react-router-dom'
import { ActionBarDropdownMenuItem } from '../../ActionBarDropdownMenuItem'
import { ActionBarActionsProps, ActionVariant } from '../../types'
import { useRequiredParams } from '../../../../hooks/useRequiredParams'

const AlertViewInBrowserEnd = ({ variant = ActionVariant.LONG, name }: ActionBarActionsProps) => {
  const navigate = useNavigate()
  const { alertId, mailLogId } = useRequiredParams(['alertId', 'mailLogId'])

  const handleClick = () => navigate(`/alerts/${alertId}/${mailLogId}`)

  if (variant === ActionVariant.MENU) {
    return (
      <ActionBarDropdownMenuItem onClick={handleClick} data-cy="alertViewInBrowserEndBtn">
        <ArrowLeft24Regular />
        {name}
      </ActionBarDropdownMenuItem>
    )
  }

  if (variant === ActionVariant.LONG) {
    return (
      <Button variant="outline" onClick={handleClick} data-cy="alertViewInBrowserEndBtn">
        <ArrowLeft24Regular />
        {name}
      </Button>
    )
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="outline" size="icon" onClick={handleClick} data-cy="alertViewInBrowserEndBtn">
          <ArrowLeft24Regular />
          <span className="sr-only">{name}</span>
        </Button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{name}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  )
}

export default AlertViewInBrowserEnd
