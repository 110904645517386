import { Loader } from '@opoint/infomedia-storybook'
import { Trans, useTranslation } from 'react-i18next'

import { useEffect, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Search48Regular } from '@fluentui/react-icons'
import { useInviteDirectListInfinite } from '../../../../../api/invite/invite'
import UserInvitationsTable from '../UserInvitationsTable'
import { InvitationSentStatusMapping, InvitationsTableItem } from '../../../../types/userManagement'
import { extractPageNumber } from '../../../../../helpers/pagination'
import EmptyData from '../../../../../pages/empty/EmptyData'
import ErrorWithRetry from '../../../../common/ErrorWithRetry'

const InvitationsList = () => {
  const loadingElement = useRef<HTMLDivElement>(null)
  const [searchParams] = useSearchParams()
  const searchTerm = searchParams.get('search') ?? ''
  const { t } = useTranslation()

  const { data, error, hasNextPage, isFetching, fetchNextPage, refetch } = useInviteDirectListInfinite(
    //@ts-expect-error: fix api schema
    { page_size: 15, q: searchTerm },
    {
      query: {
        keepPreviousData: true,
        getNextPageParam: (lastPage) => extractPageNumber(lastPage?.next),
        staleTime: 5000,
      },
    },
  )

  const pages = useMemo(() => data?.pages.filter(Boolean) || [], [data?.pages])
  const allData = useMemo(() => pages.flatMap((page) => page.results) || [], [pages])

  useEffect(() => {
    if (!allData.length || !hasNextPage) {
      return
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries[0]?.isIntersecting) {
        void fetchNextPage()
      }
    })

    if (loadingElement.current) {
      observer.observe(loadingElement.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [allData, fetchNextPage, hasNextPage])

  if (error) {
    return (
      <div className="p-6">
        <ErrorWithRetry
          primaryText={t("Can't load the content right now.")}
          secondaryText={t('Please try again later.')}
          retry={refetch}
        />
      </div>
    )
  }

  if (!data) {
    return (
      <div className="flex size-full items-center justify-center">
        <Loader />
      </div>
    )
  }

  if (allData.length === 0) {
    return (
      <div className="flex size-full items-center justify-center">
        <EmptyData
          text={t('No results found. Try adjusting your search to find what you’re looking for.')}
          icon={Search48Regular}
        />
      </div>
    )
  }

  const preparedData: InvitationsTableItem[] = allData.map((item) => {
    const status = InvitationSentStatusMapping[Number(item?.status)]

    return {
      email: item.email,
      id: Number(item.id),
      invitation: item,
      isUser: false,
      role: item.role,
      status,
    }
  })

  return (
    <div>
      <UserInvitationsTable invitationItems={preparedData} />

      {hasNextPage ? (
        <div ref={loadingElement} className="flex h-16 w-full items-center justify-center p-s">
          {isFetching && <Loader size="small" />}
        </div>
      ) : (
        <p className="mx-auto my-11 w-full text-center text-sm text-sky.cloudy">
          <Trans>You have reached the end. Nothing else to see here.</Trans>
        </p>
      )}
    </div>
  )
}

export default InvitationsList
